/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { GetRoute, GetRouteSWR, PostRoute, PostRouteSWR, PostRouteFD } from '../services/Private';

export const useRequest = (path, method, form, config) => {
    if (method === 'GET') {
        const { data } = useSWR(path, GetRoute);
        return { data };
    }
    if (method === 'GETSWR') {
        const { data, error } = useSWR(path, GetRouteSWR, config);
        return {
            // data: data?.data ?? [],
            data: data ?? [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'GET5MILISEC') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 5000 });
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'GET30MILISEC') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 30000 });
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'getInmutable') {
        const { data, error } = useSWRImmutable(path, GetRouteSWR);
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'POST') {
        const { data } = useSWR([path, form], PostRoute);
        return {
            data: data ?? []
        };
    }
    if (method === 'POSTSWR') {
        const { data, error } = useSWR([path, form], PostRouteSWR, config);
        return {
            //    data: data?.data ?? [],
            data: data ?? [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'postInmutable') {
        const { data, error } = useSWRImmutable([path, form], PostRoute);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        };
    }
    if (method === 'FORMDATA') {
        const { data } = useSWR([path, form], PostRouteFD());
        return { data };
    }
};
