/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import Chart from 'react-apexcharts';

const LineChart = ({ data, label, name, id }) => {
    const options = {
        chart: {
            id: id || ''
        },
        xaxis: {
            categories: label
        }
    };
    const series = [
        {
            name,
            data
        }
    ];

    return id ? (
        <Chart id={id} options={options} series={series} type="line" width="100%" height="300px" />
    ) : (
        <Chart options={options} series={series} type="line" width="100%" height="300px" />
    );
};

export default LineChart;
