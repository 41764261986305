/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute, PostRouteFD } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { LoadingContext } from 'utils/components/loading/context';
import ImgSuccess from 'assets/images/svg/persona/Checklist.svg';
import { UserContext } from 'utils/context/userContext';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Roles, userRoles } = useContext(UserContext);
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    // All data
    const [allData, setAllData] = useState([]);
    const [allDataEstadoCivil, setAllDataEstadoCivil] = useState([]);
    const [allDataGenero, setAllDataGenero] = useState([]);
    const [allDataEtnia, setAllDataEtnia] = useState([]);
    const [allDataPais, setAllDataPais] = useState([]);
    const [allDataTipoPersona, setAllDataTipoPersona] = useState([]);
    const [allDataGradoAcademico, setAllDataGradoAcedemico] = useState([]);
    const [allDataProfesion, setAllDataProfesion] = useState([]);
    const [allDataDepartamento, setAllDataDepartamento] = useState([]);
    const [allDataMunicipio, setAllDataMunicipio] = useState([]);
    const [allDataFederacion, setAllDataFederacion] = useState([]);
    const [allDataLinea, setAllDataLinea] = useState([]);
    const [allDataRanking, setAllDataRanking] = useState([]);
    const [allDataDeporteAdaptado, setAllDataDeporteAdaptado] = useState([]);
    // One Data
    const [oneData, setOneData] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'persona';
    const All = async (data) => {
        await setShowLoad(true);
        if (data) {
            const response = await PostRoute(`${nameController}/all`, { tipoPersona: data });
            setAllData(response.length ? response : []);
        } else {
            setAllData([]);
        }
        await setShowLoad(false);
    };
    // Label
    const AllEstadoCivil = async () => {
        const response = await GetRoute(`estadocivil/label`);
        setAllDataEstadoCivil(response.length ? response : []);
    };
    const AllGenero = async () => {
        const response = await GetRoute(`genero/label`);
        setAllDataGenero(response.length ? response : []);
    };
    const AllPais = async () => {
        const response = await GetRoute(`pais/label`);
        setAllDataPais(response.length ? response : []);
    };
    const AllGradoAcademico = async () => {
        const response = await GetRoute(`gradoacademico/label`);
        setAllDataGradoAcedemico(response.length ? response : []);
    };
    const AllProfesion = async () => {
        const response = await GetRoute(`profesion/label`);
        setAllDataProfesion(response.length ? response : []);
    };
    const AllEtnia = async () => {
        const response = await GetRoute(`etnia/label`);
        setAllDataEtnia(response.length ? response : []);
    };
    const AllDepartamento = async () => {
        const response = await GetRoute(`departamento/label`);
        setAllDataDepartamento(response.length ? response : []);
    };
    const AllMunicipio = async (data) => {
        let response = [];
        if (data) {
            response = await PostRoute(`municipio/label`, { departamento: data.value });
        }
        setAllDataMunicipio(response.length ? response : []);
    };
    const AllFederacion = async () => {
        const response = await GetRoute(`federacion/label`);
        setAllDataFederacion(response.length ? response : []);
    };
    const AllLinea = async () => {
        const response = await GetRoute(`linea/label`);
        setAllDataLinea(response.length ? response : []);
    };
    const AllRanking = async () => {
        const response = await GetRoute(`ranking/label`);
        setAllDataRanking(response.length ? response : []);
    };
    const AllDeporteAdaptado = async () => {
        const response = await GetRoute(`deporteadaptado/label`);
        setAllDataDeporteAdaptado(response.length ? response : []);
    };
    const AllTipoPersona = async () => {
        const response = await GetRoute(`tipoPersona/${userRoles.includes(Roles.administrador) ? 'label' : 'admin-label'}`);
        setAllDataTipoPersona(response.length ? response : []);
    };
    // ---------------------------------------------------------
    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data, url) => {
        setShowLoad(true);
        const response = await PostRouteFD(`${nameController}/${url}`, data);
        Notification(response.message, 1);
        setShowLoad(false);
        return response;
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = {
        All,
        One,
        Actions,
        StoreUpdate,
        toggleModal,
        setOpcion,
        AllMunicipio,
        ImgSuccess,
        allData,
        show,
        oneData,
        opcion,
        allDataEstadoCivil,
        allDataTipoPersona,
        allDataGenero,
        allDataEtnia,
        allDataPais,
        allDataGradoAcademico,
        allDataProfesion,
        allDataDepartamento,
        allDataMunicipio,
        allDataFederacion,
        allDataLinea,
        allDataRanking,
        allDataDeporteAdaptado
    };
    useEffect(() => {
        AllTipoPersona();
        AllEstadoCivil();
        AllGenero();
        AllPais();
        AllGradoAcademico();
        AllProfesion();
        AllEtnia();
        AllDepartamento();
        AllFederacion();
        AllLinea();
        AllRanking();
        AllDeporteAdaptado();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
