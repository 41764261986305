/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import { Modal, Box, Grid, Typography, Button } from '@mui/material';
import BootstrapTooltip from 'utils/components/tooltip/index';
import MainCard from 'utils/components/modal/MainCardModal';
import LineChart from 'utils/charts/lineChart/lineChartAerobica1';
import Barchar from 'utils/charts/barChartH/index';
import BarcharVertical from 'utils/charts/barChartH/barchar';
import ApexCharts from 'apexcharts';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ListadoVisualizar from './listVisualizar';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { PictureAsPdf } from '@mui/icons-material';
import { ContentContext } from '../context';
import { generatePDF } from '../export/evaluacionAerobica/templatePdf';
import { UserContext } from 'utils/context/userContext';

const Visualizar = () => {
    const { user, allDataDetallePruebaAerobica, oneDataPruebaAerobica, showDataPruebaAerobica, setShowDataPruebaAerobica } =
        useContext(ContentContext);
    const { userAuth } = useContext(UserContext);
    const theme = useTheme();
    const [allDataTestWFooter, setAllDataTestWFooter] = useState([]);
    const [allDataTestWHeader, setAllDataTestWHeader] = useState([]);
    const [zonasEntrenamiento, setZonasEntrenamiento] = useState([]);
    const [recuperacion, setRecuperacion] = useState([]);
    const obtenerPesos = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.val2);
        label = items.map((item) => item.tiempo);
        setAllDataTestWFooter(label);
        setAllDataTestWHeader(cantidad);
    };
    useEffect(() => {
        if (allDataDetallePruebaAerobica.length > 0) {
            obtenerPesos(allDataDetallePruebaAerobica);
            const FCE = oneDataPruebaAerobica?.fcE || 0;
            setZonasEntrenamiento([
                parseInt(FCE * 0.95, 10),
                parseInt(FCE * 0.9, 10),
                parseInt(FCE * 0.8, 10),
                parseInt(FCE * 0.7, 10),
                parseInt(FCE * 0.6, 10)
            ]);
            setRecuperacion([
                oneDataPruebaAerobica?.min1Porcentaje,
                oneDataPruebaAerobica?.min2Porcentaje,
                oneDataPruebaAerobica?.min3Porcentaje
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataDetallePruebaAerobica]);

    const Imprimir = async () => {
        const imgArray = [];

        const getChartImage = async (chartId) => {
            const chartElement = document.getElementById(chartId);
            if (chartElement) {
                const chartInstance = await ApexCharts.exec(chartId, 'dataURI');
                if (chartInstance) {
                    const { imgURI } = await chartInstance;
                    imgArray.push(imgURI);
                }
            }
        };

        await getChartImage('chart-id');
        await getChartImage('chart-id2');
        await getChartImage('chart-id3');

        const data = {
            user,
            datosGenerales: oneDataPruebaAerobica,
            allDataDetallePruebaAerobica,
            img: imgArray
        };
        generatePDF([], [], 'PRUEBA AERÓBICA', '', 'prueba-aerobica', data, userAuth.nombreCompleto);
    };

    function DataSection({ label, value, size }) {
        return (
            <Grid item xs={size} className="css-15j76c0">
                <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                    {label}
                </Typography>
                <Typography variant="h4" className="css-17o47au">
                    {value}
                </Typography>
            </Grid>
        );
    }

    return (
        <Box>
            <Modal
                keepMounted
                open={showDataPruebaAerobica}
                onClose={() => setShowDataPruebaAerobica(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Visualizar" size="xl" closeButton={() => setShowDataPruebaAerobica(false)}>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid item xs={1}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <AnimateButton>
                                        <BootstrapTooltip title="Exportar" arrow placement="left">
                                            <Button
                                                onClick={() => Imprimir()}
                                                size="medium"
                                                type="button"
                                                variant="contained"
                                                sx={{
                                                    background: theme.palette.error.main,
                                                    '&:hover': {
                                                        background: theme.palette.error.dark
                                                    },
                                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                                }}
                                            >
                                                <PictureAsPdf fontSize="medium" />
                                            </Button>
                                        </BootstrapTooltip>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* eslint-disable-next-line react/self-closing-comp */}
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box component="fieldset" border={1} borderColor="grey.400" p={1} mb={2} borderRadius={4}>
                                        <Typography component="legend" color="grey.900" fontWeight={700}>
                                            Datos Generales
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <DataSection
                                                label="Tipo de Prueba"
                                                size={6}
                                                value={oneDataPruebaAerobica?.tipoPruebaAerobica || ''}
                                            />
                                            <DataSection label="Tipo de Prueba" size={6} value={oneDataPruebaAerobica?.fecha || ''} />
                                        </Grid>
                                        <Grid sx={{ mt: 1 }} container spacing={2}>
                                            <DataSection label="FC. Max" size={2} value={oneDataPruebaAerobica?.fcMax || ''} />
                                            <DataSection label="Fc. E" size={2} value={oneDataPruebaAerobica?.fcE || ''} />
                                            <DataSection label="Fc. 85%" size={2} value={oneDataPruebaAerobica?.fc85 || ''} />
                                            <DataSection label="Fc. Basal" size={2} value={oneDataPruebaAerobica?.fcBasal || ''} />
                                            <DataSection label="Fc. Factor" size={2} value={oneDataPruebaAerobica?.fcFactor || ''} />
                                        </Grid>
                                    </Box>
                                    <Box component="fieldset" border={1} borderColor="grey.400" p={1} my={2} borderRadius={4}>
                                        <Typography component="legend" color="grey.900" fontWeight={700}>
                                            Observaciones
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <DataSection label="Vo2 Max" size={2} value={oneDataPruebaAerobica?.vo2Max || ''} />
                                            <DataSection label="Vo2" size={2} value={oneDataPruebaAerobica?.vo2 || ''} />
                                            <DataSection label="PO2" size={2} value={oneDataPruebaAerobica?.po2 || ''} />
                                            <DataSection label="METS" size={2} value={oneDataPruebaAerobica?.mets || ''} />
                                            <DataSection label="%FCE" size={2} value={oneDataPruebaAerobica?.porcentajeFce || ''} />
                                            <DataSection label="UMAN" size={2} value={oneDataPruebaAerobica?.uman || ''} />
                                        </Grid>
                                        <Grid sx={{ mt: 1 }} container spacing={2}>
                                            <DataSection label="% UMAN" size={2} value={oneDataPruebaAerobica?.porcentajeUman || ''} />
                                            <DataSection label="FC. UMAN" size={2} value={oneDataPruebaAerobica?.fcUman || ''} />
                                            <DataSection label="TUMAN" size={2} value={oneDataPruebaAerobica?.tuman || ''} />
                                            <DataSection label="TTOTAL" size={2} value={oneDataPruebaAerobica?.ttotal || ''} />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        {' '}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" alignItems="center" justifyContent="center" height="100%" textAlign="center">
                                            <Typography variant="h5" component="span">
                                                TEST MADDER
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ListadoVisualizar />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <LineChart
                                                    id="chart-id"
                                                    posicion={oneDataPruebaAerobica?.fcUman}
                                                    label={allDataTestWFooter}
                                                    data={allDataTestWHeader}
                                                    name="series-1"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={6}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        height="100%"
                                                        textAlign="center"
                                                    >
                                                        <Typography variant="h5" component="span">
                                                            ZONAS DE ENTRENAMIENTO
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Barchar id="chart-id2" zonasEntrenamiento={zonasEntrenamiento} name="series-1" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box component="fieldset" border={1} borderColor="grey.400" p={1} my={2} borderRadius={4}>
                                        <Typography component="legend" color="grey.900" fontWeight={700}>
                                            Recuperación
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <DataSection label="FCR 1" size={6} value={oneDataPruebaAerobica?.min1Frc || ''} />
                                            <DataSection
                                                label="Porcentaje Recup. Min. 1"
                                                size={6}
                                                value={oneDataPruebaAerobica?.min1Porcentaje || ''}
                                            />
                                            <DataSection label="FCR 2" size={6} value={oneDataPruebaAerobica?.min2Frc || ''} />
                                            <DataSection
                                                label="Porcentaje Recup. Min. 2"
                                                size={6}
                                                value={oneDataPruebaAerobica?.min2Porcentaje || ''}
                                            />
                                            <DataSection label="FCR 3" size={6} value={oneDataPruebaAerobica?.min3Frc || ''} />
                                            <DataSection
                                                label="Porcentaje Recup. Min. 3"
                                                size={6}
                                                value={oneDataPruebaAerobica?.min3Porcentaje || ''}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <BarcharVertical id="chart-id3" recuperacion={recuperacion} />
                                </Grid>
                            </Grid>
                        </div>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
};

export default Visualizar;
