import { IconBriefcase, IconOlympics, IconMinus, IconCrown, IconWorldLatitude, IconUserPlus, IconCertificate } from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';

const Aside = () => (
    <NavCollapse icon={IconBriefcase} id="Admin" title="Administración" level={1}>
        <NavItem
            item={{
                id: 'federacion',
                title: 'Federacion',
                type: 'item',
                url: '/admin/federacion',
                icon: IconOlympics,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'linea',
                title: 'Línea',
                type: 'item',
                url: '/admin/linea',
                icon: IconMinus,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'ranking',
                title: 'Ranking',
                type: 'item',
                url: '/admin/ranking',
                icon: IconCrown,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'etnia',
                title: 'Etnia',
                type: 'item',
                url: '/admin/etnia',
                icon: IconWorldLatitude,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'profesion',
                title: 'Profesión',
                type: 'item',
                url: '/admin/profesion',
                icon: IconCertificate,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'persona',
                title: 'Persona',
                type: 'item',
                url: '/admin/persona',
                icon: IconUserPlus,
                breadcrumbs: true
            }}
            level={2}
        />
    </NavCollapse>
);

export default Aside;
