/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import Chart from 'react-apexcharts';

const PieChart = ({ zonasEntrenamiento, id }) => {
    const options = {
        chart: {
            id: id || ''
        },
        xaxis: {
            categories: [5, 4, 3, 2, 1, 0]
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        }
    };
    const series = [
        {
            name: 'FC.',
            data: zonasEntrenamiento
        },
        {
            name: 'ZE.',
            data: [95, 90, 80, 70, 60]
        }
    ];

    return id ? (
        <Chart id={id} options={options} series={series} type="bar" width="500" />
    ) : (
        <Chart options={options} series={series} type="bar" width="500" />
    );
};

export default PieChart;
