import { useContext } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Save } from '@mui/icons-material';

const Index = () => {
    const { StoreUpdateHojaEvolucion } = useContext(ContentContext);
    const currentDate = new Date().toISOString().split('T')[0]; // Obtiene la fecha actual en formato ISO
    const {
        register,
        handleSubmit,
        formState: { errors },
        // setValue,
        reset
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();
    const onSubmit = (data) => {
        StoreUpdateHojaEvolucion(data);
        reset();
    };

    // const setData = () => {
    //     setValue('motivo', oneDataHojaEvolutiva.motivo);
    // };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                DATOS GENERALES
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.fecha}
                            {...register('fecha', {
                                required: { value: true, message: 'Este campo es requerido' }
                            })}
                            InputLabelProps={{ shrink: true }}
                            defaultValue={currentDate}
                            type="date"
                            size="small"
                            id="outlined-error-helper-text"
                            label="Fecha"
                            helperText={!!errors.fecha && errors.fecha.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.hora}
                            {...register('hora', {
                                required: { value: true, message: 'Este campo es requerido' }
                            })}
                            InputLabelProps={{ shrink: true }}
                            type="time"
                            size="small"
                            id="outlined-error-helper-text"
                            label="Hora"
                            helperText={!!errors.hora && errors.hora.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.motivo}
                            {...register('motivo', {
                                required: { value: true, message: 'Por favor, completa este campo. Es obligatorio.' },
                                maxLength: {
                                    value: 750,
                                    message: 'El texto no debe exceder los 750 caracteres. Por favor, reduce su longitud.'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Motivo"
                            multiline
                            rows={2}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.motivo && errors.motivo.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.datosSubjetivos}
                            {...register('datosSubjetivos', {
                                required: { value: true, message: 'Por favor, completa este campo. Es obligatorio.' },
                                maxLength: {
                                    value: 750,
                                    message: 'El texto no debe exceder los 750 caracteres. Por favor, reduce su longitud.'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Datos Subjetivos"
                            multiline
                            rows={3}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.datosSubjetivos && errors.datosSubjetivos.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.datosObjetivos}
                            {...register('datosObjetivos', {
                                required: { value: true, message: 'Por favor, completa este campo. Es obligatorio.' },
                                maxLength: {
                                    value: 750,
                                    message: 'El texto no debe exceder los 750 caracteres. Por favor, reduce su longitud.'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Datos Ojetivos"
                            multiline
                            rows={3}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.datosObjetivos && errors.datosObjetivos.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.diagnostico}
                            {...register('diagnostico', {
                                required: { value: true, message: 'Por favor, completa este campo. Es obligatorio.' },
                                maxLength: {
                                    value: 750,
                                    message: 'El texto no debe exceder los 750 caracteres. Por favor, reduce su longitud.'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Diagnóstico"
                            multiline
                            rows={3}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.diagnostico && errors.diagnostico.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.tratamiento}
                            {...register('tratamiento', {
                                required: { value: true, message: 'Por favor, completa este campo. Es obligatorio.' },
                                maxLength: {
                                    value: 750,
                                    message: 'El texto no debe exceder los 750 caracteres. Por favor, reduce su longitud.'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Tratamiento"
                            multiline
                            rows={3}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.tratamiento && errors.tratamiento.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                <AnimateButton>
                    <BootstrapTooltip title="Guardar" arrow placement="left">
                        <Button
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{
                                background: theme.palette.success.main,
                                '&:hover': {
                                    background: theme.palette.success.dark
                                },
                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                maxWidth: '48px' // Cambia el ancho máximo del botón
                            }}
                        >
                            <Save fontSize="medium" />
                        </Button>
                    </BootstrapTooltip>
                </AnimateButton>
            </Box>
        </Box>
    );
};

export default Index;
