import PropTypes from 'prop-types';
import { forwardRef, useContext, useRef, useState, useEffect } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography, Box, IconButton, Modal, LinearProgress } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { LoadingContext } from './context';
// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            size,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            closeButton,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const { showLoad } = useContext(LoadingContext);
        const customization = useSelector((state) => state.customization);
        const [progress, setProgress] = useState(0);
        const [buffer, setBuffer] = useState(10);

        const progressRef = useRef(() => {});
        useEffect(() => {
            progressRef.current = () => {
                if (progress > 100) {
                    setProgress(0);
                    setBuffer(10);
                } else {
                    const diff = Math.random() * 10;
                    const diff2 = Math.random() * 10;
                    setProgress(progress + diff);
                    setBuffer(progress + diff + diff2);
                }
            };
        });

        useEffect(() => {
            const timer = setInterval(() => {
                progressRef.current();
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }, []);
        const validSize = (size) => {
            let newSize = '550px';
            if (size === 'xl') {
                newSize = '1140px';
            } else if (size === 'lg') {
                newSize = '800px';
            } else if (size === 'sm') {
                newSize = '300px';
            }
            return newSize;
        };
        const style = {
            position: 'absolute',
            borderRadius: `${customization.borderRadius}px`,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: validSize(size),
            width: '100%',
            bgcolor: '#FFF',
            boxShadow: 24
        };
        return (
            <Modal
                keepMounted
                open={showLoad}
                aria-labelledby="keep-mounted-modal-title"
                style={{ zIndex: 20000 }}
                aria-describedby="keep-mounted-modal-description"
            >
                <Card
                    ref={ref}
                    {...others}
                    sx={{
                        ':hover': {
                            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                        },
                        ...style,
                        ...sx
                    }}
                >
                    {/* card header and action */}
                    {!darkTitle && !closeButton && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                    {darkTitle && title && (
                        <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                    )}
                    {closeButton && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                bgcolor: 'background.paper',
                                borderRadius: 1
                            }}
                        >
                            <CardHeader sx={{ ...headerSX, fontWeight: 'bolder' }} title={title} />
                            <IconButton
                                onClick={() => closeButton()}
                                aria-label="Salir"
                                sx={{
                                    alignSelf: 'center',
                                    mr: 2
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        </Box>
                    )}

                    {/* content & header divider */}
                    {title && <Divider />}

                    {/* card content */}
                    {content && (
                        <CardContent
                            sx={{ ...contentSX, px: 3, py: 5, pt: 3, fontSize: 'bolder', textAlign: 'center' }}
                            className={contentClass}
                        >
                            <Typography variant="h4">Su petición esta siendo procesada, espere un momento por favor...</Typography>
                            <Box sx={{ width: '100%', pt: 2 }}>
                                <LinearProgress
                                    variant="buffer"
                                    value={progress}
                                    valueBuffer={buffer}
                                    sx={{ height: 10, borderRadius: customization.borderRadius }}
                                />
                            </Box>
                        </CardContent>
                    )}
                    {!content && children}
                </Card>
            </Modal>
        );
    }
);

MainCard.propTypes = {
    size: PropTypes.string,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    closeButton: PropTypes.func,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
