import { useContext } from 'react';
import { IconDashboard, IconId, IconCalendarPlus } from '@tabler/icons';
import { UserContext } from 'utils/context/userContext';
// project imports
import NavItem from '../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import IT from './it/index';
import Admin from './admin/index';
import CienciasAplicadas from './cienciasAplicadas';
import CompensacionEconomica from './compensacionEconomica';
import Resultados from './resultados';
import Certificados from './certificadoMedicos';

const Aside = () => {
    const { Roles, userRoles } = useContext(UserContext);

    return (
        <>
            <NavItem
                item={{
                    id: 'default',
                    title: 'Tablero',
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDashboard,
                    breadcrumbs: false
                }}
                level={1}
            />
            {userRoles.includes(Roles.it) && <IT />}
            {userRoles.includes(Roles.adm) && <Admin />}
            {userRoles.includes(Roles.mod_ciencias) && <CienciasAplicadas userRoles={userRoles} Roles={Roles} />}
            {userRoles.includes(Roles.ceco) && <CompensacionEconomica />}
            {userRoles.includes(Roles.res) && <Resultados />}
            {userRoles.includes(Roles.fic) && (
                <NavItem
                    item={{
                        id: 'perfil',
                        title: 'Ficha Técnica',
                        type: 'item',
                        url: '/ficha-tecnica',
                        icon: IconId,
                        breadcrumbs: false
                    }}
                    level={1}
                />
            )}
            {userRoles.includes(Roles.ci_opc) && (
                <NavItem
                    item={{
                        id: 'agenda',
                        title: 'Citas Medicas',
                        type: 'item',
                        url: '/agendar-cita',
                        icon: IconCalendarPlus,
                        breadcrumbs: false
                    }}
                    level={1}
                />
            )}
            {userRoles.includes(Roles.mod_cert_med) && <Certificados userRoles={userRoles} Roles={Roles} />}
        </>
    );
};

export default Aside;
