import { Routes, Route, Navigate } from 'react-router-dom';
import Federacion from '../../views/administracion/federacion/index';
import Etnia from '../../views/administracion/etnia/index';
import Linea from '../../views/administracion/linea/index';
import Persona from '../../views/administracion/persona/index';
import Profesion from '../../views/administracion/profesion/index';
import Ranking from '../../views/administracion/ranking/index';

const AdminRoutes = () => (
    <Routes>
        <Route path="federacion" element={<Federacion />} />
        <Route path="etnia" element={<Etnia />} />
        <Route path="ranking" element={<Ranking />} />
        <Route path="linea" element={<Linea />} />
        <Route path="persona" element={<Persona />} />
        <Route path="profesion" element={<Profesion />} />
        <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
);

export default AdminRoutes;
