import { useContext, useState, useEffect } from 'react';
import { Modal, IconButton, Box, Tabs, Tab } from '@mui/material';
import { TabPanel } from 'utils/components/tabPanel/index';
import Add from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import MainCard from 'utils/components/modal/MainCardModal';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { ContentContext } from './context';
import Atleta from './forms/atleta';
import Entrenador from './forms/entrenador';
import Operador from './forms/operador';
import Profesional from './forms/profesional';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export default function KeepMountedModal() {
    const { show, toggleModal, opcion, oneData } = useContext(ContentContext);
    const theme = useTheme();

    const [value, setTabs] = useState(0);

    const handleChange = (event, newValue) => {
        setTabs(newValue);
    };
    useEffect(() => {
        if (oneData?.idTipoPersona) {
            switch (Number(oneData?.idTipoPersona)) {
                case 2:
                    setTabs(3);
                    break;
                case 3:
                    setTabs(0);
                    break;
                case 4:
                    setTabs(1);
                    break;
                case 5:
                    setTabs(2);
                    break;
                default:
                    console.log('Opción no válida');
                    break;
            }
        }
    }, [oneData]);

    return (
        <Box>
            <BootstrapTooltip title="Crear" arrow placement="left">
                <IconButton
                    onClick={() => toggleModal(1)}
                    aria-label="Crear"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.primary.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Add fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box spacing={2}>
                    <MainCard
                        style={{ overFlow: 'visible !important' }}
                        contentSX={{ pt: 0, mt: 0 }}
                        title={`${opcion === 1 ? 'Crear' : ''} ${opcion === 2 ? 'Visualizar' : ''} ${
                            opcion === 3 ? 'Actualizar' : ''
                        } Registro`}
                        size="xl"
                        closeButton={() => toggleModal(0)}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="tabs config">
                                <Tab disabled={opcion !== 1} label="Atleta" {...a11yProps(0)} />
                                <Tab disabled={opcion !== 1} label="Entrenador" {...a11yProps(1)} />
                                <Tab disabled={opcion !== 1} label="Profesional" {...a11yProps(2)} />
                                <Tab disabled={opcion !== 1} label="Operador" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Atleta />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Entrenador />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Profesional />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Operador />
                        </TabPanel>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
