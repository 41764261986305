import { Typography, Link } from '@mui/material';

const Copyright = (props) => (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '} {new Date().getFullYear()}{' '}
        <Link color="inherit" href="https://www.cog.org.gt/" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
            COG
        </Link>{' '}
        {' All Rights Reserved '}.
    </Typography>
);

export default Copyright;
