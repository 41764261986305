/* eslint-disable react/prop-types */
import { createContext, useState, useEffect } from 'react';
import { PostRoute } from 'services/Private';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const [oneDataAtleta, setOneDataAtleta] = useState([]);

    const OneDataAtleta = async () => {
        const response = await PostRoute(`fichaTecnica/data-atleta`, { persona: 2 });
        setOneDataAtleta(response.length ? response[0] : []);
    };

    const value = { oneDataAtleta };

    useEffect(() => {
        OneDataAtleta();
    }, []);

    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
