/* eslint-disable no-return-assign */
/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const PdfFicha = (image, item) => {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: 'Formato 1-H' });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let str = null;
    let Y = 90;
    doc.setFontSize(14).text(200, 60, 'Cuadro de Competencia y Resultados', { align: 'left', width: 80 });
    doc.addImage(image, 'png', 40, 80, 100, 100);

    doc.setFontSize(10).text(160, Y, `FAND: ${item?.deporte || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(160, Y, `Atleta: ${item?.nombres || ''} ${item?.apellidos || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(160, Y, `Entrenador: ${item?.entrenador || ''}`, { align: 'left', width: 80 });

    Y += 30;
    doc.setFontSize(10).text(160, Y, `DPI: ${item?.dpi || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Fecha Nacimiento: ${item?.fechaNacimiento || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(160, Y, `Pasaporte: ${item?.pasaporte || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Edad: ${item?.edad || ''}`, { align: 'left', width: 80 });

    Y += 15;
    doc.setFontSize(10).text(160, Y, `Prueba: ${item?.prueba || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Genero: ${item?.genero || ''}`, { align: 'left', width: 80 });

    Y += 15;
    doc.setFontSize(10).text(160, Y, `Estatura en metros: ${item?.prueba || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Estado civil: ${item?.genero || ''}`, { align: 'left', width: 80 });

    Y += 15;
    doc.setFontSize(10).text(160, Y, `Peso en libras: ${item?.prueba || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Profesión: ${item?.profesion || ''}`, { align: 'left', width: 80 });

    Y += 15;
    doc.setFontSize(10).text(160, Y, `Mejor Resultado`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Mejor Resultado: ${item?.profesion || ''}`, { align: 'left', width: 80 });

    Y += 15;
    doc.setFontSize(10).text(160, Y, `Histórico: ${item?.prueba || ''}`, { align: 'left', width: 80 });

    Y += 40;
    doc.setFontSize(14).text(225, Y, 'Compensación Económica', { align: 'left', width: 80 });

    Y += 20;
    doc.setFontSize(10).text(60, Y, `Caja: ${item?.caja || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Monto: ${item?.monto || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(60, Y, `Ubicación: ${item?.ubicacion || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Programa: ${item?.programa || ''}`, { align: 'left', width: 80 });

    Y += 40;
    doc.setFontSize(14).text(225, Y, 'Variables Ciencias Aplicadas', { align: 'left', width: 80 });

    Y += 20;
    doc.setFontSize(10).text(60, Y, `MedicinaGeneral: ${item?.caja || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Nutrición: ${item?.monto || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(60, Y, `Fisioterapia: ${item?.ubicacion || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Biomecánica: ${item?.programa || ''}`, { align: 'left', width: 80 });
    Y += 15;
    doc.setFontSize(10).text(60, Y, `Psicología: ${item?.ubicacion || ''}`, { align: 'left', width: 80 });
    doc.setFontSize(10).text(350, Y, `Fisiología: ${item?.programa || ''}`, { align: 'left', width: 80 });

    Y += 45;
    function pageContent(data) {
        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }

    const columns = [
        ['No.', 'Nombre de la Competencia y/o Campamento de Entrenamiento', 'Sede de la Competencia y/o Campamento de Entrenamiento', '4']
    ];
    const rows = Array(24)
        .fill()
        .map((_, i) => [i + 1, i, i, i]);

    doc.autoTable({
        head: columns,
        body: rows,
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            width: 'wrap',
            overFlow: 'linebreak',
            // cellWidth: 'auto',
            0: { cellWidth: 40, halign: 'center' },
            1: { cellWidth: 107, halign: 'left', width: 'wrap', overflow: 'linebreak' },
            2: { cellWidth: 80, halign: 'center' },
            3: { cellWidth: 40, halign: 'right' }
        },
        headStyles: {
            fillColor: false,
            textColor: [255],
            valign: 'middle',
            halign: 'center'
        },
        theme: 'grid',
        styles: {
            fontSize: 6,
            fillColor: false,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        didDrawPage: pageContent,
        margin: {
            left: 70.8661,
            right: 70.8661,
            bottom: 50
        }
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    return doc.output('datauristring');
};

export default {
    PdfFicha
};
