import { useState, Fragment } from 'react';
import {
    Button,
    InputLabel,
    IconButton,
    InputAdornment,
    Box,
    Grid,
    Stack,
    Typography,
    FormControl,
    FormHelperText,
    CircularProgress,
    OutlinedInput,
    useMediaQuery
} from '@mui/material';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { createTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Copyright from './copyRight';
import { userService } from 'services/UserServices';

const theme = createTheme();

const FormLogin = () => {
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [loading, setLoading] = useState(false);
    const onSubmit = async (data) => {
        setMessageError('');
        setLoading(true);
        userService.login(data).then(
            () => {
                window.location.href = '/';
            },
            (error) => {
                setLoading(false);
                if (String(error) === 'TypeError: Failed to fetch') {
                    setMessageError('El navegador no pudo establecer una conexión al servidor');
                } else {
                    setMessageError(error);
                }
            }
        );
    };

    return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
            <Grid item>
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                        Ingrese sus credenciales para continuar
                    </Typography>
                </Stack>
            </Grid>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                <FormControl
                    fullWidth
                    error={Boolean(!!errors.username && errors.username.message)}
                    sx={{ ...theme.typography.customInput }}
                >
                    <InputLabel htmlFor="outlined-adornment-email-login">Nombre de usuario</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-email-login"
                        type="text"
                        variant="filled"
                        label="Nombre de usuario"
                        {...register('username', { required: { value: true, message: 'Este campo es requerido' } })}
                    />
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                        {!!errors.username && errors.username.message}
                    </FormHelperText>
                </FormControl>

                <FormControl
                    fullWidth
                    error={Boolean(!!errors.password && errors.password.message)}
                    sx={{ ...theme.typography.customInput, mt: 3 }}
                >
                    <InputLabel htmlFor="outlined-adornment-password-login">Contraseña</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password-login"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="large"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        type={showPassword ? 'text' : 'password'}
                        label="Contraseña"
                        {...register('password', { required: { value: true, message: 'Este campo es requerido' } })}
                    />
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                        {!!errors.password && errors.password.message}
                    </FormHelperText>
                    {/* )} */}
                </FormControl>
                <div style={{ color: '#e01e5a !important', width: '100%' }}>
                    <small>{messageError}</small>
                </div>
                <Box sx={{ my: 2, position: 'relative' }}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            disabled={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Iniciar Sesión
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#FFF',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px'
                                }}
                            />
                        )}
                    </AnimateButton>
                </Box>
                <Grid container>
                    <Grid item xs>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Link to="/auth/forgot-password" style={{ textDecoration: 'none' }}>
                                <Typography variant="subtitle1" color="primary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    ¿Se te olvidó tu contraseña?
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Box>
        </Fragment>
    );
};

export default FormLogin;
