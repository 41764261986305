import { useContext, useState } from 'react';
import { Estados, CustomText } from 'utils/components/customTable/index';
import { Grid, TextField, Container, IconButton } from '@mui/material';
import { Delete, Edit, Check } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { ContentContext } from '../context';

const List = () => {
    const { OneDataDetalleTipoFuerza, allDataDetalleTipoFuerza, ActionsDetalleTipoFuerza } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allDataDetalleTipoFuerza.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Nombre',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombre)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => Estados(row.estado)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <>
                    {row.estado === 1 && (
                        <IconButton color="warning" aria-label="Asignar Federación" onClick={() => OneDataDetalleTipoFuerza(row, 3)}>
                            <Edit />
                        </IconButton>
                    )}
                    <IconButton
                        color={row.estado === 1 ? 'error' : 'success'}
                        aria-label="Asignar Federación"
                        onClick={() => ActionsDetalleTipoFuerza(row, 3)}
                    >
                        {row.estado === 1 ? <Delete /> : <Check />}
                    </IconButton>
                </>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    {' '}
                </Grid>
                <Grid item xs={5}>
                    <TextField label="Buscar" sx={{ width: '100%' }} size="small" value={searchValue} onChange={handleFilter} />
                </Grid>
            </Grid>
            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allDataDetalleTipoFuerza}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
