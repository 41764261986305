import { useContext } from 'react';
// material-ui
import { Typography, Grid, Box } from '@mui/material';

// custom
import IT from 'views/tablero/it';
import Default from 'views/tablero/default';
import { UserContext } from 'utils/context/userContext';

const Index = () => {
    const { Roles, userRoles } = useContext(UserContext);
    return (
        // <ContainerCustom>
        <Box spacing={2} sx={{ mt: 1 }}>
            <Grid item lg={8} xs={12} sx={{ justifyContent: 'center' }}>
                <Typography variant="h1" sx={{ pt: 3, textAlign: 'center' }} textTransform="uppercase">
                    Bienvenido Al Portal del Atleta.
                </Typography>
            </Grid>
            {userRoles.includes(Roles.administrador) && <IT />}
            {!userRoles.includes(Roles.administrador) && <>{userRoles.includes(Roles.operador) && <Default />}</>}
        </Box>
        // </ContainerCustom>
    );
};

export default Index;
