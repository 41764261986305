import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import ExportPDF from '../export/historiaClinicaDeportiva';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Save } from '@mui/icons-material';

const DatosFamiliares = () => {
    const { StoreUpdateDf, oneDataDf, user, oneDataAnamnesis, allDataAntecedentes } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            padre: data.padre,
            edadPadre: data.edadPadre,
            ocupacionPadre: data.ocupacionPadre,
            madre: data.madre,
            edadMadre: data.edadMadre,
            ocupacionMadre: data.ocupacionMadre,
            direccion: data.direccion,
            telefono: data.telefono,
            id: oneDataDf.length !== 0 ? oneDataDf.id : null
        };
        StoreUpdateDf(jsData);
    };
    const activeLabel = { shrink: true };
    const setData = () => {
        setValue('padre', oneDataDf.padre);
        setValue('ocupacionPadre', oneDataDf.ocupacionPadre);
        setValue('edadPadre', oneDataDf.edadPadre);
        setValue('madre', oneDataDf.madre);
        setValue('ocupacionMadre', oneDataDf.ocupacionMadre);
        setValue('edadMadre', oneDataDf.edadMadre);
        setValue('telefono', oneDataDf.telefono);
        setValue('direccion', oneDataDf.direccion);
    };

    useEffect(() => {
        if (oneDataDf.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataDf]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 2 }} spacing={2}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    <Typography sx={{ my: 2 }} variant="h4">
                        DATOS FAMILIARES
                    </Typography>
                </Grid>
                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                    <ExportPDF
                        nameFile="Centro de Atención"
                        data={{ datosFamiliares: oneDataDf, user, oneDataAnamnesis, allDataAntecedentes }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.padre}
                            {...register('padre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="padre"
                            label="Nombre del padre"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.padre && errors.padre.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.edadPadre}
                            {...register('edadPadre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="edadPadre"
                            label="Edad"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.edadPadre && errors.edadPadre.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.ocupacionPadre}
                            {...register('ocupacionPadre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="ocupacionPadre"
                            label="Ocupación"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.ocupacionPadre && errors.ocupacionPadre.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.madre}
                            {...register('madre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="madre"
                            label="Nombre de la Madre"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.madre && errors.madre.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.edadMadre}
                            {...register('edadMadre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="edadMadre"
                            label="Edad"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.edadMadre && errors.edadMadre.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.ocupacionMadre}
                            {...register('ocupacionMadre', { required: { value: false, message: 'Este campo es requerido' } })}
                            size="small"
                            id="ocupacionMadre"
                            label="Ocupación"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.ocupacionMadre && errors.ocupacionMadre.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.direccion}
                            {...register('direccion', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="direccion"
                            label="Dirección"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.direccion && errors.direccion.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.telefono}
                            {...register('telefono', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="telefono"
                            label="Teléfono"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.telefono && errors.telefono.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <AnimateButton>
                        <BootstrapTooltip title="Guardar" arrow placement="left">
                            <Button
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{
                                    background: theme.palette.success.main,
                                    '&:hover': {
                                        background: theme.palette.success.dark
                                    },
                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                }}
                            >
                                <Save fontSize="medium" />
                            </Button>
                        </BootstrapTooltip>
                    </AnimateButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DatosFamiliares;
