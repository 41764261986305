import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Add } from '@mui/icons-material';
import Select from 'react-select';

const Potencia = () => {
    const {
        StoreUpdateDetalleEvaluacionFuerzaFlexibilidad,
        AllDataDetalleEvaluacionFuerzaFlexibilidad,
        oneDataEvaluacionFuerzaFlexibilidad,
        allDataTipoFuerza,
        AllDetalleTipoFuerza,
        allDataDetalleTipoFuerza
    } = useContext(ContentContext);
    const columns2 = [
        {
            name: 'Tipo Fuerza/Flexibilidad',
            selector: (row) => row.tipoFuerza,
            width: '250px'
        },
        {
            name: 'Detalle Tipo Fuerza/Flexibilidad',
            selector: (row) => row.detalleTipoFuerza
        },
        {
            name: 'Valor',
            selector: (row) => row.valor
        }
    ];

    const {
        watch,
        reset,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const activeLabel = { shrink: true };
    const tipoFuerzaFlexibilidad = watch('tipoFuerza');
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            valor: data.valor,
            detalleTipoFuerza: data.detalleTipoFuerza.value,
            evaluacionFuerzaFlexibilidad: oneDataEvaluacionFuerzaFlexibilidad.id,
            id: null
        };
        StoreUpdateDetalleEvaluacionFuerzaFlexibilidad(jsData);
        setValue('valor', '');
        reset();
    };

    useEffect(() => {
        AllDetalleTipoFuerza(tipoFuerzaFlexibilidad);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoFuerzaFlexibilidad]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                Detalle Tipo Fuerza y Flexibilidad
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="tipoFuerza" shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo Fuerza
                            </InputLabel>
                            <Controller
                                control={control}
                                name="tipoFuerza"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoFuerza}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoFuerza ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoFuerza && errors.tipoFuerza.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="detalleTipoFuerza" shrink sx={{ background: '#FFF', px: 1 }}>
                                Fuerza o Flexibilidad
                            </InputLabel>
                            <Controller
                                control={control}
                                name="detalleTipoFuerza"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataDetalleTipoFuerza}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.detalleTipoFuerza ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.detalleTipoFuerza && errors.detalleTipoFuerza.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.valor}
                                {...register('valor', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Resultado"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.valor && errors.valor.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable
                    noDataComponent="No hay registros para mostrar."
                    columns={columns2}
                    data={AllDataDetalleEvaluacionFuerzaFlexibilidad}
                />
            </Box>
        </Box>
    );
};

export default Potencia;
