// material-ui
import { Typography, Grid, Box } from '@mui/material';
import ContainerCustom from 'ui-component/container/index';
import PieChart from 'utils/charts/pie/index';
import BarChart from 'utils/charts/barChart/index';

const Index = () => (
    <Box spacing={2} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12} align="center">
                <ContainerCustom>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                            Total Por Mes
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <BarChart />
                    </Grid>
                </ContainerCustom>
            </Grid>
            <Grid item lg={6} xs={12} align="center">
                <ContainerCustom>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                            Total Por Mes
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <BarChart />
                    </Grid>
                </ContainerCustom>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item lg={12} xs={12} align="center" sx={{ mt: 2 }}>
                <ContainerCustom>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                            Total Por Mes
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <PieChart
                            data={[
                                [1, 2, 3, 4, 5],
                                [1, 2, 3, 4, 5]
                            ]}
                        />
                    </Grid>
                </ContainerCustom>
            </Grid>
        </Grid>
    </Box>
);

export default Index;
