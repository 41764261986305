// material-ui
import { Container } from '@mui/material';
import Header from './header';
import { ContentProvider } from './context';
import { useSelector } from 'react-redux';
import HistoriaClinicaDeportiva from './historiaClinicaDeportiva/index';
import ExamenFisicoDeportivo from './examenFisicoDeportivo/index';
import HojaEvolucion from './hojaDeEvolucion/index';
import NutricionCineantropometria from './nutricionCineantropometria/index';
import Tablero from './tablero/index';
import FichaPsicologica from './fichaPsicologica/index';
import ValoracionFisioterapeutica from './valoracionFisioterapeutica/index';
import EvaluacionBiomecanica from './evaluacionBiomecanica/index';
import PruebaAerobica from './pruebaAerobica/index';
import PruebaAnaerobica from './pruebaAnaerobica/index';
import EvaluacionFuerzaFlexibilidad from './EvaluacionFuerzaFlexibilidad/index';
import EstudiosdeGabinete from './estudiosDeGabinete';

const Index = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <ContentProvider>
            <Header />
            <Container maxWidth="xl" sx={{ backgroundColor: '#FFF', pb: 5, borderRadius: `${customization.borderRadius}px` }}>
                <Tablero />
                <HistoriaClinicaDeportiva />
                <ExamenFisicoDeportivo />
                <HojaEvolucion />
                <NutricionCineantropometria />
                <FichaPsicologica />
                <ValoracionFisioterapeutica />
                <EvaluacionBiomecanica />
                <PruebaAerobica />
                <PruebaAnaerobica />
                <EvaluacionFuerzaFlexibilidad />
                <EstudiosdeGabinete />
            </Container>
        </ContentProvider>
    );
};

export default Index;
