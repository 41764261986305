import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography, Box, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            size,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            closeButton,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const customization = useSelector((state) => state.customization);
        const validSize = (size) => {
            let newSize = '500px';
            if (size === 'xl') {
                newSize = '1140px';
            } else if (size === 'lg') {
                newSize = '800px';
            } else if (size === 'sm') {
                newSize = '300px';
            }
            return newSize;
        };
        const style = {
            position: 'absolute',
            borderRadius: `${customization.borderRadius}px`,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: validSize(size),
            width: '100%',
            bgcolor: '#FFF',
            boxShadow: 24,
            maxHeight: '95vh', // 90% de la altura de la ventana
            overflowY: 'auto' // Habilita el scroll si el contenido excede la altura máxima
        };
        return (
            <Card
                id="modal-custom"
                ref={ref}
                {...others}
                sx={{
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...style,
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && !closeButton && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}
                {closeButton && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            bgcolor: 'background.paper',
                            borderRadius: 1
                        }}
                    >
                        <CardHeader sx={{ ...headerSX, fontWeight: 'bolder' }} title={title} />
                        <IconButton
                            onClick={() => closeButton()}
                            aria-label="Salir"
                            sx={{
                                alignSelf: 'center',
                                mr: 2
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    </Box>
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent
                        sx={{
                            px: 3,
                            py: 5,
                            ...contentSX,
                            overflowY: 'auto', // Habilita el scroll si el contenido excede la altura máxima
                            maxHeight: '85vh' // 70% de la altura de la ventana
                        }}
                        className={contentClass}
                    >
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    size: PropTypes.string,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    closeButton: PropTypes.func,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
