/* eslint-disable react/prop-types */
import { IconButton, Box, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import BootstrapTooltip from 'utils/components/tooltip/index';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ModalPdf from 'ui-component/templatePdf/visor';
import { generatePDF } from './templatePdf';
import { UserContext } from 'utils/context/userContext';
import { PostRoute } from '../../../../../services/Private';

const ExportPDF = ({ data, nameFile }) => {
    const { userAuth } = useContext(UserContext);
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState([]);

    const OneNutricionCineantropometria = async () => {
        const response = await PostRoute(`cineantropometria/all`, { nutricionCineantropometria: data.row.id });
        const response2 = await PostRoute(`cicloCompetencia/all`, { nutricionCineantropometria: data.row.id });
        return { cineantropometria: Array.isArray(response) ? response : [], cicloCompetencia: Array.isArray(response2) ? response2 : [] };
    };

    const getInitialItems = (data) => [
        { tipoCineantropometria: 'Fecha', valor: data.row.fecha },
        { tipoCineantropometria: 'Edad', valor: data.user.edad }
    ];

    const getHeader = () => [
        [
            {
                content: 'III. CICLO DE COMPETENCIAS:',
                colSpan: 3,
                styles: {
                    halign: 'left',
                    fontStyle: 'bold',
                    fillColor: null,
                    cellPadding: {
                        top: 25,
                        bottom: 25
                    }
                }
            },
            '',
            ''
        ],
        [
            { content: 'No.', styles: { halign: 'center', fontStyle: 'bold' } },
            { content: 'Tipo Ciclo de Competencia', styles: { halign: 'center', fontStyle: 'bold' } },
            { content: 'Observaciones', styles: { halign: 'center', fontStyle: 'bold' } }
        ]
    ];

    const showModal = async () => {
        const response = await OneNutricionCineantropometria(data);
        const columns = [['No.', 'Tipo Cineantropometría', 'Observaciones']];
        const items = getInitialItems(data);

        // Combina los items iniciales con la respuesta sin mutar el objeto original
        const cineantropometria = [...items, ...response.cineantropometria].map((item, i) => [
            i + 1,
            item.tipoCineantropometria,
            item.valor
        ]);
        const header = getHeader();
        const cicloCompetencia = [{ tipoCiclosCompetencia: 'Fecha', valor: data.row.fecha }, ...response.cicloCompetencia].map(
            (item, i) => [i + 1, item.tipoCiclosCompetencia, item.valor]
        );

        // Combina cineantropometria, header y cicloCompetencia
        const combinedData = [...cineantropometria, ...header, ...cicloCompetencia];

        const doc = await generatePDF(combinedData, columns, 'NUTRICIÓN-CINEANTROPOMETRÍA', '', nameFile, data, userAuth.nombreCompleto);

        setPdf(doc);
        setShow(true);
    };

    return (
        <Box>
            <BootstrapTooltip title="Exportar PDF" arrow placement="left">
                <IconButton
                    onClick={showModal}
                    aria-label="Exportar"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.error.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark
                        }
                    }}
                >
                    <PictureAsPdf fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <ModalPdf showPdf={show} setShowPdf={setShow} title="Visualizar" pdf={pdf} />
        </Box>
    );
};

export default ExportPDF;
