import { useContext, useEffect } from 'react';
import { Box, Button, Grid, FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ContentContext } from '../context';

const ConfigCorreo = () => {
    const { StoreCorreo, oneDataCorreo } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const onSubmit = (data) => {
        StoreCorreo({ ...data, id: oneDataCorreo.id || null });
    };

    const setData = () => {
        setValue('email', oneDataCorreo.emailServerAccount);
        setValue('smtp', oneDataCorreo.smtpServer);
        setValue('port', oneDataCorreo.smtpPort);
    };

    useEffect(() => {
        if (oneDataCorreo.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataCorreo]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.email}
                            {...register('email', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Correo Electrónico"
                            helperText={!!errors.email && errors.email.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.password}
                            {...register('password', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Contraseña"
                            helperText={!!errors.password && errors.password.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.smtp}
                            {...register('smtp', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Servidor SMTP"
                            helperText={!!errors.smtp && errors.smtp.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.port}
                            {...register('port', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Puerto SMTP"
                            helperText={!!errors.port && errors.port.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <Button variant="contained" size="small" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ConfigCorreo;
