/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import { IconX, IconPhoto, IconDeviceFloppy } from '@tabler/icons';

const UploadFile = ({ oneData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors
    } = useForm();
    const [Visualizar, setVisualizar] = useState('');
    const [cargarFoto, setCargarFoto] = useState('');
    const MostrarDoc = (value) => {
        if (value.target.value) {
            const pdffile = value.target.files[0];
            const pdffileUrl = URL.createObjectURL(pdffile);
            setVisualizar(`${pdffileUrl}`);
        } else {
            setVisualizar('');
        }
    };

    const clearFoto = () => {
        setVisualizar('');
        setValue('foto', null);
        clearErrors('foto');
    };

    const onSubmit = async (data) => {
        console.log(data);
        // const formData = await new FormData();
        // if (oneData && oneData.id) {
        //     formData.append('id', oneData ? oneData.id : null);
        // }
        // formData.append('nombre', data.nombre);
        // formData.append('logo', getFoto);
        // setCargarFoto(Visualizar);
        // // storeImage(formData, getFoto, oneData ? oneData.id : null);
        // clearFoto();
    };

    useEffect(() => {
        if (oneData.length !== 0) {
            setCargarFoto(oneData.logo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneData]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <div className={`kt-avatar kt-avatar--outline ${Visualizar && 'kt-avatar--changed'}`} id="kt_user_avatar">
                        <div
                            id="logo-size"
                            className={`kt-avatar__holder ${errors && errors.foto && errors.foto.message && 'border-danger'}`}
                            style={{ backgroundImage: `url('${Visualizar || cargarFoto}')` }}
                        >
                            <label
                                className="kt-avatar__upload p-0"
                                data-toggle="kt-tooltip"
                                title="Cargar Foto"
                                data-original-title="Change avatar"
                            >
                                <IconPhoto stroke={1.5} size="1.3rem" />
                                <input
                                    {...register('foto', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido'
                                        }
                                    })}
                                    lang="es"
                                    type="file"
                                    className="fileInput"
                                    accept=".png"
                                    onChange={(e) => MostrarDoc(e)}
                                />
                            </label>
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <span className="kt-avatar__cancel" onClick={() => clearFoto()}>
                            <IconX stroke={1.5} size="1.3rem" />
                        </span>
                        {Visualizar && (
                            <IconButton aria-label="fingerprint" color="success" className="kt-avatar__save" type="submit">
                                <IconDeviceFloppy stroke={1.5} size="1.3rem" />
                            </IconButton>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center">
                        <small className="text-muted text-danger">{!!errors.foto && errors.foto.message}</small>
                    </Typography>
                    <Typography align="center">
                        <small className="text-muted">Permitido *.png</small>
                    </Typography>
                    <Typography align="center">
                        <small className="text-muted">tamaño máximo de 10 MB</small>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UploadFile;
