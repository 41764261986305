/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { updateData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDataDisciplina, setAllDataDisciplina] = useState([]);
    const [allDataFederacion, setAllDataFederacion] = useState([]);
    const [allDataCodigoCaja, setAllDataCodigoCaja] = useState([]);
    const [allDataUbicacion, setAllDataUbicacion] = useState([]);
    const [allDataCompensacion, setAllDataCompensacion] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [oneDataEdit, setOneDataEdit] = useState([]);
    const [stateEdit, setStateEdit] = useState(0);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'compensacionEconomica';
    const AllPersona = async (id) => {
        if (id) {
            const response = await PostRoute(`persona/all/federacion`, { federacion: id });
            setAllData(response.length ? response : []);
        } else {
            setAllData([]);
        }
    };
    const All = async (id) => {
        const response = await PostRoute(`${nameController}/all`, { persona: id });
        setAllDataCompensacion(response.length ? response : []);
    };

    const AllFederaciones = async () => {
        const response = await GetRoute(`federacion/label`);
        setAllDataFederacion(response.length ? response : []);
    };

    const AllDisciplina = async (id) => {
        const response = await PostRoute(`disciplina/label`, { fand: id });
        setAllDataDisciplina(response.length ? response : []);
    };

    const AllCodigoCaja = async () => {
        const response = await GetRoute(`codigoCaja/label`);
        setAllDataCodigoCaja(response.length ? response : []);
    };

    const AllUbicacion = async () => {
        const response = await GetRoute(`ubicacion/label`);
        setAllDataUbicacion(response.length ? response : []);
    };

    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };
    const One = async (data) => {
        setOneData(data);
        setShowLoad(true);
        All(data.id);
        toggleModal(3);
        setShowLoad(false);
    };

    const OneCompensacionEconomica = async (data) => {
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        setOneDataEdit(response.length ? response[0] : []);
        setStateEdit(1);
    };

    const StoreUpdate = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data);
        All(data.persona);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        setAllData(updateData(allData, { ...data, estado: data.estado === 1 ? 0 : 1 }));
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = {
        One,
        Actions,
        setOpcion,
        AllPersona,
        StoreUpdate,
        toggleModal,
        setStateEdit,
        AllDisciplina,
        OneCompensacionEconomica,
        show,
        opcion,
        oneData,
        allData,
        stateEdit,
        oneDataEdit,
        allDataUbicacion,
        allDataDisciplina,
        allDataFederacion,
        allDataCodigoCaja,
        allDataCompensacion
    };
    useEffect(() => {
        AllFederaciones();
        AllUbicacion();
        AllCodigoCaja();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
