import { Fragment, useContext, useState, useEffect } from 'react';
import {
    Button,
    InputLabel,
    Box,
    Grid,
    Stack,
    Typography,
    FormControl,
    FormHelperText,
    OutlinedInput,
    useMediaQuery,
    IconButton,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { NotificationContext } from 'utils/components/snackBar/context';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme } from '@mui/material/styles';
import { PostRoute } from 'services/Public';
import { useForm } from 'react-hook-form';
import Copyright from './copyRight';

const theme = createTheme();

const FormLogin = () => {
    const { pin, codigo } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const { Notification } = useContext(NotificationContext);
    const [validar, setValidar] = useState(false);
    const [loading, setLoading] = useState(false);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
        watch
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange'
    });

    const pass1 = watch('password1');
    const Enviar = async (data) => {
        setLoading(true);
        const jsData = {
            ...data,
            codigo: pin
        };
        const response = await PostRoute(`NotificacionCorreo/resetpass`, jsData);
        Notification(response.message, 1);
        reset();
        navigate('/auth/login');
    };

    const validarPin = async () => {
        const response = await PostRoute(`NotificacionCorreo/pin-usuario`, { codigo: pin, codigos: codigo });
        if (response[0]) {
            setValidar(true);
            setValue('usuario', response[0].username);
        } else {
            setValidar(false);
        }
    };

    const passwordValidation = (value) => {
        // Aquí puedes agregar tu lógica de validación de contraseñas
        // Por ejemplo, asegurarte de que la contraseña tenga al menos 8 caracteres y contenga al menos un número y una letra mayúscula
        if (value.length < 8) {
            return 'La contraseña debe tener al menos 8 caracteres';
        }
        if (!/\d/.test(value)) {
            return 'La contraseña debe contener al menos un número';
        }
        if (!/[A-Z]/.test(value)) {
            return 'La contraseña debe contener al menos una letra mayúscula';
        }
        return true;
    };

    const comparePass = (value) => {
        if (value === pass1) {
            return true;
        }
        return 'las contraseñas no coinciden';
    };

    useEffect(() => {
        validarPin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
            {validar ? (
                <>
                    <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1} sx={{ textAlign: 'center' }}>
                            <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                <small>Ingrese su nombre de usuario a continuación y su nueva contraseña.</small>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Box component="form" noValidate onSubmit={handleSubmit(Enviar)} sx={{ mt: 1, width: '100%' }}>
                        <FormControl
                            fullWidth
                            error={Boolean(!!errors.email && errors.email.message)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-email-login" shrink focused>
                                Nombre de usuario
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                variant="filled"
                                label="Nombre de usuario"
                                readOnly
                                {...register('usuario', { required: { value: true, message: 'Este campo es requerido' } })}
                            />
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {!!errors.email && errors.email.message}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(!!errors.password1 && errors.password1.message)}
                            sx={{ ...theme.typography.customInput, mt: 3 }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Contraseña</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                type={showPassword ? 'text' : 'password'}
                                label="Contraseña"
                                {...register('password1', {
                                    required: { value: true, message: 'Este campo es requerido' },
                                    validate: passwordValidation
                                })}
                            />
                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                {!!errors.password1 && errors.password1.message}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(!!errors.password && errors.password.message)}
                            sx={{ ...theme.typography.customInput, mt: 3 }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login-r">Repetir Contraseña</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login-r"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                type={showPassword ? 'text' : 'password'}
                                label="Contraseña"
                                {...register('password', {
                                    required: { value: true, message: 'Este campo es requerido' },
                                    validate: comparePass
                                })}
                            />
                            <FormHelperText error id="standard-weight-helper-text-password-login-r">
                                {!!errors.password && errors.password.message}
                            </FormHelperText>
                        </FormControl>

                        <Box sx={{ my: 2 }}>
                            <Box sx={{ my: 2, position: 'relative' }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={loading}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Restablecer
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#FFF',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px'
                                            }}
                                        />
                                    )}
                                </AnimateButton>
                            </Box>
                        </Box>
                        <Grid container>
                            <Grid item xs>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                                        <Typography variant="subtitle1" color="primary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                            ¿Ya tienes una cuenta?
                                        </Typography>
                                    </Link>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </>
            ) : (
                <>
                    Lo siento, el enlace para restablecer su contraseña ha expirado. Por favor, solicite un nuevo enlace para recuperar su
                    cuenta.
                </>
            )}
        </Fragment>
    );
};

export default FormLogin;
