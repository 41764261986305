import { useContext } from 'react';
import { Box, Grid, Chip } from '@mui/material';
import { useForm } from 'react-hook-form';
import Carrusel from 'utils/Carrusel/index';
import { useDropzone } from 'react-dropzone';
import { ContentContext } from '../context';

const ConfigLogin = () => {
    const { handleSubmit } = useForm();
    const { oneData, listado, StoreFile, DestroyItem } = useContext(ContentContext);
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('entidad', oneData.id);
        formData.append('nombre', data.name);
        formData.append('foto', data);
        StoreFile(formData);
    };
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles) => {
            const item = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            onSubmit(item[0]);
        }
    });

    const files = listado.map((file, index) => (
        <Chip
            key={index}
            label={file.nombre}
            sx={{ width: '100%', justifyContent: 'space-between', my: 1 }}
            variant="outlined"
            onDelete={() => DestroyItem(file.id)}
        />
    ));

    return (
        <Box spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12} align="center" sx={{ px: 3 }}>
                            <Box spacing={2} component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                                <section className="containerDropZone">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>Arrastre y suelte algunas imagenes aquí, o haga clic para seleccionar archivos</p>
                                    </div>
                                </section>
                                <aside>
                                    <h4>Imagenes</h4>
                                    <Grid container spacing={2}>
                                        {files}
                                    </Grid>
                                </aside>
                            </Box>
                        </Grid>
                        <Grid item lg={8} xs={12} sx={{ border: '0.5px dashed #CCC', pb: 2, pr: 2 }}>
                            {listado.length !== 0 && <Carrusel height="55vh" List={listado} />}{' '}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ConfigLogin;
