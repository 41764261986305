/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import Carousel from 'react-material-ui-carousel';
// import Foto1 from 'assets/images/carrusel/1.jpg';
// import Foto2 from 'assets/images/carrusel/2.jpg';
// import Foto3 from 'assets/images/carrusel/3.jpg';
// import Foto4 from 'assets/images/carrusel/4.jpg';
// import Foto5 from 'assets/images/carrusel/5.jpg';

const Item = ({ item, height, width, backgroundSize }) => (
    <div
        style={{
            backgroundImage: `url(${item.foto})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: backgroundSize || 'cover',
            backgroundPosition: 'center',
            width: width || '100%',
            height: height || '100vh'
        }}
    />
);

const CustomCarrusel = ({ height, List, width, backgroundSize }) => {
    const Listado = List || [];
    return (
        <Carousel
            indicatorContainerProps={{
                style: {
                    display: 'none'
                },
                indicators: {
                    width: '10%',
                    marginTop: '10px',
                    textAlign: 'center'
                },
                indicator: {
                    cursor: 'pointer',
                    transition: '200ms',
                    padding: 0,
                    color: '#afafaf',
                    '&:hover': {
                        color: '#1f1f1f'
                    },
                    '&:active': {
                        color: '#1f1f1f'
                    }
                },
                // Applies to the active indicator
                active: {
                    color: '#F00'
                }
            }}
            sx={{ height: height || '100%' }}
        >
            {Listado.map((item, i) => (
                <Item key={i} item={item} height={height} width={width || null} backgroundSize={backgroundSize || null} />
            ))}
        </Carousel>
    );
};
export default CustomCarrusel;
