/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import { IconChartCircles, IconFriends, IconCalendarEvent, IconId } from '@tabler/icons';
import { Card, CardContent, Typography, CardMedia, Grid, CardActionArea, CardActions, Button } from '@mui/material';

const CardPersona = ({ item, LlamarAtleta, AtenderAtleta }) => (
    <>
        <Card sx={{ maxWidth: 250, border: 'solid 1px #CCC' }}>
            <CardActionArea>
                <CardMedia component="img" height="200" image={item.foto} alt="green iguana" />
                <CardContent sx={{ pb: 1 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {item.nombreCompleto}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        <IconCalendarEvent stroke={1.5} size="1rem" /> {item.fechaNacimiento}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        <IconId stroke={1.5} size="1rem" /> {item.dpi}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                            <Typography variant="caption" component="div">
                                <IconFriends stroke={1.5} size="1rem" /> {item.genero}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Typography variant="caption" component="div">
                                <IconChartCircles stroke={1.5} size="1rem" /> {item.estadoCivil}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
                {/* <Link to={} size="small" color="primary"></Link> */}
                <Button size="small" color="primary" onClick={() => AtenderAtleta(item)}>
                    Atender
                </Button>
                {item.estadoOperacion === '' && (
                    <Button size="small" color="primary" onClick={() => LlamarAtleta(item)}>
                        Llamar
                    </Button>
                )}
            </CardActions>
        </Card>
    </>
);

export default CardPersona;
