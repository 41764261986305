/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import counterReducer from 'redux/reducer/contador';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    contador: counterReducer
});

export default reducer;
