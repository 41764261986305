import { useContext, useState } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Header from './header';
import Listado from './list';

const Index = () => {
    const { dataVal } = useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    return (
        dataVal === 3 && (
            <>
                <Tabs value={dataVal2} onChange={handleTabChange2}>
                    <Tab label="Formulario" />
                    <Tab label="Historial" />
                </Tabs>

                {dataVal2 === 0 && <Header />}
                {dataVal2 === 1 && <Listado />}
            </>
        )
    );
};

export default Index;
