/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';

export async function generatePDF(filas, columnas, titulo, subTitulo, nombrePdf, item, UserAut) {
    const doc = new jsPDF('p', 'pt', [612, 936]).setProperties({ title: nombrePdf });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    let str = null;
    let Y = 0;
    const hoy = new Date();
    async function pageContent(data) {
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold').setFontSize(12);
        doc.text(titulo, 300, 50, { width: 800, align: 'center' });
        doc.text(subTitulo, 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Fecha'}: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, 100, {
            width: 800,
            align: 'left'
        });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Impreso por'}: ${UserAut}`, 300, 100, { width: 800, align: 'center' });
        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }
    Y += 140;
    doc.setFontSize(10).setFont('times', 'bold');
    doc.text('NOMBRE:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.nombres || ''} ${item?.user?.apellidos || ''}`.toUpperCase(), 110, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text(`F. NACIMIENTO:`, 400, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.fechaNacimiento || ''}`, 490, Y, { width: 800, align: 'left' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text(`DEPORTE:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.deporte || ''}`, 110, Y, { width: 800, align: 'left' });
    Y += 25;
    doc.setFont('times', 'bold');
    doc.text(`DATOS GENERALES`, 50, Y, { align: 'left' });
    Y += 15;
    // Black square with rounded corners
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, pageWidth - 100, 90, 3, 3, 'FD');
    // Función para generar celdas de encabezado
    const createHeaderCell = (content, colSpan = 1) => ({
        content,
        colSpan,
        styles: {
            fontStyle: 'bold',
            fontSize: 10
        }
    });

    // Función para generar celdas de datos
    const createDataCell = (content, colSpan = 1) => ({
        content: content || '',
        colSpan
    });

    // Uso de las funciones para generar el array bodyGenerales
    const bodyGenerales = [
        [createHeaderCell('TIPO DE PRUEBA', 3), createHeaderCell('FECHA', 3)],
        [createDataCell(item?.datosGenerales?.tipoPruebaAerobica, 3), createDataCell(item?.datosGenerales?.fecha, 3)],
        [
            createHeaderCell('FC. MAX'),
            createHeaderCell('FC. E'),
            createHeaderCell('FC. 85%'),
            createHeaderCell('FC. BASAL'),
            createHeaderCell('FC. FACTOR'),
            createDataCell('')
        ],
        [
            createDataCell(item?.datosGenerales?.fcMax),
            createDataCell(item?.datosGenerales?.fcE),
            createDataCell(item?.datosGenerales?.fc85),
            createDataCell(item?.datosGenerales?.fcBasal),
            createDataCell(item?.datosGenerales?.fcFactor),
            createDataCell('')
        ]
    ];
    // tabla que genera los datos generales
    doc.autoTable({
        head: [],
        body: bodyGenerales,
        theme: 'plain',
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto'
        },
        headStyles: {
            fillColor: null,
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: null,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        didDrawPage: pageContent,
        margin: {
            left: 50,
            right: 50,
            bottom: 50
        }
    });
    Y = doc.autoTable.previous.finalY + 20;
    doc.setFont('times', 'bold');
    doc.text(`OBSERVACIONES`, 50, Y, { align: 'left' });
    Y += 15;
    // Black square with rounded corners
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, pageWidth - 100, 90, 3, 3, 'FD');
    const generateBoldCellStyle = (content) => ({
        content,
        styles: {
            fontStyle: 'bold',
            fontSize: 10
        }
    });

    const headers = ['Vo2 Max', 'Vo2', 'PO2', 'METS', '%FCE', 'UMAN', '% UMAN', 'FC. UMAN', 'TUMAN', 'TTOTAL'];

    const values = [
        item?.datosGenerales?.vo2Max,
        item?.datosGenerales?.vo2,
        item?.datosGenerales?.po2,
        item?.datosGenerales?.mets,
        item?.datosGenerales?.porcentajeFce,
        item?.datosGenerales?.uman,
        item?.datosGenerales?.porcentajeUman,
        item?.datosGenerales?.fcUman,
        item?.datosGenerales?.tuman,
        item?.datosGenerales?.ttotal
    ];

    const bodyObservaciones = [];

    for (let i = 0; i < headers.length; i += 6) {
        bodyObservaciones.push(headers.slice(i, i + 6).map((header) => generateBoldCellStyle(header)));
        bodyObservaciones.push(values.slice(i, i + 6).map((value) => ({ content: value || '' })));
    }

    doc.autoTable({
        head: [],
        body: bodyObservaciones,
        theme: 'plain',
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto'
        },
        headStyles: {
            fillColor: null,
            textColor: [0]
        },
        styles: {
            fontSize: 10,
            fillColor: null,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        margin: {
            left: 50,
            right: 50,
            bottom: 50
        }
    });
    Y = doc.autoTable.previous.finalY + 20;
    const list = item.allDataDetallePruebaAerobica.map((item) => [item.tiempo, item.medicion, item.val2, item.val3]);
    doc.autoTable({
        head: [['Tiempo', 'Velocidad', 'FC', 'RPE']],
        body: list,
        theme: 'plain',
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto',
            0: { cellWidth: 60 }
        },
        headStyles: {
            fillColor: null,
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: null,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto',
            valign: 'middle',
            halign: 'center'
        },
        margin: {
            left: 50,
            right: 350,
            bottom: 50
        }
    });

    doc.setFont('times', 'bold');
    doc.text(`TEST MADDER`, pageWidth / 2 + 50, Y, { align: 'left' });
    Y += 10;
    doc.addImage(item.img[0], 'PNG', pageWidth / 2, Y, (pageWidth - 50) / 2 - 50, 150);
    Y += 160;
    doc.setFont('times', 'bold');
    doc.text(`ZONAS DE ENTRENAMIENTO`, pageWidth / 2 + 50, Y, { align: 'left' });
    Y += 10;
    doc.addImage(item.img[1], 'PNG', pageWidth / 2, Y, (pageWidth - 50) / 2 - 50, 150);
    Y += 160;
    doc.addImage(item.img[2], 'PNG', pageWidth / 2, Y, (pageWidth - 50) / 2 - 50, 150);
    Y = doc.autoTable.previous.finalY + 20;
    doc.setFont('times', 'bold');
    doc.text(`RECUPERACIÓN`, 50, Y, { align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 250, 135, 3, 3, 'FD');
    const headersRecuperacion = ['FCR', 'PORCENTAJE RECUP. MIN.'];
    const minutesData = [
        { min: item.datosGenerales?.min2Frc, percentage: item.datosGenerales?.min2Porcentaje },
        { min: item.datosGenerales?.min1Frc, percentage: item.datosGenerales?.min1Porcentaje },
        { min: item.datosGenerales?.min3Frc, percentage: item.datosGenerales?.min3Porcentaje }
    ];

    const rec = minutesData.reduce((acc, data, index) => {
        acc.push(
            [
                { content: `${headersRecuperacion[0]} ${index + 1}`, styles: { fontStyle: 'bold', fontSize: 10 } },
                { content: `${headersRecuperacion[1]} ${index + 1}`, styles: { fontStyle: 'bold', fontSize: 10 } }
            ],
            [{ content: data.min, colSpan: 1 }, { content: data.percentage }]
        );
        return acc;
    }, []);
    doc.autoTable({
        head: [],
        body: rec,
        theme: 'plain',
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto'
        },
        headStyles: {
            fillColor: null,
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: null,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto',
            valign: 'middle',
            halign: 'center'
        },
        didDrawPage: pageContent,
        margin: {
            left: 50,
            right: 350,
            bottom: 50
        }
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    // if (filas.length > 1000) {
    doc.save(`Reporte.pdf`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { generatePDF };
