/* eslint-disable react/prop-types */
import Brightness1 from '@mui/icons-material/Brightness1';
import BootstrapTooltip from 'utils/components/tooltip/index';

const CardPersona = ({ item }) => (
    <>
        <div className="fc-content" style={{ textAlign: 'center' }}>
            <div className="fc-title">
                {/* eslint-disable-next-line no-underscore-dangle */}
                <BootstrapTooltip title={item?.event?._def?.extendedProps?.labelEstado} arrow placement="left">
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    <Brightness1 fontSize="14px" style={{ color: item?.event?._def?.extendedProps?.estadoColor }} />
                </BootstrapTooltip>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {item?.event._def?.extendedProps?.deporte}
            </div>
            <div className="fc-title">
                {/* eslint-disable-next-line no-underscore-dangle */}
                {item?.event?._def?.title}
            </div>
        </div>
    </>
);

export default CardPersona;
