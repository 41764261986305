import { Fragment, useContext, useState } from 'react';
import {
    Button,
    InputLabel,
    Box,
    Grid,
    Stack,
    Typography,
    FormControl,
    FormHelperText,
    OutlinedInput,
    useMediaQuery,
    CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { NotificationContext } from 'utils/components/snackBar/context';
import { createTheme } from '@mui/material/styles';
import { PostRoute } from 'services/Public';
import { useForm } from 'react-hook-form';
import Copyright from './copyRight';

const theme = createTheme();

const FormLogin = () => {
    const { Notification } = useContext(NotificationContext);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm();

    const Enviar = async (data) => {
        await setLoading(true);
        const response = await PostRoute(`NotificacionCorreo/reset/default/password`, data);
        reset();
        Notification(response.message, 1);
        await setLoading(false);
    };

    return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
            <Grid item>
                <Stack alignItems="center" justifyContent="center" spacing={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                        <small>
                            Ingrese su dirección de correo electrónico a continuación y le enviaremos una autenticación con contraseña de un
                            solo uso.
                        </small>
                    </Typography>
                </Stack>
            </Grid>
            <Box component="form" noValidate onSubmit={handleSubmit(Enviar)} sx={{ mt: 1, width: '100%' }}>
                <FormControl fullWidth error={Boolean(!!errors.email && errors.email.message)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-email-login">Dirección de correo electrónico</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-email-login"
                        type="email"
                        variant="filled"
                        label="Dirección de correo electrónico"
                        {...register('email', { required: { value: true, message: 'Este campo es requerido' } })}
                    />
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                        {!!errors.email && errors.email.message}
                    </FormHelperText>
                </FormControl>

                <Box sx={{ my: 2 }}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            disabled={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Enviar Correo
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#FFF',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px'
                                }}
                            />
                        )}
                    </AnimateButton>
                </Box>
                <Grid container>
                    <Grid item xs>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                                <Typography variant="subtitle1" color="primary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    ¿Ya tienes una cuenta?
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Box>
        </Fragment>
    );
};

export default FormLogin;
