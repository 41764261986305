import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Notification from 'utils/components/snackBar/index';
import { NotificationProvider } from 'utils/components/snackBar/context';
import { LoadingProvider } from 'utils/components/loading/context';
import Loading from 'utils/components/loading/index';
import { UserProvider } from 'utils/context/userContext';
import { createTheme } from 'react-data-table-component';
import { SWRConfig } from 'swr';
// import Routes from 'routes';
import Routing from './routing/index';
import 'assets/index.css';
import 'react-contexify/dist/ReactContexify.css';
// defaultTheme
import themes from 'themes';
import themeTable from 'themes/themeTable';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    createTheme('paper', themeTable());
    return (
        <SWRConfig value={{}}>
            <StyledEngineProvider injectFirst>
                <UserProvider>
                    <LoadingProvider>
                        <NotificationProvider>
                            <ThemeProvider theme={themes(customization)}>
                                <CssBaseline />
                                <NavigationScroll>
                                    <Routing />
                                </NavigationScroll>
                                <Notification />
                                <Loading />
                            </ThemeProvider>
                        </NotificationProvider>
                    </LoadingProvider>
                </UserProvider>
            </StyledEngineProvider>
        </SWRConfig>
    );
};

export default App;
