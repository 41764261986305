// material-ui
import { Container, Typography, Grid } from '@mui/material';
import List from './list';
import { ContentProvider } from './context';
import { useSelector } from 'react-redux';

const Index = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <ContentProvider>
            <Container
                maxWidth="xl"
                sx={{ backgroundColor: '#EEF2F6', pb: 5, borderRadius: `${customization.borderRadius}px`, height: '100vh' }}
            >
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h1" py={2}>
                        Turno del Atleta
                    </Typography>
                </Grid>
                <List />
            </Container>
        </ContentProvider>
    );
};

export default Index;
