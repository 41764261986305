import { useContext } from 'react';
import { UserContext } from 'utils/context/userContext';
import {
    IconBuildingHospital,
    IconReportMedical,
    IconStethoscope,
    IconMan,
    IconCircleCheck,
    IconList,
    IconCircle,
    IconClock,
    IconClipboardList,
    IconLayoutCards,
    IconCalendarStats,
    IconFileReport,
    IconFileLike,
    IconFiles,
    IconOlympics,
    IconUserCheck
} from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';

const CienciasAplicadas = () => {
    const { Roles, userRoles } = useContext(UserContext);
    return (
        <NavCollapse icon={IconStethoscope} id="IT" title="Ciencias Aplicadas" level={1}>
            {userRoles.includes(Roles.ci_adm) && (
                <NavCollapse icon={IconLayoutCards} id="catalogos" title="Cátalogos" level={2}>
                    <NavItem
                        item={{
                            id: 'federacion',
                            title: 'Federacion',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/federacion',
                            icon: IconOlympics,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                    <NavItem
                        item={{
                            id: 'centroAtencion',
                            title: 'Centro de Atención',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/centro-atencion',
                            icon: IconBuildingHospital,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                    <NavItem
                        item={{
                            id: 'diagnostico',
                            title: 'Diagnósticos',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/diagnostico',
                            icon: IconClipboardList,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                    <NavItem
                        item={{
                            id: 'momentoTemporada',
                            title: 'Momento de Temporada',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/momento-temporada',
                            icon: IconClock,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                    <NavItem
                        item={{
                            id: 'areaAfectada',
                            title: 'Area Afectada',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/area-afectada',
                            icon: IconMan,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                    <NavItem
                        item={{
                            id: 'tipoEvaluacion',
                            title: 'Tipo de Evaluación',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-evaluacion',
                            icon: IconList,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoAtencion',
                            title: 'Tipo de Atención',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-atencion',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoAntecedente',
                            title: 'Tipo de Antecedente',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-antecedentes',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoExamenFisico',
                            title: 'Tipo Examen Físico Deportivo',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-examen-fisico-deportivo',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'actividadesRealizada',
                            title: 'Actividades Realizadas',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/actividades-realizadas',
                            icon: IconCircleCheck,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'profesionalDeporte',
                            title: 'Asignar Federación a Profesional',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/federacion-profesional',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'seccionCineantropometria',
                            title: 'Sección Cineantropometría',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/seccion-cineantropometria',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoCineantropometria',
                            title: 'Tipo Cineantropometría',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-cineantropometria',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'ciclosCompetencia',
                            title: 'Ciclos de Competencia',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-ciclos-competencia',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoCarrera',
                            title: 'Tipo Carrera',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-carrera',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoPotencia',
                            title: 'Tipo Potencia',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-potencia',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoPruebaAerobica',
                            title: 'Tipo Prueba Aeróbica',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-prueba-aerobica',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />

                    <NavItem
                        item={{
                            id: 'tipoFuerzaFlexibilidad',
                            title: 'Tipo Fuerza y Flexibilidad',
                            type: 'item',
                            url: '/ciencias-aplicadas/catalogos/tipo-fuerza-flexibilidad',
                            icon: IconCircle,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                </NavCollapse>
            )}
            <NavCollapse icon={IconFiles} id="solicitudes" title="Solicitudes Citas Medicas" level={2}>
                <NavItem
                    item={{
                        id: 'Solicitud',
                        title: 'Calendario de Solicitudes',
                        type: 'item',
                        url: '/ciencias-aplicadas/calendario-citas-medicas',
                        icon: IconCalendarStats,
                        breadcrumbs: true
                    }}
                    level={3}
                />

                {userRoles.includes(Roles.ci_auc) && (
                    <NavItem
                        item={{
                            id: 'autorizar-cita-medica',
                            title: 'Autorizar Cita Medica',
                            type: 'item',
                            url: '/ciencias-aplicadas/autorizar-cita-medica',
                            icon: IconFileLike,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                )}
                {userRoles.includes(Roles.ci_atc) && (
                    <NavItem
                        item={{
                            id: 'atencion-cita-medica',
                            title: 'Atender Cita Medica',
                            type: 'item',
                            url: '/ciencias-aplicadas/atencion-cita-medica',
                            icon: IconReportMedical,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                )}
                {userRoles.includes(Roles.ci_ate) && (
                    <NavItem
                        item={{
                            id: 'atencion-medica',
                            title: 'Atención Medica',
                            type: 'item',
                            url: '/ciencias-aplicadas/atencion-medica',
                            icon: IconUserCheck,
                            breadcrumbs: true
                        }}
                        level={3}
                    />
                )}
                <NavItem
                    item={{
                        id: 'historial-solicitudes',
                        title: 'Historial de Citas Medicas',
                        type: 'item',
                        url: '/ciencias-aplicadas/historial-citas-medicas',
                        icon: IconFileReport,
                        breadcrumbs: true
                    }}
                    level={3}
                />
            </NavCollapse>

            {/* -------------------------------------------------------------- */}
            {/* {userRoles.includes(Roles.cienciasAplicadasEvaluacion) && (
                <NavItem
                    item={{
                        id: 'Evaluacion',
                        title: 'Evaluación',
                        type: 'item',
                        url: '/ciencias-aplicadas/evaluacion',
                        icon: IconReportMedical,
                        breadcrumbs: true
                    }}
                    level={2}
                />
            )} */}
        </NavCollapse>
    );
};
export default CienciasAplicadas;
