import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { useEffect, useContext } from 'react';
import { NotificationContext } from 'utils/components/snackBar/context';
import { Grid, Button, Box } from '@mui/material';
import { IconSearch } from '@tabler/icons';
import { ContentContext } from './context';
import Select from 'react-select';

const Formulario = () => {
    const theme = useTheme();
    const { Notification } = useContext(NotificationContext);
    const {
        handleSubmit,
        formState: { errors },
        watch,
        control
    } = useForm();
    const EmpleadoOne = watch('persona');
    const { empleados, getRolUsuario, setShowRoles } = useContext(ContentContext);
    const onSubmit = (data) => {
        if (EmpleadoOne) {
            getRolUsuario({ persona: data.persona.value, usuario: data.persona.usuario });
        } else {
            Notification('Debe seleccionar una persona para asignar roles', 0);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        if (EmpleadoOne) {
            setShowRoles(false);
        }
        if (EmpleadoOne === null || EmpleadoOne === undefined || EmpleadoOne === '') {
            setShowRoles(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EmpleadoOne]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <Grid container>
                <Grid
                    item
                    sx={{
                        '& .css-b62m3t-container': { width: '100%' }
                    }}
                    xs={12}
                    sm={12}
                    lg={6}
                    md={6}
                    xl={6}
                    noValidate
                    autoComplete="off"
                >
                    <Controller
                        name="persona"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                isClearable
                                isSearchable
                                defaultValue={null}
                                options={empleados}
                                placeholder="Seleccione una opción"
                                noOptionsMessage={() => 'sin resultados'}
                            />
                        )}
                    />
                    {!!errors.persona && errors.persona.message}
                </Grid>
                <Grid
                    item
                    sx={{
                        '& .css-b62m3t-container': { width: '100%' }
                    }}
                    xs={12}
                    sm={12}
                    lg={6}
                    md={6}
                    xl={6}
                    noValidate
                    autoComplete="off"
                >
                    <Button type="submit" variant="contained" sx={{ ml: 2, px: 0, background: theme.palette.info }}>
                        <IconSearch sx={{ px: 0 }} />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Formulario;
