/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import { ContentContext } from './context';
import { Avatar, Grid, Typography, Card, CardActionArea, CardContent } from '@mui/material';
import { useSpeechSynthesis } from 'react-speech-kit';
import Carrusel from 'utils/Carrusel/index';
import Logo from 'assets/images/logo.png';

const VistaPublica = () => {
    const { dataAtleta, listado } = useContext(ContentContext);
    const { speak, voices } = useSpeechSynthesis();
    const [dataPersona, setDataPersona] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const avatarStyle = {
        width: '100%',
        height: '100%',
        maxHeight: '280px',
        borderRadius: '5px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        textAlign: 'center',
        objectFit: 'cover',
        textIndent: '10000px'
    };

    useEffect(() => {
        const defaultVoice = voices.find((v) => v.name === 'Google español' && v.lang === 'es-ES');
        setSelectedVoice(defaultVoice);
    }, [voices]);

    useEffect(() => {
        if (dataAtleta?.nombreCompleto && selectedVoice) {
            speak({
                text: `Turno del Atleta ${dataAtleta?.nombreCompleto}, por favor, diríjase al consultorio del doctor, ${dataAtleta.medico} quien lo recibirá.`,
                voice: selectedVoice
            });
            // médico
            setDataPersona(dataAtleta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataAtleta, selectedVoice]);

    const CardComponent = ({ list }) => (
        <Card sx={{ maxWidth: '90%', height: 600 }}>
            <Carrusel List={list} height={600} width="100%" backgroundSize="contain" />
        </Card>
    );
    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sm={4}
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }} // Esto asegura que el Grid ocupe todo el alto disponible
            >
                <img
                    src={Logo}
                    style={{
                        position: 'absolute',
                        zIndex: 9000,
                        height: '100px',
                        top: 10, // <-- Coloca la imagen en la parte superior
                        left: 10 // <-- Coloca la imagen a la izquierda
                    }}
                />
                <Card
                    sx={{
                        maxWidth: 350,
                        minHeight: 250,
                        border: 'solid 1px #CCC',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <CardActionArea>
                        <Avatar
                            variant="square"
                            alt={dataPersona?.nombreCompleto}
                            src={dataPersona?.foto}
                            sx={avatarStyle}
                            id="my-card-action-area"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" align="center">
                                {dataPersona?.nombreCompleto || ''}
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" align="center">
                                {dataPersona?.deporte || ''}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid
                item
                xs={12}
                lg={8}
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }} // Esto asegura que el Grid ocupe todo el alto disponible>
            >
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <CardComponent list={listado} />
                    </Grid>
                </Grid>
            </Grid>{' '}
        </Grid>
    );
};

export default VistaPublica;
