import { useContext } from 'react';
import { Avatar, Box, Grid, Tab, Tabs, Typography, Stack, IconButton } from '@mui/material';
import {
    IconCake,
    IconOlympics,
    IconId,
    IconUser,
    IconMapPin,
    IconScaleOutline,
    IconRuler,
    IconCheck,
    IconBan,
    IconArrowNarrowLeft
} from '@tabler/icons';
import { ContentContext } from './context';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { UserContext } from 'utils/context/userContext';

const Header = () => {
    const { user, dataVal, handleTabChange, Volver, FinalizarProceso, VolverCertificados } = useContext(ContentContext);
    const { Roles, userRoles } = useContext(UserContext);

    const avatarStyle = {
        width: '100%',
        height: '100%',
        maxHeight: '250px',
        borderRadius: '5px',
        textAlign: 'center',
        objectFit: 'cover',
        color: 'transparent',
        textIndent: '10000px'
    };

    const containerStyle = {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
        paddingBottom: '15px'
    };

    const menuStyle = {
        position: 'relative',
        boxSizing: 'border-box',
        display: 'flex',
        flexFlow: 'row wrap',
        minWidth: '100px !important',
        height: '60px',
        marginTop: '10px',
        paddingTop: '10px',
        paddingLeft: '10px',
        // paddingRight: '10px',
        alignItems: 'flex-end',
        flexWrap: 'wrap'
    };

    return (
        <Box sx={containerStyle}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Avatar variant="square" alt={user?.nombres} src={user?.foto} sx={avatarStyle} />
                </Grid>
                <Grid item xs={12} sm={8} md={9} lg={10}>
                    <Box sx={{ minHeight: 150 }}>
                        <Grid container>
                            <Grid item lg={8} xs={8}>
                                <Typography variant="h4">
                                    {user?.nombres} {user?.apellidos}
                                </Typography>
                                <Typography variant="body1">
                                    {user?.edad} años, {user?.estadoCivil}, {user?.genero}
                                </Typography>
                            </Grid>
                            <Grid item lg={4} xs={4}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    {userRoles.includes(Roles?.vob_cert_med) || userRoles.includes(Roles?.ope_cert_med) ? null : (
                                        <>
                                            <BootstrapTooltip title="Cancelar Atención" arrow placement="top">
                                                <IconButton
                                                    aria-label="Anular"
                                                    sx={{ px: 0, mx: 0 }}
                                                    size="small"
                                                    onClick={() => FinalizarProceso(0)}
                                                >
                                                    <IconBan size={30} />
                                                </IconButton>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title="Finalizar Proceso" arrow placement="top">
                                                <IconButton
                                                    aria-label="Finalizar"
                                                    sx={{ px: 0, mx: 0 }}
                                                    size="small"
                                                    onClick={() => FinalizarProceso(2)}
                                                >
                                                    <IconCheck size={30} />
                                                </IconButton>
                                            </BootstrapTooltip>
                                        </>
                                    )}
                                    <BootstrapTooltip title="Volver" arrow placement="top">
                                        <IconButton
                                            aria-label="Volver"
                                            sx={{ px: 0, mx: 0 }}
                                            size="small"
                                            onClick={() =>
                                                userRoles.includes(Roles?.vob_cert_med) || userRoles.includes(Roles?.ope_cert_med)
                                                    ? VolverCertificados()
                                                    : Volver()
                                            }
                                        >
                                            <IconArrowNarrowLeft size={30} />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={6}>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <IconCake stroke={1.5} />
                                    <span style={{ marginLeft: 4, marginTop: 5 }}>{user?.fechaNacimiento}</span>
                                </Typography>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <IconOlympics stroke={1.5} />
                                    {/* <span style={{ marginLeft: 4, marginTop: 5 }}>{user?.deporte}</span> */}
                                    <span style={{ marginLeft: 4, marginTop: 5 }}>Bádminton</span>
                                </Typography>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <IconMapPin stroke={1.5} />
                                    <span style={{ marginLeft: 4, marginTop: 5 }}>{user?.direccion}</span>
                                </Typography>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <IconId stroke={1.5} />
                                    <span style={{ marginLeft: 4, marginTop: 5 }}>{user?.dpi}</span>
                                </Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <IconUser stroke={1.5} />
                                    <span style={{ marginLeft: 4, marginTop: 5 }}>{user?.entrenador}</span>
                                </Typography>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <div style={{ cursor: 'pointer' }}>
                                        <IconScaleOutline stroke={1.5} />
                                    </div>
                                    <span style={{ marginLeft: 4 }}>{parseFloat(user?.peso || 0).toFixed(2)}</span>
                                    <small style={{ marginLeft: 5 }}>Ultima Actualización ({user?.ultimaActualizacion})</small>
                                </Typography>
                                <Typography
                                    sx={{ my: 2, display: 'flex', alignItems: 'center' }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    <div style={{ cursor: 'pointer' }}>
                                        <IconRuler stroke={1.5} />
                                    </div>
                                    <span style={{ marginLeft: 4 }}>{parseFloat(user?.estatura || 0).toFixed(2)}</span>
                                    <small style={{ marginLeft: 5 }}>Ultima Actualización ({user?.ultimaActualizacion})</small>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={10} sx={{ pt: '5px !important' }}>
                <Box sx={menuStyle}>
                    <Tabs value={dataVal} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                        <Tab label="Historial" />
                        {userRoles.includes(Roles.ci_hcd) && <Tab label="Historia Clínica Deportiva" />}
                        {userRoles.includes(Roles.ci_efd) && <Tab label="Examen Físico Deportivo" />}
                        {userRoles.includes(Roles.ci_hev) && <Tab label="Hoja de Evolución" />}
                        {userRoles.includes(Roles.ci_nut) && <Tab label="Nutrición-Cineantropometría" />}
                        {userRoles.includes(Roles.ci_fps) && <Tab label="Ficha Psicológica" />}
                        {userRoles.includes(Roles.ci_vfi) && <Tab label="Valoración Fisioterapéutica" />}
                        {userRoles.includes(Roles.ci_ebi) && <Tab label="Evaluación Biomecánica" />}
                        {userRoles.includes(Roles.ci_pae) && <Tab label="Prueba Aeróbica" />}
                        {userRoles.includes(Roles.ci_pan) && <Tab label="Prueba Anaeróbica" />}
                        {userRoles.includes(Roles.ci_pan) && <Tab label="Evaluación de Fuerza y Flexibilidad" />}
                        {userRoles.includes(Roles.ci_hcd) && <Tab label="Estudios de Gabinete" />}
                    </Tabs>
                </Box>
            </Grid>
        </Box>
    );
};

export default Header;
