/* eslint-disable no-undef */
import Chart from 'react-apexcharts';

// eslint-disable-next-line react/prop-types
const PieChart = ({ data }) => {
    const options = {
        plotOptions: {
            pie: {
                expandOnClick: true
            }
        },
        labels: data[1],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 380
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        ]
    };
    const series = data[0];

    return <Chart options={options} series={series} type="pie" width="380" />;
};

export default PieChart;
