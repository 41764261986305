/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, createContext, useContext } from 'react';
import { PostRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
// import { updateData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';
import { UserContext } from 'utils/context/userContext';
import { useSWRConfig } from 'swr';
import { useRequest } from '../../../hooks/useRequestSWR';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { mutate } = useSWRConfig();
    const { userRoles, Roles, userAuth } = useContext(UserContext);
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    // const [allData, setAllData] = useState([]);
    const [idTipoCertificado, setIdTipoCertificado] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [pdf, setPdf] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'certificados';
    // const { data: allDataHistorica } = useRequest(`${nameController}/allHistorico`, 'GETSWR');
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    // const All = async () => {
    //     const response = await GetRoute(`${nameController}/all`);
    //     setAllData(response.length ? response : []);
    // };
    const toggleModal = (data) => {
        setOpcion(data);
        data === 1 && setOneData([]);
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data);
        toggleModal(0);
        mutate(`${nameController}/all`);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const UpdateEstadoCertificado = async (data) => {
        const response = await PostRoute(`${nameController}/${'UpdateEstadoGestiones'}`, data);
        Notification(response.message, 1);
        mutate(`${nameController}/all`);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        mutate(`${nameController}/all`);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const labelTiposCertificados = [
        { value: 1, label: 'Certificado de Precompetencia' },
        { value: 2, label: 'Certificado de Buena Salud' }
    ];

    const EnviarARevision = async (data, opcion) => {
        toggleModal(opcion);
        const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data);
        Notification(response.message, 1);
        mutate(`${nameController}/all`);
    };
    const GestionesXAprobador =
        allData &&
        allData?.value !== 2009 &&
        allData?.filter(
            (item) =>
                (item?.idEstado === 4 && userRoles.includes(Roles.vob_cert_med)) ||
                ((item?.idEstado === 2 || item?.idEstado === 10) && userRoles.includes(Roles.ope_cert_med)) ||
                (item?.idEstado === 11 && userRoles.includes(Roles.sol_cert_med) && Number(userAuth?.federacionId) === item?.idFedereacion)
        );
    const value = {
        Actions,
        EnviarARevision,
        One,
        setIdTipoCertificado,
        setPdf,
        setOpcion,
        setShow,
        StoreUpdate,
        UpdateEstadoCertificado,
        toggleModal,
        allData,
        idTipoCertificado,
        labelTiposCertificados,
        GestionesXAprobador,
        oneData,
        opcion,
        pdf,
        Roles,
        show,
        userAuth,
        userRoles
    };
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
