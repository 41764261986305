/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { appendData, updateData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'genero';
    const All = async () => {
        const response = await GetRoute(`${nameController}/all`);
        setAllData(response.length ? response : []);
    };
    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data);
        opcion === 1 && setAllData(appendData(allData, data, { id: response.id, codigo: response.codigo }));
        opcion === 3 && setAllData(updateData(allData, data));
        Notification(response.message, 1);
        toggleModal(0);
        setShowLoad(false);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        setAllData(updateData(allData, { ...data, estado: data.estado === 1 ? 0 : 1 }));
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = { All, One, Actions, StoreUpdate, toggleModal, setOpcion, allData, show, oneData, opcion };
    useEffect(() => {
        All();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
