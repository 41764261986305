import { Box, Grid, Typography, TextField, Button, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { obtenerFechaActual } from 'utils/function/global';
import { ContentContext } from '../context';
import { useForm, Controller } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Add, Check } from '@mui/icons-material';

const Formulario = () => {
    const { oneDataValoracionFisioterapeutica, StoreUpdateValoracionFisioterapeutica, FinalizarValoracionFisioterapeutica } =
        useContext(ContentContext);
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();
    const onSubmit = (data) => {
        if (!oneDataValoracionFisioterapeutica?.id) {
            const jsonData = {
                fecha: obtenerFechaActual(),
                etapaEntrenamiento: data.etapaEntrenamiento,
                tratamiento: data.tratamiento,
                hallazgo: data.hallazgo,
                areaLesion: data.areaLesion,
                protocoloPrevencion: data.protocoloPrevencion,
                otros: data.otros
            };
            StoreUpdateValoracionFisioterapeutica(jsonData);
        } else {
            FinalizarValoracionFisioterapeutica(oneDataValoracionFisioterapeutica);
        }
    };
    const setData = () => {
        setValue('etapaEntrenamiento', oneDataValoracionFisioterapeutica.etapaEntrenamiento);
        setValue('tratamiento', oneDataValoracionFisioterapeutica.tratamiento);
        setValue('hallazgo', oneDataValoracionFisioterapeutica.hallazgo);
        setValue('areaLesion', oneDataValoracionFisioterapeutica.areaLesion);
        setValue('otros', oneDataValoracionFisioterapeutica.otros);
        setValue('protocoloPrevencion', oneDataValoracionFisioterapeutica.protocoloPrevencion);
    };

    useEffect(() => {
        if (oneDataValoracionFisioterapeutica.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataValoracionFisioterapeutica]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={5}>
                    <Typography sx={{ mb: 2 }} variant="h4">
                        Formulario de Valoración Fisioterapéutica
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={5}>
                    <Typography sx={{ mb: 2 }} variant="h4">
                        DATOS GENERALES
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.areaLesion}
                            {...register('areaLesion', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Área de Lesión"
                            InputLabelProps={activeLabel}
                            helperText={errors?.areaLesion?.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.hallazgo}
                            {...register('hallazgo', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Hallazgos"
                            InputLabelProps={activeLabel}
                            helperText={errors?.hallazgo?.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Typography sx={{ mb: 2 }} variant="h6">
                Etapa de Entrenamiento:
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="etapaEntrenamiento"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <Grid item xs={4}>
                                    <FormControlLabel value="1" control={<Radio />} label="General" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel value="2" control={<Radio />} label="Específica" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel value="3" control={<Radio />} label="Complementaria" />
                                </Grid>
                            </RadioGroup>
                        )}
                    />
                </Grid>
            </Grid>
            <Typography sx={{ mb: 2 }} variant="h6">
                Tratamiento:
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="tratamiento"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <Grid item xs={3}>
                                    <FormControlLabel value="1" control={<Radio />} label="Clínica" />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel value="2" control={<Radio />} label="Mecanoterapia" />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel value="3" control={<Radio />} label="Hidroterapia" />
                                </Grid>
                            </RadioGroup>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
                        <TextField
                            error={!!errors.protocolo}
                            {...register('protocoloPrevencion', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Protocolo de prevención y correlativo de lesiones:"
                            InputLabelProps={activeLabel}
                            helperText={errors?.protocoloPrevencion?.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
                        <TextField
                            error={!!errors.otros}
                            {...register('otros', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Otros:"
                            InputLabelProps={activeLabel}
                            helperText={errors?.otros?.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <AnimateButton>
                        <BootstrapTooltip title={oneDataValoracionFisioterapeutica?.id ? 'Finalizar' : 'Crear'} arrow placement="left">
                            <Button
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{
                                    background: oneDataValoracionFisioterapeutica?.id
                                        ? theme.palette.success.main
                                        : theme.palette.primary.main,
                                    '&:hover': {
                                        background: oneDataValoracionFisioterapeutica?.id
                                            ? theme.palette.success.dark
                                            : theme.palette.primary.dark
                                    },
                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                }}
                            >
                                {oneDataValoracionFisioterapeutica?.id ? <Check fontSize="medium" /> : <Add fontSize="medium" />}
                            </Button>
                        </BootstrapTooltip>
                    </AnimateButton>
                </Box>
            </Grid>
        </Box>
    );
};

export default Formulario;
