/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { PostRoute } from 'services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { updateData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [dateSelect, setDateSelect] = useState([]);
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDataDetalle, setAllDataDetalle] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'solicitud';
    const nameController2 = 'detalleSolicitud';
    const nameController3 = 'Asistencia';
    const All = async () => {
        const response = await PostRoute(`${nameController}/all-state`, { estado: 3 });
        setAllData(response.length ? response : []);
    };
    const AllDetalleSolicitud = async (data) => {
        const response = await PostRoute(`${nameController2}/all`, { solicitud: data });
        setAllDataDetalle(response.length ? response : []);
    };
    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        // eslint-disable-next-line no-underscore-dangle
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        // eslint-disable-next-line no-underscore-dangle
        AllDetalleSolicitud(data.id);
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController3}/${!data.id ? 'store' : 'update'}`, data);
        All();
        Notification(response.message, 1);
        toggleModal(0);
        setShowLoad(false);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        setAllData(updateData(allData, { ...data, estado: data.estado === 1 ? 0 : 1 }));
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = {
        All,
        One,
        Actions,
        StoreUpdate,
        toggleModal,
        setOpcion,
        setAllDataDetalle,
        setDateSelect,
        allData,
        show,
        oneData,
        opcion,
        dateSelect,
        allDataDetalle
    };
    useEffect(() => {
        All();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
