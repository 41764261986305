import { Box, Grid, Typography, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import LineChart from 'utils/charts/lineChart/index';
import { Save } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { ContentContext } from '../context';
import FormDetalle from './formDetalle';
import Listado from './list';

const PruebaEsfuerzoMaximo = () => {
    const {
        allDataDetallePruebaAanaerobica,
        allDataWatts,
        setAllDataWatts,
        setAllDataTiempoSegundos,
        StoreUpdatePruebaAnaerobica,
        allDataTiempoSegundos,
        dataPotenciaMaxima,
        dataResistenciaMedia,
        dataIndiceFatiga,
        dataResistencia
    } = useContext(ContentContext);
    const currentDate = new Date().toISOString().split('T')[0]; // Obtiene la fecha actual en formato ISO
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const onSubmit = (data) => {
        const jsData = {
            fecha: data.fecha,
            noRegistro: data.noRegistro,
            resistencia: dataResistencia || 0,
            potenciaMaxima: dataPotenciaMaxima || 0,
            resistenciaMedia: dataResistenciaMedia || 0,
            indiceFatiga: dataIndiceFatiga || 0,
            detalle: allDataDetallePruebaAanaerobica
        };
        StoreUpdatePruebaAnaerobica(jsData);
        setAllDataWatts([]);
        setAllDataTiempoSegundos([]);
        reset();
    };
    return (
        <>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.observacion}
                                {...register('noRegistro', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="No. Registro"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.observacion && errors.observacion.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.fecha}
                                {...register('fecha', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                InputLabelProps={{ shrink: true }}
                                defaultValue={currentDate}
                                type="date"
                                size="small"
                                id="outlined-error-helper-text"
                                label="Fecha"
                                helperText={!!errors.fecha && errors.fecha.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.main,
                                            '&:hover': {
                                                background: theme.palette.success.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Save fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Finalizar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.main,
                                            '&:hover': {
                                                background: theme.palette.success.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Check fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box> */}
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid item xs={12} sx={{ my: 2 }}>
                    <Typography variant="h5" component="span">
                        INDICES
                    </Typography>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="span">
                            RESISTENCIA (Kp) {dataResistencia || 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="span">
                            POTENCIAS MAXIMA (Watts/KG) {dataPotenciaMaxima || 0}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="span">
                            RESISENCIA MEDIA (Watts/KG) {dataResistenciaMedia || 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="span">
                            INDICE DE FATIGA (%) {dataIndiceFatiga || 0}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormDetalle />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="center" height="100%" textAlign="center">
                            <Typography variant="h5" component="span">
                                TEST ANAEROBICO WINGATE
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Listado />
                    </Grid>
                    <Grid item xs={6}>
                        <LineChart label={allDataTiempoSegundos} data={allDataWatts} name="series-1" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PruebaEsfuerzoMaximo;
