import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Avatar } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import logo from 'assets/images/logo_oficial-1-SIN-AROS.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Avatar
            sx={{ m: 1, height: 78, width: 150, ml: 2, background: 'transparent', position: 'absolute', left: 20, bottom: -57 }}
            variant="square"
            src={logo}
        />
        {/* <div style={{ paddingLeft: '100px', fontSize: '22px', fontWeight: 'bolder', position: 'absolute' }}>COG</div> */}
    </ButtonBase>
);

export default LogoSection;
