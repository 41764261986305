import { useContext, useEffect } from 'react';
import { Modal, Box, Button, FormControl, Grid, Typography, TextField, ButtonGroup } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import MainCard from 'utils/components/modal/MainCardModal';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { Save, Add, NotInterested } from '@mui/icons-material';
import { ContentContext } from '../context';
import List from './list';

export default function KeepMountedModal() {
    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        setFocus
    } = useForm();
    const { showDetalle, oneData, StoreUpdateDetalleTipoFuerza, oneDataDetalle, setShowDetalle, setOneDataDetalle } =
        useContext(ContentContext);
    const theme = useTheme();

    const Cancel = () => {
        setOneDataDetalle();
        setValue('nombre', '');
    };

    const onSubmit = (data) => {
        const jsData = {
            id: oneDataDetalle?.id || null,
            tipoFuerza: oneData?.id,
            ...data
        };
        StoreUpdateDetalleTipoFuerza(jsData);
        Cancel();
    };

    useEffect(() => {
        if (oneDataDetalle?.id) {
            setValue('nombre', oneDataDetalle.nombre);
            setFocus('nombre');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataDetalle]);

    return (
        <Box>
            <Modal
                keepMounted
                open={showDetalle}
                onClose={() => setShowDetalle(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Asignar Detalle" closeButton={() => setShowDetalle(false)} size="lg">
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography sx={{ fontSize: 'large', ml: 2, mb: 4 }}>{oneData?.nombre || ''}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={10} md={10} sm={10} xs={12}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <TextField
                                                error={!!errors.nombre}
                                                {...register('nombre', { required: { value: true, message: 'Este campo es requerido' } })}
                                                size="small"
                                                id="outlined-error-helper-text"
                                                label="Nombre"
                                                focused={oneData.length !== 0}
                                                helperText={!!errors.nombre && errors.nombre.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    alignSelf: 'center',
                                                    background: theme.palette.primary.dark,
                                                    color: '#FFF',
                                                    '&:hover': {
                                                        background: theme.palette.primary.light,
                                                        color: theme.palette.primary.dark
                                                    }
                                                }}
                                            >
                                                <Add fontSize="medium" />
                                            </Button>
                                            {oneDataDetalle?.id && (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    onClick={() => Cancel()}
                                                    sx={{
                                                        alignSelf: 'center',
                                                        background: theme.palette.error.dark,
                                                        color: '#FFF',
                                                        '&:hover': {
                                                            background: theme.palette.error.light,
                                                            color: theme.palette.error.dark
                                                        }
                                                    }}
                                                >
                                                    <NotInterested fontSize="medium" />
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xs={12}>
                                        <List />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Guardar" arrow placement="left">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.dark,
                                            '&:hover': {
                                                background: theme.palette.success.light
                                            }
                                        }}
                                    >
                                        <Save fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
