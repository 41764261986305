/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';

export async function generatePDF(filas, columnas, titulo, subTitulo, nombrePdf, item, UserAut) {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: nombrePdf });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    let str = null;
    let Y = 0;
    const hoy = new Date();
    async function pageContent(data) {
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold').setFontSize(12);
        doc.setFontSize(14);
        doc.text(titulo || '', 300, 50, { width: 800, align: 'center' });
        doc.setFontSize(10);
        doc.text(subTitulo || '', 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Fecha'}: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, 100, {
            width: 800,
            align: 'left'
        });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Impreso por'}: ${UserAut || ''}`, 300, 100, { width: 800, align: 'center' });
        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }
    Y += 140;
    doc.setFontSize(10).setFont('times', 'bold');
    doc.text('NOMBRE:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.nombres || ''} ${item?.user?.apellidos || ''}`.toUpperCase(), 110, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text(`F. NACIMIENTO:`, 400, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.fechaNacimiento || ''}`, 490, Y, { width: 800, align: 'left' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text(`DEPORTE:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.deporte || ''}`, 110, Y, { width: 800, align: 'left' });

    Y += 35;
    const X = 300;
    doc.setFont('times', 'bold');
    doc.text(`FECHA:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.fecha || ''}`, 110, Y, { width: 800, align: 'left' });

    doc.setFont('times', 'bold');
    doc.text(`No. REGISTRO`, X, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.noRegistro || ''}`, 390, Y, { width: 800, align: 'left' });

    Y += 35;
    doc.setFont('times', 'bold');
    doc.text(`INDICES`, 50, Y, { align: 'left' });

    Y += 25;
    doc.setFont('times', 'bold');
    doc.text(`RESISTENCIA (Kp)`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.resistencia || ''}`, 150, Y, { width: 800, align: 'left' });

    doc.setFont('times', 'bold');
    doc.text(`POTENCIAS MAXIMA (Watts/KG)`, X, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.potenciaMaxima || ''}`, 480, Y, { width: 800, align: 'left' });

    Y += 25;
    doc.setFont('times', 'bold');
    doc.text(`RESISTENCIA MEDIA (Watts/KG)`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.resistenciaMedia || ''}`, 230, Y, { width: 800, align: 'left' });

    doc.setFont('times', 'bold');
    doc.text(`INDICE DE FATIGA (%)`, X, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.oneDataPruebaAnaerobica?.indiceFatiga || ''}`, 420, Y, { width: 800, align: 'left' });

    Y += 40;
    doc.setFontSize(14);
    doc.setFont('times', 'bold');
    doc.text(`TEST ANAEROBICO WINGATE`, pageWidth / 2, Y, { align: 'center' });
    Y += 25;
    const list = item.allDataDetallePruebaAanaerobicaVisualizar.map((item) => [item.tiempo, item.watts, item.resultado]);
    doc.autoTable({
        head: [['Tiempo', 'WATTS', 'WATTS/KG']],
        body: list,
        theme: 'plain',
        startY: Y,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto',
            0: { cellWidth: 60 }
        },
        headStyles: {
            fillColor: null,
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: null,
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto',
            valign: 'middle',
            halign: 'center'
        },
        didDrawPage: pageContent,
        margin: {
            left: 50,
            right: 350,
            bottom: 50
        }
    });

    doc.addImage(item.img[0], 'PNG', pageWidth / 2, Y, (pageWidth - 50) / 2 - 50, 150);

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    // if (filas.length > 1000) {
    doc.save(`Reporte.pdf`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { generatePDF };
