/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useContext, useEffect } from 'react';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Grid,
    FormControl,
    TextField,
    Avatar,
    InputAdornment,
    InputLabel
} from '@mui/material';
import { IconX, IconPhoto } from '@tabler/icons';
// import { useTheme } from '@mui/material/styles';
import { CustomLabel, CustomLabelSelect } from 'utils/components/customLabel';
import { useForm, Controller } from 'react-hook-form';
import { customStyles, customStylesDanger } from 'utils/select/index';
import Select from 'react-select';
import { ContentContext } from '../context';

const steps = ['Datos Personales', 'Datos de Contacto', 'Datos Deportivos'];

export default function HorizontalLinearStepper() {
    const {
        opcion,
        oneData,
        StoreUpdate,
        ImgSuccess,
        toggleModal,
        allDataGenero,
        allDataEstadoCivil,
        allDataPais,
        allDataProfesion,
        allDataGradoAcademico,
        allDataEtnia,
        allDataDepartamento,
        AllMunicipio,
        allDataMunicipio,
        allDataFederacion,
        allDataLinea,
        allDataRanking,
        allDataDeporteAdaptado
    } = useContext(ContentContext);
    const [validadSelect, setValidarSelect] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [Visualizar, setVisualizar] = useState('');
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
        control,
        clearErrors
    } = useForm();
    const selectPais = watch('pais');
    const selectDepartamento = watch('departamento');
    const isStepSkipped = (step) => skipped.has(step);

    const clearFoto = () => {
        setVisualizar('');
        setValue('foto', null);
        clearErrors('foto');
    };

    const resetData = () => {
        reset({
            nombres: '',
            apellidos: '',
            fechaNacimiento: '',
            estadoCivil: '',
            genero: '',
            etnia: '',
            pais: '',
            nacionalidad: '',
            dpi: '',
            pasaporte: '',
            vencimientoPasaporte: '',
            gradoAcademico: '',
            profesion: '',
            telefono: '',
            email: '',
            departamento: '',
            municipio: '',
            direccion: '',
            fadn: '',
            linea: '',
            ranking: '',
            deporteAdaptado: '',
            estatura: '',
            peso: '',
            elo: '',
            codigoFederacion: ''
        });
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        clearFoto();
        resetData();
    };
    const MostrarDoc = (value) => {
        if (value.target.value) {
            const pdffile = value.target.files[0];
            const pdffileUrl = URL.createObjectURL(pdffile);
            // setGetFoto(pdffile);
            setVisualizar(`${pdffileUrl}`);
        } else {
            setVisualizar('');
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('nombres', data.nombres);
        formData.append('apellidos', data.apellidos);
        formData.append('fechaNacimiento', data.fechaNacimiento);
        formData.append('genero', data.genero.value);
        formData.append('estadoCivil', data.estadoCivil.value);
        formData.append('tipoPersona', 3);
        data.foto && formData.append('foto', data.foto[0]);
        formData.append('vencimientoPasaporte', data.vencimientoPasaporte);
        formData.append('dpi', data.dpi);
        formData.append('pasaporte', data.pasaporte);
        formData.append('telefono', data.telefono);
        formData.append('email', data.email);
        formData.append('direccion', data.direccion);
        formData.append('etnia', data.etnia.value);
        formData.append('pais', data.pais.value);
        formData.append('gradoAcademico', data.gradoAcademico.value);
        formData.append('profesion', data.profesion.value);
        formData.append('municipio', data.municipio.value);
        formData.append('fadn', data.fadn.value);
        formData.append('linea', data.linea.value);
        formData.append('ranking', data.ranking.value);
        formData.append('deporteAdaptado', data.deporteAdaptado.value);
        formData.append('estatura', data.estatura);
        formData.append('peso', data.peso);
        formData.append('elo', data.elo);
        formData.append('codigoFederacion', data.codigoFederacion);
        const response = await StoreUpdate(formData, !oneData.id ? 'store/atleta' : 'update/atleta');
        response.value === 1 && handleNext();
        response.value === 1 && clearFoto();
    };
    function isValidValue(value) {
        return value !== null && value !== undefined && value !== '0';
    }

    const setData = () => {
        setValue('nombres', oneData.nombres);
        setValue('apellidos', oneData.apellidos);
        setValue('fechaNacimiento', oneData.fechaNacimiento);

        isValidValue(oneData.idEstadoCivil) && setValue('estadoCivil', { label: oneData.estadoCivil, value: oneData.idEstadoCivil });
        isValidValue(oneData.idGenero) && setValue('genero', { label: oneData.genero, value: oneData.idGenero });
        setValue('pasaporte', oneData.pasaporte);
        setValue('vencimientoPasaporte', oneData.vencimientoPasaporte);
        isValidValue(oneData.idEtnia) && setValue('etnia', { label: oneData.etnia, value: oneData.idEtnia });
        isValidValue(oneData.idPais) && setValue('pais', { label: oneData.pais, value: oneData.idPais });

        setTimeout(() => {
            setValue('nacionalidad', oneData.nacionalidad);
        }, 500);

        setVisualizar(oneData.foto);

        isValidValue(oneData.idGradoAcademico) &&
            setValue('gradoAcademico', { label: oneData.gradoAcademico, value: oneData.idGradoAcademico });
        isValidValue(oneData.idProfesion) && setValue('profesion', { label: oneData.profesion, value: oneData.idProfesion });
        setValue('telefono', oneData.telefono);
        setValue('email', oneData.email);
        setValue('direccion', oneData.direccion);
        setValue('dpi', oneData.dpi);

        isValidValue(oneData.idDepartamento) && setValue('departamento', { label: oneData.departamento, value: oneData.idDepartamento });
        isValidValue(oneData.idMunicipio) && setValue('municipio', { label: oneData.municipio, value: oneData.idMunicipio });
        isValidValue(oneData.idDeporte) && setValue('fadn', { label: oneData.deporte, value: oneData.idDeporte });
        isValidValue(oneData.idLinea) && setValue('linea', { label: oneData.liena, value: oneData.idLinea });
        isValidValue(oneData.idRanking) && setValue('ranking', { label: oneData.ranking, value: oneData.idRanking });
        isValidValue(oneData.idDeporteAdaptado) &&
            setValue('deporteAdaptado', { label: oneData.deporteAdaptado, value: oneData.idDeporteAdaptado });

        setValue('estatura', oneData.estatura);
        setValue('peso', oneData.peso);
        setValue('elo', oneData.elo);
        setValue('codigoFederacion', oneData.codigoFederacion);
    };
    useEffect(() => {
        if (selectPais) {
            setValue('nacionalidad', selectPais.nacionalidad);
            setValidarSelect(true);
        } else {
            setValue('nacionalidad', '');
            setValidarSelect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPais]);

    useEffect(() => {
        if (selectDepartamento) {
            AllMunicipio(selectDepartamento);
        } else {
            setValue('municipio', null);
            AllMunicipio(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDepartamento]);

    useEffect(() => {
        if (opcion === 0) {
            setActiveStep(0);
        }
        if (opcion > 1) {
            setData();
        }
        if (opcion === 1) {
            resetData();
            setVisualizar('');
            // setActiveLabel({ shrink: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion]);

    return (
        opcion > 0 && (
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                <Grid container spacing={2}>
                    <Grid item lg={3} sm={12} xs={12} sx={{ mt: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} align="center">
                                <div className={`kt-avatar kt-avatar--outline ${Visualizar && 'kt-avatar--changed'}`} id="kt_user_avatar">
                                    <div
                                        id="logo-size"
                                        className={`kt-avatar__holder ${errors && errors.foto && errors.foto.message && 'border-danger'}`}
                                        style={{ backgroundImage: `url('${Visualizar}')` }}
                                    >
                                        {opcion === 1 && (
                                            <>
                                                <label
                                                    className="kt-avatar__upload p-0 d-flex"
                                                    style={activeStep < 3 ? { display: 'flex' } : { display: 'none' }}
                                                    data-toggle="kt-tooltip"
                                                    title="Cargar Foto"
                                                    data-original-title="Change avatar"
                                                >
                                                    <TextField
                                                        {...register('foto', {
                                                            required: {
                                                                value: opcion === 1,
                                                                message: 'Este campo es requerido'
                                                            }
                                                        })}
                                                        lang="es"
                                                        type="file"
                                                        className="fileInput"
                                                        accept=".png"
                                                        onChange={(e) => MostrarDoc(e)}
                                                    />
                                                    {activeStep < 3 && <IconPhoto stroke={1.5} size="1.3rem" />}
                                                </label>
                                            </>
                                        )}
                                    </div>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                    {activeStep < 3 && opcion === 1 && (
                                        <span className="kt-avatar__cancel" onClick={() => clearFoto()}>
                                            <IconX stroke={1.5} size="1.3rem" />
                                        </span>
                                    )}

                                    {/* {Visualizar && (
                                    <IconButton aria-label="fingerprint" color="success" className="kt-avatar__save" type="submit">
                                        <IconDeviceFloppy stroke={1.5} size="1.3rem" />
                                    </IconButton>
                                )} */}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">
                                    <small className="text-muted text-danger">{!!errors.foto && errors.foto.message}</small>
                                </Typography>
                                <Typography align="center">
                                    <small className="text-muted">Permitido *.png</small>
                                </Typography>
                                <Typography align="center">
                                    <small className="text-muted">tamaño máximo de 10 MB</small>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={9} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ pb: 5 }}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.nombres}
                                            {...register('nombres', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="nombres"
                                            label={<CustomLabel required label="Nombres" size={16} />}
                                            helperText={!!errors.nombres && errors.nombres.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.apellidos}
                                            {...register('apellidos', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label={<CustomLabel required label="Apellidos" size={16} />}
                                            helperText={!!errors.apellidos && errors.apellidos.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.fechaNacimiento}
                                            {...register('fechaNacimiento', {
                                                required: { value: true, message: 'Este campo es requerido' }
                                            })}
                                            InputLabelProps={{ shrink: true }}
                                            type="date"
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label={<CustomLabel required label="Fecha Nacimiento" size={16} />}
                                            helperText={!!errors.fechaNacimiento && errors.fechaNacimiento.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CustomLabelSelect required label="Estado Civil" size={16} />
                                        <Controller
                                            id="estadoCivil"
                                            control={control}
                                            name="estadoCivil"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataEstadoCivil}
                                                    placeholder="Seleccione Estado Civil"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.estadoCivil ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.estadoCivil && errors.estadoCivil.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="genero" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Genero
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="genero"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataGenero}
                                                    placeholder="Seleccione Genero"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.genero ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.genero && errors.genero.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.dpi}
                                            {...register('dpi', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="DPI"
                                            helperText={!!errors.dpi && errors.dpi.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.pasaporte}
                                            {...register('pasaporte', { required: { value: false, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Pasaporte"
                                            helperText={!!errors.pasaporte && errors.pasaporte.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.vencimientoPasaporte}
                                            {...register('vencimientoPasaporte', {
                                                required: { value: false, message: 'Este campo es requerido' }
                                            })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Vencimiento Pasaporte"
                                            helperText={!!errors.vencimientoPasaporte && errors.vencimientoPasaporte.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="estadoCivil" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Etnia
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="etnia"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataEtnia}
                                                    placeholder="Seleccione Etnia"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.etnia ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.etnia && errors.etnia.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="estadoCivil" shrink sx={{ background: '#FFF', px: 1 }}>
                                            País
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="pais"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataPais}
                                                    placeholder="Seleccione País"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.pais ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.pais && errors.pais.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.nacionalidad}
                                            {...register('nacionalidad', {
                                                required: { value: false, message: 'Este campo es requerido' }
                                            })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Nacionalidad"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            focused={validadSelect}
                                            helperText={!!errors.nacionalidad && errors.nacionalidad.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="gradoAcademico" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Grado Academico
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="gradoAcademico"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataGradoAcademico}
                                                    placeholder="Seleccione Grado Académico"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.gradoAcademico ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.gradoAcademico && errors.gradoAcademico.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="profesion" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Profesión
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="profesion"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataProfesion}
                                                    placeholder="Seleccione Profesión"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.profesion ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.profesion && errors.profesion.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Datos de Contacto */}
                        <Grid container spacing={2} sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.telefono}
                                            {...register('telefono', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Teléfono"
                                            helperText={!!errors.telefono && errors.telefono.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.email}
                                            {...register('email', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Correo Electrónico"
                                            helperText={!!errors.email && errors.email.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="departamento" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Departamento
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="departamento"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataDepartamento}
                                                    placeholder="Seleccione Departamento"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.departamento ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.departamento && errors.departamento.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="municipio" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Municipio
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="municipio"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataMunicipio}
                                                    placeholder="Seleccione Municipio"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.municipio ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.municipio && errors.municipio.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.direccion}
                                            {...register('direccion', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Dirección"
                                            helperText={!!errors.direccion && errors.direccion.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Datos Atleticos */}
                        <Grid container spacing={2} sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="federacion" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Federación
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="fadn"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataFederacion}
                                                    placeholder="Seleccione FADN"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.fadn ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.fadn && errors.fadn.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="linea" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Linea
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="linea"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataLinea}
                                                    placeholder="Seleccione Linea"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.linea ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.linea && errors.linea.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="ranking" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Ranking
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="ranking"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataRanking}
                                                    placeholder="Seleccione Ranking"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.ranking ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.ranking && errors.ranking.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="deporteAdaptado" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Deporte Adaptado
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="deporteAdaptado"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={allDataDeporteAdaptado}
                                                    placeholder="Seleccione Deporte Adaptado"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.deporteAdaptado ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.deporteAdaptado && errors.deporteAdaptado.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.estatura}
                                            {...register('estatura', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Estatura"
                                            helperText={!!errors.estatura && errors.estatura.message}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Cm</InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.peso}
                                            {...register('peso', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Peso"
                                            helperText={!!errors.peso && errors.peso.message}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Lbs</InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.elo}
                                            {...register('elo', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="ELO"
                                            helperText={!!errors.elo && errors.elo.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.codigoFederacion}
                                            {...register('codigoFederacion', {
                                                required: { value: true, message: 'Este campo es requerido' }
                                            })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Código Federación Inter."
                                            helperText={!!errors.codigoFederacion && errors.codigoFederacion.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {activeStep === steps.length ? (
                            <>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Avatar
                                            sx={{ m: 1, height: 230, width: 330, background: 'none' }}
                                            variant="square"
                                            src={ImgSuccess}
                                        />
                                        <Typography variant="h2" sx={{ mt: 2, mb: 1 }}>
                                            Atleta {opcion === 1 ? ' Creado ' : ' Actualizado '} Exitosamente
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    {/* <Button type="button" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                    Volver
                                </Button> */}
                                    <Button
                                        type="button"
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={() => toggleModal(0)}
                                        sx={{ mr: 1 }}
                                    >
                                        Salir
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button type="button" onClick={handleReset}>
                                        Nuevo
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button type="button" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                        Volver
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {activeStep < 2 && (
                                        <Button type="button" onClick={handleNext} sx={{ mr: 1 }}>
                                            Siguiente
                                        </Button>
                                    )}
                                    {activeStep === 2 && opcion !== 3 && <Button type="submit">Guardar</Button>}
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    );
}
