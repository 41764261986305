/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';

export async function generatePDF(filas, columnas, titulo, subTitulo, nombrePdf, item, UserAut, tipo) {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: nombrePdf });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    let str = null;
    let Y = 0;
    const hoy = new Date();

    async function pageContent(data) {
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold').setFontSize(12);
        doc.text(titulo, 300, 50, { width: 800, align: 'center' });
        doc.text(subTitulo, 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Fecha'}: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, 100, {
            width: 800,
            align: 'left'
        });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Impreso por'}: ${UserAut}`, 300, 100, { width: 800, align: 'center' });
        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }
    Y += 140;
    doc.setFontSize(10).setFont('times', 'bold');
    doc.text('NOMBRE:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.nombres || ''} ${item?.user?.apellidos || ''}`.toUpperCase(), 110, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text(`F. NACIMIENTO:`, 400, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.fechaNacimiento || ''}`, 490, Y, { width: 800, align: 'left' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text(`DEPORTE:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.deporte || ''}`, 110, Y, { width: 800, align: 'left' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text(`FECHA:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.row?.fecha || ''}`, 110, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    Y += 55;
    doc.autoTable({
        head: columnas,
        body: filas,
        showHead: 'never',
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto'
        },
        headStyles: {
            fillColor: [255],
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: [233, 233, 240],
            lineWidth: 1,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        didDrawPage: pageContent,
        margin: {
            left: 50,
            right: 50,
            top: Y,
            bottom: 50
        }
    });

    Y = doc.autoTable.previous.finalY + 20;
    if (tipo === 1) {
        doc.setFontSize(14).setFont('times', 'bold');
        doc.text(`OBSERVACIONES`, pageWidth / 2, Y, { align: 'center' });
        Y += 20;
        doc.setFontSize(10).setFont('times', 'bold');
        const contenido2 = doc.splitTextToSize(item?.row?.observacion || '', 500);
        doc.setFont('helvetica', 'normal').text(contenido2, 50, Y, { maxWidth: 500, align: 'justify' });
    }

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    if (filas.length > 1000) {
        doc.save(`Reporte.pdf`);
        return '';
    }
    return doc.output('datauristring');

    // doc.save(`${nombrePdf}.pdf`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { generatePDF };
