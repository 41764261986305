/* eslint-disable react/prop-types */
import { useContext } from 'react';
import {
    Modal,
    Box,
    Grid,
    Typography,
    Divider,
    Button,
    Alert,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox
} from '@mui/material';
import MainCard from 'utils/components/modal/MainCardModal';
import { ContentContext } from './context';

function InformationSection({ label, value }) {
    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                    {label}
                </Typography>
                <Typography variant="h4" className="css-17o47au">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

function DataTable({ athletes, onCheck }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Atleta</TableCell>
                        <TableCell>Asistencia</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {athletes.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.nombreCompleto}</TableCell>
                            <TableCell>{row.asistencia ? 'Si' : 'No'}</TableCell>
                            <TableCell>
                                <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} defaultChecked onChange={() => onCheck(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function KeepMountedModal() {
    const theme = useTheme();
    const { show, toggleModal, oneData, allDataDetalle, setAllDataDetalle, StoreUpdate } = useContext(ContentContext);

    const validChecked = (data) => {
        const element = allDataDetalle.map((item) => (item.id === data.id ? { ...item, asistencia: item.asistencia === 1 ? 0 : 1 } : item));
        setAllDataDetalle(element);
    };

    const Autorizar = () => StoreUpdate({ items: allDataDetalle });

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <MainCard title={`Cita ${oneData?.start}`} sx={{ py: 2 }} closeButton={() => toggleModal(0)}>
                        <InformationSection label="Federación" value={oneData?.deporte} />
                        <InformationSection label="Tipo Atención" value={oneData?.tipoAtencion} />
                        <InformationSection label="Solicitante" value={oneData?.solicitante} />
                        <InformationSection label="Objetivo de la Evaluación solicitada" value={oneData?.motivo} />

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sx={{ px: 2 }}>
                                <Button
                                    onClick={Autorizar}
                                    size="small"
                                    sx={{
                                        alignSelf: 'center',
                                        background: theme.palette.success.dark,
                                        color: '#FFF',
                                        '&:hover': {
                                            background: theme.palette.success.light,
                                            color: theme.palette.success.dark
                                        }
                                    }}
                                >
                                    Autorizar Asistencia
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="caption" className="css-10963q2">
                                    <Divider>Listado de Atletas</Divider>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert
                                    severity="info"
                                    size="small"
                                    variant="filled"
                                    sx={{ py: 0, background: theme.palette.primary.dark, color: '#fff' }}
                                >
                                    <small>Desmarque si el atleta no va a asistir a la cita médica.</small>
                                </Alert>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <DataTable athletes={allDataDetalle} onCheck={validChecked} />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
