import { useContext, useState } from 'react';
import { EstadosSolicitud, CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Grid, Stack, IconButton } from '@mui/material';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { IconEye, IconFileCheck, IconFileX } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData, Actions } = useContext(ContentContext);
    const theme = useTheme();
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = String(item.start).toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = String(item.start).toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Correlativo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.correlativo)
        },
        {
            name: 'Fecha',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.start)
        },
        {
            name: 'Tipo Atención',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.title)
        },
        {
            name: 'Federación',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.deporte)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => EstadosSolicitud(row)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <BootstrapTooltip title="Visualizar" arrow placement="top">
                        <IconButton aria-label="visualizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => One(row, 3)}>
                            <IconEye />
                        </IconButton>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Autorizar" arrow placement="top">
                        <IconButton aria-label="Autorizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => Actions(row, 3)}>
                            <IconFileCheck />
                        </IconButton>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Rechazar" arrow placement="top">
                        <IconButton aria-label="Rechazar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => Actions(row, 4)}>
                            <IconFileX />
                        </IconButton>
                    </BootstrapTooltip>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        mb: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
                    }}
                >
                    {/* <TextField type="date" size="small" {...register('fecha')} defaultValue={today} /> */}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        mb: 1,
                        display: 'flex',
                        flexDirection: 'row-inverse',
                        justifyContent: 'flex-end',
                        [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
                    }}
                >
                    <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
                </Grid>
            </Grid>
            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
