/* eslint-disable react/prop-types */
import { useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ContentContext } from '../context';

const Listado = () => {
    const { allDataDetallePruebaAanaerobica } = useContext(ContentContext);
    const columns = [
        {
            name: 'Tiempo',
            selector: (row) => row.tiempo
        },
        {
            name: 'WATTS',
            selector: (row) => row.watts
        },
        {
            name: 'WATTS/KG',
            selector: (row) => row.resultado
        }
    ];
    return <DataTable noDataComponent="No hay registros para mostrar." columns={columns} data={allDataDetallePruebaAanaerobica} />;
};

export default Listado;
