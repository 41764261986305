import { useContext } from 'react';
import { Modal, Box, Grid, FormControl, Avatar, Typography } from '@mui/material';
import MainCard from 'utils/components/modal/MainCardModal';
import { ContentContext } from './context';

export default function KeepMountedModal() {
    const { show, toggleModal, oneData, oneDataAtleta } = useContext(ContentContext);

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Ficha Técnica" size="xl" closeButton={() => toggleModal(0)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={3}>
                                <div style={{ width: '100%' }} className="kt-avatar kt-avatar--outline">
                                    <Avatar
                                        className="kt-avatar__holder"
                                        src={oneData.foto}
                                        sx={{ margin: '0px auto', width: 150, height: 150 }}
                                        variant="rounded"
                                    />
                                </div>
                                <Typography align="center" sx={{ mt: 1 }}>
                                    {oneData?.nombreCompleto}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Box className="border">
                                    <Typography fontWeight="bolder" className="text-uppercase" sx={{ mb: 2 }}>
                                        Datos Personales
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">FAND</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.deporte}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Nombres</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.nombres}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Apellidos</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.apellidos}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Fecha de Nacimiento</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.fechaNacimiento}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">DPI</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.dpi}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Pasaporte</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.pasaporte || 'N/A'}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <Typography fontWeight="bolder">Edad</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.edad} Años
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <Typography fontWeight="bolder">Genero</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.genero}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <Typography fontWeight="bolder">Estado Civil</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.estadoCivil || 'N/A'}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className="border">
                                    <Typography fontWeight="bolder" className="text-uppercase" sx={{ mb: 2 }}>
                                        Datos de Contacto
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <Typography fontWeight="bolder">Dirección</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.direccion}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className="border">
                                    <Typography fontWeight="bolder" className="text-uppercase" sx={{ mb: 2 }}>
                                        Datos Academicos
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Profesión</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.profesion}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <Typography fontWeight="bolder">Grado Académico</Typography>
                                                <Typography align="left" sx={{ mt: 1 }}>
                                                    {oneDataAtleta?.gradoAcademico}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
