// material-ui
import { Container } from '@mui/material';
import Toolbar from 'utils/components/toolbar/index';
import Formulario from './form';
import List from './list';
import { ContentProvider } from './context';
import { useSelector } from 'react-redux';

const Index = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <ContentProvider>
            <Container maxWidth="xl" sx={{ backgroundColor: '#FFF', pb: 5, borderRadius: `${customization.borderRadius}px` }}>
                <Toolbar title="Catálogo de Récord" formulario={<Formulario />} />
                <List />
            </Container>
        </ContentProvider>
    );
};

export default Index;
