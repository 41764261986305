/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { PostRoute, GetRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [oneDataDf, setDataDf] = useState([]);
    const [oneDataExamenF, setOneDataExamenF] = useState([]);
    const [oneDataAnamnesis, setOneDataAnamnesis] = useState([]);
    const [allDataAntecedentes, setAllDataAntecedentes] = useState([]);
    const [allDataDetalleExamenFisicoDep, setAllDataDetalleExamenFisicoDep] = useState([]);
    const [allDataHojaEvolutiva, setAllDataHojaEvolutiva] = useState([]);
    const [allDataTipoAntecedente, setAllDataTipoAntecedente] = useState([]);
    const [allDataTipoExamenFisico, setAllDataTipoExamenFisico] = useState([]);
    const [allDataSeccionCineantropometria, setAllDataSeccionCineantropometria] = useState([]);
    const [allDataTipoCineantropometria, setAllDataTipoCineantropometria] = useState([]);
    const [oneDataAntecedentes, setOneDataAntecedentes] = useState([]);
    const [allDataDetallePruebaFuerza, setAllDataDetallePruebaFuerza] = useState([]);
    const [allDataTipoPruebaAerobica, setAllDataTipoPruebaAerobica] = useState([]);
    const [allDataTiempoSegundos, setAllDataTiempoSegundos] = useState([]);
    const [allDataCineantropometria, setAllDataCineantropometria] = useState([]);
    const [allDataWatts, setAllDataWatts] = useState([]);

    const [allDataHistorialPeso, setAllDataHistorialPeso] = useState([]);
    const [allDataHistorialGrasaCorporal, setAllDataHistorialGrasaCorporal] = useState([]);
    const [allDataHistorialGrasa, setAllDataHistorialGrasa] = useState([]);
    const [allDataHistorialPesoMusculo, setAllDataHistorialPesoMusculo] = useState([]);
    const [allDataCicloCompetencia, setAllDataCicloCompetencia] = useState([]);
    // nutricion cineantropometria
    const [allDataNutricionCineantropometria, setAllDataNutricionCineantropometria] = useState([]);
    const [oneDataNutricionCineantropometria, setOneDataNutricionCineantropometria] = useState([]);
    const [allDataTipoCicloCompetencia, setAllDataTipoCicloCompetencia] = useState([]);
    // ficha psicologica
    const [oneDataFichaPsicologica, seOneDataFichaPsicologica] = useState([]);
    const [allDataFichaPsicologica, setAllDataFichaPsicologica] = useState([]);
    const [oneDataPruebasPsicologicasAplicadas, setOneDataPruebasPsicologicasAplicadas] = useState([]);

    const [oneDataDatosDeportivos, seOneDataDatosDeportivos] = useState([]);
    const [oneDataPruebasAplicadas, setOneDataPruebasAplicadas] = useState([]);
    const [allDataPruebasAplicadas, setAllDataPruebasAplicadas] = useState([]);
    const [allDataImc, setAllDataImc] = useState([]);
    const [dataPotenciaMaxima, setDataPotenciaMaxima] = useState(0);
    const [dataResistenciaMedia, setDataResistenciaMedia] = useState(0);
    const [dataIndiceFatiga, setDataIndiceFatiga] = useState(0);
    const [dataResistencia, setDataResistencia] = useState(0);
    const [dataVal, setDataVal] = useState(0);
    // evaluacion Biomecanica
    const [allDataEvaluacionBiomecanica, setAllDataEvaluacionBiomecanica] = useState([]);
    const [oneDataEvaluacionBiomecanica, setOneDataEvaluacionBiomecanica] = useState([]);
    const [allDataCarrera, setAllDataCarrera] = useState([]);
    const [allDataPotencia, setAllDataPotencia] = useState([]);
    const [allDataTipoCarrera, setAllDataTipoCarrera] = useState([]);
    const [allDataTipoPotencia, setAllDataTipoPotencia] = useState([]);
    // valoracion fisioterapeutica
    const [oneDataValoracionFisioterapeutica, setOneDataValoracionFisioterapeutica] = useState([]);
    const [allDataValoracionFisioterapeutica, setAllDataValoracionFisioterapeutica] = useState([]);
    const [oneDataValoraciones, setOneDataValoraciones] = useState([]);
    const [dataPartesAfectadas, setDataPartesAfectadas] = useState([]);
    const [dataPartesAfectadasDash, setDataPartesAfectadasDash] = useState([]);
    // Crear un nuevo objeto de fecha
    const anioActual = new Date().getFullYear();
    // prueba aerobica
    const [recuperacionPruebaAerobica, setRecuperacionPruebaAerobica] = useState([]);
    const [dataGlobalPruebaAerobica, setDataGlobalPruebaAerobica] = useState([]);
    const [allDataPruebaAerobica, setAllDataPruebaAerobica] = useState([]);
    const [oneDataPruebaAerobica, setOneDataPruebaAerobica] = useState([]);
    const [allDataTestWHeader, setAllDataTestWHeader] = useState([]);
    const [allDataTestWFooter, setAllDataTestWFooter] = useState([]);
    const [dataPruebaAerobica, setDataTipoPruebaAerobica] = useState(null);
    const [dataFcBasal, setDataFcBasal] = useState(null);
    const [showDataPruebaAerobica, setShowDataPruebaAerobica] = useState(false);
    const [allDataDetallePruebaAerobica, setAllDataDetallePruebaAerobica] = useState([]);

    // Prueba Anaerobica
    const [allDataPruebaAnaerobica, setAllDataPruebaAnaerobica] = useState([]);
    const [allDataDetallePruebaAanaerobica, setAllDataDetallePruebaAnaerobica] = useState([]);
    const [allDataDetallePruebaAanaerobicaVisualizar, setAllDataDetallePruebaAnaerobicaVisualizar] = useState([]);
    const [showDataPruebaAnaerobica, setShowDataPruebaAnaerobica] = useState(false);
    const [oneDataPruebaAnaerobica, setOneDataPruebaAnaerobica] = useState([]);

    const { code, solicitud } = useParams();

    const nameController = 'anamnesis';
    const nameController2 = 'perfilMedico';
    const nameController3 = 'datosFamiliares';
    const nameController4 = 'tipoAntecedente';
    const nameController5 = 'antecedentes';
    const nameController6 = 'TipoExamenFisicoDeportivo';
    const nameController7 = 'examenFisicoDeportivo';
    const nameController8 = 'detalleExamenFisicoDeportivo';
    const nameController9 = 'hojaEvolucion';
    const nameController10 = 'SeccionCineantropometria';
    const nameController11 = 'TipoCineantropometria';
    const nameController12 = 'cineantropometria';
    const nameController13 = 'TipoPruebaAerobica';
    const nameController14 = 'dashboard';
    const nameController15 = 'nutricionCineantropometria';
    const nameController16 = 'cicloCompetencia';
    const nameController17 = 'tipoCiclosCompetencia';
    const nameController18 = 'fichaPsicologica';
    const nameController19 = 'datosDeportivos';
    const nameController20 = 'PruebasPsicologicasAplicadas';
    const nameController21 = 'evaluacionBiomecanica';
    const nameController22 = 'carrera';
    const nameController23 = 'potencia';
    const nameController24 = 'tipoPotencia';
    const nameController25 = 'tipoCarrera';
    const nameController26 = 'valoracionFisioterapeutica';
    const nameController27 = 'valoraciones';
    const nameController28 = 'pruebaPostural';
    const nameController29 = 'pruebaAerobica';
    const nameController30 = 'detallePruebaAerobica';
    const nameController31 = 'pruebaAnaerobica';
    const nameController32 = 'detallePruebaAnaerobica';
    const nameController33 = 'solicitud';

    // --------------------------------------------------------------------
    // Dashboard
    // --------------------------------------------------------------------
    const HistorialPesos = async (Anio) => {
        const response = await PostRoute(`${nameController14}/historial-peso`, { persona: code, anio: Anio });
        setAllDataHistorialPeso(response.length > 0 ? response : []);
    };

    const HistorialImc = async (Anio) => {
        const response = await PostRoute(`${nameController14}/historial-imc`, { persona: code, anio: Anio });
        setAllDataImc(response.length > 0 ? response : []);
    };

    const HistorialGrasaCorporal = async (Anio) => {
        const response = await PostRoute(`${nameController14}/historial-grasa-corporal`, { persona: code, anio: Anio });
        setAllDataHistorialGrasaCorporal(response.length > 0 ? response : []);
    };

    const HistorialGrasa = async (Anio) => {
        const response = await PostRoute(`${nameController14}/historial-grasa`, { persona: code, anio: Anio });
        setAllDataHistorialGrasa(response.length > 0 ? response : []);
    };

    const HistorialPesoMusculo = async (Anio) => {
        const response = await PostRoute(`${nameController14}/historial-peso-musculo`, { persona: code, anio: Anio });
        setAllDataHistorialPesoMusculo(response.length > 0 ? response : []);
    };

    // --------------------------------------------------------------------
    // --------------------------------------------------------------------

    const handleTabChange = (event, newValue) => {
        setDataVal(newValue);
    };

    const StoreUpdateAnam = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, { ...data, persona: code });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const OneAnamnesis = async (data) => {
        const response = await PostRoute(`${nameController}/one`, { persona: data });
        setOneDataAnamnesis(response.length > 0 ? response[0] : []);
    };
    // --------------------------------------------------------------------
    // Inicio Header
    const One = async (data) => {
        const response = await PostRoute(`${nameController2}/header`, { persona: data });
        setUser(response[0] ? response[0] : []);
    };

    const Volver = () => {
        navigate(`/ciencias-aplicadas/atencion-medica`);
    };

    const VolverCertificados = () => {
        navigate(`/certificados/aprobar-certificados`);
    };

    const FinalizarProceso = async (value) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController33}/finalizar-proceso`, { estado: value, persona: code, solicitud: solicitud });
        if (response?.data !== null) {
            Volver();
            Notification(response.message, 1);
        }
        setShowLoad(false);
    };
    // Fin Header
    // --------------------------------------------------------------------
    const OneDf = async (data) => {
        const response = await PostRoute(`${nameController3}/one`, { persona: data });
        setDataDf(response[0] ? response[0] : []);
    };

    const StoreUpdateDf = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController3}/${!data.id ? 'store' : 'update'}`, { ...data, persona: code });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const AllTipoAntecendes = async () => {
        const response = await GetRoute(`${nameController4}/label`);
        setAllDataTipoAntecedente(response.length > 0 ? response : []);
    };

    const AllAntecedentes = async (data) => {
        const response = await PostRoute(`${nameController5}/all`, { persona: data });
        setAllDataAntecedentes(response.length > 0 ? response : []);
    };

    const StoreUpdateAntecedente = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController5}/${!data.id ? 'store' : 'update'}`, { ...data, persona: code });
        AllAntecedentes(code);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const AllTipoExamenFisico = async () => {
        const response = await GetRoute(`${nameController6}/label`);
        setAllDataTipoExamenFisico(response.length > 0 ? response : []);
    };

    const AllDetalleExamenF = async (data) => {
        const response = await PostRoute(`${nameController8}/all`, { examenFisico: data });
        setAllDataDetalleExamenFisicoDep(response.length > 0 ? response : []);
    };

    const DestroyExamenF = async (data) => {
        const response = await PostRoute(`${nameController8}/destroy`, { id: data.id });
        Notification(response.message, 1);
        AllDetalleExamenF(data.examenFisico);
    };

    const OneExamenF = async (data, sol) => {
        const response = await PostRoute(`${nameController7}/one`, { persona: data, solicitud: sol });
        setOneDataExamenF(response.length > 0 ? response[0] : []);
        if (response[0]) {
            AllDetalleExamenF(response[0].id);
        }
    };

    const StoreUpdateExamenF = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController7}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        OneExamenF(code, solicitud);
        setUser({ ...user, peso: data.peso, estatura: data.estatura });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const StoreUpdateDetalleExamenF = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController8}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        AllDetalleExamenF(data.examenFisico);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    // --------------------------------------------------------------------
    // Hoja de Evolucion
    // --------------------------------------------------------------------

    const AllDetalleHojaEvolucion = async () => {
        const response = await PostRoute(`${nameController9}/all`, { persona: code });
        setAllDataHojaEvolutiva(response.length > 0 ? response : []);
    };

    const StoreUpdateHojaEvolucion = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController9}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        AllDetalleHojaEvolucion();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    // --------------------------------------------------------------------
    // Nutricion-Cineantropometria
    // --------------------------------------------------------------------
    const AllNutricionCineantropometria = async () => {
        const response = await PostRoute(`${nameController15}/all`, {
            persona: code,
            solicitud: solicitud
        });
        setAllDataNutricionCineantropometria(response.length > 0 ? response : []);
    };

    const SeccionCineantropometria = async () => {
        const response = await GetRoute(`${nameController10}/label`);
        setAllDataSeccionCineantropometria(response.length > 0 ? response : []);
    };

    const TipoCineantropometria = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController11}/label`, { seccionCineantropometria: data });
            setAllDataTipoCineantropometria(response.length > 0 ? response : []);
        } else {
            setAllDataTipoCineantropometria([]);
        }
    };

    const StoreUpdateNutricionCineantropometria = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController15}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        setOneDataNutricionCineantropometria({ id: response.id, ...data });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const FinalizarEvaluacionNutricionCineantropometria = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController15}/finalizar`, { id: data.id });
        setOneDataNutricionCineantropometria([]);
        setAllDataCineantropometria([]);
        setAllDataCicloCompetencia([]);
        AllNutricionCineantropometria();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const AllCineantropometria = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController12}/all`, { nutricionCineantropometria: data });
            setAllDataCineantropometria(response.length > 0 ? response : []);
        }
    };

    const StoreUpdateCineantropometria = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController12}/${!data.id ? 'store' : 'update'}`, {
            ...data
        });
        AllCineantropometria(data.nutricionCineantropometria);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    // --------------------------------------------------------------------
    const TipoCicloCompetencia = async () => {
        const response = await GetRoute(`${nameController17}/label`);
        setAllDataTipoCicloCompetencia(response.length > 0 ? response : []);
    };

    const AllCicloCompetencia = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController16}/all`, { nutricionCineantropometria: data });
            setAllDataCicloCompetencia(response.length > 0 ? response : []);
        }
    };

    const StoreUpdateCicloCompetencia = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController16}/${!data.id ? 'store' : 'update'}`, {
            ...data
        });
        AllCicloCompetencia(data.nutricionCineantropometria);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    // --------------------------------------------------------------------
    const OneNutricionCinenatropometria = async () => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController15}/one`, {
            persona: code,
            solicitud: solicitud
        });
        setOneDataNutricionCineantropometria({ id: response.length === 1 ? response[0].id : null });
        if (response.length === 1) {
            AllCineantropometria(response[0].id);
            AllCicloCompetencia(response[0].id);
        }
        setShowLoad(false);
    };

    // --------------------------------------------------------------------
    // Prueba de Aerobica
    // --------------------------------------------------------------------
    const TipoPruebaAerobica = async () => {
        const response = await GetRoute(`${nameController13}/label`);
        setAllDataTipoPruebaAerobica(response.length > 0 ? response : []);
    };

    const AllDetallePruebaAerobica = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController30}/all`, { pruebaAerobica: data });
            setAllDataDetallePruebaAerobica(response.length > 0 ? response : []);
        }
    };

    const AllPruebaAerobica = async () => {
        const response = await PostRoute(`${nameController29}/all`, { persona: code });
        setAllDataPruebaAerobica(response.length > 0 ? response : []);
    };

    const OneDataPruebaAerobica = async (data) => {
        const response = await PostRoute(`${nameController29}/one`, { id: data.id });
        setOneDataPruebaAerobica(response.length > 0 ? response[0] : []);
        AllDetallePruebaAerobica(response[0].id);
        setShowDataPruebaAerobica(true);
    };

    const StoreUpdatePruebaAerobica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController29}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        AllPruebaAerobica();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const StoreUpdateDetallePruebaAerobica = (data) => {
        setAllDataDetallePruebaFuerza([...allDataDetallePruebaFuerza, data]);
        if (recuperacionPruebaAerobica.length < 3) {
            setRecuperacionPruebaAerobica([...recuperacionPruebaAerobica, data]);
        }
    };
    // --------------------------------------------------------------------
    // Prueba Anaerobica
    // --------------------------------------------------------------------
    const AllPruebaAnaerobica = async () => {
        const response = await PostRoute(`${nameController31}/all`, { persona: code });
        setAllDataPruebaAnaerobica(response.length > 0 ? response : []);
    };

    const AllDetallePruebaAnaerobica = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController32}/all`, { pruebaAnaerobica: data });
            setAllDataDetallePruebaAnaerobicaVisualizar(response.length > 0 ? response : []);
        }
    };

    const OneDataPruebaAnaerobica = async (data) => {
        const response = await PostRoute(`${nameController31}/one`, { id: data.id });
        setOneDataPruebaAnaerobica(response.length > 0 ? response[0] : []);
        AllDetallePruebaAnaerobica(response[0].id);
        setShowDataPruebaAnaerobica(true);
    };

    const StoreUpdatePruebaAnaerobica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController31}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        Notification(response.message, 1);
        AllPruebaAnaerobica();
        setAllDataDetallePruebaAnaerobica([]);
        setShowLoad(false);
    };

    const StoreUpdateDetallePruebaAnaerobica = async (data) => {
        setAllDataDetallePruebaAnaerobica([...allDataDetallePruebaAanaerobica, data]); // aqui
    };

    // --------------------------------------------------------------------
    // ficha Psicologica
    // --------------------------------------------------------------------
    const AllFichaPsicologica = async () => {
        const response = await PostRoute(`${nameController18}/all`, { persona: code, solicitud: solicitud });
        setAllDataFichaPsicologica(response.length > 0 ? response : []);
    };

    const AllPruebasAplicadas = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController20}/all`, { fichaPsicologica: data });
            setAllDataPruebasAplicadas(response.length > 0 ? response : []);
        }
    };

    const OnePruebasAplicadas = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController20}/one`, { id: data.id });
            setOneDataPruebasPsicologicasAplicadas(response.length > 0 ? response[0] : []);
        }
    };

    const StoreUpdatePruebasAplicadas = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController20}/${!data.id ? 'store' : 'update'}`, {
            ...data
        });
        setOneDataPruebasAplicadas({ ...data, id: response.id });
        AllPruebasAplicadas(data.fichaPsicologica);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const OneDatosDeportivos = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController19}/one`, { fichaPsicologica: data });
            seOneDataDatosDeportivos(response.length > 0 ? response[0] : []);
        }
    };

    const StoreUpdateDatosDeportivos = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController19}/${!data.id ? 'store' : 'update'}`, {
            ...data
        });
        seOneDataDatosDeportivos({ ...data, id: response.id });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const StoreUpdateFichaPsicologica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController18}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        seOneDataFichaPsicologica({ id: response.id, ...data });
        seOneDataDatosDeportivos([]);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const OneFichaPsicologica = async () => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController18}/one`, {
            persona: code,
            solicitud: solicitud
        });
        seOneDataFichaPsicologica({ id: response.length === 1 ? response[0].id : null });
        if (response.length === 1) {
            OneDatosDeportivos(response[0].id);
            AllPruebasAplicadas(response[0].id);
        }
        setShowLoad(false);
    };

    const FinalizarFichaPsicologica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController18}/finalizar`, { id: data.id });
        seOneDataFichaPsicologica([]);
        setAllDataPruebasAplicadas([]);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    // --------------------------------------------------------------------
    // Evaluacion Biomecanica
    // --------------------------------------------------------------------

    const AllEvaluacionBiomecanica = async () => {
        const response = await PostRoute(`${nameController21}/all`, { persona: code });
        setAllDataEvaluacionBiomecanica(response.length > 0 ? response : []);
    };

    const AllDatosCarrera = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController22}/all`, { evaluacionBiomecanica: data });
            setAllDataCarrera(response.length > 0 ? response : []);
        }
    };

    const AllDatosPotencia = async (data) => {
        if (data) {
            const response = await PostRoute(`${nameController23}/all`, { evaluacionBiomecanica: data });
            setAllDataPotencia(response.length > 0 ? response : []);
        }
    };

    const StoreUpdateCarrera = async (data) => {
        setShowLoad(true);
        if (!allDataCarrera.find((item) => item.idTipoCarrera === data.tipoCarrera)) {
            const response = await PostRoute(`${nameController22}/${!data.id ? 'store' : 'update'}`, {
                ...data
            });
            AllDatosCarrera(data.evaluacionBiomecanica);
            Notification(response.message, 1);
        } else {
            Notification('El tipo de carrera que intentas asignar ya existe. Por favor, selecciona uno diferente.', 0);
        }

        setShowLoad(false);
    };

    const StoreUpdatePotencia = async (data) => {
        setShowLoad(true);
        if (!allDataPotencia.find((item) => item.idTipoPotencia === data.tipoPotencia)) {
            const response = await PostRoute(`${nameController23}/${!data.id ? 'store' : 'update'}`, {
                ...data
            });
            AllDatosPotencia(data.evaluacionBiomecanica);
            Notification(response.message, 1);
        } else {
            Notification('El tipo de potencia que intentas asignar ya existe. Por favor, selecciona uno diferente.', 0);
        }
        setShowLoad(false);
    };

    const StoreUpdateEvaluacionBiomecanica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController21}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });

        if (!data?.id) {
            setOneDataEvaluacionBiomecanica({ ...data, id: response.id });
        }
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const OneEvaluacionBiomecanica = async () => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController21}/one`, {
            persona: code,
            solicitud: solicitud
        });
        setOneDataEvaluacionBiomecanica(response.length === 1 ? response[0] : null);
        if (response.length === 1) {
            AllDatosCarrera(response[0].id);
            AllDatosPotencia(response[0].id);
        }
        setShowLoad(false);
    };

    const FinalizarEvaluacionBiomecanica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController21}/finalizar`, { id: data.id });
        setAllDataCarrera([]);
        setAllDataPotencia([]);
        setOneDataEvaluacionBiomecanica([]);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const AllTipoCarrera = async () => {
        const response = await GetRoute(`${nameController25}/label`);
        setAllDataTipoCarrera(response.length > 0 ? response : []);
    };

    const AllTipoPotencia = async () => {
        const response = await GetRoute(`${nameController24}/label`);
        setAllDataTipoPotencia(response.length > 0 ? response : []);
    };

    // --------------------------------------------------------------------
    // Valoracion Fisioterapeutica
    // --------------------------------------------------------------------
    const AllValoracionFisioterapeutica = async () => {
        const response = await PostRoute(`${nameController26}/all`, { persona: code, solicitud: solicitud });
        setAllDataValoracionFisioterapeutica(response.length > 0 ? response : []);
    };

    const AllPruebaPostural = async (value, valoracion, opcion) => {
        const response = await PostRoute(`${nameController28}/all`, {
            persona: code,
            estado: value,
            valoracionFisioterapeutica: valoracion
        });
        const result = response.length > 0 ? response[0] : [];
        if (opcion === 1) {
            setDataPartesAfectadasDash(result);
        } else {
            setDataPartesAfectadas(result);
        }
    };

    const OneValoraciones = async (data) => {
        const response = await PostRoute(`${nameController27}/one`, { valoracionFisioterapeutica: data });
        setOneDataValoraciones(response.length > 0 ? response[0] : []);
    };

    const StoreUpdateValoracionFisioterapeutica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController26}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        setOneDataValoracionFisioterapeutica({ id: response.id, ...data });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const OneValoracionFisioterapeutica = async () => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController26}/one`, {
            persona: code,
            solicitud: solicitud
        });
        setOneDataValoracionFisioterapeutica(response.length === 1 ? response[0] : []);
        if (response.length === 1) {
            OneValoraciones(response[0].id);
            AllPruebaPostural(1, response[0].id, 2);
        }
        setShowLoad(false);
    };

    const FinalizarValoracionFisioterapeutica = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController26}/finalizar`, { id: data.id });
        setOneDataValoracionFisioterapeutica([]);
        AllValoracionFisioterapeutica();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const StoreUpdateValoraciones = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController27}/${!data.id ? 'store' : 'update'}`, data);
        setOneDataValoraciones({ id: response.id });
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const StoreUpdatePrubeaPostural = async (data) => {
        const response = await PostRoute(`${nameController28}/store-update`, {
            ...data
        });
        Notification(response.message, 1);
    };
    // ------------------------------------------------------------------
    // Evaluacion de fuerza y flexibilidad
    // ------------------------------------------------------------------
    const nemeController34 = 'evaluacionFuerzaFlexibilidad';
    const nemeController35 = 'tipoFuerza';
    const nemeController36 = 'detalleTipoFuerza';
    const nemeController37 = 'detalleEvaluacionFuerzaFlexibilidad';
    const [oneDataEvaluacionFuerzaFlexibilidad, setOneDataEvaluacionFuerzaFlexibilidad] = useState([]);
    const [allDataTipoFuerza, setAllDataTipoFuerza] = useState([]);
    const [allDataDetalleTipoFuerza, setAllDataDetalleTipoFuerza] = useState([]);
    const [AllDataDetalleEvaluacionFuerzaFlexibilidad, setAllDataDetalleEvaluacionFuerzaFlexibilidad] = useState([]);
    const [AllDataEvaluacionFuerzaFlexibilidad, setAllDataEvaluacionFuerzaFlexibilidad] = useState([]);

    const AllDetalleEvaluacionTipoFuerzaFlexibilidad = async (data) => {
        const response = await PostRoute(`${nemeController37}/all`, data);
        setAllDataDetalleEvaluacionFuerzaFlexibilidad(response.length > 0 ? response : []);
    };

    const AllEvaluacionFuerzaFlexibilidad = async () => {
        const response = await PostRoute(`${nemeController34}/all`, { persona: code, solicitud: solicitud });
        setAllDataEvaluacionFuerzaFlexibilidad(response.length > 0 ? response : []);
    };

    const OneEvaluacionFuerzaFlexibilidad = async () => {
        const response = await PostRoute(`${nemeController34}/one`, { persona: code, solicitud: solicitud });
        setOneDataEvaluacionFuerzaFlexibilidad(response.length > 0 ? response[0] : []);
        if (response.length > 0) {
            const id = response[0]?.id;
            if (id) {
                await AllDetalleEvaluacionTipoFuerzaFlexibilidad({ evaluacionFuerzaFlexibilidad: id });
            }
        }
    };

    const StoreUpdateEvaluacionFuerzaFlexibilidad = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nemeController34}/${!data.id ? 'store' : 'update'}`, {
            ...data,
            persona: code,
            solicitud: solicitud
        });
        OneEvaluacionFuerzaFlexibilidad();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const AllTipoFuerza = async () => {
        const response = await GetRoute(`${nemeController35}/label`);
        setAllDataTipoFuerza(response.length > 0 ? response : []);
    };

    const AllDetalleTipoFuerza = async (data) => {
        if (data?.value) {
            const response = await PostRoute(`${nemeController36}/label`, { tipoFuerza: data?.value });
            setAllDataDetalleTipoFuerza(response.length > 0 ? response : []);
        }
    };

    const StoreUpdateDetalleEvaluacionFuerzaFlexibilidad = async (data) => {
        const response = await PostRoute(`${nemeController37}/${!data.id ? 'store' : 'update'}`, {
            ...data
        });
        Notification(response.message, 1);
        AllDetalleEvaluacionTipoFuerzaFlexibilidad(data);
    };

    const FinalizarEvaluacionFuerzaFlexibilidad = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nemeController34}/finalizar`, { id: data.id });
        setOneDataEvaluacionFuerzaFlexibilidad([]);
        setAllDataEvaluacionFuerzaFlexibilidad([]);
        AllEvaluacionFuerzaFlexibilidad();
        Notification(response.message, 1);
        setShowLoad(false);
    };

    useEffect(() => {
        One(code);
        TipoCicloCompetencia();
        AllTipoAntecendes();
        AllTipoExamenFisico();
        AllDetalleHojaEvolucion();
        SeccionCineantropometria();
        TipoPruebaAerobica();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, solicitud]);

    const value = {
        code,
        user,
        dataVal,
        solicitud,
        oneDataDf,
        anioActual,
        allDataImc,
        dataFcBasal,
        allDataWatts,
        allDataCarrera,
        oneDataExamenF,
        allDataPotencia,
        dataResistencia,
        oneDataAnamnesis,
        dataIndiceFatiga,
        allDataTipoFuerza,
        dataPruebaAerobica,
        allDataTipoCarrera,
        allDataTestWHeader,
        allDataTestWFooter,
        dataPotenciaMaxima,
        dataPartesAfectadas,
        allDataTipoPotencia,
        allDataAntecedentes,
        oneDataAntecedentes,
        oneDataValoraciones,
        allDataHistorialPeso,
        dataResistenciaMedia,
        allDataHojaEvolutiva,
        allDataPruebaAerobica,
        allDataTiempoSegundos,
        allDataHistorialGrasa,
        oneDataPruebaAerobica,
        oneDataDatosDeportivos,
        allDataTipoAntecedente,
        showDataPruebaAerobica,
        allDataPruebasAplicadas,
        oneDataPruebasAplicadas,
        allDataCicloCompetencia,
        allDataTipoExamenFisico,
        allDataPruebaAnaerobica,
        oneDataFichaPsicologica,
        allDataFichaPsicologica,
        dataPartesAfectadasDash,
        oneDataPruebaAnaerobica,
        allDataDetalleTipoFuerza,
        dataGlobalPruebaAerobica,
        allDataCineantropometria,
        showDataPruebaAnaerobica,
        allDataTipoPruebaAerobica,
        recuperacionPruebaAerobica,
        allDataDetallePruebaFuerza,
        allDataHistorialPesoMusculo,
        allDataTipoCicloCompetencia,
        oneDataEvaluacionBiomecanica,
        allDataTipoCineantropometria,
        allDataDetallePruebaAerobica,
        allDataEvaluacionBiomecanica,
        allDataHistorialGrasaCorporal,
        allDataDetalleExamenFisicoDep,
        allDataDetallePruebaAanaerobica,
        allDataSeccionCineantropometria,
        allDataValoracionFisioterapeutica,
        allDataNutricionCineantropometria,
        oneDataValoracionFisioterapeutica,
        oneDataNutricionCineantropometria,
        oneDataPruebasPsicologicasAplicadas,
        oneDataEvaluacionFuerzaFlexibilidad,
        AllDataEvaluacionFuerzaFlexibilidad,
        allDataDetallePruebaAanaerobicaVisualizar,
        AllDataDetalleEvaluacionFuerzaFlexibilidad,
        StoreUpdateDetalleEvaluacionFuerzaFlexibilidad,
        FinalizarEvaluacionNutricionCineantropometria,
        StoreUpdateEvaluacionFuerzaFlexibilidad,
        setOneDataPruebasPsicologicasAplicadas,
        FinalizarEvaluacionFuerzaFlexibilidad,
        StoreUpdateValoracionFisioterapeutica,
        StoreUpdateNutricionCineantropometria,
        FinalizarValoracionFisioterapeutica,
        StoreUpdateDetallePruebaAnaerobica,
        StoreUpdateEvaluacionBiomecanica,
        StoreUpdateDetallePruebaAerobica,
        AllEvaluacionFuerzaFlexibilidad,
        OneEvaluacionFuerzaFlexibilidad,
        FinalizarEvaluacionBiomecanica,
        AllNutricionCineantropometria,
        OneNutricionCinenatropometria,
        OneValoracionFisioterapeutica,
        AllValoracionFisioterapeutica,
        StoreUpdateCineantropometria,
        setDataGlobalPruebaAerobica,
        StoreUpdateFichaPsicologica,
        StoreUpdatePruebaAnaerobica,
        StoreUpdateCicloCompetencia,
        StoreUpdatePruebasAplicadas,
        setShowDataPruebaAnaerobica,
        StoreUpdateDatosDeportivos,
        setShowDataPruebaAerobica,
        setDataTipoPruebaAerobica,
        StoreUpdatePruebaAerobica,
        FinalizarFichaPsicologica,
        StoreUpdatePrubeaPostural,
        StoreUpdateDetalleExamenF,
        AllEvaluacionBiomecanica,
        AllDetallePruebaAerobica,
        StoreUpdateHojaEvolucion,
        OneEvaluacionBiomecanica,
        setAllDataTiempoSegundos,
        OneDataPruebaAnaerobica,
        setDataResistenciaMedia,
        StoreUpdateValoraciones,
        setDataPartesAfectadas,
        StoreUpdateAntecedente,
        setOneDataAntecedentes,
        HistorialGrasaCorporal,
        OneDataPruebaAerobica,
        TipoCineantropometria,
        setDataPotenciaMaxima,
        setAllDataTestWHeader,
        setAllDataTestWFooter,
        AllDetalleTipoFuerza,
        HistorialPesoMusculo,
        AllPruebaAnaerobica,
        AllFichaPsicologica,
        setDataIndiceFatiga,
        StoreUpdatePotencia,
        OneFichaPsicologica,
        OnePruebasAplicadas,
        StoreUpdateExamenF,
        setDataResistencia,
        StoreUpdateCarrera,
        AllPruebaAerobica,
        AllDetalleExamenF,
        AllPruebaPostural,
        FinalizarProceso,
        StoreUpdateAnam,
        handleTabChange,
        setAllDataWatts,
        AllTipoPotencia,
        AllAntecedentes,
        DestroyExamenF,
        HistorialPesos,
        AllTipoCarrera,
        HistorialGrasa,
        setDataFcBasal,
        StoreUpdateDf,
        AllTipoFuerza,
        OneAnamnesis,
        HistorialImc,
        Notification,
        OneExamenF,
        Volver,
        VolverCertificados,
        OneDf
    };
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
