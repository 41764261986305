import { useContext, useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, IconButton, Stack } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Add, Edit, Block } from '@mui/icons-material';
import Select from 'react-select';

const Index = () => {
    const { StoreUpdateCicloCompetencia, allDataTipoCicloCompetencia, oneDataNutricionCineantropometria, allDataCicloCompetencia } =
        useContext(ContentContext);
    const [oneDataCicloCompetencias, setOneDataCicloCompetencias] = useState([]);
    const One = async (data) => {
        setOneDataCicloCompetencias(data);
    };
    const columns2 = [
        {
            name: 'Tipo Ciclos',
            selector: (row) => row.tipoCiclosCompetencia,
            width: '250px'
        },
        {
            name: 'Valor',
            selector: (row) => row.valor
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => One(row)}>
                        <Edit />
                    </IconButton>
                </Stack>
            )
        }
    ];
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        clearErrors
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();

    // const validateDuplicate = (items, value) => items.some((item) => item.tipoCiclosCompetencia === value);

    const Clear = () => {
        setOneDataCicloCompetencias([]);
        setValue('tipoCiclosCompetencia', '');
        setValue('valor', '');
        clearErrors();
    };

    const onSubmit = (data) => {
        // if (!validateDuplicate(allDataCicloCompetencia, data.tipoCiclosCompetencia.label)) {
        const jsData = {
            nutricionCineantropometria: oneDataNutricionCineantropometria.id,
            tipoCiclosCompetencia: data.tipoCiclosCompetencia.value,
            valor: data.valor,
            id: oneDataCicloCompetencias?.id || null
        };
        StoreUpdateCicloCompetencia(jsData);
        Clear();
        // } else {
        //     Notification(`${data.tipoCiclosCompetencia.label}, ya se encuentra registrado`, 0);
        // }
    };

    useEffect(() => {
        if (oneDataCicloCompetencias.length !== 0) {
            setValue('tipoCiclosCompetencia', {
                label: oneDataCicloCompetencias.tipoCiclosCompetencia,
                value: oneDataCicloCompetencias.idTipoCicloCompetencia
            });
            setValue('valor', oneDataCicloCompetencias.valor);
            clearErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataCicloCompetencias]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                CICLO DE COMPETENCIAS
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="tipoCiclosCompetencia" shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo de Ciclos
                            </InputLabel>
                            <Controller
                                control={control}
                                name="tipoCiclosCompetencia"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoCicloCompetencia}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoCiclosCompetencia ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoCiclosCompetencia && errors.tipoCiclosCompetencia.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.valor}
                                {...register('valor', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valor"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.valor && errors.valor.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                            {oneDataCicloCompetencias.id && (
                                <AnimateButton>
                                    <BootstrapTooltip title="Cancelar" arrow placement="left">
                                        <Button
                                            size="medium"
                                            type="button"
                                            onClick={() => Clear()}
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.error.main,
                                                '&:hover': {
                                                    background: theme.palette.error.dark
                                                },
                                                ml: 2,
                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                            }}
                                        >
                                            <Block fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns2} data={allDataCicloCompetencia} />
            </Box>
        </Box>
    );
};

export default Index;
