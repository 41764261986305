// material-ui
import { Grid, CssBaseline, Box, Avatar, Stack, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
import Notfound from '../../assets/images/svg/errors/Error404.svg';

const Error404 = () => {
    const theme = createTheme();
    const navigate = useNavigate();
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '80vh',
                        width: '100%'
                    }}
                    justifyContent="center"
                >
                    <Avatar sx={{ m: 1, height: '80%', width: '50%' }} variant="square" src={Notfound} />
                    <Typography component="h3" variant="h4" align="center" color={theme.palette.grey.main} gutterBottom>
                        Algo salio mal
                    </Typography>
                    <Grid item xs={6} alignItems="center">
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <Typography color={theme.palette.grey.main} gutterBottom>
                                ¡La página que está buscando fue movida, eliminada, renombrada o podría no existir nunca!
                            </Typography>
                        </Stack>
                    </Grid>
                    <Button variant="contained" color="primary" size="large" startIcon={<ArrowBackIcon />} onClick={() => navigate('/')}>
                        Volver
                    </Button>
                </Box>
            </Grid>
        </ThemeProvider>
    );
};

export default Error404;
