/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import { generatePDF } from '../export/evaluacionAnaerobica/templatePdf';
import { Modal, Box, Grid, Typography, Button } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import MainCard from 'utils/components/modal/MainCardModal';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'utils/context/userContext';
import LineChart from 'utils/charts/lineChart/index';
import { PictureAsPdf } from '@mui/icons-material';
import ListadoVisualizar from './listVisualizar';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import ApexCharts from 'apexcharts';

const Visualizar = () => {
    const {
        user,
        allDataDetallePruebaAanaerobicaVisualizar,
        oneDataPruebaAnaerobica,
        showDataPruebaAnaerobica,
        setShowDataPruebaAnaerobica
    } = useContext(ContentContext);
    const { userAuth } = useContext(UserContext);
    const [allDataTiempoSegundos, setAllDataTiempoSegundos] = useState([]);
    const [allDataWatts, setAllDataWatts] = useState([]);
    const theme = useTheme();

    const obtenerPesos = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.watts);
        label = items.map((item) => item.tiempo);
        setAllDataTiempoSegundos(label);
        setAllDataWatts(cantidad);
    };

    const Imprimir = async () => {
        const imgArray = [];
        const getChartImage = async (chartId) => {
            const chartElement = document.getElementById(chartId);
            if (chartElement) {
                const chartInstance = await ApexCharts.exec(chartId, 'dataURI');
                if (chartInstance) {
                    const { imgURI } = await chartInstance;
                    imgArray.push(imgURI);
                }
            }
        };
        await getChartImage('wingate');
        const data = {
            allDataDetallePruebaAanaerobicaVisualizar,
            oneDataPruebaAnaerobica,
            img: imgArray,
            user
        };
        generatePDF([], [], 'PRUEBA AERÓBICA', '', 'prueba-aerobica', data, userAuth.nombreCompleto);
    };

    useEffect(() => {
        if (allDataDetallePruebaAanaerobicaVisualizar.length > 0) {
            obtenerPesos(allDataDetallePruebaAanaerobicaVisualizar);
        }
    }, [allDataDetallePruebaAanaerobicaVisualizar]);

    return (
        <Box>
            <Modal
                keepMounted
                open={showDataPruebaAnaerobica}
                onClose={() => setShowDataPruebaAnaerobica(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" spacing={2}>
                    <MainCard title="Visualizar" size="xl" closeButton={() => setShowDataPruebaAnaerobica(false)}>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid item xs={1}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <AnimateButton>
                                        <BootstrapTooltip title="Exportar" arrow placement="left">
                                            <Button
                                                onClick={() => Imprimir()}
                                                size="medium"
                                                type="button"
                                                variant="contained"
                                                sx={{
                                                    background: theme.palette.error.main,
                                                    '&:hover': {
                                                        background: theme.palette.error.dark
                                                    },
                                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                                }}
                                            >
                                                <PictureAsPdf fontSize="medium" />
                                            </Button>
                                        </BootstrapTooltip>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box>
                            <Grid container spacing={2} sx={{ my: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span" sx={{ mr: 2 }}>
                                        Fecha:
                                    </Typography>
                                    <Typography variant="body" component="span">
                                        {oneDataPruebaAnaerobica.fecha || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span" sx={{ mr: 2 }}>
                                        No. Registro
                                    </Typography>
                                    <Typography variant="body" component="span">
                                        {oneDataPruebaAnaerobica.noRegistro || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Typography variant="h5" component="span">
                                    INDICES
                                </Typography>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={2} sx={{ my: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span">
                                        RESISTENCIA (Kp) {oneDataPruebaAnaerobica.resistencia || 0}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span">
                                        POTENCIAS MAXIMA (Watts/KG) {oneDataPruebaAnaerobica.potenciaMaxima || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={2} sx={{ my: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span">
                                        RESISTENCIA MEDIA (Watts/KG) {oneDataPruebaAnaerobica.resistenciaMedia || 0}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5" component="span">
                                        INDICE DE FATIGA (%) {oneDataPruebaAnaerobica.indiceFatiga || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" textAlign="center">
                                        <Typography variant="h5" component="span">
                                            TEST ANAEROBICO WINGATE
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ListadoVisualizar />
                                </Grid>
                                <Grid item xs={6}>
                                    <LineChart id="wingate" label={allDataTiempoSegundos} data={allDataWatts} name="series-1" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
};

export default Visualizar;
