/* eslint-disable react/prop-types */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';

const ContainerCustom = ({ children }) => {
    const customization = useSelector((state) => state.customization);
    const theme = useTheme();
    return (
        <Container
            maxWidth="xl"
            sx={{ backgroundColor: theme.palette.theme.backgroundDefault, pb: 5, borderRadius: `${customization.borderRadius}px` }}
        >
            {children}
        </Container>
    );
};

export default ContainerCustom;
