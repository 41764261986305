import { IconCoin, IconReportMoney, IconCurrentLocation, IconCircle, IconDice } from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';

const Aside = () => (
    <NavCollapse icon={IconReportMoney} id="compensacionEconomica" title="Compensación Económica" level={1}>
        <NavItem
            item={{
                id: 'pruebas',
                title: 'Pruebas',
                type: 'item',
                url: '/compensacion-economica/disciplina',
                icon: IconCircle,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'codigoCaja',
                title: 'Código Caja',
                type: 'item',
                url: '/compensacion-economica/codigo-caja',
                icon: IconDice,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'ubicacion',
                title: 'Ubicación',
                type: 'item',
                url: '/compensacion-economica/ubicacion',
                icon: IconCurrentLocation,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'compensacionEconomica',
                title: 'Compensación Económica',
                type: 'item',
                url: '/compensacion-economica/formulario',
                icon: IconCoin,
                breadcrumbs: true
            }}
            level={2}
        />
    </NavCollapse>
);

export default Aside;
