/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';

export async function generatePDF(titulo, subTitulo, nombrePdf, item, UserAut) {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: nombrePdf });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let str = null;
    let Y = 0;
    const YY = 85;
    const hoy = new Date();

    async function pageContent(data) {
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold').setFontSize(12);
        doc.text(titulo, 300, 50, { width: 800, align: 'center' });
        doc.text(subTitulo, 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Fecha'}: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, YY, {
            width: 800,
            align: 'left'
        });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Impreso por'}: ${UserAut}`, 300, YY, { width: 800, align: 'center' });
        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }

    Y += 140;
    doc.setFontSize(10).setFont('times', 'bold');
    doc.text(`FECHA:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.fecha || ''}`, 90, Y, { width: 800, align: 'left' });

    doc.setFont('times', 'bold');
    doc.text(`HORA:`, 300, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.hora || ''}`, 350, Y, { width: 800, align: 'left' });
    Y += 30;
    doc.setFont('times', 'bold');
    doc.text(`MOTIVO:`, 50, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 500, YY, 3, 3, 'FD');
    Y += 10;
    doc.setFont('times', 'normal');
    const motivo = doc.splitTextToSize(item?.motivo || '', 490);
    doc.text(motivo, 55, Y, { maxWidth: 490, align: 'justify' });

    Y += 90;
    doc.setFont('times', 'bold');
    doc.text(`DATOS SUBJETIVOS:`, 50, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 500, YY, 3, 3, 'FD');
    Y += 10;
    doc.setFont('times', 'normal');
    const datosSubjetivos = doc.splitTextToSize(item?.datosSubjetivos || '', 490);
    doc.text(datosSubjetivos, 55, Y, { maxWidth: 490, align: 'justify' });

    Y += 90;
    doc.setFont('times', 'bold');
    doc.text(`DATOS OBJETIVOS:`, 50, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 500, YY, 3, 3, 'FD');
    Y += 10;
    doc.setFont('times', 'normal');
    const datosObjetivos = doc.splitTextToSize(item?.datosObjetivos || '', 490);
    doc.text(datosObjetivos, 55, Y, { maxWidth: 490, align: 'justify' });

    Y += 90;
    doc.setFont('times', 'bold');
    doc.text(`DIAGNOSTICO:`, 50, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 500, YY, 3, 3, 'FD');
    Y += 10;
    doc.setFont('times', 'normal');
    const diagnostico = doc.splitTextToSize(item?.diagnostico || '', 490);
    doc.text(diagnostico, 55, Y, { maxWidth: 490, align: 'justify' });

    Y += 90;
    doc.setFont('times', 'bold');
    doc.text(`TRATAMIENTO:`, 50, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, Y, 500, YY, 3, 3, 'FD');
    Y += 10;
    doc.setFont('times', 'normal');
    const tratamiento = doc.splitTextToSize(item?.tratamiento || '', 490);
    doc.text(tratamiento, 55, Y, { maxWidth: 490, align: 'justify' });

    Y += 30;
    doc.autoTable({
        head: [],
        body: [],
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto'
        },
        headStyles: {
            fillColor: [255],
            textColor: [0],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: [233, 233, 240],
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        didDrawPage: pageContent,
        margin: {
            left: 50,
            right: 50,
            top: Y,
            bottom: 50
        }
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    return doc.output('datauristring');

    // doc.save(`${nombrePdf}.pdf`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { generatePDF };
