import { useContext, useState } from 'react';
import { Estados, CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Stack, IconButton, Grid } from '@mui/material';
import ExportPDF from './export/exportPdf';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { Edit, Brightness1 } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase());
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase());
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Federación',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombre)
        },
        {
            name: 'Color',
            sortable: true,
            center: true,
            selector: (row) => CustomText(<Brightness1 fontSize="14px" style={{ color: row.fondo }} />)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => Estados(row.estado)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <BootstrapTooltip title="Agregar Color de Fondo" arrow placement="top">
                        <IconButton aria-label="visualizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => One(row, 3)}>
                            <Edit />
                        </IconButton>
                    </BootstrapTooltip>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    <ExportPDF nameFile="Federaciones" data={searchValue.length ? filteredData : allData} />
                </Grid>
                <Grid item xs={5}>
                    <TextField label="Buscar" sx={{ width: '100%' }} size="small" value={searchValue} onChange={handleFilter} />
                </Grid>
            </Grid>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
