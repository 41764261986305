/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { Button, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationContext } from './context';

export default function Notification() {
    const theme = useTheme();
    const { show, setShow, option, message } = useContext(NotificationContext);
    const Variante = [theme.palette.error.danger, theme.palette.success.dark];
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <div>
            <Snackbar
                open={show}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={option === 1 ? 'success' : 'error'}
                    sx={{ width: '100%', background: Variante[option], color: '#fff' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
