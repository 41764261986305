// material-ui
import Toolbar from 'utils/components/toolbar/index';
import { ContentProvider } from './context';
import ContainerCustom from 'ui-component/container/index';
import Formulario from './form';
import List from './list';

const Index = () => (
    <ContentProvider>
        <ContainerCustom>
            <Toolbar title="Catálogo de Roles" formulario={<Formulario />} />
            <List />
        </ContainerCustom>
    </ContentProvider>
);

export default Index;
