import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Save } from '@mui/icons-material';

const Index = () => {
    const { StoreUpdateDatosDeportivos, oneDataDatosDeportivos, oneDataFichaPsicologica } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            edadDeportiva: data.edadDeportiva,
            horasEntreno: data.horasEntreno,
            diasSemana: data.diasSemana,
            fichaPsicologica: oneDataFichaPsicologica.id,
            id: oneDataDatosDeportivos.length !== 0 ? oneDataDatosDeportivos.id : null
        };
        StoreUpdateDatosDeportivos(jsData);
    };

    const setData = () => {
        setValue('edadDeportiva', oneDataDatosDeportivos.edadDeportiva);
        setValue('horasEntreno', oneDataDatosDeportivos.horasEntreno);
        setValue('diasSemana', oneDataDatosDeportivos.diasSemana);
    };

    const clearData = () => {
        setValue('edadDeportiva', '');
        setValue('horasEntreno', '');
        setValue('diasSemana', '');
    };
    useEffect(() => {
        if (oneDataDatosDeportivos.length !== 0) {
            setData();
        }

        if (oneDataDatosDeportivos.length === 0) {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataDatosDeportivos]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                DATOS DEPORTIVOS
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.edadDeportiva}
                            {...register('edadDeportiva', {
                                required: { value: true, message: 'Este campo es requerido' },
                                pattern: {
                                    value: /\S/, // Este patrón se asegura de que haya al menos un carácter no blanco
                                    message: 'El campo no debe estar vacío'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Edad Deportiva"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.edadDeportiva && errors.edadDeportiva.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.horasEntreno}
                            {...register('horasEntreno', {
                                required: { value: true, message: 'Este campo es requerido' },
                                pattern: {
                                    value: /\S/, // Este patrón se asegura de que haya al menos un carácter no blanco
                                    message: 'El campo no debe estar vacío'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Horas de Entreno"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.horasEntreno && errors.horasEntreno.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.diasSemana}
                            {...register('diasSemana', {
                                required: { value: true, message: 'Este campo es requerido' },
                                pattern: {
                                    value: /\S/, // Este patrón se asegura de que haya al menos un carácter no blanco
                                    message: 'El campo no debe estar vacío'
                                }
                            })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Dias por Semana"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.diasSemana && errors.diasSemana.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                <AnimateButton>
                    <BootstrapTooltip title="Guardar" arrow placement="left">
                        <Button
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{
                                background: theme.palette.success.main,
                                '&:hover': {
                                    background: theme.palette.success.dark
                                },
                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                maxWidth: '48px' // Cambia el ancho máximo del botón
                            }}
                        >
                            <Save fontSize="medium" />
                        </Button>
                    </BootstrapTooltip>
                </AnimateButton>
            </Box>
        </Box>
    );
};

export default Index;
