/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Alert, IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const BtnAcciones = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: '100%',
                        width: 'fit-content'
                    }
                }}
            >
                {children}
            </Menu>
        </div>
    );
};

export const CustomText = (props) => (
    /* <div data-tag="allowRowEvents" style={{ fontSize: '15px', overflow: 'hidden', textOverflow: 'ellipses' }}></div> */
    <div data-tag="allowRowEvents" style={{ fontSize: '15px', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
        {props}
    </div>
);

export const Estados = (estado) => {
    const theme = useTheme();
    const Variante = [theme.palette.error.danger, theme.palette.success.dark];
    return (
        <Alert size="small" icon={false} variant="filled" sx={{ py: 0, background: Variante[estado], color: '#fff' }}>
            <small>
                {estado === 0 && 'Inactivo'}
                {estado === 1 && 'Activo'}
                {estado === 2 && 'Autorizado'}
            </small>
        </Alert>
    );
};
export const EstadosTexto = (id, estado) => {
    const theme = useTheme();
    const Variante = [
        theme.palette.error.danger,
        theme.palette.warning.dark,
        theme.palette.error.danger,
        theme.palette.success.dark,
        theme.palette.primary.dark,
        theme.palette.error.danger,
        theme.palette.error.danger,
        theme.palette.error.danger,
        theme.palette.error.danger,
        theme.palette.error.danger,
        theme.palette.primary.danger,
        theme.palette.success.dark,
        theme.palette.dark.dark
    ];
    return (
        <Alert
            size="small"
            icon={false}
            variant="filled"
            sx={{ py: 0, background: Variante[id], color: '#fff' }}
            style={{ width: '150px', justifyContent: 'center' }}
        >
            <small style={{ textAlign: 'center', alignSelf: 'center', justifySelf: 'center', alignItems: 'center' }}>{estado}</small>
        </Alert>
    );
};

export const EstadosSolicitud = (row) => {
    const theme = useTheme();
    const Variante = [
        theme.palette.error.danger,
        theme.palette.success.dark,
        theme.palette.warning.dark,
        theme.palette.primary.dark,
        theme.palette.error.danger
    ];

    return (
        <Alert size="small" icon={false} variant="filled" sx={{ py: 0, background: Variante[row.estado], color: '#fff' }}>
            <small>{row.labelEstado}</small>
        </Alert>
    );
};

export const EstadosCustom = (data) => (
    <Alert size="small" icon={false} variant="filled" sx={{ py: 0, background: data.estado, color: '#fff' }}>
        <small>{data.labelEstado}</small>
    </Alert>
);

export default { CustomText, Estados, BtnAcciones, EstadosCustom, EstadosSolicitud, EstadosTexto };
