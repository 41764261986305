/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { Box, Grid, Divider, Typography } from '@mui/material';
import DataTable from 'react-data-table-component';
import ExportPdf from '../export/hojaEvolucion/modal';
import { ContentContext } from '../context';

const Listado = () => {
    const { allDataHojaEvolutiva } = useContext(ContentContext);
    const columns = [
        {
            name: 'Fecha',
            selector: (row) => row.fecha,
            width: '250px'
        },
        {
            name: 'Hora',
            selector: (row) => row.hora
        },
        {
            name: 'Motivo',
            selector: (row) => row.motivo,
            width: '250px'
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => <ExportPdf data={row} nameFile="Hoja de Evolución" />
        }
    ];
    // data provides access to your row data
    const ExpandedComponent = ({ data }) => {
        const { datosSubjetivos, datosObjetivos, diagnostico, tratamiento } = data;
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mx: 3 }}>
                        <p>
                            <Typography sx={{ mb: 2, mr: 2 }} variant="body" textTransform="uppercase" fontWeight="bold">
                                Datos Subjetivos:
                            </Typography>
                            {datosSubjetivos}
                        </p>
                        <Divider />
                        <p>
                            <Typography sx={{ mb: 2, mr: 2 }} variant="body" textTransform="uppercase" fontWeight="bold">
                                Datos Objetivos:
                            </Typography>
                            {datosObjetivos}
                        </p>
                        <Divider />
                        <p>
                            <Typography sx={{ mb: 2, mr: 2 }} variant="body" textTransform="uppercase" fontWeight="bold">
                                Diagnostico:
                            </Typography>
                            {diagnostico}
                        </p>
                        <Divider />
                        <p>
                            <Typography sx={{ mb: 2, mr: 2 }} variant="body" textTransform="uppercase" fontWeight="bold">
                                Tratamiento:
                            </Typography>
                            {tratamiento}
                        </p>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <DataTable
            noDataComponent="No hay registros para mostrar."
            columns={columns}
            data={allDataHojaEvolutiva}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
        />
    );
};

export default Listado;
