import {
    IconUserCheck,
    IconFriends,
    IconUserExclamation,
    IconDatabase,
    IconUsers,
    IconListDetails,
    IconSettings,
    IconMap,
    IconMap2,
    IconWorld
} from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';

const Aside = () => (
    <NavCollapse icon={IconDatabase} id="IT" title="Admin IT" level={1}>
        <NavItem
            item={{
                id: 'correo',
                title: 'Configuración',
                type: 'item',
                url: '/it/config',
                icon: IconSettings,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'rol',
                title: 'Roles',
                type: 'item',
                url: '/it/rol',
                icon: IconListDetails,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'estadoCivil',
                title: 'Estado Civil',
                type: 'item',
                url: '/it/estado-civil',
                icon: IconUsers,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'genero',
                title: 'Género',
                type: 'item',
                url: '/it/genero',
                icon: IconFriends,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'tipoPersona',
                title: 'Tipo Persona',
                type: 'item',
                url: '/it/tipo-persona',
                icon: IconUserExclamation,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'usuario',
                title: 'Usuario',
                type: 'item',
                url: '/it/usuario',
                icon: IconUserCheck,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'pais',
                title: 'Países',
                type: 'item',
                url: '/it/pais',
                icon: IconWorld,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'departamento',
                title: 'Departamento',
                type: 'item',
                url: '/it/departamento',
                icon: IconMap,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'municipio',
                title: 'Municipio',
                type: 'item',
                url: '/it/municipio',
                icon: IconMap2,
                breadcrumbs: true
            }}
            level={2}
        />
    </NavCollapse>
);

export default Aside;
