import { IconReport, IconNotes, IconAward, IconFileAnalytics } from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';

const Aside = () => (
    <NavCollapse icon={IconReport} id="Resultados" title="Resultados" level={1}>
        <NavItem
            item={{
                id: 'evento',
                title: 'Eventos',
                type: 'item',
                url: '/resultados/evento',
                icon: IconNotes,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'record',
                title: 'Record',
                type: 'item',
                url: '/resultados/record',
                icon: IconAward,
                breadcrumbs: true
            }}
            level={2}
        />
        <NavItem
            item={{
                id: 'resultados',
                title: 'Resultados',
                type: 'item',
                url: '/resultados/resultado',
                icon: IconFileAnalytics,
                breadcrumbs: true
            }}
            level={2}
        />
    </NavCollapse>
);

export default Aside;
