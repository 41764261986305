import { useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import CardEvent from 'utils/components/cards/cardEvent';
import esLocale from '@fullcalendar/core/locales/es';
import { ContentContext } from './context';

// a custom render function
const DemoApp = () => {
    const { toggleModal, setDateSelect, allData, One } = useContext(ContentContext);

    const handleEventClick = (info) => {
        // if(infor) {
        One(info.event, 3);
        // }
    };

    const handleDateSelect = (selectInfo) => {
        setDateSelect(selectInfo);
        toggleModal(1);
    };

    const eventContent = (eventInfo) => <CardEvent item={eventInfo} Actions={One} />;

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={false}
                events={allData}
                editable
                selectable
                selectMirror
                dayMaxEvents
                nowIndicator // habilitar la línea vertical de hora actual
                select={handleDateSelect}
                eventContent={eventContent}
                themeSystem="bootstrap"
                // initialEvents={allData}
                eventClick={handleEventClick}
                validRange={{ start: new Date() }}
                locale={esLocale} // pasa la localización como una propiedad de opciones
            />
        </div>
    );
};

export default DemoApp;
