/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../context';
import { Box, Grid, Typography, TextField, Button, FormControl, Checkbox, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { Save, ArrowUpward, ArrowDownward } from '@mui/icons-material';

const ValoracionFisioterapeutica = () => {
    const { oneDataValoracionFisioterapeutica, StoreUpdateValoraciones, oneDataValoraciones } = useContext(ContentContext);
    const theme = useTheme();
    const [protocoloPrevencion, setProtocoloPrevencion] = useState([]);

    const Chekear = (e, item) => {
        console.log(e, item);
        const Rol = e.target;
        if (Rol.checked) {
            setProtocoloPrevencion([...protocoloPrevencion, item]);
        } else {
            const nuevoArreglo = protocoloPrevencion.filter((elemento) => elemento !== item);
            setProtocoloPrevencion(nuevoArreglo);
        }
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        control
    } = useForm();
    const campos = [
        'flexion70PinchazoJalon',
        'extension70PinchazoJalon',
        'flexionLatIzq45PinchazoJalon',
        'flexionLatDer45PinchazoJalon',
        'rotacionIz90PinchazoJalon',
        'rotacionDer90PinchazoJalon',
        'flexion90PinchazoJalon',
        'extension30PinchazoJalon',
        'flexionLatIzq40PinchazoJalon',
        'flexionLatDer40PinchazoJalon',
        'rotacionIz45PinchazoJalon',
        'rotacionDer45PinchazoJalon'
    ];
    const valoresObservados = watch(campos);

    // ------------------------------------------------------------------

    const valoresBiomecanico = [5, 5, 7.5, 7.5];

    const valoresObservadosBiomecanicos = watch([
        'abduccinCaderaDerechaAceptaleDeficiente',
        'abduccinCaderaIzquierdaAceptaleDeficiente',
        'etencionCaderaAceptaleDeficiente',
        'flexionTroncoAceptaleDeficiente'
    ]);
    // -------------------------------------------------------------------

    const valoresParametrosBalance = [8.3333, 8.3333, 8.3333];
    const valoresObservadosBalance = watch(['oad', 'ocd', 'inestableEstable']);
    // ---------------------------------------------------------------------

    const valoresParametrosMuscularCadena = [5, 5, 3.33, 3.33, 3.33];
    const valoresObservadosMuscularCadena = watch([
        'cadenaSuperiorDerIzq',
        'cadenaInferiorDerIzq',
        'grado1DerIzq',
        'grado2DerIzq',
        'grado3DerIzq'
    ]);

    // -------------------------------------------------------------------

    const valoresParametrosFlexibilidad = [5, 5];
    const valoresObservadosFlexibilidad = watch(['msSuperiores', 'msInferior']);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const activeLabel = { shrink: true };
    const onSubmit = async (data) => {
        const newData = Object.entries(data).reduce((acc, [key, value]) => {
            acc[key] = value || 0; // Si el valor es falsy (undefined, null, "", etc.), se asigna 0
            return acc;
        }, {});

        const jsonData = {
            ...newData,
            id: oneDataValoraciones?.id || null,
            inestableEstable: data.inestableEstable,
            protocolosRecomendados: JSON.stringify(protocoloPrevencion || []),
            valoracionFisioterapeutica: oneDataValoracionFisioterapeutica?.id || null,
            flexion70Nl: 0,
            extension70Nl: 0,
            flexionLatIzq45Nl: 0,
            flexionLatDer45Nl: 0,
            rotacionIz90Nl: 0,
            rotacionDer90Nl: 0,
            flexion90Nl: 0,
            extension30Nl: 0,
            flexionLatIzq40Nl: 0,
            flexionLatDer40Nl: 0,
            rotacionIz45Nl: 0,
            rotacionDer45Nl: 0,
            oai: 0,
            oci: 0,
            inestable: 0,
            estable: 0
        };
        StoreUpdateValoraciones(jsonData);
    };

    useEffect(() => {
        const totalChequeados = Object.values(valoresObservados).reduce((acc, valor) => {
            if (valor === '0') {
                return acc + 1;
            }
            return acc;
        }, 0);
        const total = (totalChequeados * 10) / 12;
        setValue('valorTotalMovimientoActivo', total.toFixed(2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valoresObservados]);

    useEffect(() => {
        let total = 0;

        Object.entries(valoresObservadosBiomecanicos).forEach(([key, valor]) => {
            if (valor === '1') {
                total += valoresBiomecanico[key];
            }
        });

        setValue('valorTotalBiomecanico', total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valoresObservadosBiomecanicos]);

    useEffect(() => {
        let total = 0;

        Object.entries(valoresObservadosBalance).forEach(([key, valor]) => {
            if (valor === '1') {
                total += valoresParametrosBalance[key];
            }
        });

        setValue('valorTotalBalanceCore', total > 19.5 ? Math.round(total) : total.toFixed(2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valoresObservadosBalance, setValue]);

    useEffect(() => {
        let total = 0;

        Object.entries(valoresObservadosMuscularCadena).forEach(([key, valor]) => {
            if (valor === '1') {
                total += valoresParametrosMuscularCadena[key];
            }
        });

        setValue('valorTotalMuscularCadena', total > 19.5 ? Math.round(total) : total.toFixed(2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valoresObservadosMuscularCadena, setValue]);

    useEffect(() => {
        let total = 0;

        Object.entries(valoresObservadosFlexibilidad).forEach(([key, valor]) => {
            console.log(key, valor);
            if ([false, 0, '0'].includes(valor)) {
                total += valoresParametrosFlexibilidad[key];
            }
        });

        setValue('valoracionFlexibilidad', total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valoresObservadosFlexibilidad, setValue]);

    const setData = async (data) => {
        setValue('artBloqueadaFuerzaMuscular', data.artBloqueadaFuerzaMuscular);
        setValue('artBloqueadaCanedaSuperior', data.artBloqueadaCanedaSuperior);
        setValue('flexion70PinchazoJalon', data.flexion70PinchazoJalon);
        setValue('flexion70Nl', data.flexion70Nl);
        setValue('extension70PinchazoJalon', data.extension70PinchazoJalon);
        setValue('extension70Nl', data.extension70Nl);
        setValue('flexionLatIzq45PinchazoJalon', data.flexionLatIzq45PinchazoJalon);
        setValue('flexionLatIzq45Nl', data.flexionLatIzq45Nl);
        setValue('flexionLatDer45PinchazoJalon', data.flexionLatDer45PinchazoJalon);
        setValue('flexionLatDer45Nl', data.flexionLatDer45Nl);
        setValue('rotacionIz90PinchazoJalon', data.rotacionIz90PinchazoJalon);
        setValue('rotacionIz90Nl', data.rotacionIz90Nl);
        setValue('rotacionDer90PinchazoJalon', data.rotacionDer90PinchazoJalon);
        setValue('rotacionDer90Nl', data.rotacionDer90Nl);
        setValue('flexion90PinchazoJalon', data.flexion90PinchazoJalon);
        setValue('flexion90Nl', data.flexion90Nl);
        setValue('extension30PinchazoJalon', data.extension30PinchazoJalon);
        setValue('extension30Nl', data.extension30Nl);
        setValue('flexionLatIzq40PinchazoJalon', data.flexionLatIzq40PinchazoJalon);
        setValue('flexionLatIzq40Nl', data.flexionLatIzq40Nl);
        setValue('flexionLatDer40PinchazoJalon', data.flexionLatDer40PinchazoJalon);
        setValue('flexionLatDer40Nl', data.flexionLatDer40Nl);
        setValue('rotacionIz45PinchazoJalon', data.rotacionIz45PinchazoJalon);
        setValue('rotacionIz45Nl', data.rotacionIz45Nl);
        setValue('rotacionDer45PinchazoJalon', data.rotacionDer45PinchazoJalon);
        setValue('rotacionDer45Nl', data.rotacionDer45Nl);
        setValue('flexionTroncoAceptaleDeficiente', data.flexionTroncoAceptaleDeficiente);
        setValue('etencionCaderaAceptaleDeficiente', data.etencionCaderaAceptaleDeficiente);
        setValue('abduccinCaderaIzquierdaAceptaleDeficiente', data.abduccinCaderaIzquierdaAceptaleDeficiente);
        setValue('abduccinCaderaDerechaAceptaleDeficiente', data.abduccinCaderaDerechaAceptaleDeficiente);
        setValue('oad', data.oad);
        setValue('ocd', data.ocd);
        // setValue('oai', data.oai);
        // setValue('oci', data.oci);
        setValue('inestableEstable', data.inestableEstable);
        setValue('inestable', data.inestable);
        setValue('estable', data.estable);
        setValue('puntoPresionInestable', data.puntoPresionInestable);
        setValue('puntoPresionEstable', data.puntoPresionEstable);
        setValue('cadenaSuperiorDerIzq', data.cadenaSuperiorDerIzq);
        setValue('cadenaInferiorDerIzq', data.cadenaInferiorDerIzq);
        setValue('grado1DerIzq', data.grado1DerIzq);
        setValue('grado2DerIzq', data.grado2DerIzq);
        setValue('grado3DerIzq', data.grado3DerIzq);
        setValue('msSuperiores', data.msSuperiores);
        setValue('msInferior', data.msInferior);
        setValue('sxArriba', data.sxArriba);
        setValue('sxAbajo', data.sxAbajo);
        setValue('observaciones', data.observaciones);
        setValue('inestableEstable', data.inestableEstable);
        setProtocoloPrevencion(JSON.parse(data.protocolosRecomendados) || []);
        setTimeout(() => {
            setValue('valorTotalBiomecanico', data.valorTotalBiomecanico);
            setValue('valorTotalBalanceCore', data.valorTotalBalanceCore);
            setValue('valorTotalMovimientoActivo', data.valorTotalMovimientoActivo);
            setValue('valoracionFisioterapeutica', data.valoracionFisioterapeutica);
            setValue('valoracionFlexibilidad', data.valoracionFlexibilidad);
            setValue('valorTotalMuscularCadena', data.valorTotalMuscularCadena);
        }, 500);
    };
    useEffect(() => {
        if (oneDataValoraciones.length === 0) {
            setValue('valoracionFlexibilidad', 10);
        } else {
            setData(oneDataValoraciones);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataValoraciones]);

    return (
        <>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
                <Grid container spacing={2}>
                    <Grid item lg={8} xs={12}>
                        <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                            Valoración Rango de Movimiento Activo
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                error={!!errors.valorTotalMovimientoActivo}
                                {...register('valorTotalMovimientoActivo', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                size="small"
                                id="valorTotalMovimientoActivo"
                                label="Valoración de 1 a 10"
                                InputLabelProps={activeLabel}
                                helperText={errors?.valorTotalMovimientoActivo?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Columna Cervical
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión 70°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexion70PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexion70Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Extensión 70°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="extension70PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('extension70Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión Lat Iz. 45°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexionLatIzq45PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexionLatIzq45Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión Lat Der. 45°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexionLatDer45PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexionLatDer45Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Rotación Iz. 90°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="rotacionIz90PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('rotacionIz90Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Rotación Der. 90°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="rotacionDer90PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('rotacionDer90Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* otra columna */}
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Columna Toraco Lumbar
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión 90°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexion90PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexion90Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Extensión 30°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="extension30PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('extension30Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión Lat Iz. 40°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexionLatIzq40PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexionLatIzq40Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión Lat Der. 40°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="flexionLatDer40PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('flexionLatDer40Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Rotación Iz. 45°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="rotacionIz45PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('rotacionIz45Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Rotación Der. 45°
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="rotacionDer45PinchazoJalon"
                                        control={control}
                                        defaultValue="0"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                {/* <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...label} {...register('rotacionDer45Nl')} />}
                                                        label="nl"
                                                    />
                                                </Grid> */}
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="0" control={<Radio />} label="nl" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Pinchazo" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Jalón" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={8} xs={12}>
                        <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                            VALORACIÓN BIOMECÁNICO
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                error={!!errors.valorTotalBiomecanico}
                                {...register('valorTotalBiomecanico', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valoración de 1 a 25"
                                InputLabelProps={activeLabel}
                                helperText={errors?.valorTotalBiomecanico?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Examen Biomecanico
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Flexión de Tronco
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="flexionTroncoAceptaleDeficiente"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Aceptable" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Extensión de Cadera
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="etencionCaderaAceptaleDeficiente"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Aceptable" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Abducción de Cadera Izquierda
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="abduccinCaderaIzquierdaAceptaleDeficiente"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Aceptable" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Abducción de Cadera Derecha
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="abduccinCaderaDerechaAceptaleDeficiente"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Aceptable" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={8} xs={12}>
                        <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                            Valoración Balance-Core y Propiocepción
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                error={!!errors.valorTotalBalanceCore}
                                {...register('valorTotalBalanceCore', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valoración de 1 a 25"
                                InputLabelProps={activeLabel}
                                helperText={errors?.valorTotalBalanceCore?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Balance
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" textTransform="uppercase" fontWeight="bold" sx={{ mt: 2 }}>
                                                OA
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...label} defaultValue="1" defaultChecked value="1" {...register('oad')} />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" textTransform="uppercase" fontWeight="bold" sx={{ mt: 2 }}>
                                                OC
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...label} defaultValue="1" defaultChecked value="1" {...register('ocd')} />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Core
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="inestableEstable"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={12}>
                                                    <FormControlLabel value="0" control={<Radio />} label="INESTABLE" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel value="1" control={<Radio />} label="ESTABLE" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 3 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Punto de Presión
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 3 }}>
                            <Grid container spacing={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        error={!!errors.puntoPresionInestable}
                                        {...register('puntoPresionInestable', {
                                            required: { value: true, message: 'Este campo es requerido' }
                                        })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label=""
                                        InputLabelProps={activeLabel}
                                        helperText={errors?.puntoPresionInestable?.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        error={!!errors.puntoPresionEstable}
                                        {...register('puntoPresionEstable', {
                                            required: { value: true, message: 'Este campo es requerido' }
                                        })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label=""
                                        InputLabelProps={activeLabel}
                                        helperText={errors?.puntoPresionEstable?.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={8} xs={12}>
                        <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                            Valoración Muscular en Cadena
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                error={!!errors.valorTotalMuscularCadena}
                                {...register('valorTotalMuscularCadena', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valoración de 1 a 20"
                                InputLabelProps={activeLabel}
                                helperText={errors?.valorTotalMuscularCadena?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Fuerza Muscular
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Cadena Superior
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="cadenaSuperiorDerIzq"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Normal" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...label}
                                                defaultValue="1"
                                                defaultChecked
                                                value="1"
                                                {...register('artBloqueadaFuerzaMuscular')}
                                            />
                                        }
                                        label="Art. Bloqueada"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Cadena Inferior
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="cadenaInferiorDerIzq"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Normal" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Deficiente" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...label}
                                                defaultValue="1"
                                                defaultChecked
                                                value="1"
                                                {...register('artBloqueadaCanedaSuperior')}
                                            />
                                        }
                                        label="Art. Bloqueada"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Pie Plano
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Grado 1
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="grado1DerIzq"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Derecha" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Izquierda" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Grado 2
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="grado2DerIzq"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Derecha" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Izquierda" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                        Grado 3
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="grado3DerIzq"
                                        control={control}
                                        defaultValue="1"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Derecha" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel value="2" control={<Radio />} label="Izquierda" />
                                                </Grid>
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={8} xs={12}>
                        <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                            Valoración De Flexibilidad
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                error={!!errors.valoracionFlexibilidad}
                                {...register('valoracionFlexibilidad', {
                                    required: { value: true, message: 'Este campo es requerido' }
                                })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valoración de 1 a 10"
                                InputLabelProps={activeLabel}
                                helperText={errors?.valoracionFlexibilidad?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography sx={{ mb: 2 }} variant="h5" textTransform="uppercase" fontWeight="bold">
                                Fuerza Muscular
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                                Ms. Superiores
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...label}
                                                        defaultValue="1"
                                                        defaultChecked
                                                        value="1"
                                                        {...register('msSuperiores')}
                                                    />
                                                }
                                                label=""
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
                                                Ms. Inferiores
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...label}
                                                        defaultValue="1"
                                                        defaultChecked
                                                        value="1"
                                                        {...register('msInferior')}
                                                    />
                                                }
                                                label=""
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Typography sx={{ mt: 4, mb: 2 }} textTransform="uppercase" variant="h5" fontWeight="bold">
                    Protocolos de Prevención y Correctivos Recomendados:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox {...label} defaultValue="1" defaultChecked value="1" {...register('sxArriba')} />}
                            label={
                                <>
                                    <ArrowUpward /> Sx
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox {...label} defaultValue="1" defaultChecked value="1" {...register('sxAbajo')} />}
                            label={
                                <>
                                    <ArrowDownward /> Sx
                                </>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {/* <Controller
                            name="protocoloPrevencion"
                            control={control}
                            defaultValue="1"
                            render={({ field }) => (
                                <RadioGroup {...field} row>
                                    <Grid item lg={2}>
                                        <FormControlLabel value="1" control={<Radio />} label="Core" />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <FormControlLabel value="2" control={<Radio />} label="Balance" />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <FormControlLabel value="3" control={<Radio />} label="Biomecánica" />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <FormControlLabel value="4" control={<Radio />} label="Fuerza Muscular" />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <FormControlLabel value="5" control={<Radio />} label="Estiramiento" />
                                    </Grid>
                                </RadioGroup>
                            )}
                        /> */}
                    <Grid item xs={2}>
                        <FormControlLabel
                            label="Core"
                            control={<Checkbox onChange={(e) => Chekear(e, 1)} value="1" checked={protocoloPrevencion.includes(1)} />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label="Balance"
                            control={<Checkbox onChange={(e) => Chekear(e, 2)} value="2" checked={protocoloPrevencion.includes(2)} />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => Chekear(e, 3)} value="3" checked={protocoloPrevencion.includes(3)} />}
                            label="Biomecánica"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => Chekear(e, 4)} value="4" checked={protocoloPrevencion.includes(4)} />}
                            label="Fuerza Muscular"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox {...label} onChange={(e) => Chekear(e, 5)} value="5" checked={protocoloPrevencion.includes(5)} />
                            }
                            label="Estiramiento"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ my: 4 }}>
                    <FormControl fullWidth>
                        <TextField
                            error={!!errors.observaciones}
                            {...register('observaciones', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Observaciones"
                            rows={3}
                            multiline
                            InputLabelProps={activeLabel}
                            helperText={errors?.observaciones?.message}
                        />
                    </FormControl>
                </Grid>

                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                    <AnimateButton>
                        <BootstrapTooltip title="Agregar" arrow placement="left">
                            <Button
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{
                                    background: theme.palette.success.main,
                                    '&:hover': {
                                        background: theme.palette.success.dark
                                    },
                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                }}
                            >
                                <Save fontSize="medium" />
                            </Button>
                        </BootstrapTooltip>
                    </AnimateButton>
                </Box>
            </Box>
        </>
    );
};

export default ValoracionFisioterapeutica;
