import { Box, Grid, Typography, Button } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { obtenerFechaActual } from 'utils/function/global';
import { ContentContext } from '../context';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Add, Check } from '@mui/icons-material';

const Formulario = () => {
    const { oneDataNutricionCineantropometria, StoreUpdateNutricionCineantropometria, FinalizarEvaluacionNutricionCineantropometria } =
        useContext(ContentContext);
    const theme = useTheme();
    const { handleSubmit } = useForm();
    const onSubmit = () => {
        if (!oneDataNutricionCineantropometria?.id) {
            const jsonData = {
                fecha: obtenerFechaActual()
            };
            StoreUpdateNutricionCineantropometria(jsonData);
        } else {
            FinalizarEvaluacionNutricionCineantropometria(oneDataNutricionCineantropometria);
        }
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={5}>
                    <Typography sx={{ mb: 2 }} variant="h4">
                        Formulario Nutición-Cineantropometría
                    </Typography>
                </Grid>
                <Grid item lg={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <AnimateButton>
                            <BootstrapTooltip title={oneDataNutricionCineantropometria?.id ? 'Finalizar' : 'Crear'} arrow placement="left">
                                <Button
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        background: oneDataNutricionCineantropometria?.id
                                            ? theme.palette.success.main
                                            : theme.palette.primary.main,
                                        '&:hover': {
                                            background: oneDataNutricionCineantropometria?.id
                                                ? theme.palette.success.dark
                                                : theme.palette.primary.dark
                                        },
                                        minWidth: '32px', // Cambia el ancho mínimo del botón
                                        maxWidth: '48px' // Cambia el ancho máximo del botón
                                    }}
                                >
                                    {oneDataNutricionCineantropometria?.id ? <Check fontSize="medium" /> : <Add fontSize="medium" />}
                                </Button>
                            </BootstrapTooltip>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Formulario;
