import { useContext, useState, useEffect } from 'react';
import { CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Stack, IconButton, Box, Grid } from '@mui/material';
import ExportPDF from './export/exportPdf';
import { IconEye } from '@tabler/icons';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData, All } = useContext(ContentContext);
    const { register, watch, setValue } = useForm();
    const FechaI = watch('fechaI');
    const FechaF = watch('fechaF');
    const today = new Date().toISOString().substr(0, 10); // Obtiene la fecha actual y la formatea en el formato de fecha ISO
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    useEffect(() => {
        setValue('fechaI', today);
        setValue('fechaF', today);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (FechaI && FechaF) {
            All(FechaI, FechaF);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FechaI, FechaF]);
    const columns = [
        {
            name: 'Correlativo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.correlativo)
        },
        {
            name: 'Fecha',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.start)
        },
        {
            name: 'Tipo Atención',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.title)
        },
        {
            name: 'Federación',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.deporte)
        },
        // {
        //     name: 'estado',
        //     sortable: true,
        //     center: true,
        //     selector: (row) => EstadosSolicitud(row)
        // },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <BootstrapTooltip title="Visualizar" arrow placement="top">
                        <IconButton aria-label="visualizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => One(row, 3)}>
                            <IconEye />
                        </IconButton>
                    </BootstrapTooltip>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    <ExportPDF nameFile="Historial de Citas Medicas" data={searchValue.length ? filteredData : allData} />
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                        <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
                        <TextField
                            type="date"
                            sx={{ mr: 2 }}
                            label="Fecha Final"
                            size="small"
                            {...register('fechaF')}
                            defaultValue={today}
                        />
                        <TextField
                            type="date"
                            sx={{ mr: 2 }}
                            label="Fecha Inicial"
                            size="small"
                            {...register('fechaI')}
                            defaultValue={today}
                        />
                    </Box>
                </Grid>
            </Grid>
            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
