import { useContext, useState } from 'react';
import { Estados, CustomText, BtnAcciones } from 'utils/components/customTable/index';
import { Box, TextField, MenuItem } from '@mui/material';
import { Edit, Visibility, Check, Close } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData, Actions } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Rol',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombre)
        },
        {
            name: 'Codigo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.codigo)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => Estados(row.estado)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <BtnAcciones key={row.id}>
                    {row.estado === 1 && (
                        <MenuItem onClick={() => One(row, 2)}>
                            <Visibility sx={{ mr: 1 }} /> Visualizar
                        </MenuItem>
                    )}
                    {row.estado === 1 && (
                        <MenuItem onClick={() => One(row, 3)}>
                            <Edit sx={{ mr: 1 }} /> Editar
                        </MenuItem>
                    )}
                    <MenuItem onClick={() => Actions(row)}>
                        {row.estado === 1 ? (
                            <>
                                <Close sx={{ mr: 1 }} /> Deshabilitar
                            </>
                        ) : (
                            <>
                                <Check sx={{ mr: 1 }} /> Habilitar
                            </>
                        )}
                    </MenuItem>
                </BtnAcciones>
            )
        }
    ];

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
            </Box>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                columns={columns}
                theme="custom"
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </div>
    );
};

export default List;
