/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from 'react';
import Blowfish from 'javascript-blowfish';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
    const Roles = JSON.parse(process.env.REACT_APP_ROLES);
    const [validateUrl, setValidateUrl] = useState(false);
    const [userAuth, setUserAuth] = useState([]);
    const [userRoles, setUseRoles] = useState([]);
    const publicR = ['/auth/atencion', '/auth/login', '/auth/forgot-password', '/auth/reset-password'];
    useEffect(() => {
        if (localStorage.getItem('authUserCog')) {
            const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUserCog')));
            const data = JSON.parse(Decript.replace(/\0/g, ''));
            setUserAuth(data);
            setUseRoles(data.roles);
            setValidateUrl(false);
        } else {
            setUserAuth([]);
            setValidateUrl(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // const path = window.location.pathname
        const url = window.location.pathname;
        if (userAuth.length > 0 && !publicR.includes(url)) {
            localStorage.removeItem('authUserCog');
            // window.location.replace('/auth/login');
            setValidateUrl(true);
        }
        if (userAuth.length === 0 && url === '/') {
            setValidateUrl(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth]);

    const value = { userAuth, Roles, userRoles, validateUrl };
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
