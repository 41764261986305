const BaseInput = {
    boxShadow: 'none',
    color: '#212121',
    borderRadius: '10px',
    padding: '2px 4px',
    backgroundColor: '#fafafa'
};

const styleClassInputDanger = (base) => ({
    ...base,
    ...BaseInput,
    color: '#F00 !important',
    border: '1px solid #F00'
});

const styleClassInput = (base) => ({
    ...base,
    ...BaseInput
});

const styleClassMenu = (base) => ({
    ...base,
    background: '#FFF',
    zIndex: 1000000000000, // Asegúrate de que este valor sea suficientemente alto
    display: 'block'
});

// Añadir menuPortal para manejar el z-index del menú
const styleClassMenuPortal = (base) => ({
    ...base,
    zIndex: 1000000000000 // Asegúrate de que este valor sea suficientemente alto
});

export const customStyles = {
    control: styleClassInput,
    menu: styleClassMenu,
    menuPortal: styleClassMenuPortal
};

export const customStylesDanger = {
    control: styleClassInputDanger,
    menu: styleClassMenu,
    menuPortal: styleClassMenuPortal
};

export default { customStyles, customStylesDanger };
