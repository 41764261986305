import { useContext, useEffect } from 'react';
import { Modal, TextField, Box, Button, FormControl, Grid, InputLabel, Switch, Typography, Stack } from '@mui/material';
import { Add, Block } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import MainCard from 'utils/components/modal/MainCardModal';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import { ContentContext } from './context';
import { customStyles, customStylesDanger } from 'utils/select/index';
import Select from 'react-select';
import ListCompensacion from './listCompensacion';

export default function KeepMountedModal() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        control
    } = useForm();
    const {
        setStateEdit,
        stateEdit,
        show,
        toggleModal,
        oneData,
        StoreUpdate,
        opcion,
        allDataDisciplina,
        allDataCodigoCaja,
        allDataUbicacion,
        oneDataEdit
    } = useContext(ContentContext);
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            disciplina: data.disciplina.value,
            codigoCaja: data.codigoCaja.value,
            ubicacion: data.ubicacion.value,
            monto: data.monto,
            porcentaje: data.porcentaje,
            fechaIngreso: data.fechaIngreso,
            equipo: data.equipo ? 1 : 0,
            estado: oneData.length !== 0 ? oneData.estado : 1,
            persona: oneData.length !== 0 ? oneData.id : null,
            id: oneDataEdit.id ? oneDataEdit.id : null
        };
        StoreUpdate(jsData);
        reset();
        setStateEdit(0);
    };

    const setData = async () => {
        await setValue('porcentaje', oneDataEdit.porcentaje);
        await setValue('monto', oneDataEdit.monto);
        await setValue('disciplina', { label: oneDataEdit.disciplina, value: oneDataEdit.idDisciplina });
        await setValue('codigoCaja', { label: oneDataEdit.codigoCaja, value: oneDataEdit.idCodigoCaja });
        await setValue('ubicacion', { label: oneDataEdit.ubicacion, value: oneDataEdit.idUbicacion });
        await setValue('equipo', oneDataEdit.equipo === 1);
        await setValue('fechaIngreso', oneDataEdit.fechaIngreso, { shouldValidate: true, shouldDirty: true });
    };

    const Cancel = () => {
        reset();
        setStateEdit(0);
    };

    useEffect(() => {
        async function fetchMyAPI() {
            if (oneDataEdit.length !== 0 && stateEdit === 1) {
                await setData();
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataEdit, stateEdit]);

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Formulario Compensación Económica" size="xl" closeButton={() => toggleModal(0)}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ justifyContent: 'center', width: '100%' }}>
                                <div
                                    className="kt-avatar kt-avatar--outline"
                                    id="kt_user_avatar"
                                    style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <div
                                        id="logo-size"
                                        className={`kt-avatar__holder ${errors && errors.foto && errors.foto.message && 'border-danger'}`}
                                        style={{ backgroundImage: `url('${oneData.foto}')` }}
                                    />
                                    <div className="py-3 flex-shrink-1">{oneData.nombreCompleto}</div>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <InputLabel htmlFor="disciplina" shrink sx={{ background: '#FFF', px: 1 }}>
                                                Prueba
                                            </InputLabel>
                                            <Controller
                                                name="disciplina"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        isSearchable
                                                        defaultValue={null}
                                                        options={allDataDisciplina}
                                                        placeholder="Seleccione una opción"
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={!errors.disciplina ? customStyles : customStylesDanger}
                                                    />
                                                )}
                                                rules={{
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                }}
                                            />
                                            <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F00' }}>
                                                {!!errors.disciplina && errors.disciplina.message}
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <InputLabel htmlFor="codigoCaja" shrink sx={{ background: '#FFF', px: 1 }}>
                                                Código Caja
                                            </InputLabel>
                                            <Controller
                                                name="codigoCaja"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        isSearchable
                                                        defaultValue={null}
                                                        options={allDataCodigoCaja}
                                                        placeholder="Seleccione una opción"
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={!errors.codigoCaja ? customStyles : customStylesDanger}
                                                    />
                                                )}
                                                rules={{
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                }}
                                            />
                                            <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F00' }}>
                                                {!!errors.codigoCaja && errors.codigoCaja.message}
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <InputLabel htmlFor="ubicacion" shrink sx={{ background: '#FFF', px: 1 }}>
                                                Ubicación
                                            </InputLabel>
                                            <Controller
                                                name="ubicacion"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        isSearchable
                                                        defaultValue={null}
                                                        options={allDataUbicacion}
                                                        placeholder="Seleccione una opción"
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={!errors.ubicacion ? customStyles : customStylesDanger}
                                                    />
                                                )}
                                                rules={{
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                }}
                                            />
                                            <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F00' }}>
                                                {!!errors.ubicacion && errors.ubicacion.message}
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <TextField
                                                error={!!errors.monto}
                                                {...register('monto', { required: { value: true, message: 'Este campo es requerido' } })}
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                id="monto"
                                                label="Monto"
                                                InputProps={{
                                                    readOnly: opcion === 2
                                                }}
                                                helperText={!!errors.monto && errors.monto.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <TextField
                                                error={!!errors.porcentaje}
                                                {...register('porcentaje', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                id="porcentaje"
                                                label="Porcentaje"
                                                InputProps={{
                                                    readOnly: opcion === 2
                                                }}
                                                helperText={!!errors.porcentaje && errors.porcentaje.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <TextField
                                                error={!!errors.fechaIngreso}
                                                {...register('fechaIngreso', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                                size="small"
                                                id="outlined-error-helper-text"
                                                label="Fecha Ultima Acualización"
                                                helperText={!!errors.fechaIngreso && errors.fechaIngreso.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>Equipo No</Typography>
                                            <Controller
                                                control={control}
                                                name="equipo"
                                                defaultValue={false}
                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                    <Switch
                                                        name={name}
                                                        onBlur={onBlur} // notify when input is touched
                                                        onChange={onChange} // send value to hook form
                                                        checked={value}
                                                        inputRef={ref}
                                                    />
                                                )}
                                            />
                                            <Typography>Si</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <AnimateButton>
                                                <BootstrapTooltip title="Guardar" arrow placement="left">
                                                    <Button
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        sx={{
                                                            background: theme.palette.primary.dark,
                                                            '&:hover': {
                                                                background: theme.palette.primary.light
                                                            },
                                                            mt: 1
                                                        }}
                                                    >
                                                        <Add fontSize="medium" />
                                                    </Button>
                                                </BootstrapTooltip>
                                            </AnimateButton>

                                            {stateEdit === 1 && (
                                                <AnimateButton>
                                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                                        <Button
                                                            size="small"
                                                            type="button"
                                                            onClick={() => Cancel()}
                                                            variant="contained"
                                                            sx={{
                                                                background: theme.palette.error.dark,
                                                                '&:hover': {
                                                                    background: theme.palette.error.light
                                                                },
                                                                mt: 1
                                                            }}
                                                        >
                                                            <Block fontSize="medium" />
                                                        </Button>
                                                    </BootstrapTooltip>
                                                </AnimateButton>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ListCompensacion />
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
