import { useContext, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    IconButton,
    Stack
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { Add, Edit, Close } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { customStyles, customStylesDanger } from 'utils/select/index';
import { ContentContext } from '../context';

const DetalleExamenFisicoDeportivo = () => {
    const { allDataTipoExamenFisico, StoreUpdateDetalleExamenF, oneDataExamenF, allDataDetalleExamenFisicoDep, DestroyExamenF } =
        useContext(ContentContext);
    const [oneData, setOneData] = useState([]);
    const activeLabel = { shrink: true };
    const [normalChecked, setNormalChecked] = useState(false);

    const theme = useTheme();

    const [validarValoracion, setValidarValoracion] = useState(false);
    const One = (data) => {
        setOneData(data);
    };
    const columns2 = [
        {
            name: 'Tipo Examen Físico Deportivo',
            selector: (row) => row.tipoExamenFisico,
            width: '250px'
        },
        {
            name: 'Observaciones',
            selector: (row) => row.observacion
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <>
                    <Stack key={row.id} direction="row" spacing={2}>
                        <IconButton aria-label="actualizar" size="large" onClick={() => One(row)}>
                            <Edit />
                        </IconButton>
                        <IconButton aria-label="Eliminar" size="large" onClick={() => DestroyExamenF(row)}>
                            <Close />
                        </IconButton>
                    </Stack>
                </>
            )
        }
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control
    } = useForm();

    const TipoExamenFisico = watch('tipoExamenFisico');

    const setData = () => {
        setValue('tipoExamenFisico', null);
        setValue('observacion', '');
        setValue('normal', false);
    };

    const onSubmit = (data) => {
        const jsData = {
            observacion: data.observacion,
            tipoExamenFisico: data.tipoExamenFisico.value,
            examenFisico: oneDataExamenF.id,
            id: oneData?.id || null
        };

        StoreUpdateDetalleExamenF(jsData);
        setData();
    };

    const handleNormalChange = (event) => {
        setNormalChecked(event.target.checked);
        if (!event.target.checked) {
            setValue('observacion', '');
        } else {
            setValue('observacion', TipoExamenFisico.valoracion);
        }
    };

    useEffect(() => {
        if (TipoExamenFisico?.valoracion) {
            setValidarValoracion(true);
            setNormalChecked(false); // En lugar de document.getElementById('normal').checked = false;
        } else {
            setValidarValoracion(false);
            setNormalChecked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TipoExamenFisico]);

    useEffect(() => {
        if (oneData?.id) {
            setValue('observacion', oneData.observacion);
            console.log(oneData);
            console.log({ label: oneData.tipoExamenFisico, value: oneData.idTipoExamenFisico });
            setValue('tipoExamenFisico', {
                label: oneData.tipoExamenFisico,
                value: oneData.idTipoExamenFisico,
                valoracion: oneData.valoracion
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneData]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }}>
            <Typography sx={{ mb: 2 }} variant="h4">
                DETALLE EXAMEN FÍSICO DEPORTIVO
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="tipoExamenFisico" shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo Examen Físico Deportivo
                            </InputLabel>
                            <Controller
                                id="tipoExamenFisico"
                                control={control}
                                name="tipoExamenFisico"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoExamenFisico}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoExamenFisico ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoExamenFisico && errors.tipoExamenFisico.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.observacion}
                                {...register('observacion', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Observaciones"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.observacion && errors.observacion.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Controller
                                    name="normal"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={normalChecked} // Usa el estado aquí
                                            onChange={handleNormalChange} // Actualiza el estado cuando cambia el checkbox
                                            disabled={!validarValoracion}
                                        />
                                    )}
                                />
                            }
                            label="normal"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns2} data={allDataDetalleExamenFisicoDep} />
            </Box>
        </Box>
    );
};

export default DetalleExamenFisicoDeportivo;
