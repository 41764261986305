/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { Modal, Box, Grid, Typography, Divider } from '@mui/material';
import MainCard from 'utils/components/modal/MainCardModal';
import { ContentContext } from './context';

function DataSection({ label, value }) {
    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} className="css-15j76c0">
                <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                    {label}
                </Typography>
                <Typography variant="h4" className="css-17o47au">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default function KeepMountedModal() {
    const { show, toggleModal, oneData, allDataDetalle } = useContext(ContentContext);

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate sx={{ mt: 1 }} spacing={2}>
                    <MainCard title={`CITA CON FECHA ${oneData?.start}`} sx={{ py: 2 }} closeButton={() => toggleModal(0)}>
                        <DataSection label="Federación" value={oneData?.deporte} />
                        <DataSection label="Tipo Atención" value={oneData?.tipoAtencion} />
                        <DataSection label="Solicitante" value={oneData?.solicitante} />
                        <DataSection label="Objetivo de la Evaluación solicitada" value={oneData?.motivo} />

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} className="css-15j76c0">
                                <Typography variant="caption" className="css-10963q2">
                                    <Divider>Atletas</Divider>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={2} className="css-15j76c0">
                                <Typography variant="h4">No.</Typography>
                            </Grid>
                            <Grid item xs={10} className="css-15j76c0">
                                <Typography variant="h4">Atleta</Typography>
                            </Grid>
                        </Grid>
                        {allDataDetalle.length > 0 ? (
                            allDataDetalle.map((item, index) => (
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={2} className="css-15j76c0">
                                        {index + 1}
                                    </Grid>
                                    <Grid item xs={10} className="css-15j76c0">
                                        {item.nombreCompleto}
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <div>Sin atletas asignados</div>
                        )}
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
