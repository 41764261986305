import { Routes, Route, Navigate } from 'react-router-dom';
import Eventos from '../../views/resultados/eventos';
import Record from '../../views/resultados/record';
import Resultados from '../../views/resultados/resultados';

const ResultsRoutes = () => (
    <Routes>
        <Route path="evento" element={<Eventos />} />
        <Route path="record" element={<Record />} />
        <Route path="resultado" element={<Resultados />} />
        <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
);

export default ResultsRoutes;
