/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { createContext, useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { GetRoute } from 'services/Private';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    let data;
    const [connection, setConnection] = useState(null);
    const [dataAtleta, setDataAtleta] = useState([]);
    const [listado, setListado] = useState([]);

    const AllCarrousel = async () => {
        const response = await GetRoute(`public/all`);
        setListado(response.length ? response : []);
    };

    useEffect(() => {
        // const newConnection = new signalR.HubConnectionBuilder()
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_ROUTE_BASE_NOTIFICATION}/llamar-atleta`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
        AllCarrousel();
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    connection.on('notification', (respuesta) => {
                        setDataAtleta(respuesta);
                    });
                })
                .catch((e) => e);
        }
    }, [connection]);

    const value = { data, dataAtleta, listado };
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
