import colors from 'assets/scss/_themes-vars.module.scss';

export default function themeTable() {
    return {
        text: {
            primary: colors.primaryLight,
            secondary: colors.darkTextSecondary
        },
        background: {
            default: colors.darkPaper
        },
        context: {
            background: '#F00',
            text: '#FFFFFF'
        },
        divider: {
            default: '#90caf975'
        },
        button: {
            default: '#2aa198',
            hover: 'rgba(0,0,0,.08)',
            focus: 'rgba(255,255,255,.12)',
            disabled: 'rgba(255, 255, 255, .34)',
            text: '#FFFFFF !important'
        },
        sortFocus: {
            default: colors.darkBackground
        },
        selected: {
            default: 'rgba(0, 0, 0, .7)',
            text: '#FFFFFF'
        },
        highlightOnHover: {
            default: colors.darkBackground,
            text: colors.darkTextPrimary
        },
        striped: {
            default: 'rgba(0, 0, 0, .87)',
            text: '#FFFFFF'
        }
    };
}
