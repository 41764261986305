/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useContext, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Grid, FormControl, TextField, Avatar, InputLabel } from '@mui/material';
import { IconX, IconPhoto } from '@tabler/icons';
import { useForm, Controller } from 'react-hook-form';
import { customStyles, customStylesDanger } from 'utils/select/index';
import Select from 'react-select';
import { ContentContext } from '../context';

const steps = ['Datos Personales', 'Datos de Contacto'];

export default function HorizontalLinearStepper() {
    const {
        opcion,
        oneData,
        StoreUpdate,
        ImgSuccess,
        toggleModal,
        allDataGenero,
        allDataEstadoCivil,
        allDataPais,
        allDataDepartamento,
        AllMunicipio,
        allDataMunicipio
    } = useContext(ContentContext);
    const [validadSelect, setValidarSelect] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [Visualizar, setVisualizar] = useState('');
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
        control,
        clearErrors
    } = useForm();
    const selectPais = watch('pais');
    const selectDepartamento = watch('departamento');
    const isStepSkipped = (step) => skipped.has(step);

    const clearFoto = () => {
        setVisualizar('');
        setValue('foto', null);
        clearErrors('foto');
    };

    const resetData = () => {
        reset({
            nombres: '',
            apellidos: '',
            fechaNacimiento: '',
            estadoCivil: '',
            genero: '',
            pais: '',
            nacionalidad: '',
            municipio: '',
            departamento: '',
            dpi: '',
            telefono: '',
            email: ''
        });
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        clearFoto();
        resetData();
    };
    const MostrarDoc = (value) => {
        if (value.target.value) {
            const pdffile = value.target.files[0];
            const pdffileUrl = URL.createObjectURL(pdffile);
            setVisualizar(`${pdffileUrl}`);
        } else {
            setVisualizar('');
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('nombres', data.nombres);
        formData.append('apellidos', data.apellidos);
        formData.append('fechaNacimiento', data.fechaNacimiento);
        formData.append('genero', data.genero.value);
        formData.append('estadoCivil', data.estadoCivil.value);
        formData.append('municipio', data.municipio.value);
        formData.append('tipoPersona', 2);
        formData.append('foto', data.foto[0]);
        formData.append('dpi', data.dpi);
        formData.append('telefono', data.telefono);
        formData.append('email', data.email);
        formData.append('direccion', data.direccion);
        formData.append('pais', data.pais.value);
        const response = await StoreUpdate(formData, !oneData.id ? 'store/operador' : 'update/operador');
        response.value === 1 && handleNext();
        response.value === 1 && clearFoto();
    };
    const setData = () => {
        setValue('nombres', oneData.nombres);
        setValue('apellidos', oneData.apellidos);
        setValue('dpi', oneData.dpi);
    };
    useEffect(() => {
        if (selectPais) {
            setValue('nacionalidad', selectPais.nacionalidad);
            setValidarSelect(true);
        } else {
            setValue('nacionalidad', '');
            setValidarSelect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPais]);

    useEffect(() => {
        if (selectDepartamento) {
            AllMunicipio(selectDepartamento);
        } else {
            setValue('municipio', null);
            AllMunicipio(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDepartamento]);

    useEffect(() => {
        if (opcion === 0) {
            setActiveStep(0);
        }
        if (opcion > 1) {
            setData();
            // setActiveLabel({ shrink: true });
        }
        if (opcion === 1) {
            resetData();
            // setActiveLabel({ shrink: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={3} sm={12} xs={12} sx={{ mt: 10 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                            <div className={`kt-avatar kt-avatar--outline ${Visualizar && 'kt-avatar--changed'}`} id="kt_user_avatar">
                                <div
                                    id="logo-size"
                                    className={`kt-avatar__holder ${errors && errors.foto && errors.foto.message && 'border-danger'}`}
                                    style={{ backgroundImage: `url('${Visualizar}')` }}
                                >
                                    <label
                                        className="kt-avatar__upload p-0 d-flex"
                                        style={activeStep < 3 ? { display: 'flex' } : { display: 'none' }}
                                        data-toggle="kt-tooltip"
                                        title="Cargar Foto"
                                        data-original-title="Change avatar"
                                    >
                                        {activeStep < 3 && <IconPhoto stroke={1.5} size="1.3rem" />}
                                        <input
                                            {...register('foto', {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            })}
                                            lang="es"
                                            type="file"
                                            className="fileInput"
                                            accept=".png"
                                            onChange={(e) => MostrarDoc(e)}
                                        />
                                    </label>
                                </div>
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                {activeStep < 3 && (
                                    <span className="kt-avatar__cancel" onClick={() => clearFoto()}>
                                        <IconX stroke={1.5} size="1.3rem" />
                                    </span>
                                )}

                                {/* {Visualizar && (
                                    <IconButton aria-label="fingerprint" color="success" className="kt-avatar__save" type="submit">
                                        <IconDeviceFloppy stroke={1.5} size="1.3rem" />
                                    </IconButton>
                                )} */}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center">
                                <small className="text-muted text-danger">{!!errors.foto && errors.foto.message}</small>
                            </Typography>
                            <Typography align="center">
                                <small className="text-muted">Permitido *.png</small>
                            </Typography>
                            <Typography align="center">
                                <small className="text-muted">tamaño máximo de 10 MB</small>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={9} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ pb: 5 }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.nombres}
                                        InputLabelProps={activeLabel}
                                        {...register('nombres', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Nombres"
                                        helperText={!!errors.nombres && errors.nombres.message}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.apellidos}
                                        InputLabelProps={activeLabel}
                                        {...register('apellidos', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Apellidos"
                                        helperText={!!errors.apellidos && errors.apellidos.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.fechaNacimiento}
                                        InputLabelProps={{ shrink: true }}
                                        {...register('fechaNacimiento', {
                                            required: { value: true, message: 'Este campo es requerido' }
                                        })}
                                        type="date"
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Fecha Nacimiento"
                                        helperText={!!errors.fechaNacimiento && errors.fechaNacimiento.message}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="estadoCivil" shrink sx={{ background: '#FFF', px: 1 }}>
                                        Estado Civil
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name="estadoCivil"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                options={allDataEstadoCivil}
                                                placeholder="Seleccione Estado Civil"
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={!errors.estadoCivil ? customStyles : customStylesDanger}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                        {!!errors.estadoCivil && errors.estadoCivil.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="genero" shrink sx={{ background: '#FFF', px: 1 }}>
                                        Genero
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name="genero"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                options={allDataGenero}
                                                placeholder="Seleccione Genero"
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={!errors.genero ? customStyles : customStylesDanger}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                        {!!errors.genero && errors.genero.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.dpi}
                                        InputLabelProps={activeLabel}
                                        {...register('dpi', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="DPI"
                                        helperText={!!errors.dpi && errors.dpi.message}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="pais" shrink sx={{ background: '#FFF', px: 1 }}>
                                        País
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name="pais"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                options={allDataPais}
                                                placeholder="Seleccione País"
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={!errors.pais ? customStyles : customStylesDanger}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                        {!!errors.pais && errors.pais.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.nacionalidad}
                                        InputLabelProps={activeLabel}
                                        {...register('nacionalidad', {
                                            required: { value: false, message: 'Este campo es requerido' }
                                        })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Nacionalidad"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        focused={validadSelect}
                                        helperText={!!errors.nacionalidad && errors.nacionalidad.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Datos de Contacto */}
                    <Grid container spacing={2} sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.telefono}
                                        InputLabelProps={activeLabel}
                                        {...register('telefono', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Teléfono"
                                        helperText={!!errors.telefono && errors.telefono.message}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.email}
                                        InputLabelProps={activeLabel}
                                        {...register('email', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Correo Electrónico"
                                        helperText={!!errors.email && errors.email.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="departamento" shrink sx={{ background: '#FFF', px: 1 }}>
                                        Departamento
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name="departamento"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                options={allDataDepartamento}
                                                placeholder="Seleccione Departamento"
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={!errors.departamento ? customStyles : customStylesDanger}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                        {!!errors.departamento && errors.departamento.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="municipio" shrink sx={{ background: '#FFF', px: 1 }}>
                                        Municipio
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name="municipio"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                options={allDataMunicipio}
                                                placeholder="Seleccione Municipio"
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={!errors.municipio ? customStyles : customStylesDanger}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                        {!!errors.municipio && errors.municipio.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <TextField
                                        error={!!errors.direccion}
                                        InputLabelProps={activeLabel}
                                        {...register('direccion', { required: { value: true, message: 'Este campo es requerido' } })}
                                        size="small"
                                        id="outlined-error-helper-text"
                                        label="Dirección"
                                        helperText={!!errors.direccion && errors.direccion.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    {activeStep === steps.length ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar sx={{ m: 1, height: 230, width: 330, background: 'none' }} variant="square" src={ImgSuccess} />
                                    <Typography variant="h2" sx={{ mt: 2, mb: 1 }}>
                                        Operador {opcion === 1 ? ' Creado ' : ' Actualizado '} Exitosamente
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                {/* <Button type="button" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                    Volver
                                </Button> */}
                                <Button
                                    type="button"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={() => toggleModal(0)}
                                    sx={{ mr: 1 }}
                                >
                                    Salir
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button type="button" onClick={handleReset}>
                                    Nuevo
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button type="button" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                    Volver
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {activeStep < 1 && (
                                    <Button type="button" onClick={handleNext} sx={{ mr: 1 }}>
                                        Siguiente
                                    </Button>
                                )}
                                {activeStep === 1 && opcion !== 3 && <Button type="submit">Guardar</Button>}
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
