/* eslint-disable no-plusplus */
import { useState, useContext, useEffect } from 'react';
import { ContentContext } from './context';
import { useTheme } from '@mui/material/styles';
import {
    Grid,
    Card,
    Avatar,
    CardHeader,
    CardContent,
    Typography,
    Divider,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormGroup,
    Button
} from '@mui/material';
import Send from 'assets/images/png/send.png';
import Reset from 'assets/images/png/reset-password.png';

const List = () => {
    const theme = useTheme();
    const { setMisRoles, sendRolesUsuario, misRoles, roles, showRoles, dataUser, rolUsuario, defaultPassword, sendCredencialesUsuario } =
        useContext(ContentContext);
    const [count, setCount] = useState(false);
    const [verificarCheck, setVerficarCheck] = useState(false);
    const [itemData, setItemData] = useState([]);
    const Chekear = (e, item) => {
        const Rol = e.target;
        if (Rol.checked) {
            setMisRoles([...misRoles, String(Rol.value)]);
            setVerficarCheck(true);
            setItemData({ ...item, estado: 1 });
        } else {
            const r = misRoles;
            for (let i = 0; i < r.length; i++) {
                if (r[i] === Rol.value) {
                    r.splice(i, 1);
                }
            }
            setItemData({ ...item, estado: 0 });
            setMisRoles([...r]);
            setVerficarCheck(false);
        }
        setCount(true);
    };
    useEffect(() => {
        if (count) {
            sendRolesUsuario(misRoles, verificarCheck, itemData);
            setCount(!count);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, verificarCheck]);
    return (
        <div style={{ marginTop: '50px' }}>
            {showRoles ? (
                <Grid container spacing={2}>
                    <Grid item noValidate lg={6} md={6} xl={6}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant="h3">
                                        Preferencias
                                        <br />
                                        Usuario: <small>{dataUser.username}</small>
                                    </Typography>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
                                    <FormGroup>
                                        {roles.map((rol, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={String(rol.codigo)}
                                                control={
                                                    <Checkbox
                                                        name="rol"
                                                        checked={rolUsuario.includes(String(rol.codigo))}
                                                        onChange={(e) => {
                                                            Chekear(e, rol);
                                                        }}
                                                    />
                                                }
                                                label={rol.nombre}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item noValidate lg={6} md={6} xl={6}>
                        <Grid item noValidate lg={12} md={12} xl={12} sx={{ mb: 2 }}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item noValidate lg={4} md={4} xl={6}>
                                            <Avatar
                                                sx={{ bgcolor: 'transparent', m: 1, height: 100, width: 100 }}
                                                variant="square"
                                                src={Send}
                                            />
                                        </Grid>
                                        <Grid item noValidate lg={8} md={8} xl={6}>
                                            <Button
                                                variant="contained"
                                                sx={{ ml: 2, py: 1, background: theme.palette.info }}
                                                onClick={() => sendCredencialesUsuario()}
                                            >
                                                Enviar Email
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item noValidate lg={12} md={12} xl={12} sx={{ mb: 2 }}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item noValidate lg={4} md={4} xl={6}>
                                            <Avatar
                                                sx={{ bgcolor: 'transparent', m: 1, height: 100, width: 100 }}
                                                variant="square"
                                                src={Reset}
                                            />
                                        </Grid>
                                        <Grid item noValidate lg={8} md={8} xl={6}>
                                            <Button
                                                variant="contained"
                                                sx={{ ml: 2, py: 1, background: theme.palette.info }}
                                                onClick={() => defaultPassword()}
                                            >
                                                Restablecer Contraseña
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item noValidate lg={12} md={12} xl={12}>
                    <Card>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">Seleccione una persona para asignar roles</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </div>
    );
};

export default List;
