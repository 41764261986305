import { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Header from './header';
import Detalle from './detalle';
import Formulario from './form';
import Historial from './historial';

const ExamenFisicoDeportivo = () => {
    const { dataVal, oneDataFichaPsicologica, OneFichaPsicologica, AllFichaPsicologica } = useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);

    useEffect(() => {
        if (dataVal === 5) {
            OneFichaPsicologica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);

    useEffect(() => {
        if (dataVal2 === 1) {
            AllFichaPsicologica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal2]);

    if (dataVal !== 5) return null;

    return (
        <>
            <Tabs value={dataVal2} onChange={(event, newValue) => setDataVal2(newValue)}>
                <Tab label="Formulario" />
                <Tab label="Historial" />
            </Tabs>
            {dataVal2 === 0 ? (
                <>
                    <Formulario />
                    {oneDataFichaPsicologica?.id && (
                        <>
                            <Header />
                            <Detalle />
                        </>
                    )}
                </>
            ) : (
                dataVal2 === 1 && <Historial />
            )}
        </>
    );
};

export default ExamenFisicoDeportivo;
