/* eslint-disable react/prop-types */
import { IconButton, Box, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import BootstrapTooltip from 'utils/components/tooltip/index';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ModalPdf from 'ui-component/templatePdf/visor';
import { generatePDF } from './templatePdf';
import { UserContext } from 'utils/context/userContext';
import { PostRoute } from '../../../../../services/Private';

const ExportPDF = ({ data, nameFile }) => {
    const { userAuth } = useContext(UserContext);
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState([]);
    const OnePruebasPsicologicasAplicadas = async () => {
        const response = await PostRoute(`valoraciones/one`, { valoracionFisioterapeutica: data.row.id });
        const response2 = await PostRoute(`pruebaPostural/one-prueba`, { valoracionFisioterapeutica: data.row.id });
        return {
            valoraciones: response.length === 1 ? response[0] : [],
            pruebaPostural: response2.length === 1 ? response2[0] : [],
            ...data
        };
    };

    const showModal = async () => {
        const response = await OnePruebasPsicologicasAplicadas(data);
        const doc = await generatePDF('VALORACIÓN FISIOTERAPÉUTICA', '', nameFile, response, userAuth.nombreCompleto);
        setPdf(doc);
        setShow(true);
    };

    return (
        <Box>
            <BootstrapTooltip title="Exportar PDF" arrow placement="left">
                <IconButton
                    onClick={showModal}
                    aria-label="Exportar"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.error.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark
                        }
                    }}
                >
                    <PictureAsPdf fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <ModalPdf showPdf={show} setShowPdf={setShow} title="Visualizar" pdf={pdf} />
        </Box>
    );
};

export default ExportPDF;
