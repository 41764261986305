// material-ui
import { Grid, Box, Typography } from '@mui/material';
import ContainerCustom from 'ui-component/container/index';
import PieChart from 'utils/charts/pie/index';
import { PostRoute } from 'services/Private';
// import BarChart from 'utils/charts/barChart/index';
// import LineChart from 'utils/charts/lineChart/index';
import { useEffect, useState } from 'react';

const Index = () => {
    const [totalTipoPersona, setTotalTipoPersona] = useState([[], []]);
    const [totalGenero, setTotalGenero] = useState([[], []]);
    const [totalPersonaFede, setTotalPersonaFede] = useState([[], []]);
    const totalTipoPersonas = async () => {
        const response = await PostRoute(`dashboard/total-persona-tipo-persona`);
        let cantidad = [];
        let label = [];
        cantidad = response.map((item) => item.cantidad);
        label = response.map((item) => item.tipoPersona);
        setTotalTipoPersona([cantidad, label]);
    };

    const totalGeneros = async () => {
        const response = await PostRoute(`dashboard/total-persona-genero`);
        let cantidad = [];
        let label = [];
        cantidad = response.map((item) => item.cantidad);
        label = response.map((item) => item.genero);
        setTotalGenero([cantidad, label]);
    };

    const totalPersonaFederacion = async () => {
        const response = await PostRoute(`dashboard/total-persona-federacion`);
        let cantidad = [];
        let label = [];
        cantidad = response.map((item) => item.cantidad);
        label = response.map((item) => item.federacion);
        setTotalPersonaFede([cantidad, label]);
    };

    useEffect(() => {
        totalPersonaFederacion();
        totalTipoPersonas();
        totalGeneros();
    }, []);

    return (
        <Box spacing={2} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12} align="center">
                    <ContainerCustom>
                        <Grid item lg={12} xs={12}>
                            <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                Total de Personas por Genero
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} align="center">
                            <PieChart data={totalGenero} />
                        </Grid>
                    </ContainerCustom>
                </Grid>
                <Grid item lg={6} xs={12} align="center">
                    <ContainerCustom>
                        <Grid item lg={12} xs={12}>
                            <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                Total de Personas por Federación
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} align="center">
                            <PieChart data={totalPersonaFede} />
                        </Grid>
                    </ContainerCustom>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12} align="center" sx={{ mt: 2 }}>
                    <ContainerCustom>
                        <Grid item lg={12} xs={12}>
                            <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                Total de Personas por Tipo
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} align="center">
                            <PieChart data={totalTipoPersona} />
                        </Grid>
                    </ContainerCustom>
                </Grid>
                {/* <Grid item lg={6} xs={12} align="center" sx={{ mt: 2 }}>
                    <ContainerCustom>
                        <Grid item lg={12} xs={12}>
                            <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                Total de Personas por Federación
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} align="center">
                            <PieChart data={totalPersonaFede} />
                        </Grid>
                    </ContainerCustom>
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default Index;
