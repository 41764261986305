/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Stack, Grid, FormControl, InputLabel, IconButton } from '@mui/material';
import { IconPrinter, IconEye } from '@tabler/icons';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { customStyles } from 'utils/select/index';
import { ContentContext } from './context';
import Select from 'react-select';

const List = () => {
    const { One, allData, allDataFederacion, AllPersona } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const {
        formState: { errors },
        control,
        watch
    } = useForm();
    const federacion = watch('federacion');
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombreCompleto.toLowerCase().startsWith(value.toLowerCase());
                const includes = item.nombreCompleto.toLowerCase().includes(value.toLowerCase());
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Nombre Completo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombreCompleto)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => One(row, 1)}>
                        <IconEye />
                    </IconButton>
                    <IconButton aria-label="actualizar" size="large" onClick={() => One(row, 2)}>
                        <IconPrinter />
                    </IconButton>
                </Stack>
            )
        }
    ];

    useEffect(() => {
        if (federacion) {
            AllPersona(federacion.value);
        } else {
            AllPersona(null);
        }
    }, [federacion]);
    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', ml: 0 }} spacing={2}>
                <Grid item xs={4}>
                    <TextField label="Buscar" size="small" sx={{ width: '100%' }} value={searchValue} onChange={handleFilter} />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel htmlFor="federacion" shrink sx={{ background: '#FFF', px: 1 }}>
                            FADN
                        </InputLabel>
                        <Controller
                            name="federacion"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable
                                    defaultValue={null}
                                    options={allDataFederacion}
                                    placeholder="Seleccione una opción"
                                    noOptionsMessage={() => 'sin resultados'}
                                    styles={{ ...customStyles }}
                                />
                            )}
                        />
                        {!!errors.federacion && errors.federacion.message}
                    </FormControl>
                </Grid>
            </Grid>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
