// material-ui
import { Grid, Box, Typography } from '@mui/material';
import ContainerCustom from 'ui-component/container/index';
import { ContentContext } from '../context';
import CuerpoHumano from 'assets/images/cuerpo.png';
import CirculoRojo from 'assets/images/circuloRojo.png';
import { useEffect, useState, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CustomLineChart from './customLineChart';

const Index = () => {
    const {
        code,
        solicitud,
        dataVal,
        dataPartesAfectadasDash,
        allDataHistorialPeso,
        AllPruebaPostural,
        anioActual,
        HistorialPesos,
        HistorialImc,
        allDataImc,
        HistorialGrasaCorporal,
        HistorialGrasa,
        HistorialPesoMusculo,
        allDataHistorialPesoMusculo,
        allDataHistorialGrasaCorporal,
        allDataHistorialGrasa
    } = useContext(ContentContext);
    const [dataPeso, setDataPeso] = useState([[], []]);
    const [dataImc, setDataImc] = useState([[], []]);
    const [dataPesoGrasa, setDataPesoGrasa] = useState([[], []]);
    const [dataGrasa, setDataGrasa] = useState([[], []]);
    const [dataPesoMusculo, setDataPesoMusculo] = useState([[], []]);

    const obtenerPesos = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.peso);
        label = items.map((item) => item.fecha);
        setDataPeso([cantidad, label]);
    };

    const totalImc = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.valor);
        label = items.map((item) => item.fecha);
        setDataImc([cantidad, label]);
    };

    const totalPesoGrasa = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.valor);
        label = items.map((item) => item.fecha);
        setDataPesoGrasa([cantidad, label]);
    };

    const totalGrasa = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.valor);
        label = items.map((item) => item.fecha);
        setDataGrasa([cantidad, label]);
    };

    const totalPesoMusculo = async (items) => {
        let cantidad = [];
        let label = [];
        cantidad = items.map((item) => item.valor);
        label = items.map((item) => item.fecha);
        setDataPesoMusculo([cantidad, label]);
    };

    useEffect(() => {
        obtenerPesos(allDataHistorialPeso);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataHistorialPeso]);

    useEffect(() => {
        totalImc(allDataImc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataImc]);

    useEffect(() => {
        totalPesoGrasa(allDataHistorialGrasaCorporal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataHistorialGrasaCorporal]);

    useEffect(() => {
        totalGrasa(allDataHistorialGrasa);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataHistorialGrasa]);

    useEffect(() => {
        totalPesoMusculo(allDataHistorialPesoMusculo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataHistorialPesoMusculo]);

    useEffect(() => {
        if (dataVal === 0) {
            AllPruebaPostural(1, 0, 1);
            HistorialPesos(anioActual);
            HistorialImc(anioActual);
            HistorialGrasaCorporal(anioActual);
            HistorialGrasa(anioActual);
            HistorialPesoMusculo(anioActual);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, solicitud, dataVal]);

    return (
        dataVal === 0 && (
            <Box spacing={2} sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Grid item lg={12} xs={12}>
                            <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                Partes del Cuerpo Afectadas
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ height: 800, width: 500, position: 'relative' }}>
                                <img src={CuerpoHumano} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} xs={12} align="center">
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                                <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                                    Listado de partes del cuerpo afectadas
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item lg={12} xs={12}>
                                <Box sx={{ textAlign: 'justify' }}>
                                    <Typography variant="body" component="div">
                                        A continuación, se enumeran de manera detallada las áreas del cuerpo que se ven afectadas.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item lg={12} xs={12}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {JSON.parse(dataPartesAfectadasDash?.valores || '[]').length > 0 ? (
                                        JSON.parse(dataPartesAfectadasDash?.valores || '[]').map((value, index) => (
                                            <ListItem key={index} disableGutters>
                                                <img
                                                    src={CirculoRojo}
                                                    alt="Cuerpo Humano"
                                                    style={{ height: '20px', width: '20px', marginRight: '15px' }}
                                                />
                                                <span>{value}</span>
                                            </ListItem>
                                        ))
                                    ) : (
                                        <>No se encontraron registros</>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                        <ContainerCustom>
                            <CustomLineChart
                                titulo="Peso"
                                anioActual={anioActual}
                                label={dataPeso[1]}
                                data={dataPeso[0]}
                                funcion={HistorialPesos}
                            />
                        </ContainerCustom>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12} align="center">
                        <CustomLineChart titulo="IMC" anioActual={anioActual} label={dataImc[1]} data={dataImc[0]} funcion={HistorialImc} />
                    </Grid>
                    <Grid item lg={6} xs={12} align="center">
                        <CustomLineChart titulo="Peso Grasa (%)" anioActual={anioActual} label={dataPesoGrasa[1]} data={dataPesoGrasa[0]} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12} align="center">
                        <CustomLineChart titulo="% Grasa" anioActual={anioActual} label={dataGrasa[1]} data={dataGrasa[0]} />
                    </Grid>
                    <Grid item lg={6} xs={12} align="center">
                        <CustomLineChart
                            titulo="Peso Musculo (kg)"
                            anioActual={anioActual}
                            label={dataPesoMusculo[1]}
                            data={dataPesoMusculo[0]}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    );
};

export default Index;
