import { useContext, useEffect } from 'react';
import { Box, Button, Grid, FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ContentContext } from '../context';
import UploadFile from 'utils/upload/index';

const ConfigInstitucion = () => {
    const { StoreUpdate, oneData } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('nombre', data.nombre);
        formData.append('telefono', data.telefono);
        formData.append('email', data.email);
        formData.append('direccion', data.direccion);
        formData.append('abreviatura', data.abreviatura);
        formData.append('id', oneData.id);
        StoreUpdate(formData, oneData.id ? 'update' : 'store');
    };
    const setData = () => {
        setValue('nombre', oneData.nombre);
        setValue('abreviatura', oneData.abreviatura);
        setValue('telefono', oneData.telefono);
        setValue('email', oneData.email);
        setValue('direccion', oneData.direccion);
    };
    useEffect(() => {
        if (oneData.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneData]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <UploadFile oneData={oneData} />
            </Grid>
            <Grid item xs={9}>
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    error={!!errors.nombre}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('nombre', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="outlined-error-helper-text"
                                    label="Nombre"
                                    helperText={!!errors.nombre && errors.nombre.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    error={!!errors.abreviatura}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('abreviatura', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="outlined-error-helper-text"
                                    label="Abreviatura"
                                    helperText={!!errors.abreviatura && errors.abreviatura.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    error={!!errors.telefono}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('telefono', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="outlined-error-helper-text"
                                    label="Teléfono"
                                    helperText={!!errors.telefono && errors.telefono.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    error={!!errors.email}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('email', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="outlined-error-helper-text"
                                    label="Correo Electrónico"
                                    helperText={!!errors.email && errors.email.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    error={!!errors.direccion}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('direccion', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="outlined-error-helper-text"
                                    label="Dirección"
                                    helperText={!!errors.direccion && errors.direccion.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                            <Button type="submit" variant="contained" size="small">
                                Actualizar Datos
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ConfigInstitucion;
