/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';

export function generatePDF(filas, columnas, titulo, subTitulo, nombrePdf, base64, styleColumn, UserAut) {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: nombrePdf });
    const totalPagesExp = '{total_pages_count_string}';
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let str = null;
    const hoy = new Date();

    function pageContent(data) {
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold');
        doc.text(titulo, 300, 50, { width: 800, align: 'center' });
        doc.setFont('times', 'bold');
        doc.text(subTitulo, 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Fecha'}: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, 100, {
            width: 800,
            align: 'left'
        });
        doc.setFont('times', 'normal');
        doc.setFontSize(10);
        doc.text(`${'Impreso por'}: ${UserAut}`, 300, 100, { width: 800, align: 'center' });

        // Footer
        str = `Pagina ${doc.internal.getNumberOfPages()}`;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            str = `${str} de ${totalPagesExp}`;
        }
        doc.setFont('times', 'normal');
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        doc.text(str, data.settings.margin.left, pageHeight - 20);
    }

    doc.autoTable({
        head: columnas,
        body: filas,
        tableWidth: 'auto',
        columnStyles: {
            cellWidth: 'auto',
            ...styleColumn
        },
        headStyles: {
            fillColor: [7, 167, 227],
            textColor: [255],
            valign: 'middle',
            halign: 'center'
        },
        styles: {
            fontSize: 10,
            fillColor: [233, 233, 240],
            lineWidth: 0,
            lineColor: 48,
            textColor: 20,
            pageBreak: 'auto'
        },
        didDrawPage: pageContent,
        margin: {
            left: 70.8661,
            right: 70.8661,
            top: 110,
            bottom: 50
        }
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }
    if (filas.length > 1000) {
        doc.save(`Reporte.pdf`);
        return '';
    }
    return doc.output('datauristring');

    // doc.save(`${nombrePdf}.pdf`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { generatePDF };
