import { useContext, useState } from 'react';
import { Estados, CustomText } from 'utils/components/customTable/index';
import { Box, TextField, Container } from '@mui/material';
import ExportPdf from '../export/fichaPsicologica/modal';
import DataTable from 'react-data-table-component';
import { ContentContext } from '../context';

const List = () => {
    const { allDataFichaPsicologica, user } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allDataFichaPsicologica.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Fecha',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.fecha)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => Estados(row.estado)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => <ExportPdf data={{ row, user }} nameFile="FICHA PSICOLOGICA" />
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
            </Box>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allDataFichaPsicologica}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
