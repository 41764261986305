/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from 'services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { appendData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';
import { findData } from 'utils/function/global';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [dateSelect, setDateSelect] = useState([]);
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDataDetalle, setAllDataDetalle] = useState([]);
    const [allDataAtleta, setAllDataAtleta] = useState([]);
    const [allDataTipoAtencion, setAllDataTipoAtencion] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [resetData, setResetData] = useState(false);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'solicitud';
    const nameController2 = 'tipoAtencion';
    const nameController3 = 'persona';
    const nameController4 = 'detalleSolicitud';
    const All = async () => {
        const response = await GetRoute(`${nameController}/all`);
        setAllData(response.length ? response : []);
    };
    const AllDetalleSolicitud = async (data) => {
        const response = await PostRoute(`${nameController4}/all`, { solicitud: data });
        setAllDataDetalle(response.length ? response : []);
    };
    const AllTipoAtencion = async () => {
        const response = await GetRoute(`${nameController2}/label`);
        setAllDataTipoAtencion(response.length ? response : []);
    };
    const AllAtleta = async () => {
        const response = await PostRoute(`${nameController3}/label-atleta`);
        setAllDataAtleta(response.length ? response : []);
    };
    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setResetData(true);
            setOneData([]);
        }
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        // eslint-disable-next-line no-underscore-dangle
        const response = await PostRoute(`${nameController}/one`, { id: data._def.publicId });
        // eslint-disable-next-line no-underscore-dangle
        AllDetalleSolicitud(data._def.publicId);
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data, dateSelect) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data);
        setResetData(false);
        if (response?.value === 1 && opcion === 1) {
            const calendarApi = dateSelect.view.calendar;
            calendarApi.unselect(); // clear date selection
            if (response?.value === 1) {
                calendarApi.addEvent(response.data[0]);
                setOneData(response.data[0]);
                setAllDataDetalle([]);
            }
        }

        if (opcion === 3) {
            All();
        }
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const Actions = async (data, state) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/state`, { id: data.id, estado: state });
        All();
        Notification(response.message, 1);
        toggleModal(0);
        setShowLoad(false);
    };

    const StoreUpdateDetalle = async (data) => {
        setShowLoad(true);
        if (findData(allDataDetalle, data, 'nombreCompleto').length === 0) {
            // eslint-disable-next-line no-underscore-dangle
            const response = await PostRoute(`${nameController4}/store`, data);
            setAllDataDetalle(appendData(allDataDetalle, data, { id: response.id, nombreCompleto: data.nombreCompleto }));
        } else {
            Notification('El atleta ya se encuentra registrado', 0);
        }
        setShowLoad(false);
    };

    const ActionsDetalle = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController4}/destroy`, { id: data.id });
        AllDetalleSolicitud(data.solicitud);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = {
        All,
        One,
        Actions,
        setAllData,
        setOpcion,
        StoreUpdate,
        toggleModal,
        setDateSelect,
        ActionsDetalle,
        StoreUpdateDetalle,
        allDataTipoAtencion,
        allDataDetalle,
        allDataAtleta,
        dateSelect,
        resetData,
        allData,
        oneData,
        opcion,
        show
    };
    useEffect(() => {
        All();
        AllAtleta();
        AllTipoAtencion();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
