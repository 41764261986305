import { useContext, useEffect } from 'react';
import { ContentContext } from '../context';
import Header from './header';
import Detalle from './detalle';

const ExamenFisicoDeportivo = () => {
    const { dataVal, OneExamenF, code, solicitud } = useContext(ContentContext);

    useEffect(() => {
        if (dataVal === 2) {
            OneExamenF(code, solicitud);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal, code, solicitud]);

    return (
        dataVal === 2 && (
            <>
                <Header />
                <Detalle />
            </>
        )
    );
};

export default ExamenFisicoDeportivo;
