import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, IconButton, Stack } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Add, Edit, Block } from '@mui/icons-material';
import Select from 'react-select';

const Index = () => {
    const {
        allDataSeccionCineantropometria,
        StoreUpdateCineantropometria,
        // Notification,
        TipoCineantropometria,
        allDataCineantropometria,
        allDataTipoCineantropometria,
        oneDataNutricionCineantropometria
    } = useContext(ContentContext);
    const [oneDataCinenatropometria, setOneDataCineantropometria] = useState([]);
    const One = (data) => {
        setOneDataCineantropometria(data);
    };

    const columns2 = [
        {
            name: 'Sección Cineantropometría',
            selector: (row) => row.seccion,
            width: '250px'
        },
        {
            name: 'Tipo Cineantropometría',
            selector: (row) => row.tipoCineantropometria,
            width: '250px'
        },
        {
            name: 'Valor',
            selector: (row) => row.valor
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => One(row)}>
                        <Edit />
                    </IconButton>
                </Stack>
            )
        }
    ];
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch
    } = useForm();
    const activeLabel = { shrink: true };
    const seccionCineantropometria = watch('seccionCineantropometria');
    const theme = useTheme();
    const setData = () => {
        setValue('valor', '');
        setValue('tipoCineantropometria', null);
        setOneDataCineantropometria([]);
    };

    // const validateDuplicate = (items, value) => items.some((item) => item.tipoCineantropometria === value);

    const onSubmit = (data) => {
        // if (!validateDuplicate(allDataCineantropometria, data.tipoCineantropometria.label)) {
        const jsData = {
            nutricionCineantropometria: oneDataNutricionCineantropometria.id,
            tipoCineantropometria: data.tipoCineantropometria.value,
            valor: data.valor,
            id: oneDataCinenatropometria?.id || null
        };
        StoreUpdateCineantropometria(jsData);
        setData();

        // } else {
        //     Notification(`${data.tipoCineantropometria.label}, ya se encuentra registrado`, 0);
        // }
    };

    useEffect(() => {
        if (seccionCineantropometria) {
            TipoCineantropometria(seccionCineantropometria.value);
        } else {
            TipoCineantropometria(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seccionCineantropometria]);

    useEffect(() => {
        if (oneDataCinenatropometria?.id) {
            setValue('valor', oneDataCinenatropometria.valor);
            setValue('tipoCineantropometria', {
                label: oneDataCinenatropometria.tipoCineantropometria,
                value: oneDataCinenatropometria.idTipoCinenatropometria
            });
            setValue('seccionCineantropometria', { label: oneDataCinenatropometria.seccion, value: oneDataCinenatropometria.idSeccion });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataCinenatropometria]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                CINEANTROPOMETRÍA
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="seccionCineantropometria" shrink sx={{ background: '#FFF', px: 1 }}>
                                Sección Cineantropometria
                            </InputLabel>
                            <Controller
                                control={control}
                                name="seccionCineantropometria"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataSeccionCineantropometria}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.seccionCineantropometria ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.seccionCineantropometria && errors.seccionCineantropometria.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="tipoCineantropometria" shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo Cineantropometria
                            </InputLabel>
                            <Controller
                                control={control}
                                name="tipoCineantropometria"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoCineantropometria}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoCineantropometria ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoCineantropometria && errors.tipoCineantropometria.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.observacion}
                                {...register('valor', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Valor"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.valor && errors.valor.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                            {oneDataCinenatropometria.id && (
                                <AnimateButton>
                                    <BootstrapTooltip title="Cancelar" arrow placement="left">
                                        <Button
                                            size="medium"
                                            type="button"
                                            onClick={() => setData()}
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.error.main,
                                                '&:hover': {
                                                    background: theme.palette.error.dark
                                                },
                                                ml: 2,
                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                            }}
                                        >
                                            <Block fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns2} data={allDataCineantropometria} />
            </Box>
        </Box>
    );
};

export default Index;
