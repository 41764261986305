import { useContext } from 'react';
import { IconCertificate, IconFileCertificate, IconCertificate2 } from '@tabler/icons';

// project imports
import NavItem from '../../../layout/MainLayout/Sidebar/MenuList/NavItem/index';
import NavCollapse from '../../../layout/MainLayout/Sidebar/Menu/Collapse/index';
import { UserContext } from 'utils/context/userContext';

const Aside = () => {
    const { Roles, userRoles } = useContext(UserContext);
    return (
        <NavCollapse icon={IconCertificate2} id="Certificados Medicos" title="Certificados Medicos" level={1}>
            {userRoles.includes(Roles.sol_cert_med) && (
                <NavItem
                    item={{
                        id: 'Solicitar Certificado',
                        title: 'Solicitar Certificados Medicos',
                        type: 'item',
                        url: '/certificados/mis-certificados',
                        icon: IconCertificate,
                        breadcrumbs: true
                    }}
                    level={2}
                />
            )}
            {(userRoles.includes(Roles.ope_cert_med) || userRoles.includes(Roles.vob_cert_med)) && (
                <NavItem
                    item={{
                        id: 'Aprobar Certificado',
                        title: 'Aprobar Certificados',
                        type: 'item',
                        url: '/certificados/aprobar-certificados',
                        icon: IconFileCertificate,
                        breadcrumbs: true
                    }}
                    level={2}
                />
            )}
        </NavCollapse>
    );
};
export default Aside;
