import { useContext, useState, useEffect } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import PruebaPostural from './pruebaPostural';
import General from './general';
import Formulario from './form';
import Historial from './historial';

const Index = () => {
    const { dataVal, oneDataValoracionFisioterapeutica, OneValoracionFisioterapeutica, AllValoracionFisioterapeutica } =
        useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const [dataVal3, setDataVal3] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    const handleTabChange3 = (event, newValue) => {
        setDataVal3(newValue);
    };

    useEffect(() => {
        if (dataVal === 6) {
            OneValoracionFisioterapeutica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);

    useEffect(() => {
        if (dataVal2 === 1) {
            AllValoracionFisioterapeutica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal2]);
    return (
        <>
            {dataVal === 6 && (
                <>
                    <Tabs value={dataVal2} onChange={handleTabChange2}>
                        <Tab label="Formulario" />
                        <Tab label="Historial" />
                    </Tabs>

                    {dataVal2 === 0 && (
                        <>
                            <Formulario />
                            {oneDataValoracionFisioterapeutica?.id && (
                                <>
                                    <Tabs value={dataVal3} onChange={handleTabChange3}>
                                        <Tab label="Formulario de Valoración" />
                                        <Tab label="Prueba Postural" />
                                    </Tabs>

                                    {dataVal3 === 0 && <General />}
                                    {dataVal3 === 1 && <PruebaPostural />}
                                </>
                            )}
                        </>
                    )}
                    {dataVal2 === 1 && <Historial />}
                </>
            )}
        </>
    );
};

export default Index;
