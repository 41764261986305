import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputAdornment } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Save } from '@mui/icons-material';
import ModalPdf from '../export/examenfisicoDeportivo/modal';

const Index = () => {
    const { StoreUpdateExamenF, oneDataExamenF, user, allDataDetalleExamenFisicoDep } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            peso: data.peso,
            estatura: data.estatura,
            svFc: data.svFc,
            fr: data.fr,
            pa: data.pa,
            fecha: oneDataExamenF?.fecha || new Date(),
            anio: oneDataExamenF?.anio || new Date().getFullYear(),
            id: oneDataExamenF.length !== 0 ? oneDataExamenF.id : null
        };
        StoreUpdateExamenF(jsData);
    };

    const setData = () => {
        setValue('peso', oneDataExamenF.peso);
        setValue('estatura', oneDataExamenF.estatura);
        setValue('svFc', oneDataExamenF.svFc);
        setValue('fr', oneDataExamenF.fr);
        setValue('pa', oneDataExamenF.pa);
    };

    useEffect(() => {
        if (oneDataExamenF.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataExamenF]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    <Typography sx={{ my: 2 }} variant="h4">
                        DATOS GENERALES
                    </Typography>
                </Grid>
                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                    <ModalPdf
                        nameFile="Centro de Atención"
                        data={{ datosGenerales: oneDataExamenF, user, allDataDetalleExamenFisicoDep }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.peso}
                            {...register('peso', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="peso"
                            label="Peso"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.peso && errors.v.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">kg</InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.estatura}
                            {...register('estatura', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="estatura"
                            label="Estatura"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.estatura && errors.estatura.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mts</InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.svFc}
                            {...register('svFc', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="svFc"
                            label="S/V: FC"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.svFc && errors.svFc.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">FC lat/min</InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.fr}
                            {...register('fr', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="fr"
                            label="FR:"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.fr && errors.fr.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">resp/min</InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.pa}
                            {...register('pa', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="pa"
                            label="P/A:"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.pa && errors.pa.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mm/Hg</InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                <AnimateButton>
                    <BootstrapTooltip title="Guardar" arrow placement="left">
                        <Button
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{
                                background: theme.palette.success.main,
                                '&:hover': {
                                    background: theme.palette.success.dark
                                },
                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                maxWidth: '48px' // Cambia el ancho máximo del botón
                            }}
                        >
                            <Save fontSize="medium" />
                        </Button>
                    </BootstrapTooltip>
                </AnimateButton>
            </Box>
        </Box>
    );
};

export default Index;
