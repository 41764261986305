import { useContext, useState } from 'react';
import { EstadosSolicitud, CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Stack, IconButton, Box } from '@mui/material';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { IconEye } from '@tabler/icons';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData } = useContext(ContentContext);
    // const { register, watch, setValue } = useForm();
    // const Fecha = watch('fecha');
    // const today = new Date().toISOString().substr(0, 10); // Obtiene la fecha actual y la formatea en el formato de fecha ISO
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };

    const columns = [
        {
            name: 'Correlativo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.correlativo)
        },
        {
            name: 'Fecha',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.start)
        },
        {
            name: 'Tipo Atención',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.title)
        },
        {
            name: 'Federación',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.deporte)
        },
        {
            name: 'estado',
            sortable: true,
            center: true,
            selector: (row) => EstadosSolicitud(row)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <BootstrapTooltip title="Visualizar" arrow placement="top">
                        <IconButton aria-label="visualizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => One(row, 3)}>
                            <IconEye />
                        </IconButton>
                    </BootstrapTooltip>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
                {/* <TextField type="date" size="small" {...register('fecha')} /> */}
            </Box>
            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
