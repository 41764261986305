/* eslint-disable react/prop-types */
import { useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ContentContext } from '../context';

const Listado = () => {
    const { allDataDetallePruebaFuerza } = useContext(ContentContext);
    const columns = [
        {
            name: 'Tiempo',
            selector: (row) => row.tiempo
        },
        {
            name: 'Velocidad',
            selector: (row) => row.medicion
        },
        {
            name: 'FC',
            selector: (row) => row.val2
        },
        {
            name: 'RPE',
            selector: (row) => row.val3
        }
    ];
    return <DataTable noDataComponent="No hay registros para mostrar." columns={columns} data={allDataDetallePruebaFuerza} />;
};

export default Listado;
