import { Routes, Route, Navigate } from 'react-router-dom';
import Disciplina from '../../views/compensacionEconomica/disciplina/index';
import CodigoCaja from '../../views/compensacionEconomica/codigoCaja/index';
import Ubicacion from '../../views/compensacionEconomica/ubicacion/index';
import CompensacionEconomica from '../../views/compensacionEconomica/compensacionEconomica/index';

const EconomicCompensationRoutes = () => (
    <Routes>
        <Route path="disciplina" element={<Disciplina />} />
        <Route path="codigo-caja" element={<CodigoCaja />} />
        <Route path="ubicacion" element={<Ubicacion />} />
        <Route path="formulario" element={<CompensacionEconomica />} />
        <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
);

export default EconomicCompensationRoutes;
