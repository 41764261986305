/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from '../../services/Private';
// import { NotificationContext } from 'utils/components/snackBar/context';
import { LoadingContext } from 'utils/components/loading/context';
import { PdfFicha } from './pdf/index';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    // const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState('');
    const [showPdf, setShowPdf] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDataFederacion, setAllDataFederacion] = useState([]);
    const [allDataCompensacion, setAllDataCompensacion] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [oneDataAtleta, setOneDataAtleta] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'resultados';
    const nameController2 = 'fichaTecnica';
    const AllPersona = async (id) => {
        if (id) {
            const response = await PostRoute(`persona/all/federacion`, { federacion: id });
            setAllData(response.length ? response : []);
        } else {
            setAllData([]);
        }
    };
    const All = async (id) => {
        const response = await PostRoute(`${nameController}/all`, { persona: id });
        setAllDataCompensacion(response.length ? response : []);
    };

    const AllFederaciones = async () => {
        const response = await GetRoute(`federacion/label`);
        setAllDataFederacion(response.length ? response : []);
    };

    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };

    const One = async (data, opcion) => {
        setOneData(data);
        setShowLoad(true);

        const response = await PostRoute(`${nameController2}/data-atleta`, { persona: data.id });

        if (opcion === 1) {
            toggleModal(3);
            setOneDataAtleta(response.length ? response[0] : []);
        } else {
            await setPdf(PdfFicha(data.foto, response.length ? response[0] : []));
            await setShowPdf(true);
        }
        setShowLoad(false);
    };
    const value = {
        All,
        One,
        setOpcion,
        AllPersona,
        toggleModal,
        setShowPdf,
        showPdf,
        pdf,
        show,
        opcion,
        oneData,
        allData,
        oneDataAtleta,
        allDataFederacion,
        allDataCompensacion
    };
    useEffect(() => {
        AllFederaciones();
        // setShowPdf(true);
        // setPdf(PdfFicha());
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
