import { useEffect, useState } from 'react';
import { Avatar, CssBaseline, Paper, Box, Grid, Typography, Stack, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Carrusel from 'utils/Carrusel/index';
import Logo from 'assets/images/auth/Logo.png';
import { GetRoute } from 'services/Private';

import '../../assets/scss/login.scss';

const SignInSide = () => {
    const theme = createTheme();
    const location = useLocation();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [listado, setListado] = useState([]);
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (location?.pathname.includes('atencion')) {
            navigate('atencion');
        } else if (!location?.pathname.includes('reset-password')) {
            navigate('login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AllCarrousel = async () => {
        const response = await GetRoute(`public/all`);
        setListado(response.length ? response : []);
    };
    useEffect(() => {
        AllCarrousel();
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={8}>
                    <Carrusel List={listado} />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    sx={{ alignItems: 'center' }}
                    sm={8}
                    md={4}
                    justifyContent="center"
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '80vh',
                            width: '70%'
                        }}
                        justifyContent="center"
                    >
                        <Avatar sx={{ m: 1, height: 250, width: 150 }} variant="square" src={Logo} alt="logo" />
                        <Grid item>
                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                <Typography color={theme.palette.primary.main} gutterBottom variant={matchDownSM ? 'h5' : 'h4'}>
                                    PORTAL DEL ATLETA
                                </Typography>
                            </Stack>
                        </Grid>
                        <Outlet />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default SignInSide;
