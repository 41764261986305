import { useContext, useEffect, useState } from 'react';
import { formatDate } from 'utils/function/global';
import { Modal, TextField, Box, Button, FormControl, Grid, Typography, InputLabel, Divider } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import MainCard from 'utils/components/modal/MainCardModal';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { customStyles, customStylesDanger } from 'utils/select/index';
import Select from 'react-select';
import { Save, ModeEdit, Cancel, Check, DoDisturb } from '@mui/icons-material';
import DetalleForm from './detalleForm';
import ListSolicitud from './listSolicitud';
import { useForm, Controller } from 'react-hook-form';
import { ContentContext } from './context';

export default function KeepMountedModal() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        control
    } = useForm();
    const activeLabel = { shrink: true };
    const [changeState, setChangeState] = useState(false);
    const { show, resetData, toggleModal, oneData, StoreUpdate, opcion, dateSelect, allDataTipoAtencion, allDataDetalle, Actions } =
        useContext(ContentContext);
    const theme = useTheme();
    const UpdateForm = (state) => {
        setChangeState(state);
    };
    const onSubmit = (data) => {
        const jsData = {
            fecha: dateSelect?.startStr || oneData?.start,
            motivo: data.motivo,
            tipoAtencion: data.tipoAtencion.value,
            tipoAtencionLabel: data.tipoAtencion.label,
            estado: oneData.length !== 0 ? oneData.estado : 1,
            id: oneData.length !== 0 ? oneData.id : null
        };
        StoreUpdate(jsData, dateSelect);
        UpdateForm(true);
    };
    const setData = async () => {
        await setValue('motivo', oneData.motivo);
        await setValue('tipoAtencion', { label: oneData.tipoAtencion, value: oneData.idTipoAtencion });
    };

    useEffect(() => {
        async function fetchMyAPI() {
            if ((await opcion) > 1 && oneData) {
                await setChangeState(true);
                await UpdateForm(true);
                await setData();
            } else if (resetData) {
                reset();
                await UpdateForm(false);
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion, oneData]);

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={{ mt: 1 }} spacing={2}>
                    <MainCard
                        title={`${opcion === 1 ? 'Agendar' : ''} ${opcion === 2 ? 'Visualizar' : ''} ${
                            opcion === 3 ? 'Actualizar' : ''
                        } Cita ${dateSelect?.startStr ? formatDate(dateSelect?.startStr, 'es') : oneData?.start}`}
                        closeButton={() => toggleModal(0)}
                        size="lg"
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <InputLabel htmlFor="tipoAtencion" shrink sx={{ background: '#FFF', px: 1 }}>
                                            Tipo de Atención
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="tipoAtencion"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    isDisabled={changeState}
                                                    options={allDataTipoAtencion}
                                                    placeholder="Seleccione tipo de atención"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.tipoAtencion ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.tipoAtencion && errors.tipoAtencion.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.motivo}
                                            InputLabelProps={activeLabel}
                                            {...register('motivo', { required: { value: true, message: 'Este campo es requerido' } })}
                                            size="small"
                                            id="outlined-error-helper-text"
                                            label="Objetivo de la Evaluación solicitada"
                                            multiline
                                            rows={4}
                                            InputProps={{
                                                readOnly: changeState
                                            }}
                                            focused={oneData.length !== 0}
                                            helperText={!!errors.motivo && errors.motivo.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        sx={{
                                            mt: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60%'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {changeState && oneData?.estado === 1 && (
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        <BootstrapTooltip title="Actualizar" arrow placement="left">
                                                            <Button
                                                                variant="contained"
                                                                disabled={!changeState}
                                                                onClick={() => UpdateForm(false)}
                                                                size="small"
                                                                sx={{
                                                                    background: theme.palette.warning.main,
                                                                    '&:hover': {
                                                                        background: theme.palette.warning.dark
                                                                    },
                                                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                                                }}
                                                            >
                                                                <ModeEdit fontSize="small" />
                                                            </Button>
                                                        </BootstrapTooltip>
                                                    </AnimateButton>
                                                </Grid>
                                            )}
                                            {changeState && oneData?.estado === 1 && (
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        <BootstrapTooltip title="Anular" arrow placement="left">
                                                            <Button
                                                                variant="contained"
                                                                disabled={!changeState}
                                                                onClick={() => Actions(oneData, 0)}
                                                                size="small"
                                                                sx={{
                                                                    background: theme.palette.error.main,
                                                                    '&:hover': {
                                                                        background: theme.palette.error.dark
                                                                    },
                                                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                                                }}
                                                            >
                                                                <DoDisturb fontSize="small" />
                                                            </Button>
                                                        </BootstrapTooltip>
                                                    </AnimateButton>
                                                </Grid>
                                            )}
                                            {allDataDetalle.length > 0 && oneData?.estado === 1 && (
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        <BootstrapTooltip title="Finalizar" arrow placement="left">
                                                            <Button
                                                                variant="contained"
                                                                disabled={!changeState}
                                                                onClick={() => Actions(oneData, 2)}
                                                                size="small"
                                                                sx={{
                                                                    background: theme.palette.success.main,
                                                                    '&:hover': {
                                                                        background: theme.palette.success.dark
                                                                    },
                                                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                                                    maxWidth: '48px' // Cambia el ancho máximo del botón
                                                                }}
                                                            >
                                                                <Check fontSize="small" />
                                                            </Button>
                                                        </BootstrapTooltip>
                                                    </AnimateButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {!changeState && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        sx={{
                                                            background: theme.palette.success.main,
                                                            '&:hover': {
                                                                background: theme.palette.success.dark
                                                            },
                                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                                        }}
                                                    >
                                                        <Save fontSize="small" />
                                                    </Button>
                                                </Grid>
                                                {opcion === 3 && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => UpdateForm(true)}
                                                            sx={{
                                                                background: theme.palette.error.main,
                                                                '&:hover': {
                                                                    background: theme.palette.error.dark
                                                                },
                                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                                            }}
                                                        >
                                                            <Cancel fontSize="small" />
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {oneData?.id && (
                            <Box>
                                {(oneData.length === 0 || oneData?.estado === 1) && (
                                    <>
                                        <Divider sx={{ pb: 3 }}>Agregar Atleta</Divider>
                                        <DetalleForm />
                                    </>
                                )}
                                <ListSolicitud />
                            </Box>
                        )}
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
