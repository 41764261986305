import { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Carrera from './carrera';
import Formulario from './form';
import Competencia from './potencia';
import Historial from './historial';

const Index = () => {
    const { dataVal, oneDataEvaluacionBiomecanica, AllTipoCarrera, AllTipoPotencia, OneEvaluacionBiomecanica, AllEvaluacionBiomecanica } =
        useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const [dataVal3, setDataVal3] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    const handleTabChang3 = (event, newValue) => {
        setDataVal3(newValue);
    };

    useEffect(() => {
        if (dataVal === 7) {
            AllTipoCarrera();
            AllTipoPotencia();
            OneEvaluacionBiomecanica();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);

    useEffect(() => {
        if (dataVal2 === 1) {
            AllEvaluacionBiomecanica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal2]);
    return (
        <>
            {dataVal === 7 && (
                <>
                    <Tabs value={dataVal2} onChange={handleTabChange2}>
                        <Tab label="Formulario" />
                        <Tab label="Historial" />
                    </Tabs>

                    {dataVal2 === 0 && (
                        <>
                            <Formulario />
                            {oneDataEvaluacionBiomecanica?.id && (
                                <>
                                    <Tabs value={dataVal3} onChange={handleTabChang3}>
                                        <Tab label="Carrera" />
                                        <Tab label="Potencia" />
                                    </Tabs>

                                    {dataVal3 === 0 && <Carrera />}
                                    {dataVal3 === 1 && <Competencia />}
                                </>
                            )}
                        </>
                    )}
                    {dataVal2 === 1 && <Historial />}
                </>
            )}
        </>
    );
};

export default Index;
