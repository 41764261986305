/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
// import { updateData } from 'utils/function/index';
import { LoadingContext } from 'utils/components/loading/context';
import { UserContext } from 'utils/context/userContext';
import PDFCertificado1 from './PDFCertificado1';
import PDFCertificado2 from './PDFCertificado2';
import { useSWRConfig } from 'swr';
import { useRequest } from '../../../hooks/useRequestSWR';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { mutate } = useSWRConfig();
    const { userRoles, Roles, userAuth } = useContext(UserContext);
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [DataBitacoraPedido, setDataBitacoraPedido] = useState([]);
    const [show, setShow] = useState(false);
    // const [allData, setAllData] = useState([]);
    const [labelPais, setLabelPais] = useState([]);
    const [idTipoCertificado, setIdTipoCertificado] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [pdf, setPdf] = useState([]);
    const [allDataAtleta, setAllDataAtleta] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'certificados';
    const nameController2 = 'pais';
    const nameController3 = 'persona';
    const { data: allDataHistorica } = useRequest(`${nameController}/allHistorico`, 'GETSWR');
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    // const All = async () => {
    //     const response = await GetRoute(`${nameController}/all`);
    //     setAllData(response.length ? response : []);
    // };
    const LabelPais = async () => {
        const response = await GetRoute(`${nameController2}/label`);
        setLabelPais(response.length ? response : []);
    };
    const AllAtleta = async () => {
        const response = await PostRoute(`${nameController3}/label-atleta`);
        setAllDataAtleta(response.length ? response : []);
    };
    const toggleModal = (data) => {
        setOpcion(data);
        data === 1 && setOneData([]);
        setShow(!show);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/one`, { id: data.id });
        setOneData(response[0] ? response[0] : []);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const BitacoraPedido = async (data, opcion) => {
        const response = await PostRoute(`${nameController}/bitacoraGestiones`, { id: data.id });
        setDataBitacoraPedido(response || []);
        toggleModal(opcion);
    };
    const StoreUpdate = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data);
        toggleModal(0);
        mutate(`${nameController}/all`);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const UpdateEstadoCertificado = async (data) => {
        const response = await PostRoute(`${nameController}/${'UpdateEstadoGestiones'}`, data);
        Notification(response.message, 1);
        mutate(`${nameController}/all`);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        mutate(`${nameController}/all`);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const labelTiposCertificados = [
        { value: 1, label: 'Certificado de Precompetencia' },
        { value: 2, label: 'Certificado de Buena Salud' }
    ];
    const Imprimir = async (data) => {
        if (data?.idTipoCertificado === 1) {
            // Precompetencia
            const response3 = await PDFCertificado1(data);
            return response3;
        }
        if (data?.idTipoCertificado === 2) {
            // Buena Salud
            const response3 = await PDFCertificado2(data);
            return response3;
        }
    };
    const EnviarARevision = async (data, opcion) => {
        toggleModal(opcion);
        const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data);
        Notification(response.message, 1);
        mutate(`${nameController}/all`);
    };
    const value = {
        Actions,
        EnviarARevision,
        Imprimir,
        BitacoraPedido,
        One,
        setIdTipoCertificado,
        setPdf,
        setOpcion,
        setShow,
        StoreUpdate,
        UpdateEstadoCertificado,
        toggleModal,
        allData,
        allDataHistorica,
        allDataAtleta,
        idTipoCertificado,
        labelPais,
        labelTiposCertificados,
        oneData,
        opcion,
        pdf,
        Roles,
        show,
        userAuth,
        userRoles,
        DataBitacoraPedido
    };
    useEffect(() => {
        LabelPais();
        AllAtleta();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
