import { useContext, useEffect } from 'react';
import { Modal, TextField, IconButton, Box, Button, FormControl } from '@mui/material';
import Add from '@mui/icons-material/Add';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import MainCard from 'utils/components/modal/MainCardModal';
import BootstrapTooltip from 'utils/components/tooltip/index';
import Save from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { ContentContext } from './context';

export default function KeepMountedModal() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const { show, toggleModal, oneData, StoreUpdate, opcion } = useContext(ContentContext);
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            nombre: data.nombre,
            nacionalidad: data.nacionalidad,
            estado: oneData.length !== 0 ? oneData.estado : 1,
            id: oneData ? oneData.id : null
        };
        StoreUpdate(jsData);
    };
    const setData = async () => {
        await setValue('nombre', oneData.nombre);
    };

    useEffect(() => {
        async function fetchMyAPI() {
            if ((await opcion) > 1 && oneData) {
                await setData();
            } else {
                reset();
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion, oneData]);
    return (
        <Box>
            <BootstrapTooltip title="Crear" arrow placement="left">
                <IconButton
                    onClick={() => toggleModal(1)}
                    aria-label="Crear"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.primary.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Add fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <MainCard
                        title={`${opcion === 1 ? 'Crear' : ''} ${opcion === 2 ? 'Visualizar' : ''} ${
                            opcion === 3 ? 'Actualizar' : ''
                        } Registro`}
                        closeButton={() => toggleModal(0)}
                    >
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.nombre}
                                {...register('nombre', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Nombre"
                                InputProps={{
                                    readOnly: opcion === 2
                                }}
                                focused={oneData.length !== 0}
                                helperText={!!errors.nombre && errors.nombre.message}
                            />
                        </FormControl>

                        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Guardar" arrow placement="left">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.dark,
                                            '&:hover': {
                                                background: theme.palette.success.light
                                            }
                                        }}
                                    >
                                        <Save fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
