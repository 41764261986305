import { Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import MainLayout from 'layout/MainLayout';
import Dashboard from '../../views/index';
import ItRoutes from './itRoutes';
import AdminRoutes from './adminRoutes';
import ScienceRoutes from './cienciasAplicadasRoutes';
import EconomicCompensationRoutes from './compensacionEconomicaRoutes';
import ResultsRoutes from './resultadosRoutes';
import FichaTecnica from '../../views/ficha/index';
import Perfil from '../../views/perfil/index';
import AgendarCita from '../../views/gestiones/solicitud';
import CertificadosMedicos from './certificadosMedicos';
import { UserContext } from 'utils/context/userContext';

const AppRoute = () => {
    const { userRoles, Roles } = useContext(UserContext);

    return (
        <Routes>
            <Route element={<MainLayout />} path="/">
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                {userRoles.includes(Roles.it) && <Route path="/it/*" element={<ItRoutes />} />}
                {userRoles.includes(Roles.mod_cert_med) && <Route path="/certificados/*" element={<CertificadosMedicos />} />}

                {userRoles.includes(Roles.adm) && <Route path="/resultados/*" element={<ResultsRoutes />} />}
                <Route path="/ciencias-aplicadas/*" element={<ScienceRoutes userRoles={userRoles} Roles={Roles} />} />
                <Route path="/compensacion-economica/*" element={<EconomicCompensationRoutes />} />
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route exact path="agendar-cita" element={<AgendarCita />} />
                <Route exact path="ficha-tecnica" element={<FichaTecnica />} />
                <Route exact path="perfil" element={<Perfil />} />
            </Route>
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    );
};

export default AppRoute;
