/* eslint-disable react/prop-types */
import { IconButton, Box, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import BootstrapTooltip from 'utils/components/tooltip/index';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ModalPdf from 'ui-component/templatePdf/visor';
import { generatePDF } from 'ui-component/templatePdf/index';
import { UserContext } from 'utils/context/userContext';

const ExportPDF = ({ data, nameFile }) => {
    const { userAuth } = useContext(UserContext);
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState([]);

    const showModal = async () => {
        const estados = ['Inactivo', 'Activo'];
        const columns = [['No.', nameFile, 'Estado']];
        const rows = data.map((item, i) => [i + 1, item.nombre, estados[item.estado]]);

        const doc = await generatePDF(rows, columns, 'Listado', nameFile, nameFile, '', [], userAuth.nombreCompleto);

        setShow(true);
        setPdf(doc);
    };

    return (
        <Box>
            <BootstrapTooltip title="Exportar PDF" arrow placement="left">
                <IconButton
                    onClick={showModal}
                    aria-label="Exportar"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.error.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark
                        }
                    }}
                >
                    <PictureAsPdf fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <ModalPdf showPdf={show} setShowPdf={setShow} title="Visualizar" pdf={pdf} />
        </Box>
    );
};

export default ExportPDF;
