/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from 'utils/components/snackBar/context';
import { PostRoute } from '../../../services/Private';
import { LoadingContext } from 'utils/components/loading/context';
import ImgSuccess from 'assets/images/svg/persona/Checklist.svg';
import { UserContext } from 'utils/context/userContext';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { userAuth } = useContext(UserContext);
    const { setShowLoad } = useContext(LoadingContext);
    const navigate = useNavigate();
    // All data
    const [allData, setAllData] = useState([]);

    const nameController = 'asistencia';
    const nameController2 = 'solicitud';
    const All = async () => {
        const response = await PostRoute(`${nameController}/all`);
        setAllData(response.length ? response : []);
    };
    // --------------------------------------------------------

    const consultarEstado = (item, opcion) =>
        PostRoute(`${nameController2}/atender-atleta`, { persona: item.id, solicitud: item.solicitud, opcion });

    const LlamarAtleta = async (data) => {
        setShowLoad(true);
        const resp = await consultarEstado(data, 11);
        const response = resp.data ? resp.data[0] : [];
        if (response.validador === 2) {
            Notification(
                `El atleta ya está siendo atendido por el médico ${response.operador}. Por favor, espera a que finalice la consulta actual.`,
                0
            );
            All();
        } else {
            PostRoute(`${nameController}/atender-atleta`, { ...data, medico: userAuth.nombreCompleto });
        }

        setShowLoad(false);
    };

    const AtenderAtleta = async (item) => {
        //
        setShowLoad(true);
        const resp = await consultarEstado(item, 9);
        const response = resp.data ? resp.data[0] : [];
        let notificationMessage = '';
        let notificationType = 1;
        switch (response.validador) {
            case 0:
                notificationMessage = 'El atleta ha sido asociado correctamente al operador.';
                break;
            case 1:
                notificationMessage = 'El atleta está listo para ser atendido.';
                break;
            case 2:
                notificationMessage = `El atleta ya está siendo atendido por el médico ${response.operador}. Por favor, espera a que finalice la consulta actual.`;
                All();
                notificationType = 0;
                break;
            default:
                return;
        }

        Notification(notificationMessage, notificationType);

        // Si response.validador es 0 o 1, navega a la ruta correspondiente.
        if (response.validador === 0 || response.validador === 1) {
            navigate(`/ciencias-aplicadas/perfil-medico/${item.id}/${item.solicitud}`);
        }
        setShowLoad(false);
    };

    const value = {
        All,
        LlamarAtleta,
        AtenderAtleta,
        ImgSuccess,
        allData
    };

    useEffect(() => {
        All();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
