/* eslint-disable no-use-before-define */

function StatusCode(response) {
    // if(response.status === 400){
    //     return response.json();
    // }

    if (response.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
        return false;
    }

    return true;
}

export const userService = {
    LoginSesion,
    GetRoute,
    logout,
    Exit
};

async function PostLogin(url, form) {
    const data = JSON.stringify({
        ...form
    });

    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${process.env.REACT_APP_ROUTE_BASE}/${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    });
    return response;
}

async function LoginSesion(username, password) {
    let user = await PostLogin('Login/login', { username, password });

    user = await handleResponse(user);
    if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        user.authdata = window.btoa(`${username}:${password}`);

        localStorage.setItem('user', JSON.stringify(user));
    }

    return user;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('img');
}

function Exit() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.href = '/login';
}

export async function GetRoute(url) {
    const response = await fetch(`${process.env.REACT_APP_ROUTE_BASE}/${url}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => data.json())
        .catch(() => []);
    return response;
}

export async function PostRoute(url, form) {
    const data = JSON.stringify({
        ...form
    });

    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${process.env.REACT_APP_ROUTE_BASE}/${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    });
    StatusCode(response);
    return response.json();
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // eslint-disable-next-line no-restricted-globals
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
export default PostRoute;
