import { useContext } from 'react';
import { ContentContext } from '../context';

const EstudiosdeGabinete = () => {
    const { dataVal } = useContext(ContentContext);

    // useEffect(() => {
    //     if (dataVal === 50) {
    //         // OneDf(code);
    //         // AllAntecedentes(code);
    //         // OneAnamnesis(code);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [code, dataVal]);
    return <>{dataVal === 11 && <>Modulo de Estudios de Gabinete</>}</>;
};

export default EstudiosdeGabinete;
