// material-ui
import { useState } from 'react';
import Toolbar from 'utils/components/toolbar/index';
import { ContentProvider } from './context';
import ContainerCustom from 'ui-component/container/index';
import Formulario from './form';
import { Tab, Tabs } from '@mui/material';
import List from './list';
import ListHistorico from './listHistorico';

const Index = () => {
    // const { dataVal } = useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    return (
        <ContentProvider>
            <ContainerCustom>
                <Tabs value={dataVal2} onChange={handleTabChange2}>
                    <Tab label="Formulario" />
                    <Tab label="Historial" />
                </Tabs>

                {dataVal2 === 0 && (
                    <>
                        <Toolbar title="BANDEJA DE SOLICITUDES DE CERTIFICADOS MEDICOS" formulario={<Formulario />} />
                        <List />
                    </>
                )}
                {dataVal2 === 1 && (
                    <>
                        <Toolbar title="HISTORICO DE SOLICITUDES DE CERTIFICADOS MEDICOS" formulario={<Formulario />} />
                        <ListHistorico />
                    </>
                )}
            </ContainerCustom>
        </ContentProvider>
    );
};

export default Index;
