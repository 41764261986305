import { useContext, useState } from 'react';
import { CustomText } from 'utils/components/customTable/index';
import { Box, TextField, Container, Stack, IconButton } from '@mui/material';
import BootstrapTooltip from 'utils/components/tooltip/index';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';
import { IconTrash } from '@tabler/icons';

const List = () => {
    const { allDataDetalle, ActionsDetalle, oneData } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allDataDetalle.filter((item) => {
                const startsWith = item.nombreCompleto.toLowerCase().startsWith(value.toLowerCase());
                const includes = item.nombreCompleto.toLowerCase().includes(value.toLowerCase());
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'No.',
            sortable: true,
            center: true,
            width: '100px',
            selector: (row, index) => index + 1
        },
        {
            name: 'Atleta',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombreCompleto)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            omit: oneData?.estado !== 1,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <BootstrapTooltip title="Quitar de la lista" arrow placement="top">
                        <IconButton aria-label="visualizar" sx={{ px: 0, mx: 0 }} size="small" onClick={() => ActionsDetalle(row)}>
                            <IconTrash />
                        </IconButton>
                    </BootstrapTooltip>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} />
            </Box>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allDataDetalle}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
