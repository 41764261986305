/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo.png';
import CuerpoHumano from 'assets/images/cuerpo-min.jpg';

const partesCuerpo = [
    {
        titulo: 'ESPALDA',
        hijos: [
            'Suboccipital',
            'Angular de omoplato o elevador de escápula',
            'Trapecio',
            'Hombro',
            'Romboides',
            'Infraespinoso',
            'Supraespinoso',
            'Redondo menor',
            'Redondo mayor',
            'Subescapular',
            'Serrato posterior superior',
            'Dorsal ancho',
            'Erector de la espalda',
            'Glúteo mayor',
            'Glúteo menor',
            'Rotadores profundos (piriformes)',
            'Cuadrado lumbar',
            'Paraespinosos profundos'
        ]
    },
    {
        titulo: 'BRAZO',
        hijos: [
            'Deltoides anterior',
            'Deltoides medio',
            'Deltoides posterior',
            'Bíceps braquial',
            'Tríceps braquial',
            'Braquial anterior',
            'Coraco braquial',
            'Supinador',
            'Flexores de antebrazo',
            'Extensores de antebrazo',
            'Flexor de mano',
            'Extensor de mano'
        ]
    },
    {
        titulo: 'PIERNAS',
        hijos: [
            'Supinadores del muslo',
            'Cuádriceps',
            'Tensor de fascia lata',
            'Iliosoas',
            'Tibias anterior',
            'Pronadores de pierna (Ízquiotibiales)',
            'Gastrocnemius',
            'Soleo',
            'Tendón de Aquiles'
        ]
    },
    {
        titulo: 'PARTE ANTERIOR DEL TRONCO',
        hijos: ['Pectoral mayor', 'Pectoral menor', 'Serrato anterior', 'Diafragma', 'Oblicuo externo', 'Recto anterior del abdomen']
    }
];

export async function generatePDF(titulo, subTitulo, nombrePdf, item, UserAut) {
    const doc = new jsPDF('p', 'pt', [611.28, 962.2]).setProperties({ title: nombrePdf });
    let Y = 0;
    const hoy = new Date();
    function pageContent(title) {
        doc.setFontSize(8);
        doc.addImage(Logo, 'PNG', 55, 25, 70, 50);
        doc.setFont('times', 'bold').setFontSize(12);
        doc.text(title, 300, 50, { width: 800, align: 'center' });
        doc.text(subTitulo, 300, 70.8661, { width: 800, align: 'center' });
        doc.setFont('times', 'normal');
        doc.text(`Fecha: ${`00${hoy.getDate()}`.slice(-2)}/${`00${hoy.getMonth() + 1}`.slice(-2)}/${hoy.getFullYear()}`, 450, 100, {
            width: 800,
            align: 'left'
        });
        doc.text(`Impreso por: ${UserAut}`, 300, 100, { width: 800, align: 'center' });
    }
    pageContent(titulo);
    doc.setFontSize(8);

    Y += 140;
    doc.setFont('times', 'bold');
    doc.text(`FECHA:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.row?.fecha || ''}`, 110, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text(`FISIOTERAPISTA:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.row?.fisioterapista || ''}`, 130, Y, { width: 800, align: 'left' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text(`VALORACIÓN FISIOTERAPÉUTICA`, doc.internal.pageSize.width / 2, Y, { align: 'center' });
    Y += 20;
    doc.setFont('times', 'bold');
    doc.text('NOMBRE:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.nombres || ''} ${item?.user?.apellidos || ''}`.toUpperCase(), 110, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('EDAD:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.edad || ''}`.toUpperCase(), 135, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text('GENERO:', 190, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.genero || ''}`.toUpperCase(), 250, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text(`F. NACIMIENTO:`, 400, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.fechaNacimiento || ''}`, 490, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text(`TELÉFONO:`, 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.telefono || ''}`, 110, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text('FADN:', 190, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.user?.deporte || ''}`, 250, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('ÁREA DE LESIÓN:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.row?.areaLesion || ''}`.toUpperCase(), 135, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('HALLAZGO:', 50, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`${item?.row?.hallazgo || ''}`.toUpperCase(), 110, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('ETAPA DE ENTRENAMIENTO:', 50, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('GENERAL:', 50, Y, { width: 800, align: 'left' });
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.setFillColor(item?.row?.etapaEntrenamiento === 1 ? 0 : 255);
    doc.circle(110, Y - 2.5, 5, 'FD');
    doc.setFont('times', 'bold');
    doc.text('ESPECÍFICA:', 140, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.row?.etapaEntrenamiento === 2 ? 0 : 255);
    doc.circle(210, Y - 2.5, 5, 'FD');
    doc.setFont('times', 'bold');
    doc.text(`COMPLEMENTARIA:`, 240, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.row?.etapaEntrenamiento === 3 ? 0 : 255);
    doc.circle(340, Y - 2.5, 5, 'FD');
    doc.setFont('times', 'bold');
    doc.text(`VALORACIÓN GENERAL DE 1 A 100`, 380, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'normal');
    doc.text(`90`, 530, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('TRATAMIENTO:', 50, Y, { width: 800, align: 'left' });
    doc.text('CLÍNICA', 125, Y, { width: 800, align: 'left' });
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.setFillColor(item?.row?.tratamiento === 1 ? 0 : 255);
    doc.circle(215, Y - 2.5, 5, 'FD');
    doc.setFont('times', 'bold');
    doc.text('MECANOTERAPIA', 235, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.row?.tratamiento === 2 ? 0 : 255);
    doc.circle(330, Y - 2.5, 5, 'FD');
    doc.setFont('times', 'bold');
    doc.text(`HIDROTERAPIA`, 350, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.row?.tratamiento === 3 ? 0 : 255);
    doc.circle(425, Y - 2.5, 5, 'FD');
    Y += 15;
    doc.setFont('times', 'bold');
    doc.text('PROTOCOLO DE PREVENCIÓN Y CORRECCIÓN DE LESIONES', 50, Y, { width: 800, align: 'left' });
    doc.text('OTROS', 320, Y, { width: 800, align: 'left' });
    doc.text(`${item?.row?.otros || ''}`, 360, Y, { width: 800, align: 'left' });
    Y += 15;
    doc.setFont('times', 'bold');
    doc.setFontSize(10);
    doc.text('VALORACIÓN DEL RANGO DE MOVIMIENTO ACTIVO', 50, Y, { width: 800, align: 'left' });
    doc.text('VALORACIÓN DE 1 A 10', 380, Y, { width: 800, align: 'left' });
    doc.text(`${item?.valoraciones?.valorTotalMovimientoActivo || ''}`, 520, Y, { width: 800, align: 'left' });
    Y += 15;
    // Empty square
    doc.setFontSize(8);
    const heigthRect = 110;
    const widthRect = 438;
    doc.rect(50, Y, widthRect, heigthRect);
    // Tabla 1
    let X = 150;
    let Y2 = Y;
    let Y3 = Y;
    let Y4 = Y;
    let Y5 = Y;
    let Y6 = Y;
    let Y7 = Y;
    // COLUMNA 1
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect);
    Y += 15;
    doc.text('COLUMNA CERVICAL', 55, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text('nl', X + 8, Y, { width: 800, align: 'left' });
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);
    console.log(item);
    Y += 15;
    doc.text('FLEXION 70°', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.flexion70PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);

    Y += 15;
    doc.text('EXTENSION 70°', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.extension70PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);

    Y += 15;
    doc.text('FLEXION LAT. IZ. 45°', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.flexionLatIzq45PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);

    Y += 15;
    doc.text('FLEXION LAT. DER. 45°', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.flexionLatDer45PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);

    Y += 15;
    doc.text('ROTACIÓN IZ. 90°:', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.rotacionIz90PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect + 50, Y + 6);

    Y += 15;
    doc.text('ROTACIÓN DER. 90:', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.rotacionDer90PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y - 2, 5, 'FD');
    X += 20;
    // COLUMNA 2
    doc.setLineWidth(0.1).line(X, Y2, X, Y2 + heigthRect);
    Y2 += 15;
    doc.text('PINCHAZO', X + 5, Y2, { width: 800, align: 'left' });
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.flexion70PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.extension70PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatIzq45PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatDer45PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionIz90PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionDer90PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y2 - 2, 5, 'FD');

    // Aquí iría el contenido para la columna 2 cuando lo tengas.
    X += 50;
    // COLUMNA 3
    doc.setLineWidth(0.1).line(X, Y3, X, Y3 + heigthRect);
    Y3 += 15;
    doc.text('JALÓN', X + 10, Y3, { width: 800, align: 'left' });
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.flexion70PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.extension70PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatIzq45PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatDer45PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionIz90PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionDer90PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y3 - 2, 5, 'FD');
    // Aquí iría el contenido para la columna 3 cuando lo tengas.

    X += 50;
    // COLUMNA 4
    doc.setLineWidth(0.1).line(X, Y4, X, Y4 + heigthRect);
    Y4 += 7.5;
    doc.text('COLUMNA', X + 8, Y4 + 2, { width: 800, align: 'left' });
    Y4 += 7.5;
    doc.text('TORACO LUMBAR', X + 9, Y4 + 2, { width: 800, align: 'left' });
    Y4 += 15;
    doc.setFont('times', 'bold');
    doc.text('FLEXIÓN 90°', X + 5, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.text('EXTENSIÓN 30°', X + 5, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.text('FLEXIÓN LAT IZ. 40°', X + 5, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.text('FLEXIÓN LAT DER. 40°', X + 5, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.text('ROTACIÓN IZ. 45°', X + 5, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.text('ROTACIÓN DER. 45°', X + 5, Y4, { width: 800, align: 'left' });
    // COLUMNA 5
    X += 100;
    doc.setLineWidth(0.1).line(X, Y5, X, Y5 + heigthRect);
    Y5 += 15;
    doc.text('nl', X + 8, Y5, { width: 20, align: 'left' });
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.flexion90PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.extension30PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatIzq40PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatDer40PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionIz45PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionDer45PinchazoJalon === 0 ? 0 : 255);
    doc.circle(X + 10, Y5 - 2, 5, 'FD');
    X += 20;
    // COLUMNA 5
    doc.setLineWidth(0.1).line(X, Y6, X, Y6 + heigthRect);
    Y6 += 15;
    doc.text('PINCHAZO', X + 5, Y6, { width: 50, align: 'left' });
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.flexion90PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.extension30PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatIzq40PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatDer40PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionIz45PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    Y6 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionDer45PinchazoJalon === 1 ? 0 : 255);
    doc.circle(X + 25, Y6 - 2, 5, 'FD');
    X += 50;
    // COLUMNA 6
    doc.setLineWidth(0.1).line(X, Y7, X, Y7 + heigthRect);
    Y7 += 15;
    doc.text('JALÓN', X + 10, Y7, { width: 50, align: 'left' });
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.flexion90PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.extension30PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatIzq40PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.flexionLatDer40PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionIz45PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.rotacionDer45PinchazoJalon === 2 ? 0 : 255);
    doc.circle(X + 25, Y7 - 2, 5, 'FD');
    Y += 20;
    doc.setFontSize(10);
    doc.text('VALORACIÓN BIOMECÁNICO', 50, Y, { width: 800, align: 'left' });
    doc.text('VALORACIÓN DE 1 A 25', 380, Y, { width: 800, align: 'left' });
    doc.text(`${item?.valoraciones?.valorTotalBiomecanico || ''}`, 520, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setFontSize(8);
    const heigthRect2 = 85;
    const widthRect2 = 438;
    X = 220;
    Y2 = Y;
    Y3 = Y;
    Y4 = Y;
    Y5 = Y;
    Y6 = Y;
    Y7 = Y;
    doc.rect(50, Y, widthRect2, heigthRect2);
    // Tabla 2
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect2);
    Y += 15;
    doc.text('EXAMEN BIOMECÁNICA', 55, Y, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');
    doc.text('ACEPTABLE', X + 35, Y, { width: 800, align: 'left' });
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect2 + 50, Y + 6);

    Y += 15;
    doc.text('FLEXION DE TRONCO', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.flexionTroncoAceptaleDeficiente === 1 ? 0 : 255);
    doc.circle(X + 60, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect2 + 50, Y + 6);

    Y += 15;
    doc.text('EXTENSION DE CADERA', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.etencionCaderaAceptaleDeficiente === 1 ? 0 : 255);
    doc.circle(X + 60, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect2 + 50, Y + 6);

    Y += 15;
    doc.text('ABDUCCIÓN DE CADERA IZQUIERDA', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.abduccinCaderaIzquierdaAceptaleDeficiente === 1 ? 0 : 255);
    doc.circle(X + 60, Y - 2, 5, 'FD');
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect2 + 50, Y + 6);

    Y += 15;
    doc.text('ABDUCCIÓN DE CADERA DERECHA', 55, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.abduccinCaderaDerechaAceptaleDeficiente === 1 ? 0 : 255);
    doc.circle(X + 60, Y - 2, 5, 'FD');
    X += 130;
    // COLUMNA 2
    doc.setLineWidth(0.1).line(X, Y2, X, Y2 + heigthRect2);
    Y2 += 15;
    doc.text('DEFICIENTE', X + 45, Y2, { width: 800, align: 'left' });
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.flexionTroncoAceptaleDeficiente !== 1 ? 0 : 255);
    doc.circle(X + 70, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.etencionCaderaAceptaleDeficiente !== 1 ? 0 : 255);
    doc.circle(X + 70, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.abduccinCaderaIzquierdaAceptaleDeficiente !== 1 ? 0 : 255);
    doc.circle(X + 70, Y2 - 2, 5, 'FD');
    Y2 += 15;
    doc.setFillColor(item?.valoraciones?.abduccinCaderaDerechaAceptaleDeficiente !== 1 ? 0 : 255);
    doc.circle(X + 70, Y2 - 2, 5, 'FD');
    Y += 25;
    doc.setFontSize(10);
    doc.text('VALORACIÓN BALANCE-CORE Y PROPIOCEPCIÓN', 50, Y, { width: 800, align: 'left' });
    doc.text('VALORACIÓN DE 1 A 25', 380, Y, { width: 800, align: 'left' });
    doc.text(`${item?.valoraciones?.valorTotalBalanceCore || ''}`, 520, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setFontSize(8);
    const heigthRect3 = 50;
    const widthRect3 = 438;
    X = 92.5;
    Y2 = Y;
    Y3 = Y;
    Y4 = Y;
    Y5 = Y;
    Y6 = Y;
    Y7 = Y;
    doc.rect(50, Y, widthRect3, heigthRect3);
    Y += 15;
    doc.setLineWidth(0.1).line(X, Y + 6, X, Y + (heigthRect3 - 15));
    doc.text('BALANCE', 100, Y - 5, { width: 800, align: 'left' });
    doc.setFont('times', 'bold');

    X += 42.5;
    Y2 += 15;
    // doc.setLineWidth(0.1).line(X, Y2 + 6, X, Y2 + (heigthRect3 - 15)); // SEGUNDA LINEA VERTICAL
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect3 + 50, Y + 6);
    doc.text('CORE', 270, Y2 - 5, { width: 800, align: 'left' });

    X += 42.5;
    Y += 15;
    Y3 += 15;
    doc.text('OAD', 60, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.oad === 1 ? 0 : 255);
    doc.circle(113, Y - 2, 5, 'FD');

    // doc.text('OAI', 140, Y, { width: 800, align: 'left' });
    // doc.setFillColor(item?.valoraciones?.oai === 1 ? 0 : 255);
    // doc.circle(200, Y - 2, 5, 'FD');

    doc.text('INESTABLE', 225, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.inestable === 1 ? 0 : 255);
    doc.circle(325, Y - 2, 5, 'FD');
    doc.setFillColor(item?.valoraciones?.puntoPresionInestable === 1 ? 0 : 255);
    doc.circle(420, Y - 2, 5, 'FD');

    // doc.setLineWidth(0.1).line(X, Y2 + 6, X, Y2 + (heigthRect3 - 15)); // TERCERA LINEA VERTICAL
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect3 + 50, Y + 6);
    doc.text('PUNTO PRESIÓN', 385, Y3 - 5, { width: 800, align: 'left' });

    X += 42.5;
    Y += 15;
    doc.setLineWidth(0.1).line(X, Y4, X, Y4 + heigthRect3); // CUARTA LINEA VERTICAL
    doc.text('OCD', 60, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.ocd === 1 ? 0 : 255);
    doc.circle(113, Y - 2, 5, 'FD');

    // doc.text('OCI', 140, Y, { width: 800, align: 'left' });
    // doc.setFillColor(item?.valoraciones?.oci === 1 ? 0 : 255);
    // doc.circle(200, Y - 2, 5, 'FD');

    doc.text('ESTABLE', 225, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.estable === 1 ? 0 : 255);
    doc.circle(325, Y - 2, 5, 'FD');

    doc.setFillColor(item?.valoraciones?.puntoPresionEstable === 1 ? 0 : 255);
    doc.circle(420, Y - 2, 5, 'FD');

    X += 85;
    doc.setLineWidth(0.1).line(X, Y2 + 6, X, Y2 + (heigthRect3 - 15)); // QUINTA LINEA VERTICAL

    X += 42.5;
    doc.setLineWidth(0.1).line(X, Y4, X, Y4 + heigthRect3); // SEXTA LINEA VERTICAL

    // ----------------------------------------------------------------------------
    Y += 25;
    doc.setFontSize(10);
    doc.text('VALORACIÓN MUSCULAR EN CADENA', 50, Y, { width: 800, align: 'left' });
    doc.text('VALORACIÓN DE 1 A 20', 380, Y, { width: 800, align: 'left' });
    doc.text(`${item?.valoraciones?.valorTotalMuscularCadena || ''}`, 520, Y, { width: 800, align: 'left' });
    Y += 10;
    doc.setFontSize(8);
    const heigthRect4 = 70;
    Y2 = Y;
    Y3 = Y;
    Y4 = Y;
    Y5 = Y;
    Y6 = Y;
    Y7 = Y;
    let Y8 = Y;
    X = 92.5;
    // Dibujar rectángulo
    doc.rect(50, Y, widthRect3, heigthRect4);
    // Primera línea vertical
    X += 48;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);
    // Segunda línea vertical
    X += 41;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);
    // Tercera línea vertical
    X += 41;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);
    // Cuarta línea vertical
    X += 126.5;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);
    // QUINTA línea vertical
    X += 60;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);
    // // Cuarta línea vertical
    X += 42.5;
    doc.setLineWidth(0.1).line(X, Y, X, Y + heigthRect4);

    // Primera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect3 + 50, Y + 6);
    // Segunda línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect3 + 50, Y + 6);
    // Tercera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect3 + 50, Y + 6);

    Y2 += 15;
    doc.text('FUERZA MUSCULAR', 55, Y2 - 5, { width: 800, align: 'left' });
    Y2 += 15;
    doc.text('CADENA SUPERIOR', 55, Y2, { width: 800, align: 'left' });
    Y2 += 15;
    doc.text('CADENA INFERIOR', 55, Y2, { width: 800, align: 'left' });

    Y3 += 15;
    doc.text('DER.', 150, Y3 - 5, { width: 800, align: 'left' });
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.cadenaSuperiorDerIzq === 1 ? 0 : 255);
    doc.circle(160, Y3 - 2, 5, 'FD');
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 1 ? 0 : 255);
    doc.circle(160, Y3 - 2, 5, 'FD');

    Y4 += 15;
    doc.text('IZQ.', 195, Y4 - 5, { width: 800, align: 'left' });
    Y4 += 15;
    doc.setFillColor(item?.valoraciones?.cadenaSuperiorDerIzq === 0 ? 0 : 255);
    doc.circle(200, Y4 - 2, 5, 'FD');
    Y4 += 15;
    doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    doc.circle(200, Y4 - 2, 5, 'FD');

    Y5 += 15;
    doc.text('ART. BLOQUEADA', 250, Y5 - 5, { width: 800, align: 'left' });
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.artBloqueadaCanedaSuperior === 1 ? 0 : 255);
    doc.circle(285, Y5 - 2, 5, 'FD');
    Y5 += 15;
    doc.setFillColor(item?.valoraciones?.artBloqueadaFuerzaMuscular === 1 ? 0 : 255);
    doc.circle(285, Y5 - 2, 5, 'FD');

    Y6 += 15;
    doc.text('PIE PLANO', 355, Y6 - 5, { width: 800, align: 'left' });
    Y6 += 15;
    doc.text('GRADO 1', 355, Y6, { width: 800, align: 'left' });
    Y6 += 15;
    doc.text('GRADO 2', 355, Y6, { width: 800, align: 'left' });
    Y6 += 15;
    doc.text('GRADO 3', 355, Y6, { width: 800, align: 'left' });

    Y7 += 15;
    doc.text('DER.', 420, Y7 - 5, { width: 800, align: 'left' });
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.grado1DerIzq === 1 ? 0 : 255);
    doc.circle(430, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.grado2DerIzq === 1 ? 0 : 255);
    doc.circle(430, Y7 - 2, 5, 'FD');
    Y7 += 15;
    doc.setFillColor(item?.valoraciones?.grado3DerIzq === 1 ? 0 : 255);
    doc.circle(430, Y7 - 2, 5, 'FD');

    Y8 += 15;
    doc.text('IZQ.', 460, Y8 - 5, { width: 800, align: 'left' });
    Y8 += 15;
    doc.setFillColor(item?.valoraciones?.grado1DerIzq === 2 ? 0 : 255);
    doc.circle(470, Y8 - 2, 5, 'FD');
    Y8 += 15;
    doc.setFillColor(item?.valoraciones?.grado2DerIzq === 2 ? 0 : 255);
    doc.circle(470, Y8 - 2, 5, 'FD');
    Y8 += 15;
    doc.setFillColor(item?.valoraciones?.grado3DerIzq === 2 ? 0 : 255);
    doc.circle(470, Y8 - 2, 5, 'FD');

    // ----------------------------------------------------------------------------
    Y += 40;
    const heigthRect5 = 100;
    const widthRect5 = 270;
    Y2 = Y;
    Y3 = Y;
    Y4 = Y;
    X = 92.5;
    // Dibujar rectángulo
    doc.rect(50, Y, widthRect5, heigthRect5);
    // Primera línea vertical
    X += 89;
    doc.setLineWidth(0.1).line(X, Y + 22, X, Y + heigthRect5);
    // Primera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect5 + 50, Y + 6);
    // Segunda línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect5 + 50, Y + 6);
    // Tercera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect5 + 50, Y + 6);
    // Tercera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect5 + 50, Y + 6);
    // Tercera línea horizontal
    Y += 15;
    doc.setLineWidth(0.1).line(50, Y + 6, widthRect5 + 50, Y + 6);

    Y2 += 15;
    doc.setFontSize(10);
    doc.text('EVALUACIÓN DE FLEXIBILIDAD', 60, Y2, { width: 800, align: 'left' });
    doc.text('VALORACIÓN DE 1 A 10', 380, Y2, { width: 800, align: 'left' });
    doc.text(`${item?.valoraciones?.valoracionFlexibilidad || ''}`, 520, Y2, { width: 800, align: 'left' });
    doc.setFontSize(8);

    Y3 += 30;
    doc.text('Ms. SUPERIORES.', 60, Y3, { width: 800, align: 'left' });
    Y3 += 15;
    doc.setFillColor(item?.valoraciones?.msSuperiores === 1 ? 0 : 255);
    doc.circle(100, Y3 - 2, 5, 'FD');
    // Y3 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    // doc.circle(100, Y3 - 2, 5, 'FD');
    // Y3 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    // doc.circle(100, Y3 - 2, 5, 'FD');
    // Y3 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    // doc.circle(100, Y3 - 2, 5, 'FD');

    Y4 += 30;
    doc.text('Ms. INFERIORES', 195, Y4, { width: 800, align: 'left' });
    Y4 += 15;
    doc.setFillColor(item?.valoraciones?.msInferior === 1 ? 0 : 255);
    doc.circle(250, Y4 - 2, 5, 'FD');
    // Y4 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    // doc.circle(250, Y4 - 2, 5, 'FD');
    // Y4 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaSuperiorDerIzq === 0 ? 0 : 255);
    // doc.circle(250, Y4 - 2, 5, 'FD');
    // Y4 += 15;
    // doc.setFillColor(item?.valoraciones?.cadenaInferiorDerIzq === 0 ? 0 : 255);
    // doc.circle(250, Y4 - 2, 5, 'FD');

    // --------------------------------------------------------------------------------
    Y += 40;
    doc.setFontSize(10);
    doc.text('PROTOCOLOS DE PREVENCIÓN Y CORRECTIVOS RECOMENDADOS:', 50, Y, { width: 800, align: 'left' });

    Y += 20;
    doc.text('Sx ^', 50, Y - 5, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.sxArriba === 1 ? 0 : 255);
    doc.roundedRect(80, Y - 13, 10, 10, 3, 3, 'FD');

    doc.text('Sx v', 150, Y - 5, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.sxAbajo === 1 ? 0 : 255);
    doc.roundedRect(200, Y - 13, 10, 10, 3, 3, 'FD');
    // -------------------------------------------------
    Y += 15;
    doc.text('CORE:', 50, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.protocoloPrevencion === 1 ? 0 : 255);
    doc.roundedRect(90, Y - 8, 10, 10, 3, 3, 'FD');

    doc.text('BALANCE:', 115, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.protocoloPrevencion === 2 ? 0 : 255);
    doc.roundedRect(175, Y - 8, 10, 10, 3, 3, 'FD');

    doc.text('BIOMECÁNICA:', 190, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.protocoloPrevencion === 3 ? 0 : 255);
    doc.roundedRect(280, Y - 8, 10, 10, 3, 3, 'FD');

    doc.text('FUERZA MUSCULAR:', 295, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.protocoloPrevencion === 4 ? 0 : 255);
    doc.roundedRect(405, Y - 8, 10, 10, 3, 3, 'FD');

    doc.text('ESTIRAMIENTO:', 425, Y, { width: 800, align: 'left' });
    doc.setFillColor(item?.valoraciones?.protocoloPrevencion === 5 ? 0 : 255);
    doc.roundedRect(525, Y - 8, 10, 10, 3, 3, 'FD');
    // Agregar una nueva página
    doc.addPage();
    pageContent('PRUEBA POSTURAL');

    doc.addImage(CuerpoHumano, 'PNG', 30, 150, 350, 750);
    Y = 150;

    doc.setFontSize(8);
    partesCuerpo.forEach((parte) => {
        doc.setFont('times', 'bold');
        doc.text(`${parte.titulo}`, 400, Y, { width: 800, align: 'left' });
        Y += 15;
        parte.hijos.forEach((hijo) => {
            doc.setFont('times', 'normal');
            doc.text(`${item.pruebaPostural.valores.includes(hijo) ? '_X_' : '___'}${hijo}`, 400, Y, { width: 800, align: 'left' });
            Y += 15;
        });
    });

    return doc.output('datauristring');
}

export default { generatePDF };
