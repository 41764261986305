import { Routes, Route, Navigate } from 'react-router-dom';
import SolicitudCertificados from '../../views/CertificadosMedicos/SolicitudCertificados/index';
import AprobarSolicitudCertificados from '../../views/CertificadosMedicos/AprobarSolicitudCertificados/index';

const CertificadosMedicos = () => (
    <Routes>
        <Route exact path="/mis-certificados" element={<SolicitudCertificados />} />
        <Route exact path="/aprobar-certificados" element={<AprobarSolicitudCertificados />} />
        <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
);

export default CertificadosMedicos;
