import { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Detalle from './detalle';
import Formulario from './form';
import Historial from './historial';

const Index = () => {
    const {
        dataVal,
        oneDataEvaluacionFuerzaFlexibilidad,
        AllTipoFuerza,
        OneEvaluacionFuerzaFlexibilidad,
        AllEvaluacionFuerzaFlexibilidad
    } = useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };

    useEffect(() => {
        if (dataVal === 10) {
            AllTipoFuerza();
            OneEvaluacionFuerzaFlexibilidad();
            AllEvaluacionFuerzaFlexibilidad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);

    return (
        <>
            {dataVal === 10 && (
                <>
                    <Tabs value={dataVal2} onChange={handleTabChange2}>
                        <Tab label="Formulario" />
                        <Tab label="Historial" />
                    </Tabs>

                    {dataVal2 === 0 && (
                        <>
                            <Formulario />
                            {oneDataEvaluacionFuerzaFlexibilidad?.id && <Detalle />}
                        </>
                    )}
                    {dataVal2 === 1 && <Historial />}
                </>
            )}
        </>
    );
};

export default Index;
