import { Box, Grid, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import { useEffect, useContext } from 'react';
import { ContentContext } from '../context';

const FormDetalle = () => {
    const {
        StoreUpdateDetallePruebaAnaerobica,
        setAllDataWatts,
        allDataWatts,
        allDataTiempoSegundos,
        setAllDataTiempoSegundos,
        setDataPotenciaMaxima,
        setDataResistenciaMedia,
        setDataIndiceFatiga,
        setDataResistencia,
        user
    } = useContext(ContentContext);
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();
    const Peso = user?.peso;
    const Watts = watch('watts');
    const onSubmit = async (data) => {
        const jsData = {
            tiempo: data.tiempo,
            watts: data.watts,
            resultado: data.resultado
        };

        const result = Number(data.tiempo) + 5; // Incrementamos el valor de 'tiempo' en 5
        await setAllDataWatts([...allDataWatts, data.resultado]);
        await setAllDataTiempoSegundos([...allDataTiempoSegundos, jsData.tiempo]);
        await StoreUpdateDetallePruebaAnaerobica(jsData);

        setValue('watts', '');
        setValue('resultado', '');
        await setValue('tiempo', result); // Asignamos el nuevo valor de 'tiempo' incrementado en 5
    };
    useEffect(() => {
        if (Watts) {
            const resultado = Watts / Peso;
            setValue('resultado', parseFloat(resultado).toFixed(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Watts]);

    useEffect(() => {
        setValue('tiempo', 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allDataWatts.length > 0) {
            // Convertir los datos a números
            const watts = allDataWatts.map((valor) => parseFloat(valor));
            // Calcular la potencia máxima
            const potenciaMaxima = Math.max(...watts);
            setDataPotenciaMaxima(potenciaMaxima);
            // Calcular la suma de los valores del arreglo
            const suma = watts.reduce((acumulador, valor) => acumulador + valor, 0);
            // Calcular el promedio dividiendo la suma entre la cantidad de elementos
            const resistenciaMedia = suma / watts.length;
            setDataResistenciaMedia(resistenciaMedia.toFixed(1));
            // Encontrar el valor mínimo de watts (potencia mínima)
            const potenciaMinima = Math.min(...watts);
            // Evitar división por cero si la potencia media es cero
            const indiceFatiga = potenciaMaxima > 0 ? ((potenciaMaxima - potenciaMinima) / potenciaMaxima) * 100 : 0;
            setDataResistencia(parseFloat(Peso * 0.07).toFixed(1));
            // Mostrar el resultado del índice de fatiga
            setDataIndiceFatiga(Math.ceil(indiceFatiga));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataWatts]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={3}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.tiempo}
                            {...register('tiempo', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="Tiempo (SEG)"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.tiempo && errors.tiempo.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.watts}
                            {...register('watts', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="WATTS"
                            InputLabelProps={activeLabel}
                            helperText={!!errors.watts && errors.watts.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.resultado}
                            {...register('resultado', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="outlined-error-helper-text"
                            label="WATTS/KG"
                            InputLabelProps={activeLabel}
                            multiline
                            helperText={!!errors.resultado && errors.resultado.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <AnimateButton>
                            <BootstrapTooltip title="Agregar" arrow placement="left">
                                <Button
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        background: theme.palette.primary.main,
                                        '&:hover': {
                                            background: theme.palette.info.dark
                                        },
                                        minWidth: '32px', // Cambia el ancho mínimo del botón
                                        maxWidth: '48px' // Cambia el ancho máximo del botón
                                    }}
                                >
                                    <Add fontSize="medium" />
                                </Button>
                            </BootstrapTooltip>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormDetalle;
