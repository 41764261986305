import { useContext } from 'react';
import { Box, Typography, Grid, FormControl, InputLabel, Button } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import { ContentContext } from './context';
import Select from 'react-select';

const DetalleForm = () => {
    const {
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm();
    const { oneData, StoreUpdateDetalle, allDataAtleta } = useContext(ContentContext);
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            atleta: data.atleta.value,
            nombreCompleto: data.atleta.label,
            solicitud: oneData.id
        };
        StoreUpdateDetalle(jsData);
        reset();
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel htmlFor="atleta" shrink sx={{ background: '#FFF', px: 1 }}>
                            Atleta
                        </InputLabel>
                        <Controller
                            control={control}
                            name="atleta"
                            defaultValue={null}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable
                                    options={allDataAtleta}
                                    placeholder="Seleccione un atleta"
                                    noOptionsMessage={() => 'sin resultados'}
                                    styles={!errors.atleta ? customStyles : customStylesDanger}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            }}
                        />
                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                            {!!errors.atleta && errors.atleta.message}
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <AnimateButton>
                        <BootstrapTooltip title="Agregar" arrow placement="left">
                            <Button
                                size="small"
                                type="submit"
                                variant="contained"
                                sx={{
                                    background: theme.palette.primary.main,
                                    '&:hover': {
                                        background: theme.palette.primary.dark
                                    },
                                    minWidth: '32px', // Cambia el ancho mínimo del botón
                                    maxWidth: '48px', // Cambia el ancho máximo del botón
                                    mt: 1
                                }}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </BootstrapTooltip>
                    </AnimateButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DetalleForm;
