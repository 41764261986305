/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import LineChart from 'utils/charts/lineChart/index';
import ContainerCustom from 'ui-component/container/index';

const CustomLineChart = ({ anioActual, titulo, data, label, funcion }) => {
    const [selectedYear, setSelectedYear] = useState(0);
    const [yearList, setYearList] = useState([]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        funcion(event.target.value);
    };

    useEffect(() => {
        if (anioActual) {
            setSelectedYear(anioActual);
            const years = [];
            for (let year = anioActual - 5; year <= anioActual; year++) {
                years.push(year);
            }
            setYearList(years);
        }
    }, [anioActual]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h3" sx={{ pt: 2, textAlign: 'center' }}>
                        {titulo}
                    </Typography>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id="year-label" shrink sx={{ background: '#FFF', px: 1 }}>
                                Año
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="year-label"
                                id="year-select"
                                value={selectedYear}
                                name="anio"
                                onChange={handleYearChange}
                            >
                                {yearList.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormControl>
                </Grid>
            </Grid>
            <ContainerCustom>
                <Grid item lg={12} xs={12} align="center">
                    <LineChart label={label} data={data} name="series-1" />
                </Grid>
            </ContainerCustom>
        </>
    );
};

export default CustomLineChart;
