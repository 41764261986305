/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable object-shorthand */
import { useContext, useEffect } from 'react';
import { Modal, TextField, Box, Button, FormControl, Grid, Typography, Card, CardContent } from '@mui/material';
// import Add from '@mui/icons-material/Add';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import MainCard from 'utils/components/modal/MainCardModal';
import BootstrapTooltip from 'utils/components/tooltip/index';
import Save from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import { ContentContext } from './context';
import { customStyles, customStylesDanger } from 'utils/select/index';
import { HighlightOff, CheckCircleOutline } from '@mui/icons-material';
import { CustomLabel, CustomLabelSelect } from 'utils/components/customLabel';
import Select from 'react-select';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import Moment from 'react-moment';
import 'moment/locale/es-mx';

export default function KeepMountedModal() {
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        watch,
        formState: { errors },
        control
    } = useForm();
    const inputTipoCertificado = watch('tipoCertificado');
    const {
        show,
        toggleModal,
        oneData,
        StoreUpdate,
        opcion,
        labelTiposCertificados,
        labelPais,
        allDataAtleta,
        setIdTipoCertificado,
        idTipoCertificado,
        userAuth,
        setShow,
        pdf,
        EnviarARevision,
        UpdateEstadoCertificado,
        DataBitacoraPedido
        // userRoles,
        // Roles
    } = useContext(ContentContext);
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const onSubmit = (data) => {
        if (idTipoCertificado === 1) {
            const jsData = {
                idTipoCertificado: idTipoCertificado,
                idAtletaPersona: data?.atleta.value,
                idFederacion: userAuth?.federacionId,
                nombreEventoCompetencia: data?.nombreEvento,
                idPaisCompetencia: data?.pais.value,
                ciudadCompetencia: data?.ciudadCompetencia,
                fechaInicioCompetencia: data?.fechaInicioCompetencia,
                fechaFinCompetencia: data?.fechaFinalizacionCompetencia,
                id: oneData.length !== 0 ? oneData.id : null
            };
            StoreUpdate(jsData);
        }
        if (idTipoCertificado === 2) {
            const jsData = {
                idTipoCertificado: idTipoCertificado,
                idAtletaPersona: data?.atleta.value,
                idFederacion: userAuth?.federacionId,
                fechaEvaluacionMorfo: data?.fechaEvaluacionMorfo,
                id: oneData.length !== 0 ? oneData.id : null
            };
            StoreUpdate(jsData);
        }
    };
    const onSubmitEnviarRevision = () => {
        if (opcion === 20) {
            const json = {
                id: oneData ? oneData.id : null
            };
            EnviarARevision(json);
        }
        reset();
        toggleModal(0);
    };
    const onSubmitEstado = (data) => {
        if (opcion === 21) { // Aprobar Graciela
            const json = {
                id: (oneData) ? oneData.id : null,
                tipoRechazo: null,
                descripcion: null,
                estado: 4, // 0 COG 1 FADN // 11 FINALIZA 3 PASA A TECNICA
            }

            UpdateEstadoCertificado(json)
        }
        if (opcion === 22) { // Rechazar Graciela
            const json = {
                id: (oneData) ? oneData.id : null,
                tipoRechazo: 3,
                descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                estado: 0
            }
            UpdateEstadoCertificado(json)
        }
        if (opcion === 23) { // Aprobar VoBo Dr
            const json = {
                id: (oneData) ? oneData.id : null,
                tipoRechazo: null,
                descripcion: null,
                estado: 3
            }

            UpdateEstadoCertificado(json)
        }
        if (opcion === 24) {// Rechazo VoBo Dr
            const json = {
                id: (oneData) ? oneData.id : null,
                tipoRechazo: 3,
                descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                estado: 2
            }
            UpdateEstadoCertificado(json)
        }
        reset()
        toggleModal(0)
    };
    const setData = async () => {
        await setValue('atleta', { label: oneData?.nombreAtletaPersona, value: oneData?.idAtletaPersona });
        await setValue('tipoCertificado', { label: oneData?.tipoCertificado, value: oneData?.idTipoCertificado });
        await setValue('nombreEvento', oneData?.nombreEventoCompetencia);
        await setValue('pais', { label: oneData?.nombrePaisCompetencia, value: oneData?.idPaisCompetencia });
        await setValue('ciudadCompetencia', oneData?.ciudadCompetencia);
        await setValue('fechaInicioCompetencia', oneData?.fechaInicioCompetenciaFormateada);
        await setValue('fechaFinalizacionCompetencia', oneData?.fechaFinCompetenciaFormateada);
        await setValue('fechaEvaluacionMorfo', oneData?.fechaEvaluacionMorfoFormateada);
    };

    useEffect(() => {
        async function fetchMyAPI() {
            if ((await opcion) > 1 && oneData) {
                await setData();
            } else {
                reset();
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion, oneData]);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchMyAPI() {
            if (inputTipoCertificado) {
                if (inputTipoCertificado?.value !== 0) {
                    setIdTipoCertificado(inputTipoCertificado?.value);
                } else {
                    setIdTipoCertificado(0);
                }
            }
            if (inputTipoCertificado === null || inputTipoCertificado === '') {
                setIdTipoCertificado(0);
            }
        }
        fetchMyAPI();
        return () => {
            abortController.abort();
        };
    }, [inputTipoCertificado, setIdTipoCertificado]);
    // console.log(userAuth?.federacionId);
    // {userRoles.includes(Roles.it) && <Route path="/it/*" element={<ItRoutes />} />}
    return (
        <Box>
            {/* Crear - Solcitar */}
            {opcion === 2 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                        <MainCard
                            // eslint-disable-next-line prettier/prettier
                            title={`${opcion === 1 ? 'Crear' : ''} ${opcion === 2 ? 'Visualizar' : ''} ${opcion === 3 ? 'Actualizar' : ''
                                // eslint-disable-next-line prettier/prettier
                                } Solicitud de Certificado Médico`}
                            closeButton={() => toggleModal(0)}
                            size="xl"
                        >
                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CustomLabelSelect required label="Atleta" size={16} />
                                        <Controller
                                            name="atleta"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    defaultValue={null}
                                                    options={allDataAtleta}
                                                    placeholder="Seleccione una opción"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.atleta ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.atleta && errors.atleta.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CustomLabelSelect required label="Tipo de Certificado" size={16} />
                                        <Controller
                                            id="tipoCertificado"
                                            control={control}
                                            name="tipoCertificado"
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    options={labelTiposCertificados}
                                                    placeholder="Seleccione una opción"
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={!errors.tipoCertificado ? customStyles : customStylesDanger}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                            {!!errors.tipoCertificado && errors.tipoCertificado.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {idTipoCertificado === 1 && (
                                <>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.nombreEvento}
                                            {...register('nombreEvento', { required: { value: true, message: 'Este campo es requerido' } })}
                                            InputLabelProps={activeLabel}
                                            size="small"
                                            id="nombreEvento"
                                            label={<CustomLabel required label="Nombre de Evento / Competencia Deportiva" size={16} />}
                                            helperText={!!errors.nombreEvento && errors.nombreEvento.message}
                                        />
                                    </FormControl>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <CustomLabelSelect required label="Pais de Competencia" size={16} />
                                                <Controller
                                                    name="pais"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            isSearchable
                                                            defaultValue={null}
                                                            options={labelPais}
                                                            placeholder="Seleccione una opción"
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={!errors.pais ? customStyles : customStylesDanger}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }}
                                                />
                                                <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                                    {!!errors.pais && errors.pais.message}
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <TextField
                                                    error={!!errors.ciudadCompetencia}
                                                    {...register('ciudadCompetencia', {
                                                        required: { value: true, message: 'Este campo es requerido' }
                                                    })}
                                                    InputLabelProps={activeLabel}
                                                    size="small"
                                                    id="ciudadCompetencia"
                                                    label={<CustomLabel required label="Ciudad de Competencia" size={16} />}
                                                    helperText={!!errors.ciudadCompetencia && errors.ciudadCompetencia.message}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <TextField
                                                    error={!!errors.fechaInicioCompetencia}
                                                    {...register('fechaInicioCompetencia', {
                                                        required: { value: true, message: 'Este campo es requerido' }
                                                    })}
                                                    InputLabelProps={{ shrink: true }}
                                                    type="date"
                                                    size="small"
                                                    id="outlined-error-helper-text"
                                                    label={<CustomLabel required label="Fecha Inicio de Competencia" size={16} />}
                                                    helperText={!!errors.fechaInicioCompetencia && errors.fechaInicioCompetencia.message}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth sx={{ mb: 3 }}>
                                                <TextField
                                                    error={!!errors.fechaFinalizacionCompetencia}
                                                    {...register('fechaFinalizacionCompetencia', {
                                                        required: { value: true, message: 'Este campo es requerido' }
                                                    })}
                                                    InputLabelProps={{ shrink: true }}
                                                    type="date"
                                                    size="small"
                                                    id="outlined-error-helper-text"
                                                    label={<CustomLabel required label="Fecha Finalización de Competencia" size={16} />}
                                                    helperText={
                                                        !!errors.fechaFinalizacionCompetencia && errors.fechaFinalizacionCompetencia.message
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {idTipoCertificado === 2 && (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <TextField
                                                error={!!errors.fechaEvaluacionMorfo}
                                                {...register('fechaEvaluacionMorfo', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                InputLabelProps={{ shrink: true }}
                                                type="date"
                                                size="small"
                                                id="outlined-error-helper-text"
                                                label={
                                                    <CustomLabel required label="Fecha de Realización de Evaluación Morfofuncional" size={16} />
                                                }
                                                helperText={!!errors.fechaEvaluacionMorfo && errors.fechaFinalizacionCompetencia.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}

                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Ver PDF */}
            {opcion === 5 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2}>
                        <MainCard title="PDF CERTIFICADO MEDICO" size="xl" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <object
                                        className="Visor"
                                        width="100%"
                                        height="500px"
                                        id="pdf-ficha"
                                        aria-labelledby="perfil"
                                        type="application/pdf"
                                        data={pdf}
                                    />
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Enviar Solicitud */}
            {opcion === 20 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                        <MainCard title="ENVIAR SOLICITUD" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" gutterBottom>
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.dark,
                                                '&:hover': {
                                                    background: theme.palette.success.light
                                                }
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Rechazar Solicitud Director */}
            {opcion === 22 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} onSubmit={handleSubmit(onSubmitEstado)}>
                        <MainCard title="RECHAZAR SOLICITUD" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.descripcion}
                                            // InputLabelProps={activeLabel}
                                            {...register('descripcion', { required: { value: true, message: 'Este campo es requerido' } })}
                                            size="small"
                                            id="descripcion"
                                            label="Motivo de Rechazo"
                                            multiline
                                            rows={4}
                                            InputLabelProps={activeLabel}
                                            helperText={!!errors.descripcion && errors.descripcion.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.dark,
                                                '&:hover': {
                                                    background: theme.palette.success.light
                                                }
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Aprobar Solicitud Director */}
            {opcion === 21 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} onSubmit={handleSubmit(onSubmitEstado)}>
                        <MainCard title="VISTO BUENO SOLICITUD" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" gutterBottom>
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.dark,
                                                '&:hover': {
                                                    background: theme.palette.success.light
                                                }
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Validar Solicitud Medica */}
            {opcion === 23 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} onSubmit={handleSubmit(onSubmitEstado)}>
                        <MainCard title="VALIDAR SOLICITUD" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" gutterBottom>
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.dark,
                                                '&:hover': {
                                                    background: theme.palette.success.light
                                                }
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Rechazar Solicitud Medica */}
            {opcion === 24 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} onSubmit={handleSubmit(onSubmitEstado)}>
                        <MainCard title="RECHAZAR SOLICITUD" closeButton={() => setShow(false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <TextField
                                            error={!!errors.descripcion}
                                            // InputLabelProps={activeLabel}
                                            {...register('descripcion', { required: { value: true, message: 'Este campo es requerido' } })}
                                            size="small"
                                            id="descripcion"
                                            label="Motivo de Rechazo"
                                            multiline
                                            rows={4}
                                            InputLabelProps={activeLabel}
                                            helperText={!!errors.descripcion && errors.descripcion.message}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Guardar" arrow placement="left">
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.dark,
                                                '&:hover': {
                                                    background: theme.palette.success.light
                                                }
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </MainCard>
                    </Box>
                </Modal>
            )}
            {/* Ver Proceso*/}
            {opcion === 6 && (
                <Modal
                    keepMounted
                    open={show}
                    onClose={() => toggleModal(0)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box component="form" sx={{ mt: 1 }} spacing={2} >
                        <MainCard title="PROCESO" closeButton={() => setShow(false)} size="xl">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h4" align="center" gutterBottom>
                                                    Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido?.length - 1]?.tiempoTranscurrido} || Solicitado: <Moment locale="es" format="lll" fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment>
                                                </Typography>
                                                <Box className="hori-timeline" >
                                                    <Swiper
                                                        slidesPerView={1}
                                                        navigation
                                                        pagination={{ clickable: true }}
                                                        breakpoints={{
                                                            678: {
                                                                slidesPerView: 2,
                                                            },
                                                            992: {
                                                                slidesPerView: 3,
                                                            },
                                                            1400: {
                                                                slidesPerView: 4,
                                                            },
                                                        }}
                                                        loop={false}
                                                        modules={[Pagination, Navigation]}
                                                        className="owl-carousel owl-theme navs-carousel events"
                                                        id="timeline-carousel"
                                                    >
                                                        {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                            <SwiperSlide key={index} className="item event-list" style={{ display: 'inline-table', textAlign: 'center' }}>
                                                                <Box my={4} alignItems="center">
                                                                    <Box className="event-date">
                                                                        <Typography variant="body2" color="primary">
                                                                            <Moment locale="es" format="lll" fromNow>{item?.creado}</Moment>
                                                                        </Typography>
                                                                        <Typography variant="h5" gutterBottom>{item?.estadoTexto}</Typography>
                                                                    </Box>
                                                                    <Box className="event-down-icon"> {(
                                                                        item?.estadoTexto?.includes('Rechazado') ?
                                                                            <HighlightOff sx={{ mr: 1 }} color='error' style={{ fontSize: '40' }} />
                                                                            :
                                                                            <CheckCircleOutline sx={{ mr: 1 }} color='success' style={{ fontSize: '40' }} />
                                                                    )}

                                                                    </Box>
                                                                    <Box mt={3} px={3}>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {item?.usuario}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box mt={3} px={3}>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            <strong>{item?.rechazoTipoTexto}</strong>
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Box>
                </Modal>
            )}
        </Box>
    );
}
