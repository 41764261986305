/* eslint-disable react/prop-types */
import { Stack, Grid, Typography, Box } from '@mui/material';

const Toolbar = ({ ...props }) => (
    <Box container xs={{ bgcolor: 'background.paper' }}>
        <Grid item xs={false} sm={12} md={12}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    py: 4,
                    m: 1
                }}
            >
                <Stack spacing={1} sx={{ alignSelf: 'center', textTransform: 'uppercase', fontWeight: 'bolder' }}>
                    <Typography variant="h2">{props.title}</Typography>
                </Stack>
                {props.formulario}
            </Box>
        </Grid>
    </Box>
);

export default Toolbar;
