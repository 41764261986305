export function appendData(all, data, response) {
    const json = {
        ...data,
        ...response
    };
    return [...all, json];
}

export function updateData(allD, oneD) {
    const AllDa = allD.map((u) => (u.id !== oneD.id ? u : oneD));
    return AllDa;
}

export function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
}

export default { appendData, updateData, formatDate };
