/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { PostRoute, GetRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { updateData } from 'utils/function/index';
import { findData } from 'utils/function/global';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDataFederacion, setAllDataFederacion] = useState([]);
    const [allDataDetalleFederaciones, setAllDataDetalleFederaciones] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [opcion, setOpcion] = useState(0);
    const nameController = 'persona';
    const nameController2 = 'ProfesionalDeporte';

    const toggleModal = (data) => {
        setOpcion(data);
        if (data === 1) {
            setOneData([]);
        }
        setShow(!show);
    };

    // --------------------------------------------------
    const AllFederaciones = async (data) => {
        const response = await PostRoute(`${nameController2}/all`, {
            persona: data.id
        });
        setAllDataDetalleFederaciones(response.length ? response : []);
    };

    // --------------------------------------------------
    const All = async () => {
        const response = await PostRoute(`${nameController}/all`, {
            tipoPersona: 5
        });
        setAllData(response.length ? response : []);
    };

    const AllFederacion = async () => {
        const response = await GetRoute(`federacion/label`);
        setAllDataFederacion(response.length ? response : []);
    };
    const One = async (data, opcion) => {
        setShowLoad(true);
        setOneData(data);
        AllFederaciones(data);
        toggleModal(opcion);
        setShowLoad(false);
    };
    const StoreUpdate = async (data) => {
        setShowLoad(true);
        if (findData(allDataDetalleFederaciones, data, 'deporte').length === 0) {
            const response = await PostRoute(`${nameController2}/${!data.id ? 'store' : 'update'}`, data);
            AllFederaciones({ id: data.persona });
            Notification(response.message, 1);
        } else {
            Notification('La federacion ya se encuentra registradas', 0);
        }
        setShowLoad(false);
    };
    const Actions = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id });
        setAllData(updateData(allData, { ...data, estado: data.estado === 1 ? 0 : 1 }));
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const value = {
        All,
        One,
        Actions,
        setOpcion,
        StoreUpdate,
        toggleModal,
        allDataDetalleFederaciones,
        allDataFederacion,
        allData,
        show,
        oneData,
        opcion
    };
    useEffect(() => {
        All();
        AllFederacion();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
