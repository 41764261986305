/* eslint-disable jsx-a11y/alt-text */
import { useContext } from 'react';
import { Modal, Box, Grid } from '@mui/material';
import MainCard from 'utils/components/modal/MainCardModal';
import { ContentContext } from './context';

export default function KeepMountedModal() {
    const { showPdf, setShowPdf, pdf } = useContext(ContentContext);
    return (
        <Box>
            <Modal
                keepMounted
                open={showPdf}
                onClose={() => setShowPdf(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Ficha Técnica" size="xl" closeButton={() => setShowPdf(false)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <object
                                    className="Visor"
                                    width="100%"
                                    height="700px"
                                    id="pdf-ficha"
                                    aria-labelledby="perfil"
                                    type="application/pdf"
                                    data={pdf}
                                />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
