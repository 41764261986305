import { Routes, Route, Navigate } from 'react-router-dom';
import Rol from '../../views/it/roles/index';
import ConfigCorreo from '../../views/it/config/index';
import Usuario from '../../views/it/usuarios/index';
import TipoPersona from '../../views/it/tipoPersona/index';
import Departamento from '../../views/it/departamento/index';
import Municipio from '../../views/it/municipio/index';
import Pais from '../../views/it/pais/index';
import EstadoCivil from '../../views/it/estadoCivil/index';
import Genero from '../../views/it/genero/index';

const ItRoutes = () => (
    <Routes>
        <Route exact path="rol" element={<Rol />} />
        <Route exact path="config" element={<ConfigCorreo />} />
        <Route exact path="tipo-persona" element={<TipoPersona />} />
        <Route exact path="usuario" element={<Usuario />} />
        <Route exact path="pais" element={<Pais />} />
        <Route exact path="departamento" element={<Departamento />} />
        <Route exact path="municipio" element={<Municipio />} />
        <Route exact path="estado-civil" element={<EstadoCivil />} />
        <Route exact path="genero" element={<Genero />} />
        <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
);

export default ItRoutes;
