import { useContext, useEffect } from 'react';
import { ContentContext } from '../context';
import DatosFamiliares from './datosFamiliares';
import Anamnesis from './anamnesis';
import Antecedentes from './antecedentes';

const HistoriaClinicaDeportiva = () => {
    const { dataVal, code, OneDf, AllAntecedentes, OneAnamnesis } = useContext(ContentContext);

    useEffect(() => {
        if (dataVal === 1) {
            OneDf(code);
            AllAntecedentes(code);
            OneAnamnesis(code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, dataVal]);
    return (
        <>
            {dataVal === 1 && (
                <>
                    <DatosFamiliares />
                    <Anamnesis />
                    <Antecedentes />
                </>
            )}
        </>
    );
};

export default HistoriaClinicaDeportiva;
