/* eslint-disable react/prop-types */
import { Routes, Route } from 'react-router-dom';
import Federaciones from '../../views/cienciasAplicadas/federacion/index';
import CentroAtencion from '../../views/cienciasAplicadas/centroAtencion/index';
import Diagnostico from '../../views/cienciasAplicadas/diagnostico/index';
import MomentoTemporada from '../../views/cienciasAplicadas/momentoTemporada/index';
import AreaAfectada from '../../views/cienciasAplicadas/areaAfectada/index';
import TipoEvaluacion from '../../views/cienciasAplicadas/tipoEvaluacion/index';
import TipoAtencion from '../../views/cienciasAplicadas/tipoAtencion/index';
import TipoPotencia from '../../views/cienciasAplicadas/tipoPotencia/index';
import TipoCarrera from '../../views/cienciasAplicadas/tipoCarrera/index';
import TipoAntecedentes from '../../views/cienciasAplicadas/tipoAntecedente/index';
import TipoExamenFisicoDeportivo from '../../views/cienciasAplicadas/tipoExamenFisico/index';
import ActividadesRealizadas from '../../views/cienciasAplicadas/actividadesRealizadas/index';
import AtenderPersona from '../../views/cienciasAplicadas/atenderPersona/index';
import PerfilMedico from '../../views/cienciasAplicadas/perfilMedico/index';
import FederacionProfesional from '../../views/cienciasAplicadas/profesionalDeporte/index';
import SeccionCineantropometria from '../../views/cienciasAplicadas/seccionCineantropometria/index';
import TipoCineantropometria from '../../views/cienciasAplicadas/tipoCineantropometria/index';
import TipoCiclosCompetencia from '../../views/cienciasAplicadas/tipoCiclosCompetencia/index';
import TipoPruebaAerobica from '../../views/cienciasAplicadas/tipoPruebaAerobica/index';
// import Evaluacion from '../../views/cienciasAplicadas/evaluacion/index';
import Solicitud from '../../views/cienciasAplicadas/solicitud/index';
import SolicitudesActuales from '../../views/cienciasAplicadas/atencionCitasMedicas/index';
import HistorialCitas from '../../views/cienciasAplicadas/HistorialSolicitudes/index';
import AutorizarCitas from '../../views/cienciasAplicadas/AutorizarCitaMedica/index';
import TipoFuerzaFlexibilidad from '../../views/cienciasAplicadas/tipoFuerzaFlexibilidad/index';

const ScienceRoutes = ({ userRoles, Roles }) => (
    <Routes>
        {userRoles.includes(Roles.ci_adm) && (
            <Route path="catalogos/">
                <Route path="federacion" element={<Federaciones />} />
                <Route path="centro-atencion" element={<CentroAtencion />} />
                <Route path="diagnostico" element={<Diagnostico />} />
                <Route path="momento-temporada" element={<MomentoTemporada />} />
                <Route path="area-afectada" element={<AreaAfectada />} />
                <Route path="tipo-evaluacion" element={<TipoEvaluacion />} />
                <Route path="tipo-atencion" element={<TipoAtencion />} />
                <Route path="tipo-antecedentes" element={<TipoAntecedentes />} />
                <Route path="tipo-examen-fisico-deportivo" element={<TipoExamenFisicoDeportivo />} />
                <Route path="actividades-realizadas" element={<ActividadesRealizadas />} />
                <Route path="federacion-profesional" element={<FederacionProfesional />} />
                <Route path="seccion-cineantropometria" element={<SeccionCineantropometria />} />
                <Route path="tipo-cineantropometria" element={<TipoCineantropometria />} />
                <Route path="tipo-ciclos-competencia" element={<TipoCiclosCompetencia />} />
                <Route path="tipo-carrera" element={<TipoCarrera />} />
                <Route path="tipo-potencia" element={<TipoPotencia />} />
                <Route path="tipo-prueba-aerobica" element={<TipoPruebaAerobica />} />
                <Route path="tipo-fuerza-flexibilidad" element={<TipoFuerzaFlexibilidad />} />
            </Route>
        )}
        {/* <Route path="evaluacion" element={<Evaluacion />} /> */}
        <Route path="atencion-cita-medica" element={<SolicitudesActuales />} />
        <Route path="calendario-citas-medicas" element={<Solicitud />} />
        <Route path="historial-citas-medicas" element={<HistorialCitas />} />
        <Route path="autorizar-cita-medica" element={<AutorizarCitas />} />
        <Route path="atencion-medica" element={<AtenderPersona />} />

        <Route path="perfil-medico/:code/:solicitud" element={<PerfilMedico />} />
        {/* <Route path="*" element={<Navigate to="/error" />} /> */}
    </Routes>
);

export default ScienceRoutes;
