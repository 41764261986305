/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import { CustomText } from 'utils/components/customTable/index';
import { TextField, Container, Stack, Grid, IconButton } from '@mui/material';
import { IconEdit } from '@tabler/icons';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px'
            }
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px'
            }
        }
    };

    const { OneCompensacionEconomica, allDataCompensacion } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allDataCompensacion.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Última Acualización',
            sortable: true,
            center: true,
            width: '150px',
            selector: (row) => CustomText(row.fechaIngreso)
        },
        {
            name: 'Prueba',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.disciplina)
        },
        {
            name: 'Código Caja',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.codigoCaja)
        },
        {
            name: 'Ubicación',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.ubicacion)
        },
        {
            name: 'monto',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.monto)
        },
        {
            name: 'Porcentaje',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.porcentaje)
        },
        {
            name: 'Equipo',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.equipo)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => OneCompensacionEconomica(row)}>
                        <IconEdit />
                    </IconButton>
                </Stack>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', ml: 0 }} spacing={2}>
                <Grid item xs={4}>
                    <TextField label="Buscar" size="small" sx={{ width: '100%' }} value={searchValue} onChange={handleFilter} />
                </Grid>
            </Grid>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                data={searchValue.length ? filteredData : allDataCompensacion}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                customStyles={customStyles}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
