/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable no-use-before-define */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/images/logo-2.png'; // Cambia esta ruta al logo correspondiente
import Ft1 from 'assets/images/footer/ft1.png';
import Ft2 from 'assets/images/footer/ft2.png';
import Ft3 from 'assets/images/footer/ft3.png';

let base64Ft1 = null;
toDataURL(Ft1, (Base) => {
    base64Ft1 = Base;
});

let base64Ft2 = null;
toDataURL(Ft2, (Base) => {
    base64Ft2 = Base;
});

let base64Ft3 = null;
toDataURL(Ft3, (Base) => {
    base64Ft3 = Base;
});

let base64 = null;
toDataURL(Logo, (Base) => {
    base64 = Base;
});

export default function PDFCertificado1(dataHeader) {
    const doc = new jsPDF('p', 'pt', 'letter').setProperties({ title: 'Certificado Médico 2' });

    function pageContent() {
        const margin = 85.05; // 3 cm in points
        const indent = 35; // Sangría para el texto
        const maxWidth = doc.internal.pageSize.getWidth() - margin * 2;

        // Logo Empresa
        const logoWidth = 40;
        const logoHeight = 80;
        doc.setFontSize(6).setFont('helvetica', 'normal');
        doc.addImage(base64, 'PNG', margin - 50, 15, logoWidth, logoHeight); // Ajusta la posición del logo según sea necesario

        // Encabezado centrado
        doc.setFontSize(14);
        const headerText = 'SUBGERENCIA DE CIENCIAS DEL DEPORTE PARA EL\nALTO RENDIMIENTO DEPORTIVO\nCOMITÉ OLIMPICO GUATEMALTECO';
        const headerLines = doc.splitTextToSize(headerText, maxWidth - logoWidth - 20); // Dividir texto para ajustarlo al espacio disponible

        let y = 40;
        headerLines.forEach((line) => {
            doc.text(line, doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
            y += 15;
        });

        y += 55; // Añadir espacio después del encabezado

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal').text('EL COMITÉ OLIMPICO GUATEMALTECO', doc.internal.pageSize.getWidth() / 2, y, {
            align: 'center'
        });
        y += 15;
        doc.text('Y LA DIRECCIÓN DE FISIOLOGÍA Y MEDICINA DEL DEPORTE', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 60;
        doc.setFont('helvetica', 'bold');
        doc.text('CERTIFICA QUE:', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });

        y += 75;

        // Función para justificar texto con negrita
        function addParagraph(text, boldSegments, y, isBold = false) {
            const lines = doc.splitTextToSize(text, maxWidth - indent);
            lines.forEach((line, index) => {
                const words = line.split(' ');
                const lineIndent = index === 0 ? indent : 0; // Añadir sangría solo a la primera línea
                let lineWidth = words.reduce((acc, word) => acc + doc.getTextWidth(word + ' '), 0);
                let gapWidth = (maxWidth - lineWidth - indent) / (words.length - 1);

                // Ajustar para líneas cortas
                if (index === lines.length - 1 || words.length === 1 || lineWidth > maxWidth - indent) {
                    gapWidth = 0;
                }

                let currentX = margin + lineIndent;
                let currentWordIndex = 0;

                while (currentWordIndex < words.length) {
                    let segmentFound = false;

                    for (let i = 0; i < boldSegments?.length; i++) {

                        const segment = boldSegments[i];
                        const segmentWords = segment.split(' ');
                        const segmentLength = segmentWords.length;

                        if (words.slice(currentWordIndex, currentWordIndex + segmentLength).join(' ') === segment) {
                            doc.setFont('helvetica', 'bold');
                            doc.text(segment, currentX, y);
                            currentX += doc.getTextWidth(segment + ' ');
                            currentWordIndex += segmentLength;
                            segmentFound = true;
                            break;
                        }
                    }

                    if (!segmentFound) {
                        if (isBold) {
                            doc.setFont('helvetica', 'bold');
                        } else {
                            doc.setFont('helvetica', 'normal');
                        }
                        const word = words[currentWordIndex];
                        doc.text(word, currentX, y);
                        currentX += doc.getTextWidth(word + ' ') + (currentWordIndex < words?.length - 1 ? gapWidth : 0);
                        currentWordIndex++;
                    }
                }

                y += 15;
            });
            return y;
        }

        // Llama a la función de ejemplo
        y = addParagraph(
            `Atleta ${dataHeader?.nombreAtletaPersona?.toUpperCase()} de ${dataHeader?.edad} años de edad, de la Federación / Asociación Deportiva Nacional de ${dataHeader?.nombreFederacion?.toUpperCase()} tras reconocimiento médico con realización y valoración de pruebas clínicas multidisciplinarias, se establece que en la actualidad no presenta ninguna enfermedad o lesión deportiva.`,
            [
                dataHeader?.nombreAtletaPersona?.toUpperCase(),
                dataHeader?.edad,
                dataHeader?.nombreFederacion?.toUpperCase()
            ],
            y
        );

        y += 12;

        y = addParagraph('No le impide continuar con entrenamiento físico. Siendo considerado:', [], y);

        y += 12;

        y = addParagraph(
            `APTO PARA LA PRACTICA DE LA DISCIPLINA DEPORTIVA DE ${dataHeader?.nombreFederacion?.toUpperCase()} A NIVEL COMPETITIVO.`,
            [],
            y,
            true // Hacer todo el párrafo en negrilla
        );

        y += 12;

        y = addParagraph('Se hace constar a efectos oportunos y a instancias del servicio médico del Comité Olímpico Guatemalteco.', [], y);

        y += 12;

        y = addParagraph(`En Ciudad de Guatemala, ${dataHeader?.textoFechaAprobacionDr}.`, [`${dataHeader?.textoFechaAprobacionDr}.`], y);

        // Firma centrada
        y += 80; // Añadir espacio para la firma
        doc.setFont('helvetica', 'normal');
        doc.text('______________________________', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 20;
        doc.text('Dr. Allan Kevin Divas', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 15;
        doc.text('Director de Fisiología y Medicina Deportiva', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 15;
        doc.text('Comité Olímpico Guatemalteco', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 15;
        doc.text('Colegiado No. 19931', doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });

        // Añadir el footer
        const footerY = doc.internal.pageSize.getHeight() - 60;
        const footerMargin = margin - 30;
        const iconSize = 20;
        const textOffset = 5;

        // Logo y texto a la izquierda
        doc.addImage(base64Ft1, 'PNG', footerMargin, footerY, iconSize, iconSize);
        doc.setFontSize(10).setFont('helvetica', 'normal');
        doc.text('PBX: (502) 2223 9500', footerMargin + iconSize + textOffset, footerY + 15);

        // Logo y texto al centro
        const centerX = doc.internal.pageSize.getWidth() / 2 - 85;
        doc.addImage(base64Ft2, 'PNG', centerX, footerY, iconSize, iconSize);
        doc.text('26 calle 9-31 zona 5, Palacio de ', centerX + iconSize + textOffset, footerY + 10);
        doc.text('Los Deportes, Guatemala, C.A.', centerX + iconSize + textOffset, footerY + 20);

        // Logo y texto a la derecha
        const rightX = doc.internal.pageSize.getWidth() / 2 + 130;
        doc.addImage(base64Ft3, 'PNG', rightX, footerY, iconSize + 5, iconSize);
        doc.text('congua@cog.org.gt', rightX + iconSize + textOffset, footerY + 15);
    }

    // Llama a pageContent directamente
    pageContent();

    return doc.output('datauristring');
}

function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
