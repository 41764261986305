import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Templatelogin from '../views/auth/index';
import Forgot from '../views/auth/forgot';
import ResetPassword from '../views/auth/resetPassword';
import Login from '../views/auth/login';
import Error from '../views/errors/error404';
import AtenderAtleta from '../views/public/llamarAtleta/index';
import { UserContext } from 'utils/context/userContext';
// Private
import App from './app/index';

const Routing = () => {
    const { userAuth, validateUrl } = useContext(UserContext);

    return (
        <Routes>
            {validateUrl && userAuth.length === 0 && <Navigate to="/auth/login" replace />}
            {/* routes public */}
            {userAuth.length === 0 ? (
                <Route>
                    <Route path="atencion" element={<AtenderAtleta />} />
                    <Route path="/auth" element={<Templatelogin />}>
                        <Route path="login" element={<Login />} />
                        <Route path="forgot-password" element={<Forgot />} />
                        <Route path="reset-password/:pin/:codigo" element={<ResetPassword />} />
                    </Route>
                </Route>
            ) : (
                // routes private
                !userAuth.length && userAuth.roles && userAuth.estado === '1' && <Route path="/*" element={<App />} />
            )}

            <Route path="/error" element={<Error />} />
        </Routes>
    );
};

export default Routing;
