/* eslint-disable react/prop-types */
import { Card, Box, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import { IconChartCircles, IconFriends, IconCalendarEvent, IconId, IconX, IconEye, IconEdit, IconCheck } from '@tabler/icons';
import { Menu, Item, useContextMenu } from 'react-contexify';

const CardPersona = ({ item, One, Actions }) => {
    const { show } = useContextMenu({ id: `id-${item.id}` });

    const displayMenu = (e) => {
        show(e);
    };
    return (
        <>
            <Card sx={{ display: 'flex' }} className="box-shadow" onContextMenu={displayMenu}>
                <Grid container sx={{ display: 'flex' }} className="box-shadow">
                    <Grid item lg={5} md={12} sm={12} xs={12} sx={{ maxHeight: '210.859px', minHeight: '210.859px' }}>
                        <CardMedia component="img" sx={{ height: '100%' }} image={item.foto} alt={item.nombreCompleto} />
                    </Grid>
                    <Grid item lg={7} md={12} sm={12} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {item.id} - {item.nombreCompleto}
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <IconCalendarEvent stroke={1.5} size="1rem" /> {item.fechaNacimiento}
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <IconId stroke={1.5} size="1rem" /> {item.dpi}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="caption" component="div">
                                            <IconFriends stroke={1.5} size="1rem" /> {item.genero}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="caption" component="div">
                                            <IconChartCircles stroke={1.5} size="1rem" /> {item.estadoCivil}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    py: 1
                                }}
                            >
                                <Typography variant="caption">Ultima Actualización: {item.actualizado}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Menu id={`id-${item.id}`}>
                    <Item onClick={() => One(item, 3)}>
                        <IconEye stroke={1.5} size="1rem" /> Visualizar
                    </Item>
                    <Item onClick={() => One(item, 2)}>
                        <IconEdit stroke={1.5} size="1rem" /> Editar
                    </Item>
                    <Item onClick={() => Actions(item)}>
                        {item.estado ? (
                            <>
                                <IconX stroke={1.5} size="1rem" /> Deshabilitar
                            </>
                        ) : (
                            <>
                                <IconCheck stroke={1.5} size="1rem" /> Habilitar
                            </>
                        )}
                    </Item>
                </Menu>
            </Card>
        </>
    );
};

export default CardPersona;
