/* eslint-disable react/prop-types */
import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data, label, name, posicion, id }) => {
    // Verificar que 'posición' es un número válido y que no está fuera de los límites del array 'data'
    // Primero, redondeamos la posición
    const roundedPosition = Math.round(posicion);

    // Luego, determinamos si "position" es un número o una cadena
    const formattedPosition = Number(posicion) ? roundedPosition : String(roundedPosition);
    // Finalmente, encontramos el índice de esa posición en el array de datos
    const xAnnotation = data.indexOf(formattedPosition) + 1;

    const options = {
        chart: {
            id: id || ''
        },
        xaxis: {
            categories: label
        },
        annotations: {
            xaxis: [
                {
                    x: xAnnotation,
                    borderColor: '#F00',
                    yAxisIndex: 0,
                    label: {
                        show: xAnnotation > 0,
                        text: 'UAn',
                        style: {
                            color: '#fff',
                            background: '#F00'
                        }
                    }
                }
            ]
        }
    };

    const series = [
        {
            name,
            data
        }
    ];

    return id ? (
        <Chart id={id} options={options} series={series} type="line" width="100%" height="300px" />
    ) : (
        <Chart options={options} series={series} type="line" width="100%" height="300px" />
    );
};

export default React.memo(LineChart);
