import { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Cineantropometria from './cineantropometria';
import CicloCompetencias from './cicloCompetencias';
import Formulario from './formulario';
import Historial from './historial';

const Index = () => {
    const { dataVal, AllNutricionCineantropometria, oneDataNutricionCineantropometria, OneNutricionCinenatropometria } =
        useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const [dataVal3, setDataVal3] = useState(0);

    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    const handleTabChange3 = (event, newValue) => {
        setDataVal3(newValue);
    };

    useEffect(() => {
        if (dataVal === 4) {
            OneNutricionCinenatropometria();
            AllNutricionCineantropometria();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);
    return (
        <>
            {dataVal === 4 && (
                <>
                    <Tabs value={dataVal2} onChange={handleTabChange2}>
                        <Tab label="Formulario" />
                        <Tab label="Historial" />
                    </Tabs>

                    {dataVal2 === 0 && (
                        <>
                            <Formulario />
                            {oneDataNutricionCineantropometria?.id && (
                                <>
                                    <Tabs value={dataVal3} onChange={handleTabChange3}>
                                        <Tab label="CINEANTROPOMETRIA" />
                                        <Tab label="CICLO DE COMPETENCIAS" />
                                    </Tabs>

                                    {dataVal3 === 0 && <Cineantropometria />}
                                    {dataVal3 === 1 && <CicloCompetencias />}
                                </>
                            )}
                        </>
                    )}
                    {dataVal2 === 1 && <Historial />}
                </>
            )}
        </>
    );
};

export default Index;
