/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { createContext, useState, useContext, useEffect } from 'react';
import { LoadingContext } from 'utils/components/loading/context';
import { NotificationContext } from 'utils/components/snackBar/context';
import { PostRouteFD, PostRoute } from '../../../services/Private';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const { setShowLoad } = useContext(LoadingContext);
    const [listado, setListado] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [oneData, setOneData] = useState([]);
    const [oneDataCorreo, setOneDataCorreo] = useState([]);
    const nameController = 'entidad';
    const nameController2 = 'configLogin';
    const nameController3 = 'correo';
    const StoreUpdate = async (data, url) => {
        setShowLoad(true);
        const response = await PostRouteFD(`${nameController}/${url}`, data);
        Notification(response.message, 1);
        setShowLoad(false);
        return response;
    };
    const One = async () => {
        const response = await PostRoute(`${nameController}/one`, { id: 1 });
        setOneData(response.length ? response[0] : []);
    };
    const AllCarrousel = async () => {
        const response = await PostRoute(`${nameController2}/all`, { entidad: 1 });
        setListado(response.length ? response : []);
    };
    const StoreFile = async (storeItem) => {
        const response = await PostRouteFD(`${nameController2}/${!storeItem.id ? 'store' : 'update'}`, storeItem);
        if (response.value === 1) {
            AllCarrousel();
        }
        Notification(response.message, 1);
    };
    const DestroyItem = async (idItem) => {
        const response = await PostRoute(`${nameController2}/destroy`, { id: idItem });
        Notification(response.message, 1);
        AllCarrousel();
    };
    const StoreCorreo = async (data) => {
        setShowLoad(true);
        const response = await PostRoute(`${nameController3}/${!data.id ? 'store' : 'update'}`, data);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const OneCorreo = async () => {
        const response = await PostRoute(`${nameController3}/one`, { entidad: 1 });
        setOneDataCorreo(response.length ? response[0] : []);
    };
    useEffect(() => {
        One();
        AllCarrousel();
        OneCorreo();
    }, []);

    const value = {
        StoreCorreo,
        DestroyItem,
        StoreUpdate,
        setDataList,
        setListado,
        StoreFile,
        oneData,
        listado,
        dataList,
        oneDataCorreo
    };
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
