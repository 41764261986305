import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Save } from '@mui/icons-material';

const DatosFamiliares = () => {
    const { StoreUpdateAnam, oneDataAnamnesis } = useContext(ContentContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            motivo: data.motivo,
            historiaClinica: data.historiaClinica,
            id: oneDataAnamnesis.length !== 0 ? oneDataAnamnesis.id : null
        };
        StoreUpdateAnam(jsData);
    };

    const setData = () => {
        setValue('motivo', oneDataAnamnesis.motivo);
        setValue('historiaClinica', oneDataAnamnesis.historiaClinica);
    };

    useEffect(() => {
        if (oneDataAnamnesis.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataAnamnesis]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 2 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                ANAMNESIS
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.motivo}
                            {...register('motivo', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="motivo"
                            label="Motivo de Consulta"
                            multiline
                            rows={4}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.motivo && errors.motivo.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            error={!!errors.historiaClinica}
                            {...register('historiaClinica', { required: { value: true, message: 'Este campo es requerido' } })}
                            size="small"
                            id="historiaClinica"
                            label="Historia Clinica Deportiva"
                            multiline
                            rows={4}
                            InputLabelProps={activeLabel}
                            helperText={!!errors.historiaClinica && errors.historiaClinica.message}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                <AnimateButton>
                    <BootstrapTooltip title="Guardar" arrow placement="left">
                        <Button
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{
                                background: theme.palette.success.main,
                                '&:hover': {
                                    background: theme.palette.success.dark
                                },
                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                maxWidth: '48px' // Cambia el ancho máximo del botón
                            }}
                        >
                            <Save fontSize="medium" />
                        </Button>
                    </BootstrapTooltip>
                </AnimateButton>
            </Box>
        </Box>
    );
};

export default DatosFamiliares;
