import { useContext, useEffect } from 'react';
import {
    Modal,
    TextField,
    IconButton,
    Box,
    Button,
    FormControl,
    InputLabel,
    Typography,
    Checkbox,
    Grid,
    FormControlLabel
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import MainCard from 'utils/components/modal/MainCardModal';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import Save from '@mui/icons-material/Save';
import { ContentContext } from './context';
import Select from 'react-select';

export default function KeepMountedModal() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset
    } = useForm();
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const { show, toggleModal, oneData, StoreUpdate, opcion, allDataSeccionCineantropometria } = useContext(ContentContext);
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            seccion: data.seccion.value,
            nombre: data.nombre,
            unidadMedida: data.unidadMedida,
            modal: data.modal,
            estado: oneData.length !== 0 ? oneData.estado : 1,
            id: oneData.length !== 0 ? oneData.id : null
        };
        StoreUpdate(jsData);
        setValue('nombre', '');
    };
    const setData = async () => {
        await setValue('nombre', oneData.nombre);
        await setValue('seccion', { label: oneData.seccionCineantropometria, value: oneData.idSeccionCineantropometria });
    };

    useEffect(() => {
        async function fetchMyAPI() {
            if ((await opcion) > 1 && oneData) {
                await setData();
            } else {
                reset();
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcion, oneData]);
    return (
        <Box>
            <BootstrapTooltip title="Crear" arrow placement="left">
                <IconButton
                    onClick={() => toggleModal(1)}
                    aria-label="Crear"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.primary.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark
                        }
                    }}
                >
                    <Add fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <MainCard
                        title={`${opcion === 1 ? 'Crear' : ''} ${opcion === 2 ? 'Visualizar' : ''} ${
                            opcion === 3 ? 'Actualizar' : ''
                        } Registro`}
                        closeButton={() => toggleModal(0)}
                    >
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="seccion" shrink sx={{ background: '#FFF', px: 1 }}>
                                Sección Cineantropometría
                            </InputLabel>
                            <Controller
                                control={control}
                                name="seccion"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataSeccionCineantropometria}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.seccion ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.seccion && errors.seccion.message}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.nombre}
                                {...register('nombre', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Nombre"
                                InputProps={{
                                    readOnly: opcion === 2
                                }}
                                focused={oneData.length !== 0}
                                helperText={!!errors.nombre && errors.nombre.message}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.unidadMedida}
                                {...register('unidadMedida', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Unidad de Medida"
                                InputProps={{
                                    readOnly: opcion === 2
                                }}
                                focused={oneData.length !== 0}
                                helperText={!!errors.unidadMedida && errors.unidadMedida.message}
                            />
                        </FormControl>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox {...label} defaultChecked {...register('modal')} />}
                                    label="Mantener formulario"
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Guardar" arrow placement="left">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.dark,
                                            '&:hover': {
                                                background: theme.palette.success.light
                                            }
                                        }}
                                    >
                                        <Save fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
