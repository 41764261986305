import { useContext, useState, useEffect } from 'react';
import { ContentContext } from '../context';
import { Tab, Tabs } from '@mui/material';
import Visualizar from './visualizar';
import Formulario from './formulario';
import Historial from './historial';

const Index = () => {
    const { dataVal, AllPruebaAerobica } = useContext(ContentContext);
    const [dataVal2, setDataVal2] = useState(0);
    const handleTabChange2 = (event, newValue) => {
        setDataVal2(newValue);
    };
    useEffect(() => {
        if (dataVal === 8) {
            AllPruebaAerobica();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVal]);
    return (
        <>
            {dataVal === 8 && (
                <>
                    <Tabs value={dataVal2} onChange={handleTabChange2}>
                        <Tab label="FORMULARIO" />
                        <Tab label="HISTORIAL" />
                    </Tabs>

                    {dataVal2 === 0 && <Formulario />}
                    {dataVal2 === 1 && <Historial />}
                    <Visualizar />
                </>
            )}
        </>
    );
};

export default Index;
