/* eslint-disable react/prop-types */
import { useContext, useState, useCallback } from 'react';
import { IconButton, Box, useTheme } from '@mui/material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import BootstrapTooltip from 'utils/components/tooltip/index';
import ModalPdf from 'ui-component/templatePdf/visor';
import { generatePDF } from './templatePdf';
import { UserContext } from 'utils/context/userContext';
import { PostRoute } from '../../../../../services/Private';

const ExportPDF = ({ data, nameFile }) => {
    const { userAuth } = useContext(UserContext);
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState([]);

    const getHeader = useCallback(
        (item) => ({
            content: item,
            colSpan: 3,
            styles: {
                halign: 'center',
                fontStyle: 'bold',
                fontSize: 14,
                fillColor: null,
                cellPadding: { top: 5, bottom: 5 }
            }
        }),
        []
    );

    const detaultData = useCallback((data) => [data.detalleTipoFuerza, data.valor], []);

    const showModal = useCallback(async () => {
        const response = await PostRoute(`detalleEvaluacionFuerzaFlexibilidad/all`, { evaluacionFuerzaFlexibilidad: data.row?.id });
        let titulo = '';
        const items = [];

        response.forEach((item, index) => {
            if (index === 0 || titulo !== item.tipoFuerza) {
                titulo = item.tipoFuerza;
                items.push([getHeader(titulo), '']);
            }
            items.push(detaultData(item));
        });

        const doc = await generatePDF(
            items, // Asegúrate de que generatePDF maneja correctamente esta estructura plana.
            [['', '']],
            'EVALUACIÓN FUERZA Y FLEXIBILIDAD',
            '',
            nameFile,
            data,
            userAuth.nombreCompleto,
            1
        );

        setPdf(doc);
        setShow(true);
    }, [data, nameFile, userAuth.nombreCompleto, getHeader, detaultData]);

    return (
        <Box>
            <BootstrapTooltip title="Exportar PDF" arrow placement="left">
                <IconButton
                    onClick={showModal}
                    aria-label="Exportar"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.error.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark
                        }
                    }}
                >
                    <PictureAsPdf fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <ModalPdf showPdf={show} setShowPdf={setShow} title="Visualizar" pdf={pdf} />
        </Box>
    );
};

export default ExportPDF;
