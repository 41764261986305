/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Blowfish from 'javascript-blowfish';

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('authUserCog');
    localStorage.removeItem('img');
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // eslint-disable-next-line no-restricted-globals
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function PostLogin(url, form) {
    const data = JSON.stringify({
        ...form
    });

    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${process.env.REACT_APP_ROUTE_BASE}/${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    });

    return response;
}

export async function login(data) {
    let user = await PostLogin('Login/login', data);

    user = await handleResponse(user);
    if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes 'Llave para obtener contenido nuevo'
        const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
        user.authdata = window.btoa(data);
        const dataString = JSON.stringify(user);
        const encrypted = await bf.encrypt(dataString);
        const encryptedMime = bf.base64Encode(encrypted);
        localStorage.setItem('authUserCog', encryptedMime);
    }

    return user;
}

export function Exit() {
    // remove user from local storage to log user out
    localStorage.removeItem('authUserCog');
    window.location.href = '/login';
}

export const userService = {
    login,
    logout,
    Exit
};
