/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import { useContext, useState, useEffect } from 'react';
import { ContentContext } from '../context';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CirculoRojo from 'assets/images/circuloRojo.png';
import CuerpoHumano from 'assets/images/cuerpo.png';

const Prueba = () => {
    const { oneDataValoracionFisioterapeutica, StoreUpdatePrubeaPostural, dataPartesAfectadas } = useContext(ContentContext);
    const [expanded, setExpanded] = useState(false);
    const [partes, setPartes] = useState([]);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const Chekear = (e, item) => {
        const data = e.target;
        if (data.checked) {
            setPartes([...partes, item]);
        } else {
            const rast = partes.filter((value) => value !== item);
            setPartes(rast);
        }

        StoreUpdatePrubeaPostural({ valor: item, valoracionFisioterapeutica: oneDataValoracionFisioterapeutica?.id || null });
    };

    const partesCuerpo = [
        {
            titulo: 'ESPALDA',
            hijos: [
                'Suboccipital',
                'Angular de omoplato o elevador de escápula',
                'Trapecio',
                'Hombro',
                'Romboides',
                'Infraespinoso',
                'Supraespinoso',
                'Redondo menor',
                'Redondo mayor',
                'Subescapular',
                'Serrato posterior superior',
                'Dorsal ancho',
                'Erector de la espalda',
                'Glúteo mayor',
                'Glúteo menor',
                'Rotadores profundos (piriformes)',
                'Cuadrado lumbar',
                'Paraespinosos profundos'
            ]
        },
        {
            titulo: 'BRAZO',
            hijos: [
                'Deltoides anterior',
                'Deltoides medio',
                'Deltoides posterior',
                'Bíceps braquial',
                'Tríceps braquial',
                'Braquial anterior',
                'Coraco braquial',
                'Supinador',
                'Flexores de antebrazo',
                'Extensores de antebrazo',
                'Flexor de mano',
                'Extensor de mano'
            ]
        },
        {
            titulo: 'PIERNAS',
            hijos: [
                'Supinadores del muslo',
                'Cuádriceps',
                'Tensor de fascia lata',
                'Iliosoas',
                'Tibias anterior',
                'Pronadores de pierna (Ízquiotibiales)',
                'Gastrocnemius',
                'Soleo',
                'Tendón de Aquiles'
            ]
        },
        {
            titulo: 'PARTE ANTERIOR DEL TRONCO',
            hijos: ['Pectoral mayor', 'Pectoral menor', 'Serrato anterior', 'Diafragma', 'Oblicuo externo', 'Recto anterior del abdomen']
        }
    ];

    useEffect(() => {
        setPartes(JSON.parse(dataPartesAfectadas?.valores || '[]'));
    }, [dataPartesAfectadas]);
    return (
        <Box component="form" noValidate sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4" textTransform="uppercase" fontWeight="bold" textAlign="center">
                Prueba Postural
            </Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Box sx={{ height: 800, width: 500, position: 'relative' }}>
                        {(partes.length > 0 ? partes : ['']).includes('Angular de omóplato o elevador de escápula') && (
                            <>
                                {/* Left */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 65, left: 107 }}>
                                    <BootstrapTooltip title="Angular de omóplato o elevador de escápula" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* scrum */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 65, left: 320 }}>
                                    <BootstrapTooltip title="Angular de omóplato o elevador de escápula" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* RIGHT */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 433, left: 107 }}>
                                    <BootstrapTooltip title="Angular de omóplato o elevador de escápula" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* supine */}

                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 433, left: 360 }}>
                                    <BootstrapTooltip title="Angular de omóplato o elevador de escápula" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                            </>
                        )}
                        {(partes.length > 0 ? partes : ['']).includes('Suboccipital') && (
                            <>
                                {/* Left */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 65, left: 120 }}>
                                    <BootstrapTooltip title="Suboccipital" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* scrum */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 58, left: 340 }}>
                                    <BootstrapTooltip title="Suboccipital" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* RIGHT */}
                                <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 428, left: 92 }}>
                                    <BootstrapTooltip title="Suboccipital" arrow placement="left">
                                        <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                    </BootstrapTooltip>
                                </Box>
                                {/* supine */}

                                {/* <Box sx={{ height: 20, maxWidth: 20, position: 'absolute', top: 433, left: 360 }}>
                                            <BootstrapTooltip title="Suboccipital" arrow placement="left">
                                                <img src={CirculoRojo} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                                            </BootstrapTooltip>
                                        </Box> */}
                            </>
                        )}
                        <img src={CuerpoHumano} alt="Cuerpo Humano" style={{ height: '100%', width: '100%' }} />
                    </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                    {partesCuerpo.map((data, index) => {
                        const titulo = data.titulo.replace(/ /g, '');
                        return (
                            <Accordion
                                key={index}
                                expanded={expanded === titulo}
                                onChange={handleChange(titulo)}
                                sx={{ border: '1px solid #CCC' }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${titulo}-content`}
                                    id={`${titulo}-header`}
                                >
                                    <Typography
                                        sx={{ width: '33%', flexShrink: 0 }}
                                        textTransform="uppercase"
                                        variant="h5"
                                        fontWeight="bold"
                                    >
                                        {data.titulo}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {data.hijos.map((data, index) => (
                                            <Grid key={index} item lg={12} xs={6}>
                                                <FormControlLabel
                                                    control={<Checkbox {...label} />}
                                                    label={data}
                                                    checked={partes.includes(String(data))}
                                                    onChange={(e) => {
                                                        Chekear(e, data);
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Prueba;
