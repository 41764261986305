/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import Chart from 'react-apexcharts';

const PieChart = ({ recuperacion, id }) => {
    const options = {
        chart: {
            id: id || ''
        },
        xaxis: {
            categories: [1, 2, 3]
        },
        plotOptions: {
            bar: {
                horizontal: false
            }
        }
    };
    const series = [
        {
            name: 'FC. ZE',
            data: recuperacion.length > 0 ? recuperacion : [0, 0, 0]
        }
    ];

    return id ? (
        <Chart id={id} options={options} series={series} type="bar" width="500" />
    ) : (
        <Chart options={options} series={series} type="bar" width="500" />
    );
};

export default PieChart;
