import { Box, Grid, TextField, Button, FormControl } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { NotificationContext } from 'utils/components/snackBar/context';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import { useEffect, useContext } from 'react';
import { ContentContext } from '../context';

const FormDetalle = () => {
    const {
        dataPruebaAerobica,
        dataFcBasal,
        StoreUpdateDetallePruebaAerobica,
        setAllDataTestWFooter,
        allDataTestWFooter,
        setAllDataTestWHeader,
        allDataTestWHeader
    } = useContext(ContentContext);
    const { Notification } = useContext(NotificationContext);
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setFocus
    } = useForm();
    const onSubmit = async (data) => {
        const jsData = {
            tiempo: data.tiempo,
            medicion: data.medicion,
            val2: data.val2,
            val3: data.val3
        };

        if (!dataPruebaAerobica) {
            Notification('El campo "Tipo de prueba Aeróbica" no puede estar vacío. Por favor, selecciónelo.', 0);
        }
        if (!dataFcBasal) {
            Notification('El campo "FC. Basal" no puede estar vacío. Por favor, ingresarlo.', 0);
        }
        if (dataPruebaAerobica && dataFcBasal) {
            const result = Number(data.tiempo) + 1;
            StoreUpdateDetallePruebaAerobica(jsData);
            setAllDataTestWFooter([...allDataTestWFooter, data.tiempo]);
            setAllDataTestWHeader([...allDataTestWHeader, Number(data.val2)]);
            await setValue('tiempo', result);
            setValue('medicion', '');
            setValue('val2', '');
            setValue('val3', '');
            setFocus('medicion');
        }
    };

    useEffect(() => {
        setValue('tiempo', 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={6} sm={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.tiempo}
                                {...register('tiempo', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Tiempo"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.tiempo && errors.tiempo.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={6} ms={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.medicion}
                                {...register('medicion', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="medicion"
                                label="Velocidad"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.medicion && errors.medicion.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={5} ms={5}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.val2}
                                {...register('val2', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="val2"
                                label="FC."
                                InputLabelProps={activeLabel}
                                helperText={!!errors.val2 && errors.val2.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={5} ms={5}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.val3}
                                {...register('val3', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="val3"
                                label="RPE"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.val3 && errors.val3.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={1} md={2} ms={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.info.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FormDetalle;
