import { Box, Grid, Typography, Button, FormControl, TextField } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { obtenerFechaActual } from 'utils/function/global';
import { ContentContext } from '../context';
import { useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Add, Check, Edit } from '@mui/icons-material';

const Formulario = () => {
    const { oneDataEvaluacionBiomecanica, StoreUpdateEvaluacionBiomecanica, FinalizarEvaluacionBiomecanica } = useContext(ContentContext);
    const theme = useTheme();
    const activeLabel = { shrink: true };
    const { handleSubmit, register, setValue } = useForm();
    const onSubmit = (data) => {
        const jsonData = {
            fecha: obtenerFechaActual(),
            observacion: oneDataEvaluacionBiomecanica?.id ? data.observacion : null,
            id: oneDataEvaluacionBiomecanica?.id || null
        };
        StoreUpdateEvaluacionBiomecanica(jsonData);
    };

    useEffect(() => {
        if (oneDataEvaluacionBiomecanica?.id) {
            setValue('observacion', oneDataEvaluacionBiomecanica.observacion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataEvaluacionBiomecanica]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item lg={5}>
                    <Typography sx={{ mb: 2 }} variant="h4">
                        Formulario Evaluación Biomecánica
                    </Typography>
                </Grid>
                <Grid item lg={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <AnimateButton>
                            <BootstrapTooltip title={oneDataEvaluacionBiomecanica?.id ? 'Actualizar' : 'Crear'} arrow placement="left">
                                <Button
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        background: oneDataEvaluacionBiomecanica?.id
                                            ? theme.palette.warning.main
                                            : theme.palette.primary.main,
                                        '&:hover': {
                                            background: oneDataEvaluacionBiomecanica?.id
                                                ? theme.palette.warning.dark
                                                : theme.palette.primary.dark
                                        },
                                        minWidth: '32px', // Cambia el ancho mínimo del botón
                                        maxWidth: '48px' // Cambia el ancho máximo del botón
                                    }}
                                >
                                    {oneDataEvaluacionBiomecanica?.id ? <Edit fontSize="medium" /> : <Add fontSize="medium" />}
                                </Button>
                            </BootstrapTooltip>
                        </AnimateButton>
                    </Box>
                </Grid>
                {oneDataEvaluacionBiomecanica?.id && (
                    <Grid item lg={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Finalizar" arrow placement="left">
                                    <Button
                                        onClick={() => FinalizarEvaluacionBiomecanica(oneDataEvaluacionBiomecanica)}
                                        size="medium"
                                        type="button"
                                        variant="contained"
                                        sx={{
                                            background: oneDataEvaluacionBiomecanica?.id
                                                ? theme.palette.success.main
                                                : theme.palette.primary.main,
                                            '&:hover': {
                                                background: oneDataEvaluacionBiomecanica?.id
                                                    ? theme.palette.success.dark
                                                    : theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Check fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                )}
            </Grid>
            {oneDataEvaluacionBiomecanica?.id && (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <FormControl fullWidth sx={{ my: 3 }}>
                            <TextField
                                {...register('observacion')}
                                size="small"
                                id="observacion"
                                label="Observación"
                                multiline
                                rows={4}
                                InputLabelProps={activeLabel}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default Formulario;
