import { useContext, useState, useMemo, useEffect } from 'react';
import CardPersona from '../../../utils/components/cards/cardEmpleado';
import { Box, TextField, Container, Grid, FormControl, Typography, Button, Menu, MenuItem } from '@mui/material';
import { customStyles } from 'utils/select/index';
import Pagination from '../../../utils/Pagination/Pagination';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from './context';
import Select from 'react-select';

const List = () => {
    const theme = useTheme();
    const { One, allData, Actions, allDataTipoPersona, All } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState('9');
    const { watch, control } = useForm();
    const tipoPersona = watch('tipoPersona');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (value) => {
        setSelectedOption(value);
        handleClose();
    };

    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombreCompleto.toLowerCase().startsWith(value.toLowerCase());
                const includes = item.nombreCompleto.toLowerCase().includes(value.toLowerCase());
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    useEffect(() => {
        if (tipoPersona) {
            All(tipoPersona.value);
        } else {
            All(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoPersona]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * selectedOption;
        const lastPageIndex = firstPageIndex + selectedOption;
        return (searchValue.length ? filteredData : allData).slice(firstPageIndex, lastPageIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, allData, filteredData, searchValue, selectedOption]);

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', mb: 5 }}>
                <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                    <Grid item xs={4} align="center">
                        <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <Controller
                                control={control}
                                name="tipoPersona"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoPersona}
                                        placeholder="Seleccione Tipo Persona"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={customStyles}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} sx={{ justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        {`${(currentPage - 1) * selectedOption + 1}-${Math.min(currentPage * selectedOption, allData.length)} de ${
                            allData.length
                        }`}
                    </Typography>
                </div>
                <div>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="small"
                        sx={{
                            alignSelf: 'center',
                            background: theme.palette.primary.dark,
                            color: '#FFF',
                            '&:hover': {
                                background: theme.palette.primary.light,
                                color: theme.palette.primary.dark
                            }
                        }}
                    >
                        {selectedOption} {/* Mostrar la opción seleccionada */}
                    </Button>
                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={() => handleMenuClick('9')}>9</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('18')}>18</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('27')}>27</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('36')}>36</MenuItem>
                    </Menu>
                </div>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 2 }}>
                {currentTableData.map((item, index) => (
                    <Grid key={index} item lg={4} md={4} sm={6} xs={12} sx={{ mt: 10 }}>
                        <CardPersona key={index} item={item} Actions={Actions} One={One} />
                    </Grid>
                ))}
                {currentTableData.length === 0 && (
                    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                        <Grid item xs={12} align="center">
                            <Typography variant="h5">
                                --------------------------------- No se encontraron datos ---------------------------------
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sx={{ mt: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage || filteredData}
                        totalCount={filteredData ? filteredData?.length : allData?.length}
                        pageSize={selectedOption}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default List;
