export function formatDate(date, lang) {
    let fecha = null;
    const f = date.split('-');
    if (lang === 'en') {
        fecha = `${f[0]}-${`00${f[1]}`.slice(-2)}-${`00${f[2]}`.slice(-2)}`;
    }
    if (lang === 'es') {
        fecha = `${f[2]}-${`00${f[1]}`.slice(-2)}-${`00${f[0]}`.slice(-2)}`;
    }
    if (lang === 'es-en') {
        fecha = new Date(`${`${Number(f[2])}`}-${`00${Number(f[1])}`.slice(-2)}-${`00${Number(f[0]) + 1}`.slice(-2)}`);
    }
    return fecha;
}

export const stringToDate = (_date, _format, _delimiter) => {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex], 10);
    month -= 1;
    const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
};

export function findData(allD, oneD, tipo) {
    let AllDa = [];
    switch (tipo) {
        case 'id':
            AllDa = allD.filter((item) => item.id === oneD.id);
            break;
        case 'nombreCompleto':
            AllDa = allD.filter((item) => item.nombreCompleto.toLowerCase() === oneD.nombreCompleto.toLowerCase());
            break;
        case 'fondo':
            AllDa = allD.filter((item) => String(item.fondo) === String(oneD.fondo));
            break;
        case 'tipoAntecedente':
            AllDa = allD.filter((item) => item.idTipoAntecedente === oneD.tipoAntecedente);
            break;
        case 'deporte':
            AllDa = allD.filter((item) => item.idDeporte === oneD.deporte);
            break;
        default:
            AllDa = [];
            break;
    }
    if (AllDa.length > 0) {
        AllDa = AllDa[0];
    }
    return AllDa;
}

export function obtenerFechaActual() {
    const fechaActual = new Date();
    const año = fechaActual.getFullYear();
    const mes = fechaActual.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que sumamos 1.
    const dia = fechaActual.getDate();

    const fechaFormateada = `${año}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;
    return fechaFormateada;
}

export default {
    formatDate,
    stringToDate,
    findData,
    obtenerFechaActual
};
