import { useContext } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Add } from '@mui/icons-material';
import Select from 'react-select';

const Potencia = () => {
    const { oneDataEvaluacionBiomecanica, StoreUpdatePotencia, allDataPotencia, allDataTipoPotencia } = useContext(ContentContext);
    const columns2 = [
        {
            name: 'Tipo Potencia',
            selector: (row) => row.tipoPotencia,
            width: '250px'
        },
        {
            name: 'Datos de Referencia',
            selector: (row) => row.referencia
        },
        {
            name: 'Valor',
            selector: (row) => row.valor
        }
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            valor: data.valor,
            tipoPotencia: data.tipoPotencia.value,
            evaluacionBiomecanica: oneDataEvaluacionBiomecanica.id,
            id: null
        };
        StoreUpdatePotencia(jsData);
        setValue('valor', '');
        reset();
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                Resultados de Potencia
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel htmlFor="tipoPotencia" shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo de Potencia
                            </InputLabel>
                            <Controller
                                control={control}
                                name="tipoPotencia"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        options={allDataTipoPotencia}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoPotencia ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoPotencia && errors.tipoPotencia.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.valor}
                                {...register('valor', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Resultado"
                                InputLabelProps={activeLabel}
                                helperText={!!errors.valor && errors.valor.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns2} data={allDataPotencia} />
            </Box>
        </Box>
    );
};

export default Potencia;
