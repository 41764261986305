import { useContext, useState } from 'react';
import {
    Box,
    Grid,
    FormControl,
    InputLabel,
    Typography,
    Tabs,
    Tab,
    FormHelperText,
    CircularProgress,
    Button,
    OutlinedInput,
    IconButton,
    InputAdornment
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { TabPanel } from 'utils/components/tabPanel/index';
import { NotificationContext } from 'utils/components/snackBar/context';
import { ContentContext } from './context';
import { useForm } from 'react-hook-form';
import { PostRoute } from 'services/Private';
import { createTheme } from '@mui/material/styles';
import FotoPerfil from '../../assets/pdf/img.png';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const theme = createTheme();

export default function KeepMountedModal() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange'
    });
    const { Notification } = useContext(NotificationContext);
    const pass1 = watch('password1');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { oneData, oneDataAtleta } = useContext(ContentContext);
    const [value, setTabs] = useState(0);
    const onSubmit = async (data) => {
        await setLoading(true);
        const response = await PostRoute(`NotificacionCorreo/update-password`, data);
        Notification(response.message, response.value !== 1 ? 0 : 1);
        if (response.value === 1) {
            reset();
        }
        await setLoading(false);
    };
    const handleChange = (event, newValue) => {
        setTabs(newValue);
    };

    const passwordValidation = (value) => {
        // Aquí puedes agregar tu lógica de validación de contraseñas
        // Por ejemplo, asegurarte de que la contraseña tenga al menos 8 caracteres y contenga al menos un número y una letra mayúscula
        if (value.length < 8) {
            return 'La contraseña debe tener al menos 8 caracteres';
        }
        if (!/\d/.test(value)) {
            return 'La contraseña debe contener al menos un número';
        }
        if (!/[A-Z]/.test(value)) {
            return 'La contraseña debe contener al menos una letra mayúscula';
        }
        return true;
    };

    const comparePass = (value) => {
        if (value === pass1) {
            return true;
        }
        return 'las contraseñas no coinciden';
    };

    return (
        <Box sx={{ mt: 1 }} spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={3} sx={{ justifyContent: 'center', width: '100%' }}>
                    <div
                        className="kt-avatar kt-avatar--outline"
                        id="kt_user_avatar"
                        style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <div
                            id="logo-size"
                            className="kt-avatar__holder"
                            style={{ backgroundImage: `url('${oneData?.foto || FotoPerfil}')` }}
                        />
                        <div className="py-3 flex-shrink-1">{oneData?.nombreCompleto}</div>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="tabs config">
                            <Tab label="Datos Personales" {...a11yProps(0)} />
                            <Tab label="Actualizar Contraseña" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Nombres
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.nombres}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Apellidos
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.apellidos}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Fecha de Nacimiento
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.fechaNacimiento}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        DPI
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.dpi}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Pasaporte
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.pasaporte || 'N/A'}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Edad
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.edad} Años
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Genero
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.genero}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Estado Civil
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.estadoCivil || 'N/A'}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Dirección
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.direccion}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Profesión
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.profesion}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel htmlFor="disciplina" shrink>
                                        Grado Academico
                                    </InputLabel>
                                    <Typography align="left" sx={{ mt: 2 }}>
                                        {oneDataAtleta?.gradoAcademico}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(!!errors.password0 && errors.password0.message)}
                                        sx={{ ...theme.typography.customInput, mt: 3 }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password-login-a">Contraseña Anterior</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password-login-a"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            type={showPassword ? 'text' : 'password'}
                                            label="Contraseña"
                                            {...register('password0', {
                                                required: { value: true, message: 'Este campo es requerido' }
                                            })}
                                        />
                                        <FormHelperText error id="standard-weight-helper-text-password-login-a">
                                            {!!errors.password0 && errors.password0.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(!!errors.password1 && errors.password1.message)}
                                        sx={{ ...theme.typography.customInput, mt: 3 }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password-login">Nueva Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password-login"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            type={showPassword ? 'text' : 'password'}
                                            label="Contraseña"
                                            {...register('password1', {
                                                required: { value: true, message: 'Este campo es requerido' },
                                                validate: passwordValidation
                                            })}
                                        />
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {!!errors.password1 && errors.password1.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(!!errors.password && errors.password.message)}
                                        sx={{ ...theme.typography.customInput, mt: 3 }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password-login-r">Repetir Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password-login-r"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            type={showPassword ? 'text' : 'password'}
                                            label="Contraseña"
                                            {...register('password', {
                                                required: { value: true, message: 'Este campo es requerido' },
                                                validate: comparePass
                                            })}
                                        />
                                        <FormHelperText error id="standard-weight-helper-text-password-login-r">
                                            {!!errors.password && errors.password.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={loading}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Restablecer
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: '#FFF',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px'
                                                }}
                                            />
                                        )}
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                </Grid>
            </Grid>
        </Box>
    );
}
