/* eslint-disable react/prop-types */
import { IconButton, Box, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import BootstrapTooltip from 'utils/components/tooltip/index';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ModalPdf from 'ui-component/templatePdf/visor';
import { generatePDF } from './templatePdf';
import { UserContext } from 'utils/context/userContext';
import { PostRoute } from '../../../../../services/Private';
import { ContentContext } from '../../context';

const ExportPDF = ({ data, nameFile }) => {
    const { userAuth } = useContext(UserContext);
    const { code } = useContext(ContentContext);
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [pdf, setPdf] = useState([]);
    let response = [];
    let response2 = [];
    let tipo = 0;

    const OneNutricionCineantropometria = async () => {
        if (data.row) {
            response = await PostRoute(`carrera/all-carrera`, { evaluacionBiomecanica: data.row.id });
            response2 = await PostRoute(`potencia/all-potencia`, { evaluacionBiomecanica: data.row.id });
            tipo = 1;
        } else {
            response = await PostRoute(`carrera/all-carrera-historico`, { persona: code });
            response2 = await PostRoute(`potencia/all-potencia-historico`, { persona: code });
            tipo = 0;
        }
        return { carrera: response, potencia: response2 };
    };

    const getHeader = () => {
        const Head = [
            {
                content: 'POTENCIA',
                colSpan: tipo === 0 ? 4 : 3,
                styles: {
                    halign: 'center',
                    fontStyle: 'bold',
                    fontSize: 14,
                    fillColor: null,
                    cellPadding: {
                        top: 25,
                        bottom: 25
                    }
                }
            },
            '',
            ''
        ];
        const header = [
            { content: 'EVALUACIÓN.', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } },
            { content: 'DATOS DE REFERENCIA', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } },
            { content: 'VALOR', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } }
        ];

        if (tipo === 0) {
            header.unshift({ content: 'FECHA.', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } });
        }

        return [Head, header];
    };

    const showModal = async () => {
        const response = await OneNutricionCineantropometria(data);
        const head = [
            { content: 'EVALUACIÓN.', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } },
            { content: 'DATOS DE REFERENCIA', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } },
            { content: 'VALOR', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } }
        ];

        if (tipo === 0) {
            head.unshift({ content: 'FECHA.', styles: { halign: 'center', fontStyle: 'bold', fillColor: null } });
        }
        const columns = [head];
        // Combina los items iniciales con la respuesta sin mutar el objeto original
        const cineantropometria = [...response.carrera].map((item) =>
            tipo === 0 ? [item.fecha, item.tipoCarrera, item.referencia, item.valor] : [item.tipoCarrera, item.referencia, item.valor]
        );
        const header = getHeader();
        const cicloCompetencia = response.potencia.map((item) =>
            tipo === 0 ? [item.fecha, item.tipoPotencia, item.referencia, item.valor] : [item.tipoPotencia, item.referencia, item.valor]
        );

        // Combina Cineantropometría, header y cicloCompetencia
        const combinedData = [...cineantropometria, ...header, ...cicloCompetencia];

        const doc = await generatePDF(
            combinedData,
            columns,
            'EVALUACIÓN BIOMECÁNICA',
            tipo === 0 ? 'COMPARATIVO' : '',
            nameFile,
            data,
            userAuth.nombreCompleto,
            tipo
        );

        setPdf(doc);
        setShow(true);
    };

    return (
        <Box>
            <BootstrapTooltip title="Exportar PDF" arrow placement="left">
                <IconButton
                    onClick={showModal}
                    aria-label="Exportar"
                    sx={{
                        alignSelf: 'center',
                        background: theme.palette.error.dark,
                        color: '#FFF',
                        '&:hover': {
                            background: theme.palette.error.light,
                            color: theme.palette.error.dark
                        }
                    }}
                >
                    <PictureAsPdf fontSize="inherit" />
                </IconButton>
            </BootstrapTooltip>
            <ModalPdf showPdf={show} setShowPdf={setShow} title="Visualizar" pdf={pdf} />
        </Box>
    );
};

export default ExportPDF;
