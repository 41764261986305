import { useContext } from 'react';
import { Modal, Box, Button, FormControl, Grid, Avatar, Typography } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import MainCard from 'utils/components/modal/MainCardModal';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { Save, Add } from '@mui/icons-material';
import { ContentContext } from './context';
import List from './listFda';
import Select from 'react-select';

export default function KeepMountedModal() {
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm();
    const { show, toggleModal, oneData, StoreUpdate, allDataFederacion } = useContext(ContentContext);
    const theme = useTheme();
    const onSubmit = (data) => {
        const jsData = {
            persona: oneData.id,
            deporte: data.fadn.value
        };
        StoreUpdate(jsData);
        reset();
    };

    return (
        <Box>
            <Modal
                keepMounted
                open={show}
                onClose={() => toggleModal(0)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }} spacing={2}>
                    <MainCard title="Asignar Federación" closeButton={() => toggleModal(0)} size="xl">
                        <Grid container spacing={2}>
                            <Grid item lg={3} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} align="center">
                                        <Avatar
                                            alt="Remy Sharp"
                                            variant="square"
                                            src={oneData.foto}
                                            sx={{ width: 200, height: 200, border: '1px solid #CCC' }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Typography align="center" className="text-muted">
                                            {oneData.nombreCompleto}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={9} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={10} md={10} sm={10} xs={12}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <Controller
                                                control={control}
                                                name="fadn"
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        isSearchable
                                                        options={allDataFederacion}
                                                        placeholder="Seleccione FADN"
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={!errors.fadn ? customStyles : customStylesDanger}
                                                    />
                                                )}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }}
                                            />
                                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                                {!!errors.fadn && errors.fadn.message}
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <AnimateButton>
                                            <BootstrapTooltip title="Agregar" arrow placement="left">
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        alignSelf: 'center',
                                                        background: theme.palette.primary.dark,
                                                        color: '#FFF',
                                                        '&:hover': {
                                                            background: theme.palette.primary.light,
                                                            color: theme.palette.primary.dark
                                                        }
                                                    }}
                                                >
                                                    <Add fontSize="medium" />
                                                </Button>
                                            </BootstrapTooltip>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xs={12}>
                                        <List />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Guardar" arrow placement="left">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.success.dark,
                                            '&:hover': {
                                                background: theme.palette.success.light
                                            }
                                        }}
                                    >
                                        <Save fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                    </MainCard>
                </Box>
            </Modal>
        </Box>
    );
}
