/* eslint-disable import/named */
import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { TabPanel } from 'utils/components/tabPanel/index';
import ConfigInstitucion from './components/configInstitucion';
import ConfigCorreo from './components/configCorrero';
import ConfigLogin from './components/configLogin';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs config">
                    <Tab label="Intitución" {...a11yProps(0)} />
                    <Tab label="Correo" {...a11yProps(1)} />
                    <Tab label="Inicio de Sesión" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ConfigInstitucion />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ConfigCorreo />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ConfigLogin />
            </TabPanel>
        </Box>
    );
}
