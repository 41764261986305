import { useContext, useState } from 'react';
import { CustomText } from 'utils/components/customTable/index';
import { Grid, TextField, Container, IconButton, Avatar } from '@mui/material';
import ExportPDF from './export/exportPdf';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';

const List = () => {
    const { One, allData } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase()) || item.codigo.startsWith(value);
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase()) || item.codigo.includes(value);
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const columns = [
        {
            name: 'Foto',
            sortable: true,
            center: true,
            selector: (row) => <Avatar alt={row.nombreCompleto} src={row.foto} />
        },
        {
            name: 'Profesional',
            sortable: true,
            center: true,
            selector: (row) => CustomText(row.nombreCompleto)
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <IconButton color="primary" aria-label="Asignar Federación" onClick={() => One(row, 3)}>
                    <PlaylistAddCheckIcon />
                </IconButton>
            )
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={2}>
                    <ExportPDF nameFile="Profesional" data={searchValue.length ? filteredData : allData} />
                </Grid>
                <Grid item xs={5}>
                    <TextField label="Buscar" sx={{ width: '100%' }} size="small" value={searchValue} onChange={handleFilter} />
                </Grid>
            </Grid>

            <DataTable
                noHeader
                highlightOnHover
                pagination
                theme="solarized"
                columns={columns}
                data={searchValue.length ? filteredData : allData}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por pagina',
                    rangeSeparatorText: 'de'
                }}
                noDataComponent="No se encontraron registros"
            />
        </Container>
    );
};

export default List;
