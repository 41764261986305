import { useContext, useState, useMemo } from 'react';
import CardMedica from '../../../utils/components/cards/cardMedica';
import { Box, TextField, Container, Grid, Typography } from '@mui/material';
import Pagination from '../../../utils/Pagination/Pagination';
import { ContentContext } from './context';

const PageSize = 8;

const List = () => {
    const { One, allData, Actions, LlamarAtleta, AtenderAtleta } = useContext(ContentContext);
    const [searchValue, setSearchValue] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const handleFilter = (e) => {
        const value = String(e.target.value);
        let updatedData = [];
        setSearchValue(value);
        if (value.length) {
            updatedData = allData.filter((item) => {
                const startsWith = item.nombre.toLowerCase().startsWith(value.toLowerCase());
                const includes = item.nombre.toLowerCase().includes(value.toLowerCase());
                if (startsWith) {
                    return startsWith;
                }
                if (!startsWith && includes) {
                    return includes;
                }
                return null;
            });
            setFilteredData(updatedData);
            setSearchValue(value);
        }
    };
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return (searchValue.length ? filteredData : allData).slice(firstPageIndex, lastPageIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, allData, filteredData, searchValue]);

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', mb: 3 }}>
                <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                    <Grid item xs={4} align="center">
                        <TextField label="Buscar" size="small" value={searchValue} onChange={handleFilter} sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={4}>
                        {/* dasds */}
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1} sx={{ mt: 1 }}>
                {currentTableData.map((item, index) => (
                    <Grid key={index} item lg={4} md={4} sm={6} xs={12} sx={{ mt: 2 }}>
                        <CardMedica
                            key={index}
                            item={item}
                            Actions={Actions}
                            One={One}
                            LlamarAtleta={LlamarAtleta}
                            AtenderAtleta={AtenderAtleta}
                        />
                    </Grid>
                ))}
                {currentTableData.length === 0 && (
                    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                        <Grid item xs={12} align="center">
                            <Typography variant="h5">
                                --------------------------------- No se encontraron datos ---------------------------------
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 10 }}>
                    <Pagination
                        className="pagination-bar justify-content-center"
                        currentPage={currentPage}
                        totalCount={allData.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default List;
