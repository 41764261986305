import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, IconButton, Stack } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import { findData } from 'utils/function/global';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import DataTable from 'react-data-table-component';
import { Add, Edit, Block } from '@mui/icons-material';
import Select from 'react-select';

const DatosFamiliares = () => {
    const { allDataAntecedentes, allDataTipoAntecedente, StoreUpdateAntecedente, Notification } = useContext(ContentContext);
    const theme = useTheme();
    const [oneData, setOneData] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset
    } = useForm();
    const activeLabel = { shrink: true };
    const Cancel = () => {
        reset();
        setValue('descripcion', '');
        setOneData([]);
    };
    const onSubmit = (data) => {
        const jsData = {
            tipoAntecedente: data.tipoAntecedente.value,
            descripcion: data.descripcion,
            id: oneData.length !== 0 ? oneData.id : null
        };
        if (findData(allDataAntecedentes, jsData, 'tipoAntecedente').length === 0) {
            StoreUpdateAntecedente(jsData);
            reset();
        } else if (oneData.id) {
            StoreUpdateAntecedente(jsData);
            Cancel();
        } else {
            Notification('El tipo de antecedente ha sido registrado previamente en nuestro sistema', 0);
        }
    };

    const One = (data) => {
        setOneData(data);
    };

    const setData = () => {
        setValue('tipoAntecedente', { label: oneData.tipoAntecedente, value: oneData.idTipoAntecedente });
        setValue('descripcion', oneData.descripcion);
    };

    const columns = [
        {
            name: 'Antecedente',
            width: '250px',
            selector: (row) => row.tipoAntecedente
        },
        {
            name: 'Observación',
            width: '450px',
            selector: (row) => row.descripcion,
            cell: (row) => <div style={{ whiteSpace: 'normal' }}>{row.descripcion}</div>
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => One(row)}>
                        <Edit />
                    </IconButton>
                </Stack>
            )
        }
    ];

    useEffect(() => {
        if (oneData.length !== 0) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneData]);
    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 2 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                ANTECEDENTES
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel shrink sx={{ background: '#FFF', px: 1 }}>
                                Tipo Antecedente
                            </InputLabel>
                            <Controller
                                control={control}
                                name="tipoAntecedente"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable
                                        // isDisabled={changeState}
                                        options={allDataTipoAntecedente}
                                        placeholder="Seleccione una opción"
                                        noOptionsMessage={() => 'sin resultados'}
                                        styles={!errors.tipoAntecedente ? customStyles : customStylesDanger}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    }
                                }}
                            />
                            <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                {!!errors.tipoAntecedente && errors.tipoAntecedente.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.descripcion}
                                // InputLabelProps={activeLabel}
                                {...register('descripcion', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="descripcion"
                                label="Observaciones"
                                multiline
                                rows={4}
                                InputLabelProps={activeLabel}
                                helperText={!!errors.descripcion && errors.descripcion.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                        </Box>
                        {oneData?.id && (
                            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Cancelar" arrow placement="left">
                                        <Button
                                            size="medium"
                                            type="button"
                                            variant="contained"
                                            onClick={() => Cancel()}
                                            sx={{
                                                background: theme.palette.error.main,
                                                '&:hover': {
                                                    background: theme.palette.error.dark
                                                },
                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                            }}
                                        >
                                            <Block fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns} data={allDataAntecedentes} />
            </Box>
        </Box>
    );
};

export default DatosFamiliares;
