import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';
import { InputLabel } from '@mui/material';

const CustomLabel = ({ label, size, required, bolder }) => (
    <div>
        {required && <CircleIcon className="circle-required" />}
        <span style={{ fontSize: size || 16, fontWeight: bolder ? 'bolder' : 'normal' }}>{label}</span>
    </div>
);

CustomLabel.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.number,
    required: PropTypes.bool,
    bolder: PropTypes?.bool
};

const CustomLabelSelect = ({ label, size, required, bolder }) => (
    <InputLabel
        htmlFor="estadoCivil"
        shrink
        sx={{ background: '#FFF', px: 1, fontSize: size || 16, fontWeight: bolder ? 'bolder' : 'normal' }}
    >
        {required && <CircleIcon className="circle-required" />}
        {label}
    </InputLabel>
);

CustomLabelSelect.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.number,
    required: PropTypes.bool,
    bolder: PropTypes?.bool
};

export { CustomLabel, CustomLabelSelect };
