import { useContext, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, IconButton, Stack } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../context';
import { Add, Edit, Block } from '@mui/icons-material';

const Index = () => {
    const {
        StoreUpdatePruebasAplicadas,
        oneDataFichaPsicologica,
        allDataPruebasAplicadas,
        OnePruebasAplicadas,
        oneDataPruebasPsicologicasAplicadas,
        setOneDataPruebasPsicologicasAplicadas
    } = useContext(ContentContext);
    const columns2 = [
        {
            name: 'pruebaAplicada',
            selector: (row) => row.pruebaAplicada
        },
        {
            name: 'Acciones',
            sortable: true,
            center: true,
            selector: (row) => (
                <Stack key={row.id} direction="row" spacing={2}>
                    <IconButton aria-label="actualizar" size="large" onClick={() => OnePruebasAplicadas(row)}>
                        <Edit />
                    </IconButton>
                </Stack>
            )
        }
    ];
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const activeLabel = { shrink: true };
    const theme = useTheme();

    const Clear = () => {
        setOneDataPruebasPsicologicasAplicadas([]);
        setValue('pruebaAplicada', '');
    };

    const onSubmit = (data) => {
        const jsData = {
            pruebaAplicada: data.pruebaAplicada,
            fichaPsicologica: oneDataFichaPsicologica.id,
            id: oneDataPruebasPsicologicasAplicadas?.id || null
        };
        StoreUpdatePruebasAplicadas(jsData);
        Clear();
    };

    useEffect(() => {
        if (oneDataPruebasPsicologicasAplicadas.length !== 0) {
            setValue('pruebaAplicada', oneDataPruebasPsicologicasAplicadas.pruebaAplicada);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneDataPruebasPsicologicasAplicadas]);

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
            <Typography sx={{ mb: 2 }} variant="h4">
                PRUEBAS PSICOLÓGICAS APLICADAS
            </Typography>
            <Box>
                <Grid container>
                    <Grid item xs={10}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                error={!!errors.pruebaAplicada}
                                {...register('pruebaAplicada', { required: { value: true, message: 'Este campo es requerido' } })}
                                size="small"
                                id="outlined-error-helper-text"
                                label="Prueba"
                                InputLabelProps={activeLabel}
                                multiline
                                rows={4}
                                helperText={!!errors.pruebaAplicada && errors.pruebaAplicada.message}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                            <AnimateButton>
                                <BootstrapTooltip title="Agregar" arrow placement="left">
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            background: theme.palette.primary.main,
                                            '&:hover': {
                                                background: theme.palette.primary.dark
                                            },
                                            minWidth: '32px', // Cambia el ancho mínimo del botón
                                            maxWidth: '48px' // Cambia el ancho máximo del botón
                                        }}
                                    >
                                        <Add fontSize="medium" />
                                    </Button>
                                </BootstrapTooltip>
                            </AnimateButton>
                            {oneDataPruebasPsicologicasAplicadas.id && (
                                <AnimateButton>
                                    <BootstrapTooltip title="Cancelar" arrow placement="left">
                                        <Button
                                            size="medium"
                                            type="button"
                                            onClick={() => Clear()}
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.error.main,
                                                '&:hover': {
                                                    background: theme.palette.error.dark
                                                },
                                                ml: 2,
                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                            }}
                                        >
                                            <Block fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DataTable noDataComponent="No hay registros para mostrar." columns={columns2} data={allDataPruebasAplicadas} />
            </Box>
        </Box>
    );
};

export default Index;
