/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, createContext, useContext } from 'react';
import { GetRoute, PostRoute } from '../../../services/Private';
import { NotificationContext } from 'utils/components/snackBar/context';
import { LoadingContext } from 'utils/components/loading/context';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const { Notification } = useContext(NotificationContext);
    const [empleados, setEmpleados] = useState([]);
    const [empleado, setEmpleado] = useState([]);
    const [misRoles, setMisRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolUsuario, setRolUsuario] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [showRoles, setShowRoles] = useState(false);
    const { setShowLoad } = useContext(LoadingContext);
    const nameController = 'usuario';
    const GetPersonas = async () => {
        const response = await GetRoute(`persona/label`);
        setEmpleados(response);
    };
    const getRoles = async () => {
        const response = await GetRoute(`rol/all`);
        setRoles(response);
    };
    const getRolUsuario = async (data) => {
        setShowLoad(true);
        setShowRoles(false);
        setMisRoles([]);
        const response = await PostRoute(`${nameController}/get-roles`, { id: data.persona });
        setEmpleado(data);
        setDataUser(response[0] ? response[0] : []);
        setRolUsuario(response[0] && response[0].roles !== null ? JSON.parse(response[0].roles) : []);
        setMisRoles(response[0] && response[0].roles !== null ? JSON.parse(response[0].roles) : []);
        setShowRoles(true);
        setShowLoad(false);
    };
    const sendRolesUsuario = async (data, tipo, item) => {
        setShowLoad(true);
        const response = await PostRoute(`usuario/asignar-rol`, {
            id: empleado.usuario,
            username: empleado.usuario,
            persona: empleado.persona,
            rol: item.id,
            estado: item.estado,
            roles: JSON.stringify(data)
        });
        setRolUsuario(misRoles);
        setShowLoad(false);
        if (tipo) {
            Notification(response.message, 1);
        } else {
            Notification(response.message, 2);
        }
    };
    const defaultPassword = async () => {
        setShowLoad(true);
        const response = await PostRoute(`NotificacionCorreo/reset-password`, { id: empleado.usuario });
        setRolUsuario(response.roles ? JSON.parse(response.roles) : []);
        Notification(response.message, 1);
        setShowLoad(false);
    };
    const sendCredencialesUsuario = async () => {
        setShowLoad(true);
        const response = await PostRoute(`NotificacionCorreo/credential-notify`, { id: empleado.usuario });
        setRolUsuario(response.roles ? JSON.parse(response.roles) : []);
        Notification(response.message, 1);
        setShowLoad(false);
    };

    const value = {
        empleados,
        empleado,
        misRoles,
        roles,
        rolUsuario,
        dataUser,
        showRoles,
        setShowLoad,
        setMisRoles,
        setShowRoles,
        getRolUsuario,
        sendRolesUsuario,
        defaultPassword,
        sendCredencialesUsuario
    };
    useEffect(() => {
        GetPersonas();
        getRoles();
    }, []);
    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
