/* eslint-disable react/prop-types */
import { createContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState(false);
    const [option, setOption] = useState(false);
    const Notification = (message, option) => {
        setShow(true);
        setOption(option);
        setMessage(message);
    };
    const value = {
        show,
        option,
        message,
        setShow,
        Notification
    };
    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
