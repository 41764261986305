/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, InputAdornment } from '@mui/material';
import { customStyles, customStylesDanger } from 'utils/select/index';
import AnimateButton from 'ui-component/extended/AnimateButton';
import BootstrapTooltip from 'utils/components/tooltip/index';
import { useForm, Controller } from 'react-hook-form';
import LineChart from 'utils/charts/lineChart/lineChartAerobica1';
import BarcharVertical from 'utils/charts/barChartH/barchar';
import Barchar from 'utils/charts/barChartH/index';
import { useTheme } from '@mui/material/styles';
import { Save } from '@mui/icons-material';
import Select from 'react-select';
import FormDetalle from './formDetalle';
import Listado from './list';
import { ContentContext } from '../context';
import { useEffect, useContext, useState } from 'react';

const PruebaEsfuerzoMaximo = () => {
    const {
        allDataDetallePruebaFuerza,
        allDataTestWHeader,
        allDataTestWFooter,
        setAllDataTestWFooter,
        setAllDataTestWHeader,
        allDataTipoPruebaAerobica,
        StoreUpdatePruebaAerobica,
        setDataTipoPruebaAerobica,
        setDataFcBasal,
        user
    } = useContext(ContentContext);
    const theme = useTheme();
    const [zonasEntrenamiento, setZonasEntrenamiento] = useState([]);
    const [pesoKl, setPesoKl] = useState(0);
    const activeLabel = { shrink: true };
    let newArrayCercano = [];
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
        reset
    } = useForm();
    const FCM = watch('fcMax');
    const FCE = watch('fcE');
    const FCBasal = watch('fcBasal');
    const FCFactor = watch('fcFactor');
    const min1Frc = watch('min1Frc');
    const min2Frc = watch('min2Frc');
    const min3Frc = watch('min3Frc');
    const fcUman = watch('fcUman');
    const TipoPruebaAerobica = watch('tipoPruebaAerobica');
    const onSubmit = (data) => {
        const jsData = {
            ...data,
            tipoPruebaAerobica: data.tipoPruebaAerobica.value,
            detalle: allDataDetallePruebaFuerza
        };
        StoreUpdatePruebaAerobica(jsData);
        setAllDataTestWFooter([]);
        setAllDataTestWHeader([]);
        reset();
    };

    useEffect(() => {
        const result = 220 - user?.edad || 0;
        setPesoKl(Number(user?.peso || 0));
        setValue('fcMax', result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue('fc85', FCM * 0.85);
        setZonasEntrenamiento([
            parseInt(FCE * 0.95, 10),
            parseInt(FCE * 0.9, 10),
            parseInt(FCE * 0.8, 10),
            parseInt(FCE * 0.7, 10),
            parseInt(FCE * 0.6, 10)
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FCE]);

    useEffect(() => {
        if (Number(FCBasal) > 0) {
            setValue('fcFactor', Number(FCM) - Number(FCBasal));
            setDataFcBasal(FCBasal);
        } else {
            setValue('fcFactor', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FCBasal]);

    useEffect(() => {
        if (allDataDetallePruebaFuerza.length > 0) {
            newArrayCercano = [...allDataDetallePruebaFuerza];
        }
    }, [allDataDetallePruebaFuerza]);

    useEffect(() => {
        const getMaxValue = (data, opcion) => {
            if (!Array.isArray(data) || data.length === 0) return 0;
            let values;
            // opcion 1 valida por FC, opcion 2 valida por velocidad
            if (opcion === 1) {
                values = data.map((obj) => (obj && obj.val2 ? obj.val2 : 0));
            }
            if (opcion === 2) {
                values = data.map((obj) => (obj && obj.medicion ? obj.medicion : 0));
            }
            return Math.max(...values);
        };
        const valorCercanoFC = newArrayCercano.sort((a, b) => Math.abs(a.val2 - 174) - Math.abs(b.val2 - 174))[0]; // Ordena el array basado en la diferencia absoluta con 174 y toma el primer objeto

        const FCValorAlto = getMaxValue(allDataDetallePruebaFuerza, 1);
        const VelocidadAlta = getMaxValue(allDataDetallePruebaFuerza, 2);
        setValue('fcE', FCValorAlto);
        const resultVo2Max = VelocidadAlta > 0 ? (VelocidadAlta * 2.58 + 8) * 1.051 : 0;
        setValue('vo2Max', resultVo2Max.toFixed(2));
        const resultVo2 = resultVo2Max * pesoKl;
        setValue('vo2', resultVo2.toFixed(2));
        const resultPo2 = resultVo2 / FCValorAlto;
        setValue('po2', (Number.isNaN(resultPo2) ? 0 : resultPo2).toFixed(2));
        const resultMets = resultVo2Max / 3.5;
        setValue('mets', resultMets.toFixed(2));
        const resultPorcentajeFce = (FCE / FCM) * 100;
        setValue('porcentajeFce', (Number.isNaN(resultPorcentajeFce) ? 0 : resultPorcentajeFce).toFixed(2));
        const val1 = Number(valorCercanoFC?.medicion || 0) * 2.58;
        const val2 = val1 + 8;
        const resultUman = val2 * Number(pesoKl);
        setValue('uman', resultUman.toFixed(2));
        const resultPorcentajeUman = (resultUman / resultVo2) * 100;
        setValue('porcentajeUman', (Number.isNaN(resultPorcentajeUman) ? 0 : resultPorcentajeUman).toFixed(2));
        const resultFcUman = valorCercanoFC?.val2 > 0 ? valorCercanoFC?.val2 || 0 : 0;
        setValue('fcUman', Number(resultFcUman).toFixed(2));
        const resultTuman = valorCercanoFC?.tiempo > 0 ? valorCercanoFC?.tiempo || 0 : 0;
        setValue('tuman', resultTuman || 0);
        const resultTTotal = allDataDetallePruebaFuerza[allDataDetallePruebaFuerza.length - 1];
        setValue('ttotal', resultTTotal?.tiempo || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDataDetallePruebaFuerza]);

    useEffect(() => {
        if (min1Frc) {
            setValue('min1Porcentaje', Math.round(((Number(FCE) - Number(min1Frc)) / Number(FCFactor)) * 100));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min1Frc]);

    useEffect(() => {
        if (min2Frc) {
            setValue('min2Porcentaje', Math.round(((Number(FCE) - Number(min2Frc)) / Number(FCFactor)) * 100));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min2Frc]);

    useEffect(() => {
        if (min3Frc) {
            setValue('min3Porcentaje', Math.round(((Number(FCE) - Number(min3Frc)) / Number(FCFactor)) * 100));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min3Frc]);
    useEffect(() => {
        if (TipoPruebaAerobica?.value) {
            setDataTipoPruebaAerobica(TipoPruebaAerobica.value);
        } else {
            setDataTipoPruebaAerobica(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TipoPruebaAerobica]);

    return (
        <>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }} spacing={2}>
                <Box component="fieldset" border={1} borderColor="grey.400" p={1} my={2} borderRadius={4}>
                    <Typography component="legend" color="grey.900" fontWeight={700}>
                        Datos Generales
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <InputLabel htmlFor="tipoPruebaAerobica" shrink sx={{ background: '#FFF', px: 1 }}>
                                    Tipo de Prueba Aeróbica
                                </InputLabel>
                                <Controller
                                    control={control}
                                    name="tipoPruebaAerobica"
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            isSearchable
                                            options={allDataTipoPruebaAerobica}
                                            placeholder="Seleccione una opción"
                                            noOptionsMessage={() => 'sin resultados'}
                                            styles={!errors.tipoPruebaAerobica ? customStyles : customStylesDanger}
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido'
                                        }
                                    }}
                                />
                                <Typography sx={{ fontSize: 'small', ml: 2 }} className="text-danger">
                                    {!!errors.tipoPruebaAerobica && errors.tipoPruebaAerobica.message}
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcl}
                                    {...register('fcl', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcl"
                                    label="Protocolo"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fcl && errors.fcl.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <AnimateButton>
                                    <BootstrapTooltip title="Agregar" arrow placement="left">
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: theme.palette.success.main,
                                                '&:hover': {
                                                    background: theme.palette.success.dark
                                                },
                                                minWidth: '32px', // Cambia el ancho mínimo del botón
                                                maxWidth: '48px' // Cambia el ancho máximo del botón
                                            }}
                                        >
                                            <Save fontSize="medium" />
                                        </Button>
                                    </BootstrapTooltip>
                                </AnimateButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcMax}
                                    {...register('fcMax', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcMax"
                                    label="FC. Max."
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fcMax && errors.fcMax.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcE}
                                    {...register('fcE', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcE"
                                    label="FC. E."
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fcE && errors.fcE.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fc85}
                                    {...register('fc85', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fc85"
                                    label="FC. 85%."
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fc85 && errors.fc85.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcBasal}
                                    {...register('fcBasal', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcBasal"
                                    label="FC. Basal."
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fcBasal && errors.fcBasal.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcFactor}
                                    {...register('fcFactor', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcFactor"
                                    label="FC. Factor."
                                    InputLabelProps={activeLabel}
                                    multiline
                                    helperText={!!errors.fcFactor && errors.fcFactor.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                {/* datos footer */}
                <Box component="fieldset" border={1} borderColor="grey.400" p={1} my={2} borderRadius={4}>
                    <Typography component="legend" color="grey.900" fontWeight={700}>
                        Observaciones
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.vo2Max}
                                    {...register('vo2Max', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="vo2Max"
                                    label="Vo2 Max"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">ml/kg/min</InputAdornment>
                                    }}
                                    helperText={!!errors.vo2Max && errors.vo2Max.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.vo2}
                                    {...register('vo2', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="vo2"
                                    label="Vo2"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">ml oxigeno</InputAdornment>
                                    }}
                                    helperText={!!errors.vo2 && errors.vo2.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.po2}
                                    {...register('po2', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="po2"
                                    label="PO2"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">mmHg</InputAdornment>
                                    }}
                                    helperText={!!errors.po2 && errors.po2.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.mets}
                                    {...register('mets', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="mets"
                                    label="METS"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">METS</InputAdornment>
                                    }}
                                    helperText={!!errors.mets && errors.mets.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.porcentajeFce}
                                    {...register('porcentajeFce', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="porcentajeFce"
                                    label="%FCE"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    helperText={!!errors.porcentajeFce && errors.porcentajeFce.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.uman}
                                    {...register('uman', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="uman"
                                    label="UMAN"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">ml/min</InputAdornment>
                                    }}
                                    helperText={!!errors.uman && errors.uman.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.procentajeUman}
                                    {...register('porcentajeUman', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="porcentajeUman"
                                    label="% UMAN"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    helperText={!!errors.procentajeUman && errors.procentajeUman.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.fcUman}
                                    {...register('fcUman', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="fcUman"
                                    label="FC UMAN"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">lat/min</InputAdornment>
                                    }}
                                    helperText={!!errors.fcUman && errors.fcUman.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.TUMAN}
                                    {...register('tuman', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="tuman"
                                    label="TUMAN"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">min</InputAdornment>
                                    }}
                                    helperText={!!errors.TUMAN && errors.TUMAN.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <TextField
                                    error={!!errors.ttotal}
                                    {...register('ttotal', { required: { value: true, message: 'Este campo es requerido' } })}
                                    size="small"
                                    id="ttotal"
                                    label="TTotal"
                                    InputLabelProps={activeLabel}
                                    multiline
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">min</InputAdornment>
                                    }}
                                    helperText={!!errors.ttotal && errors.ttotal.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormDetalle />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="center" height="100%" textAlign="center">
                            <Typography variant="h5" component="span">
                                TEST MADDER
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Listado />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LineChart
                                    label={allDataTestWFooter}
                                    data={allDataTestWHeader}
                                    posicion={Number(fcUman || '0')}
                                    name="series-1"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" textAlign="center">
                                        <Typography variant="h5" component="span">
                                            ZONAS DE ENTRENAMIENTO
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Barchar zonasEntrenamiento={zonasEntrenamiento} name="series-1" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box component="fieldset" border={1} borderColor="grey.400" p={1} my={2} borderRadius={4}>
                                <Typography component="legend" color="grey.900" fontWeight={700}>
                                    Recuperación
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min1Frc}
                                                {...register('min1Frc', { required: { value: true, message: 'Este campo es requerido' } })}
                                                size="small"
                                                id="min1Frc"
                                                label="FCR 1"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">lat/min</InputAdornment>
                                                }}
                                                helperText={!!errors.min1Frc && errors.min1Frc.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min1Porcentaje}
                                                {...register('min1Porcentaje', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                size="small"
                                                id="min1Porcentaje"
                                                label="Porcentaje Recup. Min. 1"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                }}
                                                helperText={!!errors.min1Porcentaje && errors.min1Porcentaje.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min2Frc}
                                                {...register('min2Frc', { required: { value: true, message: 'Este campo es requerido' } })}
                                                size="small"
                                                id="min2Frc"
                                                label="FCR 2"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">lat/min</InputAdornment>
                                                }}
                                                helperText={!!errors.min2Frc && errors.min2Frc.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min2Porcentaje}
                                                {...register('min2Porcentaje', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                size="small"
                                                id="min2Porcentaje"
                                                label="Porcentaje Recup. Min. 2"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                }}
                                                helperText={!!errors.min2Porcentaje && errors.min2Porcentaje.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min3Frc}
                                                {...register('min3Frc', { required: { value: true, message: 'Este campo es requerido' } })}
                                                size="small"
                                                id="min3Frc"
                                                label="FCR 3"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">lat/min</InputAdornment>
                                                }}
                                                helperText={!!errors.min3Frc && errors.min3Frc.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <TextField
                                                error={!!errors.min3Porcentaje}
                                                {...register('min3Porcentaje', {
                                                    required: { value: true, message: 'Este campo es requerido' }
                                                })}
                                                size="small"
                                                id="min3Porcentaje"
                                                label="Porcentaje Recup. Min. 3"
                                                InputLabelProps={activeLabel}
                                                multiline
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                }}
                                                helperText={!!errors.min3Porcentaje && errors.min3Porcentaje.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <BarcharVertical recuperacion={[]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PruebaEsfuerzoMaximo;
